import { FulfillmentType } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class FulfillmentTypeMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [FulfillmentType.unset]: {
                label: "Not Set",
                value: FulfillmentType.unset,
                color: "neutral"
            },
            [FulfillmentType.fulfilledByJuniper]: {
                label: "Fulfilled by Juniper",
                value: FulfillmentType.fulfilledByJuniper,
                color: "neutral"
            },
            [FulfillmentType.fulfilledByCreator]: {
                label: "Fulfilled by Creator",
                value: FulfillmentType.fulfilledByCreator,
                color: "neutral"
            }
        };
    }
}