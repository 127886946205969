import React, { ChangeEvent, useEffect, useState } from "react";
import { TextInputField } from "evergreen-ui";

interface StytchOTPInputProps {
    disabled: boolean,
    error: boolean;
    onPasscodeUpdate: (valid: boolean, passcode: string) => void;
}

export const StytchOTPInput = ({error, disabled, onPasscodeUpdate}: StytchOTPInputProps) => {
    const [passcode, setPasscode] = useState<string>("");

    useEffect(() => {
        onPasscodeUpdate(isValidPasscode(), passcode);
    }, [passcode]);

    const isValidPasscode = (): boolean => {
        const regex = /^[0-9]{6}$/;
        return regex.test(passcode);
    };

    return (
        <TextInputField
            className={"jc-otp-input"}
            inputMode={"numeric"}
            pattern={"^[0-9]{0,6}$"}
            placeholder={"000000"}
            maxLength={6}
            isInvalid={error}
            disabled={disabled}
            value={passcode}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPasscode(e.target.value);
            }}
        />
    );
};
