import { Dialog, Pane } from "evergreen-ui";
import { NotificationOverridePreference, NotificationPreference } from "../../../../../common/models";
import { NotificationPreferenceItem } from "./NotificationPreferenceItem";

type Props = {
    notificationOverridePreference: NotificationOverridePreference;
    notificationOverridePreferences: NotificationOverridePreference[];
    title: string;
    isShown: boolean;
    onChange: (notificationOverridePreference: NotificationOverridePreference) => void;
    onConfirm: () => void;
    onCloseComplete: () => void;
}

export const ConversationPreferenceDialog = ({ 
    title, 
    isShown, 
    notificationOverridePreference,
    notificationOverridePreferences,
    onConfirm,
    onCloseComplete,
    onChange,
}: Props) => {
    const hasPreferenceChanged = () => {
        const initialPref = notificationOverridePreferences.find(
            (preference) => preference.overrideEntityId === notificationOverridePreference.overrideEntityId);
        return JSON.stringify(initialPref) === JSON.stringify(notificationOverridePreference);
    };

    return (
        <Dialog
            title={title}
            isShown={isShown}
            onConfirm={onConfirm}
            onCloseComplete={onCloseComplete}
            isConfirmDisabled={hasPreferenceChanged()}
            confirmLabel="Save"
        >
            {/* This is a bit ugly for styling, 
            but our default element has margin built in, here we remove it. */}
            <Pane marginLeft="-16px" marginTop="-24px">
                <NotificationPreferenceItem
                    notificationPreference={notificationOverridePreference}
                    onChange={(newNotifOverridePreference: NotificationPreference) => {
                        const newPreference = { ...newNotifOverridePreference, overrideEntityId: notificationOverridePreference.overrideEntityId };
                        onChange(newPreference);
                    }}
                    showChannelSelector={true}
                    showFrequencySelector={true}
                />
            </Pane>
        </Dialog>
    )
}