import {
    Dialog, Heading,
    Pane, SideSheet,
    Text,
    TextInputField, toaster
} from "evergreen-ui";
import * as Sentry from "@sentry/react";
import { useEffect, useRef, useState } from "react";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { useUserContext } from "../../context/UserContext";
import { addParticipant, removeParticipant } from "../../datastore/MessagesDataStore";
import { User as TwilioUser } from "@twilio/conversations";
import { isInternalEmail } from "../../../../common/utils/isInternalEmail";
import { PeopleContainer } from "./PeopleContainer";
import { NotificationPreferenceContainer } from "../../pages/MultiTabSettingsPage/components/NotificationPreferenceContainer";
import { getNotificationOverridePreference, updateNotificationOverridePreferences } from "../../datastore/NotificationPreferenceDataStore";
import { NotificationOverridePreference, NotificationPreference } from "../../../../common/models";
import { NotificationCategory } from "../../../../common/enums";
import { getDefaultNotificationPreference } from "../../../../common/notifications";

import './styles/ConversationSidebar.css';

export type Props = {
    isShown: boolean;
    isLoadingViewer: boolean;
    onClose: () => void;
    conversationSid: string;
    participants: TwilioUser[];
    conversationDescription: string | undefined;
    conversationTitle: string;
};

// TODO: We need to add a displayRole column in the User database that shows what role we should show in chat
// TODO: Add loading state for participants
export const ConversationSidebar = ({
    isShown,
    onClose,
    participants,
    conversationSid,
    isLoadingViewer,
    conversationDescription,
    conversationTitle
}: Props) => {
    const { user } = useUserContext();
    const [addDialogShown, setAddDialogShown] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const participantInputRef = useRef<HTMLInputElement>(null);
    const [notificationOverridePreference, setNotificationOverridePreference] = useState<NotificationOverridePreference>(
        { ...getDefaultNotificationPreference(NotificationCategory.ConversationMessage), overrideEntityId: conversationSid});

    useEffect(() => {
        setIsLoading(true);
        getNotificationOverridePreference(NotificationCategory.ConversationMessage, conversationSid).then((notificationPreference: NotificationOverridePreference) => {
            if (notificationPreference) {
                setNotificationOverridePreference(notificationPreference);
            } else {
                setNotificationOverridePreference(
                    { ...getDefaultNotificationPreference(NotificationCategory.ConversationMessage), overrideEntityId: conversationSid}
                );
            }
            setIsLoading(false);
        }).catch((error) => {
            Sentry.captureException(error);
            toaster.danger("Could not load new Conversation Notification Preferences.")
        });
    }, [conversationSid]);

    const renderSidebarTitle = () => {
        return (
            <Pane backgroundColor="white">
                <Pane paddingLeft={30} paddingTop={30}>
                    <Heading size={600} fontWeight={400}> {conversationTitle} </Heading>
                </Pane>
            </Pane>
        );
    };

    const renderDescription = () => {
        return conversationDescription !== undefined && (
            <Pane paddingLeft={30} paddingTop={16}>
                <Text fontSize={11}>
                    {conversationDescription}
                </Text>
            </Pane>
        );
    };

    const handleAddParticipant = async () => {
        if (participantInputRef && participantInputRef.current) {
            if (!isInternalEmail(participantInputRef.current.value)) {
                toaster.danger("Added user must be an internal @hellojuniper.com user.");
                participantInputRef.current.value = "";
                return;
            }
            setIsLoading(true);
            let participant = await addParticipant(participantInputRef.current.value, conversationSid)
                .catch((error) => {
                    setAddDialogShown(false);
                    toaster.danger(`Failed to add ${participantInputRef.current!.value}`)
                    console.error(error);
                })
            if (participant) {
                setAddDialogShown(false);
                toaster.success(`${participant.firstName} ${participant.lastName} has been added successfully.`)
            }
            setIsLoading(false);
        }
    };

    const handleRemoveParticipant = async ({ identity, friendlyName }: TwilioUser) => {
        if (!window.confirm(`Are you sure you want to remove ${friendlyName} from the conversation?`)) {
            return;
        }

        setIsLoading(true);
        let removedParticipant = await removeParticipant(identity, conversationSid)
            .catch((error) => {
                toaster.danger(`Failed to remove ${identity}.`)
                console.error(error);
            });
        if (removedParticipant) {
            toaster.success(`${friendlyName} has been removed successfully.`)
        }
        setIsLoading(false);
    };

    const onChange = (notificationOverridePreference: NotificationOverridePreference) => {
        updateNotificationOverridePreferences([notificationOverridePreference])
            .catch((error) => {
                Sentry.captureException(error);
                toaster.danger("Failed to update Conversation Notification Preferences.");
            });
    };

    return (
        <>
            <SideSheet
                isShown={isShown}
                onCloseComplete={onClose}
                width="300px"
            >
                {(isLoading || isLoadingViewer) ? (<CenteredSpinner size={28} />) :
                    <>
                        {renderSidebarTitle()}
                        {renderDescription()}
                        <PeopleContainer
                            user={user}
                            participants={participants}
                            onAddButtonClick={() => setAddDialogShown(true)}
                            handleRemoveParticipant={handleRemoveParticipant}
                        />
                        <NotificationPreferenceContainer
                            header={"Notifications"}
                            subheading={"These settings are conversation-specific."}
                            paneProps={{
                                paddingX: 15
                            }}
                            expandableProps={{
                                alignItems: "center",
                                cursor: "pointer",
                                display: "flex",
                                paddingX: 30,
                                paddingTop: 20
                            }}
                            notificationPreference={notificationOverridePreference}
                            onChange={(notificationOverridePreference: NotificationPreference) => {
                                setNotificationOverridePreference(notificationOverridePreference as NotificationOverridePreference);
                                onChange(notificationOverridePreference as NotificationOverridePreference);
                            }}
                        />
                    </>
                }
            </SideSheet>
            <Dialog
                isShown={addDialogShown}
                title="Add Participant"
                onCloseComplete={() => setAddDialogShown(false)}
                width={360}
                confirmLabel={"Add Participant"}
                onConfirm={() => handleAddParticipant()}
            >
                <TextInputField
                    ref={participantInputRef}
                    label="Please enter the participant's email and confirm."
                />
            </Dialog>
        </>
    );
}
