import { FC } from "react";
import { AddOn, AddOnGroup } from "../../../../common/models";
import { TileSelectorType } from "../../common/selector/TileSelector";
import { ProductWizardAddOnTileSelectorGroup } from "../ProductWizardAddOnTileSelectorGroup";

interface AddOnsPageProps {
    title: string;
    description: string;
    identifier: string;
    addOnGroups: AddOnGroup[];
    onSelect: (selectedItem: AddOn) => void;
    selectedIds: string[];
}

export const AddOnsPage: FC<AddOnsPageProps> = ({
    title,
    description,
    addOnGroups,
    onSelect,
    selectedIds
}) => {
    const renderAddOnTileSelectorGroup = () => {
        return addOnGroups.map((addOnGroup) => (
            <div className={"jc-wizard-selector-add-on"}
                 key={addOnGroup.displayName}
            >
                <ProductWizardAddOnTileSelectorGroup
                    isMultiselect={addOnGroup.isMultiselect}
                    key={addOnGroup.displayName}
                    items={[...addOnGroup.addOns].sort((a, b) => Number(b.isDefault) - Number(a.isDefault))}
                    groupIdentifier={addOnGroup.displayName}
                    selectedIds={selectedIds}
                    onSelect={onSelect}
                    tileSelectorType={TileSelectorType.basic}
                />
            </div>
        ));
    }

    return (
        <div className={"jc-wizard-container"}>
            <div className={"jc-wizard-header"}>
                <h1 className={"jc-wizard-title"}>
                    {title}
                </h1>
                <span className={"jc-wizard-description"}>
                    {description}
                </span>
            </div>
            {renderAddOnTileSelectorGroup()}
        </div>
    )
}
