import { Pane, RadioGroup } from "evergreen-ui";
import React, { ChangeEvent } from "react";
import { NotificationFrequency } from "../../../../../common/enums";
import { NotificationPreference } from "../../../../../common/models";
import { NotificationFrequencyDisplayOptions } from "../../../common/NotificationSettings";

import '../styles/MultiTabSettingsPage.css';

type Props = {
    title: string;
    notificationPreference: NotificationPreference;
    onChange: (notificationPreference: NotificationPreference) => void;
}

export const NotificationFrequencySelector = ({ title, notificationPreference, onChange }: Props) => {
    return (
        <Pane>
            <Pane className={"notification-preference-selector-heading"}>
                {title}
            </Pane>
            <Pane className={"notification-preference-selector-contents"}>
                <RadioGroup
                    value={notificationPreference.frequency}
                    options={NotificationFrequencyDisplayOptions[notificationPreference.category]}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onChange({
                            ...notificationPreference,
                            frequency: event.target.value as NotificationFrequency,
                        });
                    }}
                />
            </Pane>
        </Pane>
    )
};