import React from "react";
import { AbstractTableBodyCellRenderer } from "./AbstractTableBodyCellRenderer";
import { TableBodyCellRendererInput } from "../input";
import { TableInputCell } from "../../view/TableInputCell";

export class TableInputCellRenderer<T> extends AbstractTableBodyCellRenderer<T> {

    private readonly inputType:  "number" | "text";

    constructor(input: TableBodyCellRendererInput<T>, inputType: "number" | "text") {
        super(input);
        this.inputType = inputType;
    }

    protected getMemoizedComponent(): React.FunctionComponent {
        const { columnConfig, cellValue, onCellChanged, id } = this.input;
        return React.memo(() => {
            return (
                <TableInputCell
                    columnConfig={columnConfig}
                    id={id}
                    cellValue={cellValue}
                    inputType={this.inputType}
                    onCellChanged={onCellChanged}
                />
            )
        });
    }
}
