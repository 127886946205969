import { toHyphenCase } from "../../../common/utils";
import { TileSelector } from "../common/selector/TileSelector";
import { AddOnSelectorGroupProps } from "../common/selector/TileSelectorGroup";
import { AddOn } from "../../../common/models";
import { ChevronDownIcon, ChevronRightIcon, Pane } from "evergreen-ui";
import { useState } from "react";

import '../common/selector/styles/TileSelectorGroup.css';

export function ProductWizardAddOnTileSelectorGroup(props: AddOnSelectorGroupProps) {
    const { items = [], groupIdentifier = "", selectedIds, onSelect, tileSelectorType, isMultiselect } = props;
    const [expanded, setExpanded] = useState<boolean>(true);
    const getSubtitleText = () => isMultiselect ? "Select any (or none) of the following add-ons."
        : `Select 1 of the following ${groupIdentifier.toLowerCase()}.`;

    return (
        <>
            <Pane marginY={16} onClick={() => setExpanded(!expanded)}>
                {
                    expanded ?
                        <ChevronDownIcon paddingRight={6} /> :
                        <ChevronRightIcon paddingRight={6} />
                }
                <span className="add-on-tile-selector-group-title">
                    {groupIdentifier}
                </span>
            </Pane>
            {expanded &&
                <Pane marginBottom={24}>
                    <div className="add-on-tile-selector-group-subtitle"> {getSubtitleText()} </div>
                    <div className="basic-tile-selector-group-container">
                        {items.map((item: AddOn) => {
                            const { name, imageUrl, id, displayName, description, shortDescription, isDefault } = item;
                            return (
                                <TileSelector
                                    id={toHyphenCase(groupIdentifier, displayName || name)}
                                    key={id}
                                    isDefault={isDefault}
                                    selected={selectedIds!.includes(id)}
                                    name={displayName || name}
                                    description={description}
                                    shortDescription={shortDescription}
                                    imageUrl={imageUrl}
                                    onSelected={() => onSelect(item)}
                                    tileSelectorType={tileSelectorType}
                                />
                            );
                        })}
                    </div>
                </Pane>
            }
        </>
    );
}
