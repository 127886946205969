import { useCallback, useEffect, useRef } from "react";

interface CancellablePromise<T> {
    promise: Promise<T>;
    cancel: () => void;
};

const makePromiseCancellable = <T>(promise: Promise<T>): CancellablePromise<T> => {
    let isCancelled = false;
  
    // Only allow promise to continue if it hasn't yet been cancelled
    const wrappedPromise = new Promise<T>((resolve, reject) => {
        promise
            .then(val => !isCancelled && resolve(val))
            .catch(error => !isCancelled && reject(error));
    });
  
    return {
        promise: wrappedPromise,
        cancel: () => {
            isCancelled = true;
        }
    };
};

// This hook will keep track of all promises based in to it via its helper
// method `cancellablePromise()`, and cancels them (prevents them from
// continuing)when components using the hook get unmounted. This prevents
// async functions waiting on these promises to continue once their
// component unmounts, thus preventing the state of the unmounted
// component from being changed.
//
// References:
// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
// https://rajeshnaroth.medium.com/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
export const useCancellablePromise = () => {
    // Array of cancellable promises
    const cancellablePromises = useRef<CancellablePromise<unknown>[]>([]);

    useEffect(() => {
        return () => {
            cancellablePromises.current.forEach(p => p.cancel());
            cancellablePromises.current = [];
        };
    }, []);

    const addToCancellablePromises = useCallback(<T>(p: Promise<T>): Promise<T> => {
        const cPromise = makePromiseCancellable(p);
        cancellablePromises.current.push(cPromise);
        return cPromise.promise;
    }, []);

    return { addToCancellablePromises };
};