import * as Sentry from "@sentry/react";
import React, {
    createContext,
    PropsWithChildren,
    useCallback, useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { toaster } from "evergreen-ui";
import { FilterExpressionType, QueryInput } from "../../../../../common/query-filters";
import { FilterExpressionsByColumn, Sort, TableProviderAPI } from "../state/TableProviderAPI";
import { useSavedTableConfig } from "../state/SavedTableConfig";
import { TableConfig } from "../config/TableConfig";
import { ExtendableFilterExpression } from "../../../../../common/query-filters/FilterExpression";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";
import { TableCellFactory } from "../factory";

export const AbstractTableViewContext = createContext<TableProviderAPI<TableDataWithId, TableDataWithId>>(
    {} as TableProviderAPI<TableDataWithId, TableDataWithId>);

/**
 * This is a generic context hook that provides access to table related data and APIs.
 *
 * @template T The table's column definitions. This may include columns joined (flattened) from
 * other tables, such as `Store`, whose `name` and `id` can be joined onto the `ProductListing`
 * table.
 *
 * @template D The raw (GraphQL) data type, which may include nested fields that are not joined
 * (flattened) onto the top-level object. For example, a `ProductListing` GraphQL object has a
 * `store` field with nested `name` and `id` subfields.
 */
export function useTableViewContext<T extends TableDataWithId, D extends TableDataWithId>() {
    const context = useContext<TableProviderAPI<T, D>>(
        (AbstractTableViewContext as unknown) as React.Context<TableProviderAPI<T, D>>
    );
    if (!context) {
        throw new Error('useTableViewContext must be used under AbstractTableViewContext.Provider');
    }
    return context;
}

interface ProviderProps<T extends TableDataWithId, D extends TableDataWithId> {
    queryTableData: (input: QueryInput<T>) => Promise<D[]>,
    onUpdateRow: (
        prevData: D[],
        id: string | number,
        columnName: keyof T,
        value: string | number | boolean | null,
        label?: string,
    ) => D[];
    updateRowLoading: boolean,
    updateRowCalled: boolean,
    updateRowError: boolean,
    createRowLoading?: boolean,
    createRowCalled?: boolean,
    createRowError?: boolean,
    tableCellFactory: TableCellFactory<T, D>,
    savedTableConfigProps: {
        tableName: string;
        identifiers: string[];
        defaultConfigValue: TableConfig<T>;
    }
}

export function AbstractTableViewProvider<T extends TableDataWithId, D extends TableDataWithId>({
    queryTableData,
    onUpdateRow,
    updateRowLoading,
    updateRowCalled,
    updateRowError,
    createRowLoading = false,
    createRowCalled = false,
    createRowError = false,
    tableCellFactory,
    savedTableConfigProps,
    children,
}: PropsWithChildren<ProviderProps<T, D>>) {

    const [ tableConfig, setTableConfig ] = useSavedTableConfig(
        savedTableConfigProps.tableName,
        savedTableConfigProps.identifiers,
        savedTableConfigProps.defaultConfigValue
    );

    const [ data, setData ] = useState<D[]>([]);
    const [ hasMoreData, setHasMoreData ] = useState<boolean>(true);
    const [ initializingTable, setInitializingTable ] = useState<boolean>(true);
    const [ queryingFirstPage, setQueryingFirstPage ] = useState<boolean>(true);
    const [ paginating, setPaginating ] = useState<boolean>(false);
    const [ error, setError ] = useState<boolean>(false);

    const queryFirstPage = useCallback((tableConfig: TableConfig<T>) => {
        if (queryingFirstPage && !initializingTable) {
            // Normally, if `queryingFirstPage` is `true`, then that means this function is already
            // in-flight, and we should quick-return. However, the default value of
            // `queryingFirstPage` needs to be `true` when the context provider mounts because
            // the consuming table component must start in a "loading state" (and not an empty
            // state when `productListings` is an empty list). As a workaround, we make an
            // exception here with the one-time flag, `initializingTable`, which is `true` only when
            // the context provider mounts and has yet to call `queryFirstPage(..)` for the
            // first time. All subsequent calls of  `queryFirstPage(..)` (i.e. when filter
            // properties change) will be quick-returned under normal conditions if a request
            // is already in-flight.
            return;
        }

        setQueryingFirstPage(true);
        queryTableData(tableConfig.filters)
            .then((productListings: D[]) => {
                setData(productListings);
                setHasMoreData(productListings.length === tableConfig.filters.pageSize);
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                setError(true);
            })
            .finally(() => {
                setInitializingTable(false);
                setQueryingFirstPage(false);
            });
    }, [queryingFirstPage, initializingTable]);

    const queryNextPage = useCallback((): void => {
        if (paginating) {
            return;
        }

        setPaginating(true);
        queryTableData({
            ...tableConfig.filters,
            lastRecordId: data[data.length - 1].id,
        })
            .then((data: D[]) => {
                setData((prev: D[]) => {
                    return [...prev, ...data];
                });
                setHasMoreData(data.length === tableConfig.filters.pageSize);
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                setError(true);
            })
            .finally(() => {
                setPaginating(false);
            });
    }, [paginating, tableConfig.filters, data]);

    const setSort = useCallback((sort: Sort<T>) => {
        setTableConfig((prevTableConfig: TableConfig<T>) => {
            const newTableConfig = {
                ...prevTableConfig,
                filters: {
                    ...prevTableConfig.filters,
                    sortOrder: sort.order,
                    sortColumn: sort.column,
                },
            };

            queryFirstPage(newTableConfig);

            return newTableConfig;
        });
    }, [setTableConfig, queryFirstPage]);

    const setFilterExpressions = useCallback((
        columnName: keyof T,
        columnValue: T[keyof T][],
    ) => {
        setTableConfig((prevTableConfig: TableConfig<T>) => {
            const newTableConfig: TableConfig<T> = {
                ...prevTableConfig,
                filters: {
                    ...prevTableConfig.filters,
                    expressions: prevTableConfig.filters.expressions
                        // Remove the old expressions for the given column name.
                        .filter(expression => (expression.columnName !== columnName))
                        // Add the new (non-empty) expression for the given column name.
                        .concat(columnValue.length > 0 ? [{
                            columnName,
                            columnValue,
                            type: FilterExpressionType.equals,
                        }] : []),
                },
            };

            queryFirstPage(newTableConfig);

            return newTableConfig;
        });
    }, [setTableConfig, queryFirstPage]);

    const setPageSize = useCallback((pageSize: number) => {
        setTableConfig((tableConfig: TableConfig<T>) => {
            const newTableConfig = {
                ...tableConfig,
                filters: {
                    ...tableConfig.filters,
                    pageSize: pageSize,
                },
            };

            queryFirstPage(newTableConfig);

            return newTableConfig;
        });
    }, [setTableConfig, queryFirstPage]);

    const updateData = useCallback((
        id: string | number,
        columnName: keyof T,
        value: string | number | boolean | null,
        label?: string
    ): void => {
        setData((prevLoadedData: D[]) => {
            return onUpdateRow(prevLoadedData, id, columnName, value, label);
        });
    }, [onUpdateRow]);

    const allFilterExpressions: FilterExpressionsByColumn<T> = useMemo(() => {
        return tableConfig.filters.expressions.reduce<FilterExpressionsByColumn<T>>((
            output: FilterExpressionsByColumn<T>,
            expression: ExtendableFilterExpression<T>,
        ) => {
            return {
                ...output,
                [expression!.columnName]: [
                    ...(output[expression.columnName] as any || []),
                    expression,
                ],
            };
        }, {});
    }, [tableConfig.filters.expressions]);

    const sort: Sort<T> = useMemo(() => {
        return {
            column: tableConfig.filters.sortColumn,
            order: tableConfig.filters.sortOrder,
        };
    }, [tableConfig.filters.sortColumn, tableConfig.filters.sortOrder]);

    const api: TableProviderAPI<T, D> = {
        tableConfig: tableConfig,
        dataQuerying: queryingFirstPage,
        dataPaginating: paginating,
        dataError: error,
        data: data,
        updateRowLoading: updateRowLoading,
        updateRowCalled: updateRowCalled,
        updateRowError: updateRowError,
        updateRow: updateData,
        paginate: queryNextPage,
        hasMoreData: hasMoreData,
        setPageSize: setPageSize,
        setSort: setSort,
        sort: sort,
        setFilterExpressions: setFilterExpressions,
        allFilterExpressions: allFilterExpressions,
        tableCellFactory: tableCellFactory,
    };

    // This is strictly to initialize the table on mount, thus `queryFirstPage` and
    // `tableConfig` do not need to be in the dependency list. We can disable the React
    // warning and leave the list empty to avoid an infinite loop.
    // eslint-disable-next-line
    useEffect(() => {
        queryFirstPage(tableConfig);
    }, []);

    useEffect(() => {
        // The mutation either hasn't been called yet, or it's still loading.
        if (!createRowCalled || createRowLoading) {
            return;
        }

        // A new row failed to be created, so show an error message.
        if (createRowError) {
            toaster.danger("Failed to create a new row.");
            return;
        }

        // A new row was created successfully, so refresh the table.
        toaster.success("Successfully created a new row.");
        queryFirstPage(tableConfig);
    }, [createRowCalled, createRowLoading, createRowError]);

    return (
        <AbstractTableViewContext.Provider
            value={api as unknown as TableProviderAPI<TableDataWithId, TableDataWithId>}
        >
            { children }
        </AbstractTableViewContext.Provider>
    );
}
