import { ErrorIcon, Heading, Icon, LockIcon, Pane, Tab, TabNavigation } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LaunchType } from "../../../../common/enums";
import { TabStatus } from "../../../../common/enums/TabStatus";
import { ProductListing } from "../../../../common/models";
import { TimelineStatus } from "../../../../common/models/TimelineStatus";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { EmptyState } from "../../common/EmptyState";
import { removeJcContentContainerStylesEffect } from "../../common/removeJcContentContainerStylesEffect";
import { ColorPalette } from "../../context/Theme";
import { fetchProductListingsForStore } from "../../datastore/StoreDataStore";
import { useProductListingQuery, useTimelineStatusQuery } from "../../datastore/ProductListingDataStore";
import { BackNavigationButton } from "../../navigation/BackNavigationButton";
import { NavigationHelper } from "../../navigation/PrevLocationState";
import { isProductListingInDevelopment } from "../../product/Utils";
import { ProductSetupTab } from "./ProductSetupTab";
import { TimelineTab } from "./TimelineTab";
import { ProductStageBadge } from "./etc/ProductStageBadge";
import { TabInfo } from "../../common/multitab/TabInfo";

const resolveFirstTabIndex = (focusedTab: string): number => {
    switch (focusedTab) {
        case "setup":
            return 1;
        case "timeline":
        default:
            return 0;
    }
}

export const MultiTabProductPage = () => {

    const history = useHistory();
    const { productListingId, focusedTab } = useParams<{ productListingId: string, focusedTab: string }>();
    const [ selectedTabIndex, setSelectedTabIndex ] = useState<number>(resolveFirstTabIndex(focusedTab));
    const [ productListingsForStore, setProductListingsForStore ] = useState<ProductListing[]>([]);
    const [ bypassPreOrderRestriction, setBypassPreOrderRestriction ] = useState<boolean>(false);
    const [ isPreOrderRestricted, setIsPreOrderRestricted ] = useState<boolean>(false);
    const [ showSubmitDialogOverride, setShowSubmitDialogOverride ] = useState<boolean>(false);
    const [ redirectToMessagesOnRefresh, setRedirectToMessagesOnRefresh ] = useState<boolean>(false);

    const {
        loading: productLoading,
        error: productError,
        data: productData,
        refetch: productRefetch
    } = useProductListingQuery(productListingId);

    const {
        loading: timelineLoading,
        error: timelineError,
        data: timelineData,
        refetch: timelineRefetch
    } = useTimelineStatusQuery(productListingId);

    useEffect(() => removeJcContentContainerStylesEffect(false), []);

    useEffect(() => {
        if (!productData || !productData.store) {
            // We need the store info to figure out all the products under that store
            setProductListingsForStore([]);
            return;
        }

        if (redirectToMessagesOnRefresh) {
            setRedirectToMessagesOnRefresh(false);
            onTabSelect(2);
        }

        const store = productData.store;
        setBypassPreOrderRestriction(store.bypassPreOrderRestriction);
        fetchProductListingsForStore(store.id)
            .then(productListings => setProductListingsForStore(productListings))
            .catch(() => setProductListingsForStore([]));
    }, [productData]);

    useEffect(() => {
        // If the store has this flag set, then it can have an unlimited amount of Pre-Order products
        if (bypassPreOrderRestriction) {
            setIsPreOrderRestricted(false);
            return;
        }
        // Check if there are any other ProductListings in development have a Pre-Order
        // launch type
        for (const pl of productListingsForStore) {
            // ProductListing is not in development, or it's the currently selected ProductListing,
            // which doesn't affect the pre-order restriction, so skip.
            if (!isProductListingInDevelopment(pl) || pl.id === productListingId) {
                continue;
            }
            if (pl.launchType === LaunchType.preOrder) {
                setIsPreOrderRestricted(true);
                return;
            }
        }
        // None of the other in-development ProductListings have a Pre-Order launch type
        setIsPreOrderRestricted(false);
    }, [bypassPreOrderRestriction, productListingId, productListingsForStore]);

    // Only load spinner on initial page load
    if ((productLoading && (!productData && !productError)) || (timelineLoading && (!timelineData && !timelineError))) {
        return <CenteredSpinner/>;
    }

    if (productError || !productData || timelineError || !timelineData) {
        return (
            <EmptyState
                title="Failed to load product"
                subtitle="Refresh the page to try again"
            >
                <ErrorIcon size={60}/>
            </EmptyState>
        );
    }

    const reloadSetup = (redirectToMessages: boolean = false) => {
        setRedirectToMessagesOnRefresh(redirectToMessages);
        productRefetch();
    };

    const onTabSelect = (index: number, showSubmit: boolean = false) => {
        const tab = tabs[index];
        if (!tab.locked) {
            window.history.replaceState(null, document.title, `/products/${productListingId}/${tabs[index].tabId}`);
            setSelectedTabIndex(index);
            setShowSubmitDialogOverride(showSubmit);
            if (tab.redirect) {
                // This is a special case where we need to use href because of we need to reload userContext on
                // loading the messages immediately after. We add the useHref whenever we should use href to redirect.
                if (tab.useHref) {
                    window.location.href = window.location.origin + tab.redirect;
                } else {
                    history.push(tab.redirect);
                }
            }
        }
    }

    const hasPaymentMethods = (timelineData: TimelineStatus): boolean => {
        const creditCard = timelineData.getStarted.tabs.creditCard;
        return (creditCard === TabStatus.done || creditCard === TabStatus.doneEditable);
    }

    const isGetStartedReadyForSubmission = (timelineData: TimelineStatus): boolean => {
        const phoneNumber = timelineData.getStarted.tabs.contactInfo;
        return (phoneNumber === TabStatus.done || phoneNumber === TabStatus.doneEditable);
    }

    const tabs: TabInfo[] = [
        {
            label: "Timeline",
            tabId: "timeline",
            locked: false,
            component: <TimelineTab
                initialProductListing={productData}
                timelineStatus={timelineData}
                storeId={productData.store.id}
                twilioConversationId={productData.twilioConversationId || undefined}
                shopDomain={productData.customerStage?.domain}
                goToMenuTab={onTabSelect}
                refreshTimeline={timelineRefetch} />
        },
        {
            label: "Setup",
            tabId: "setup",
            locked: false,
            component: <ProductSetupTab
                initialProductListing={productData}
                isTimelineReadyForSubmission={isGetStartedReadyForSubmission(timelineData)}
                hasPaymentMethods={hasPaymentMethods(timelineData)}
                isPreOrderRestricted={isPreOrderRestricted}
                showSubmitDialogOverride={showSubmitDialogOverride}
                setShowSubmitDialogOverride={setShowSubmitDialogOverride}
                reloadTimeline={timelineRefetch}
                reloadSetup={reloadSetup}
                goToMenuTab={onTabSelect} />
        },
        {
            label: "Messages",
            tabId: "timeline",
            locked: !productData?.twilioConversationId,
            redirect: productData.twilioConversationId ? `/messages/${productData?.twilioConversationId}` : undefined,
            useHref: true
        }
    ];

    return (
        <>
            <Pane overflowX={"hidden"} height={"100%"} minWidth={"375px"}>
                <Pane padding={"24px"} paddingBottom={0} borderBottom={"1px solid var(--grey-outline)"}>
                    <BackNavigationButton
                        backNavigation={NavigationHelper.createForProductListView()}
                        size={'large'}
                        style={{
                            color: `var(--grey-secondary-text)`
                        }}
                    />
                    <Pane display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Heading
                            size={800}
                            style={{
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                marginRight: "12px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                fontWeight: "unset",
                            }}
                        >
                            {productData.name}
                        </Heading>
                        <ProductStageBadge
                            flexShrink={0}
                            productListing={productData}
                        />
                    </Pane>
                    <TabNavigation paddingBottom={"16px"}>
                        {tabs.map((tab, index) => {
                            return <Tab
                                appearance={"primary"}
                                color={tab.locked ? ColorPalette.disabledText : ColorPalette.primaryText}
                                key={tab.label}
                                id={tab.label}
                                style={{
                                    fontSize: "14px",
                                    pointerEvents: tab.locked ? "none" : "auto"
                                }}
                                onSelect={() => onTabSelect(index)}
                                isSelected={index === selectedTabIndex}
                                aria-controls={`panel-${tab.label}`}
                            >
                                {tab.locked && <Icon paddingRight={"2px"} size={12} icon={LockIcon} />}
                                {tab.label}
                            </Tab>
                        })}
                    </TabNavigation>
                </Pane>

                <Pane
                    id={"multi-tab-scrollable-container"}
                    overflow={"auto"}
                    padding={0}
                    height={"calc(100% - 166px)"}
                >
                    {tabs.map((tab, index) => {
                        return tab.component && <Pane
                            key={tab.label}
                            id={`panel-${tab.label}`}
                            role="tabpanel"
                            aria-labelledby={tab.label}
                            aria-hidden={index !== selectedTabIndex}
                            display={index === selectedTabIndex ? 'block' : 'none'}
                        >
                            {tab.component || <></>}
                        </Pane>
                    })}
                </Pane>
            </Pane>
        </>
    );
}
