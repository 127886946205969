import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { StytchContext } from "../stytch/StytchContext";
import { JUNIPER_DOMAIN } from "../../../common/constants";

export const useGoHomeOrBack = (): () => void => {
    const history = useHistory();
    const { isAuthenticated } = useContext(StytchContext);

    return () => {
        if (!isAuthenticated) {
            window.location.href = `https://${JUNIPER_DOMAIN}`;
        } else {
            history.goBack();
        }
    };
};
