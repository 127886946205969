import { Pane, Select, SelectField, TextInput, TextInputField } from "evergreen-ui";
import React, { MutableRefObject, useRef } from "react";
import { TundraStorefrontCreateFromExistingInput } from "../../../../common/inputs/TundraStorefrontInput";
import { ContentPosition, DetailsPageSection } from "../../common/DetailsPageSection";
import { FooterActionBar } from "../../common/FooterActionBar";

export interface StoreTundraAdminCreateViewProps {
    createStorefront: (input: TundraStorefrontCreateFromExistingInput) => void;
    createStorefrontLoading: boolean;
    storeId: string;
}

export const StoreTundraAdminCreateView = (props: StoreTundraAdminCreateViewProps): JSX.Element => {

    const {
        createStorefrontLoading,
        createStorefront,
        storeId
    } = props;

    const existingStorefrontIdRef: MutableRefObject<string> = useRef<string>("");
    const existingThemeVersionRef: MutableRefObject<number> = useRef<number>(1);

    const titleRef: MutableRefObject<string> = useRef<string>("");
    const shortNameRef: MutableRefObject<string> = useRef<string>("");
    const prefixRef: MutableRefObject<string> = useRef<string>("");
    const tierRef: MutableRefObject<"campaigns" | "partnerships" | "vendor"> =
        useRef<"campaigns" | "partnerships" | "vendor">("campaigns");

    const updateRef = (e: any, ref: React.MutableRefObject<any>): void => {
        ref.current = e.target.value;
    }

    const submitCreateStorefront = (): void => {
        createStorefront({
            existingStoreId: existingStorefrontIdRef.current,
            existingVersion: Number.parseInt(`${existingThemeVersionRef.current}`), // I know, it's dumb
            newStorePrefix: prefixRef.current.toLowerCase(),
            newStoreShortName: shortNameRef.current,
            newStoreTitle: titleRef.current,
            newStoreTier: tierRef.current,
            selfServeStoreId: storeId
        });
    };

    return <Pane>
        <DetailsPageSection
            heading={"From Existing"}
            contentPosition={ContentPosition.left}
        >
            <TextInputField
                name={"storefront-id"}
                label={"Storefront ID"}
                required={true}
                maxWidth={"300px"}
                onChange={(e: any) => updateRef(e, existingStorefrontIdRef)}
            />
            <TextInputField
                name={"theme-version"}
                label={"Theme Version"}
                type={"number"}
                required={true}
                maxWidth={"300px"}
                onChange={(e: any) => updateRef(e, existingThemeVersionRef)}
            />
        </DetailsPageSection>
        <DetailsPageSection
            heading={"To New"}
            contentPosition={ContentPosition.left}
        >
            <TextInputField
                name={"title"}
                label={"Title"}
                required={true}
                maxWidth={"300px"}
                onChange={(e: any) => updateRef(e, titleRef)}
            />
            <TextInputField
                name={"short-name"}
                label={"Short Name"}
                required={true}
                maxWidth={"300px"}
                onChange={(e: any) => updateRef(e, shortNameRef)}
            />
            <TextInputField
                name={"prefix"}
                label={"Prefix"}
                required={true}
                maxWidth={"300px"}
                onChange={(e: any) => updateRef(e, prefixRef)}
            />
            <SelectField
                name={"tier"}
                label={"Tier"}
                required={true}
                maxWidth={"300px"}
                onChange={(e: any) => updateRef(e, tierRef)}
            >
                <option value={"campaigns"}>Campaigns</option>
                <option value={"partnerships"}>Partnerships</option>
                <option value={"vendor"}>Vendor</option>
            </SelectField>
        </DetailsPageSection>
        <FooterActionBar
            primaryButtonText={"Create Storefront"}
            isPrimaryLoading={createStorefrontLoading}
            primaryButtonDisabled={false}
            onPrimaryClick={submitCreateStorefront}
            showSecondaryButton={false}
            infoComponent={
                <p>Create a new storefront from an existing one</p>
            }
        />
    </Pane>;
}
