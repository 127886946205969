import { AbstractRenderer } from "./AbstractRenderer";
import { TableHeaderRendererInput } from "../input";

export abstract class AbstractTableHeaderCellRenderer<T> extends AbstractRenderer {

    protected readonly input: TableHeaderRendererInput<T>;

    constructor(input: TableHeaderRendererInput<T>) {
        super();
        this.input = input;
    }

    protected getKey(): string {
        return `${String(this.input.columnConfig.columnName)}`;
    }
}
