import { useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { MultiTabView } from "../../common/multitab/MultiTabView";
import { TabInfo } from "../../common/multitab/TabInfo";
import { BillingTab } from "./tabs/BillingTab";
import { LegalAgreement } from "../../legal/LegalAgreement";
import { Profile } from "../Profile";
import { SETTINGS_ROUTE_PATH } from "../../navigation/Routes";
import { NavigationHelper } from "../../navigation/PrevLocationState";
import { NotificationsTab } from "./tabs/NotificationsTab";

type Params = {tabId?: keyof typeof SettingsTabs}

enum SettingsTabs {
    account = "account",
    notifications = "notifications",
    billing = "billing",
    agreement = "agreement",
}

const resolveFirstTabIndex = (tabId?: keyof typeof SettingsTabs): number => {
    switch (tabId) {
        case SettingsTabs.notifications:
            return 3;
        case SettingsTabs.agreement:
            return 2;
        case SettingsTabs.billing:
            return 1;
        case SettingsTabs.account:
        default:
            return 0;
    }
};

export const MultiTabSettingsPage = () => {
    const history = useHistory();
    const {tabId} = useParams<Params>();
    const [selectedIndex, setSelectedIndex] = useState<number>(resolveFirstTabIndex(tabId));

    const tabs: TabInfo[] = [
        {
            label: "Account",
            tabId: "account",
            useDefaultLayout: true,
            component: <Profile />
        },
        {
            label: "Billing",
            tabId: "billing",
            useDefaultLayout: true,
            component: <BillingTab />
        },
        {
            label: "Agreement",
            tabId: "agreement",
            useDefaultLayout: false,
            component: <LegalAgreement noPadding={false} />
        },
        {
            label: "Notifications",
            tabId: "notifications",
            useDefaultLayout: false,
            component: <NotificationsTab />
        }
    ];

    useEffect(() => {
        NavigationHelper.pushPathname(
            history,
            generatePath(SETTINGS_ROUTE_PATH, {
                tabId: tabs[selectedIndex].tabId,
            }),
        );
    }, [selectedIndex]);

    return (
        <MultiTabView
            header={"Settings"}
            selectedTabIndex={selectedIndex}
            tabs={tabs}
            onTabSelected={setSelectedIndex}
            fullPathFormat={SETTINGS_ROUTE_PATH}
        />
    );
}
