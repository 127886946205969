import { useHistory, useParams, generatePath, matchPath } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { MESSAGES_ROUTE_PATH } from "../../navigation/Routes";

type ParamsType = {conversationSid?: string};
type ReturnType = {
    conversationSidParam: string | undefined;
    setConversationSidParam: (conversationSid?: string) => void;
};

/**
 * A hook for accessing the active conversation SID from the React Router URL params.
 */
export const useConversationSidParam = (): ReturnType => {
    const history = useHistory();
    const { conversationSid } = useParams<ParamsType>();
    const [ sidInternal, setSidInternal ] = useState<string | undefined>(conversationSid);

    const getSidFromPath = (path: string): string => {
        const result = matchPath<ParamsType>(path, {
            path: MESSAGES_ROUTE_PATH
        });
        return result?.params?.conversationSid ?? "";
    };

    const updatePathWithSid = useCallback((conversationSid?: string): void => {
        if (conversationSid === getSidFromPath(history.location.pathname)) {
            return; // Do nothing if the incoming SID is the same.
        }
        const path = generatePath(MESSAGES_ROUTE_PATH, {conversationSid: conversationSid});
        history.push(path);
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            const sid = getSidFromPath(location.pathname);
            setSidInternal(sid);
        });
    }, []);

    return {
        conversationSidParam: sidInternal,
        setConversationSidParam: updatePathWithSid,
    };
};
