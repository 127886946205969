import {
    PaperclipIcon,
    IconButton,
    Pane,
    SendMessageIcon,
    Spinner,
    toaster,
    Position,
    Table,
    Popover
} from "evergreen-ui";
import { KeyboardEventHandler, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { createRegexRenderer, RichTextarea, RichTextareaHandle } from "rich-textarea";
import { isPrintableCharacterEvent } from "../../common/isPrintableCharacterEvent";
import { ColorPalette } from "../../context/Theme";
import { useUserContext } from "../../context/UserContext";
import { useTaggedInputState } from "../tagging/state/useTaggedInputState";
import { Tag } from "../../../../common/types/messaging/Tag";
import { SendResult } from "../types";

import "./text-input-placeholder.css";

interface ConversationViewState {
    draftMessage?: string;
}

export interface ConversationViewTextInputProps {
    isDisabled: boolean;
    isDragActive: boolean;
    isMobile?: boolean;
    getDragInputProps: any;
    onSubmit: (text: string, tags?: Tag[]) => Promise<SendResult>;
    openDialog: () => void;
}

const MIN_ROWS = 1;
const MAX_ROWS = 5;

const BORDER_WIDTH = 1;
const PADDING = 8;
const FONT_SIZE = 12;
const LINE_HEIGHT = 16;
const BORDER_RADIUS = 10;

export const ConversationViewTextInput = (props: ConversationViewTextInputProps) => {

    const { state } = useLocation<ConversationViewState>();
    const { user } = useUserContext();

    const { isDisabled, isDragActive, isMobile, getDragInputProps, onSubmit, openDialog } = props;

    const [leftShiftKeyHeld, setLeftShiftKeyHeld] = useState<boolean>(false);
    const [rightShiftKeyHeld, setRightShiftKeyHeld] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [tagMenuItemSelected, setTagMenuItemSelected] = useState<number>(0);

    const textAreaHandle = useRef<RichTextareaHandle>(null);

    const [
        message,
        currentTagValues,
        possibleTagValues,
        onTextInputChanged,
        commitTagInternal,
        sendMessage
    ] = useTaggedInputState(textAreaHandle, onSubmit, state?.draftMessage);

    const commitTag = (tag: Tag): void => {
        commitTagInternal(tag);
        setTagMenuItemSelected(0);
    }

    const keyPressHandler: KeyboardEventHandler<HTMLTextAreaElement> = (event): void => {
        // maintain state of if a Shift Key is being held down or not
        if (event.code === "ShiftLeft") {
            setLeftShiftKeyHeld(event.type === "keydown");
        } else if (event.code === "ShiftRight") {
            setRightShiftKeyHeld(event.type === "keydown");
        }

        // If "enter" is pressed down, and either shift isn't engaged, send the message;
        //  or if we are in the tag menu, commit the current tag.
        else if (event.code === "Enter" && event.type === "keydown" && !(leftShiftKeyHeld || rightShiftKeyHeld)) {
            if (currentTagValues.length > 0) {
                commitTag(currentTagValues[tagMenuItemSelected]);
            } else {
                submitMessage();
            }
            event.preventDefault();
        }

        // Tab autocomplete backstop
        else if (event.code === "Tab" && event.type === "keydown" && currentTagValues.length > 0) {
            commitTag(currentTagValues[tagMenuItemSelected]);
            event.preventDefault();
        }

        // Spacing away with tags available case; if there is only one tag available and we hit the space bar,
        //  let's autocomplete that tag to be safe.
        else if (event.code === "Space" && event.type === "keydown" && currentTagValues.length === 1) {
            commitTag(currentTagValues[tagMenuItemSelected]);
            event.preventDefault();
        }

        else if (event.code === "ArrowUp" && event.type === "keydown" && currentTagValues.length > 0) {
            setTagMenuItemSelected((prevIdx: number) => prevIdx - 1 < 0 ? currentTagValues.length - 1 : prevIdx - 1);
            event.preventDefault();
        }

        else if (event.code === "ArrowDown" && event.type === "keydown" && currentTagValues.length > 0) {
            setTagMenuItemSelected((prevIdx: number) => prevIdx + 1 > currentTagValues.length - 1 ? 0 : prevIdx + 1);
            event.preventDefault();
        }

        else if (event.code === "Escape" && event.type === "keydown" && currentTagValues.length > 0) {
            onTextInputChanged(message + " ");
            if (textAreaHandle.current) {
                textAreaHandle.current.focus();
            }
            event.preventDefault();
        } else if (isPrintableCharacterEvent(event)) {
            setTagMenuItemSelected(0);
        }

        updateInputHeight();
    };

    const onMessageContentsChanged = (content: string) => {
        // This is used to simulate a disabled state while the message is being sent. We can't
        // rely on the built-in `textarea[disabled=true]` attribute because the browser
        // automatically dismisses focus from the `textarea` when this attr is true, which
        // consequently (and unintentionally) dismisses the mobile keyboard
        if (!isSending) {
            onTextInputChanged(content);
        }
    };

    const submitMessage = () => {
        if (isSending || !textAreaHandle || !textAreaHandle.current) {
            return;
        }

        // Maintain focus on the input so that the mobile keyboard isn't dismissed.
        textAreaHandle?.current?.focus();
        setIsSending(true);

        // cut whitespace/newlines at start/end of messages
        const message = textAreaHandle.current.value.trim();
        sendMessage(message)
            .then((status: SendResult) => {
                if (status === SendResult.failure) {
                    // Let the catch block handle the result.
                    return Promise.reject();
                }

                if (status === SendResult.success) {
                    // On success, clear the contents.
                    onTextInputChanged("");
                }

                // Both the `success` and `ignored` cases are resolved.
                return Promise.resolve();
            })
            .catch(() => {
                // Show toast, but don't clear the input so that the user can try again in case
                // the error was transient.
                toaster.danger("Message failed to send");
            })
            .finally(() => {
                setIsSending(false);
                updateInputHeight();
            });
    };

    const updateInputHeight = () => {
        if (textAreaHandle && textAreaHandle.current) {
            // Textarea properties are derived as follows:
            //   > height = topBorder + topPadding + lineHeight + bottomPadding + bottomBorder
            //   > scrollHeight = topPadding + lineHeight + bottomPadding
            //   > rows = (scrollHeight - (topPadding + bottomPadding)) / lineHeight

            // Reset the height to a single row, otherwise the textarea will not behave properly.
            textAreaHandle.current.style.height = `${calculateHeight()}px`;

            // Determine the number of lines based on the current scrollHeight.
            const rows = (textAreaHandle.current.scrollHeight - PADDING * 2) / LINE_HEIGHT;

            // Recalculate the height based on the number of rows of content.
            textAreaHandle.current.style.height = `${calculateHeight(Math.min(MAX_ROWS, rows))}px`;
        }
    };

    // With the RichTextArea component, we need to subtract one row that is automatically added
    // internally by the component; hence the MIN_ROWS - 1 stuff.
    const calculateHeight = (rows: number = MIN_ROWS - 1): number => {
        const realRowCount: number = rows === MIN_ROWS - 1 ? 0 : rows - 1;
        return (LINE_HEIGHT * realRowCount) + (PADDING * 2) + (BORDER_WIDTH * 2);
    };

    const onClickSendButton = (event: React.MouseEvent) => {
        event.preventDefault();
        submitMessage();
    };

    const iconStyling = {
        marginLeft: "16px",
        color: "muted",
        width: "30px",
        height: "30px"
    };

    // Simple replace mechanism to automatically escape special RegExp characters in a string.
    const escapeRegExp = (text: string): string => {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    }

    // To explain this regex a little; basically if no tags are committed, then nothing should match or be highlighted ->
    //    e.x., (?!) matches nothing
    // If there are tags committed, then we build an expression that only matches on exactly the committed tag display names ->
    //    e.x., If the potential tags are "Apple", "Banana", "Orange", and "Grape", with "Apple" and "Orange" committed, then
    //          (Apple|Orange) will match on "Apple" or "Orange" but NOT on "Banana" or "Grape".
    const filteredCommittedTags: Tag[] = possibleTagValues.filter((tag: Tag) => tag.character === "@" && tag.data.userId !== user?.email); // All tags, but the current user's
    const MENTION_HIGHLIGHT_REG = (): RegExp => {
        try {
            return new RegExp(
                `${filteredCommittedTags.length === 0 ? '(?!)' : `(${filteredCommittedTags.map((c) => `${escapeRegExp(c.data.displayName)}`).join("|")})`}`,
                "g"
            );
        } catch (e) {
            console.warn("Failed to compile mentions RegExp", e);
            return new RegExp('(?!)');
        }
    }

    const currentUserTag: Tag | undefined = possibleTagValues.find((tag: Tag) => tag.character === "@" && tag.data.userId === user?.email); // current user's tags only
    const CURRENT_USER_MENTION_HIGHLIGHT_REG = (): RegExp => {
        try {
            return new RegExp(
                currentUserTag ? `(${escapeRegExp(currentUserTag.data.displayName)})` : '(?!)',
                "g"
            );
        } catch (e) {
            console.warn("Failed to compile user RegExp", e);
            return new RegExp('(?!)');
        }
    }

    // NOTE: The styles chosen here can't change the size/spacing of the text in any way.
    //  No changing font family, weight, style, size, etc. - otherwise the cursor will fall out of sync.
    const mentionRenderer = createRegexRenderer([
        [MENTION_HIGHLIGHT_REG(), {
            color: "var(--grey-primary-text)",
            backgroundColor: "var(--grey-background-focus)",
            padding: "1px",
            marginRight: "-1px",
            marginLeft: "-1px",
            borderRadius: "5px"
        }],
        [CURRENT_USER_MENTION_HIGHLIGHT_REG(), {
            color: "var(--dark-green-primary)",
            backgroundColor: "var(--green-background-focus-bold)",
            padding: "1px",
            marginRight: "-1px",
            marginLeft: "-1px",
            borderRadius: "4px"
        }]
    ]);

    return (
        <Pane
            float={"left"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            background={"white"}
            padding={"12px"}
            borderTop={"1px solid " + ColorPalette.borderColor}
        >
            <IconButton
                display={isDragActive ? "none" : "flex"}
                style={{...iconStyling}}
                icon={(
                    <PaperclipIcon
                        size={20}
                        color={isSending
                            ? ColorPalette.grey
                            : ColorPalette.primaryAccent
                        }
                    />
                )}
                appearance={"minimal"}
                marginRight={"8px"}
                onClick={openDialog}
                disabled={isDisabled || isSending}
            />
            { /** TODO: This Popover is super rudimentary, we'll surely revisit this **/ }
            <Popover position={Position.TOP_LEFT} isShown={currentTagValues.length > 0} content={(
                <Table style={{
                    minWidth: "300px"
                }}>
                    <Table.Body>
                        { currentTagValues.map((tag: Tag, idx: number) => {
                           return (
                               <Table.Row
                                   className={"tag-menu-row"}
                                   key={tag.character + idx}
                                   tabIndex={undefined}
                                   style={{
                                       height: "40px",
                                       border: "none"
                                   }}
                                   isSelected={tagMenuItemSelected === idx}
                                   onMouseEnter={() => setTagMenuItemSelected(idx)}
                                   onClick={() => {
                                       commitTag(currentTagValues[idx]);
                                   }}
                                   onKeyDown={(event: any) => {
                                       if ((event.code === "Tab" || event.code === "Enter") && currentTagValues.length > 0) {
                                           commitTag(currentTagValues[tagMenuItemSelected]);
                                       } else if (event.code === "ArrowUp") {
                                           setTagMenuItemSelected((prevIdx: number) => prevIdx - 1 < 0 ? currentTagValues.length - 1 : prevIdx - 1);
                                       } else if (event.code === "ArrowDown") {
                                           setTagMenuItemSelected((prevIdx: number) => prevIdx + 1 > currentTagValues.length - 1 ? 0 : prevIdx + 1);
                                       } else if (event.code === "Escape") {
                                           onTextInputChanged(message + " ");
                                           setTagMenuItemSelected(0);
                                           if (textAreaHandle.current) {
                                               textAreaHandle.current.focus();
                                           }
                                       }
                                       event.preventDefault();
                                   }}
                               >
                                   <Table.TextCell>
                                       <strong>{ tag.data.displayName.substring(1) }</strong>
                                   </Table.TextCell>
                               </Table.Row>
                           );
                        })}
                    </Table.Body>
                </Table>
            )} >
                <Pane
                    position={"relative"}
                    width={"calc(100% - 100px)"}
                    marginRight={PADDING}
                >
                    <Pane
                        position={"absolute"}
                        display={isDragActive ? "flex" : "none"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        textAlign={"center"}
                        background={"greenTint"}
                        width={"100%"}
                        color={ColorPalette.primaryAccent}
                        padding={PADDING}
                        minHeight={calculateHeight()}
                        height={textAreaHandle?.current?.clientHeight ?? calculateHeight()}
                        border={`1px dashed ${ColorPalette.borderColor}`}
                        borderRadius={BORDER_RADIUS}
                        fontSize={FONT_SIZE}
                    >
                        <input {...getDragInputProps()} />
                        {"Drag + Drop to Attach Files"}
                    </Pane>
                    <RichTextarea
                        style={{
                            border: `0.5px solid var(--grey-input-border)`,
                            width: "100%",
                            display: "flex",
                            lineHeight: `${LINE_HEIGHT}px`,
                            visibility: isDragActive ? "hidden" : "visible",
                            borderRadius: `${BORDER_RADIUS}px`,
                            height: `${calculateHeight()}px`,
                            minHeight: `${calculateHeight(1)}px`,
                            padding: `${PADDING}px`,
                            marginRight: `${PADDING}px`,
                            fontFamily: `var(--font-family-body)`,
                            fontSize: `${FONT_SIZE}px`,
                            float: "left",
                            resize: "none"
                        }}
                        disabled={isDisabled || isSending}
                        onResize={() => {}}
                        onResizeCapture={() => {}}
                        placeholder={`Send message... ${!isMobile ? "(Shift + Enter to insert a new line)" : ""}`}
                        ref={textAreaHandle}
                        onKeyDown={keyPressHandler}
                        onKeyUp={keyPressHandler}
                        onChange={(event) => onMessageContentsChanged(event.target.value)}
                        onSelectionChange={(pos, value) => onMessageContentsChanged(value)}
                        value={message}
                        // A CSS selector uses this attr to visually simulate a disabled state.
                        data-is-sending={isSending}
                    >
                        { mentionRenderer }
                    </RichTextarea>
                </Pane>
            </Popover>
            <IconButton
                display={isDragActive ? "none" : "flex"}
                style={{...iconStyling}}
                icon={isSending ? (
                    <Spinner size={16}/>
                ) : (
                    <SendMessageIcon size={20} color={ColorPalette.primaryAccent}/>
                )}
                appearance={"minimal"}
                onClick={onClickSendButton}
                disabled={isDisabled}
            />
        </Pane>
    );
};

