import { ProductLifecycleStatus } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class ProductLifecycleStatusMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [ProductLifecycleStatus.Draft]: {
                label: ProductLifecycleStatus.Draft,
                value: ProductLifecycleStatus.Draft,
                color: "neutral"
            },
            [ProductLifecycleStatus.Active]: {
                label: ProductLifecycleStatus.Active,
                value: ProductLifecycleStatus.Active,
                color: "blue"
            },
            [ProductLifecycleStatus.Completed]: {
                label: ProductLifecycleStatus.Completed,
                value: ProductLifecycleStatus.Completed,
                color: "green"
            },
            [ProductLifecycleStatus.Cancelled]: {
                label: ProductLifecycleStatus.Cancelled,
                value: ProductLifecycleStatus.Cancelled,
                color: "red"
            },
        };
    }
}