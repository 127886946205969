export enum NotificationCategory {
    ConversationMessage = "ConversationMessage",
    ProductDevUpdate = "ProductDevUpdate",
    ProductDevConfirmation = "ProductDevConfirmation",
    StoreUpdate = "StoreUpdate"
}

export enum NotificationType {
    ChatMessage = "ChatMessage",
    SampleApproved = "SampleApproved",
    SubmittedForSampling = "SubmittedForSampling",

    // PRODUCT DEV UPDATES
    ProductAccepted = "ProductAccepted",
    // PRODUCT CANCELLATION
    ProductCancelledUnder18 = "ProductCancelledUnder18",
    ProductCancelledLowEngagement = "ProductCancelledLowEngagement",
    ProductCancelledSpam = "ProductCancelledSpam",
    ProductCancelledUnableToMeetCreatorDesire = "ProductCancelledUnableToMeetCreatorDesire",
    ProductCancelledAbandoned = "ProductCancelledAbandoned",
    ProductCancelledTermination = "ProductCancelledTermination",
    ProductCancelledPaused = "ProductCancelledPaused",
    ProductCancelledDesignIssues = "ProductCancelledDesignIssues",
    ProductCancelledSampleIssues = "ProductCancelledSampleIssues",
    ProductCancelledPriceMOQTooHigh = "ProductCancelledPriceMOQTooHigh",
    ProductCancelledReducingNumProducts = "ProductCancelledReducingNumProducts",
    ProductCancelledMovedToPartnerships = "ProductCancelledMovedToPartnerships",
  
    // PRODUCT DEV CONFIRMATION
    ProductSubmitted = "ProductSubmitted",

    // STORE UPDATE
    InvitedUserPartnerships = "InvitedUserPartnerships",
    InvitedUser = "InvitedUser"
}

export enum NotificationFrequency {
    // Messaging Frequency
    MessageAll = "MessageAll",
    MessageTagged = "MessageTagged",

    // Product Dev Updates Frequency
    ProductDevAll = "ProductDevAll",

    // Product Confirmation Frequency
    ProductConfirmationAll = "ProductConfirmationAll",

    // Store Update Frequency
    StoreUpdateAll = "StoreUpdateAll"
}

export enum NotificationChannel {
    SMS = "SMS",
    Email = "Email",
    Push = "Push"
}