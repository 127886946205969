import * as FullStory from "@fullstory/browser";

const isDebug = process.env.NODE_ENV !== "production";
const fullStoryOrgId = "14BAM3";

type IdentifyVars = {
    email: string,
    firstName_str: string,
    lastName_str: string,
    internalId_str: string,
};

export enum EventName {
    CreationStarted = "creation_started",
    CategorySelected = "category_selected",
    CategoryWritten = "category_written",
    ProductSelected = "option_selected",
    ProductWritten = "option_written",
    SignUpStarted = "signup_started",
    StytchInitiated = "stytch_initiated",
    SignUpCompleted = "signup_completed",
    LoginCompleted = "login_completed",
    ProjectDetailsCompleted = "project_details_completed",
    StoreInfoCompleted = "store_info_completed",
    ProfileInfoCompleted = "profile_info_completed",
    SubmittedForReview = "submitted_for_review",
    PageView = "page_view",
    Exception = "exception",
}

export enum MessageEventName {
    ClientInitSuccess = "twilio_client_init_success",
    ClientInitFail = "twilio_client_init_fail",
    ClientConnected = "twilio_client_connected",
    ClientConnecting = "twilio_client_connecting",
    ClientDenied = "twilio_client_denied",
    ClientDisconnected = "twilio_client_disconnected",
    ClientShutdown = "twilio_client_shutdown",
    ClientShutdownError = "twilio_client_shutdown_error",
    AccessTokenSuccess = "twilio_access_token_success",
    AccessTokenFail = "twilio_access_token_fail",
    AccessTokenExpireSoon = "twilio_access_token_expire_soon",
    InitialConversationStoreSuccess = "twilio_initial_conversation_store_success",
    ConversationStoreSuccess = "twilio_conversations_store_success",
    ConversationStoreFail = "twilio_conversations_store_fail",
    InitialMessagesLoadSuccess = "twilio_initial_messages_load_success",
    InitialMessagesLoadFail = "twilio_initial_messages_load_fail",
    PaginatedMessagesLoadSuccess = "twilio_paginated_messages_load_success",
    PaginatedMessagesLoadFail = "twilio_paginated_messages_load_fail",
    ConversationMetadataSuccess = "twilio_conversations_metadata_success",
    ConversationMetadataFail = "twilio_conversations_metadata_fail",
    ActiveConversationLoadSuccess = "twilio_active_conversation_load_success",
    ActiveConversationLoadFail = "twilio_active_conversation_load_fail",
    MessageAdapterError = "twilio_message_adapter_error",
    GetSubscribedConversationsSuccess = "twilio_get_subscribed_conversations_success",
    GetSubscribedConversationsFail = "twilio_get_subscribed_conversations_fail",
    MessageAttributeSerializationFail = "twilio_message_attributes_serialization_fail",
    MessageAttributeDeserializationFail = "twilio_message_attributes_deserialization_fail",
    MessageSentSuccess = "twilio_message_sent_success",
    MessageSentFail = "twilio_message_sent_fail"
}

const stub = (_: string, eventName: string, properties?: any) => {
    console.debug(`eventName='${eventName}'; properties='${JSON.stringify(properties || {})}'`);
};

const gtag: (command: string, eventName: string, properties?: any) => void = (window as any).gtag || stub;

const GA_MEASUREMENT_ID = process.env.REACT_APP_UA_IDENTIFIER as string;
const GTAG_EVENT_CMD = "event";
const GTAG_CONFIG_CMD = "config";

class Analytics {
    private initialized: boolean = false;

    public init() {
        if (!this.initialized) {
            FullStory.init({
                orgId: fullStoryOrgId,
                debug: isDebug,
                devMode: isDebug,
            });
            this.initialized = true;
        }
    }

    public identify(identifier: string, vars: IdentifyVars) {
        FullStory.identify(identifier, Analytics.transformIdentityVars(vars));
        gtag(GTAG_CONFIG_CMD, GA_MEASUREMENT_ID, {
            user_id: vars.internalId_str, // GA privacy rules don't allow using email addresses.
        });
        const {heap} = (window as any);
        if (heap && heap.hasOwnProperty('addUserProperties')) {
            heap.addUserProperties(Analytics.transformIdentityVars(vars));
        }
    }

    public setUserVars(vars: IdentifyVars) {
        FullStory.setUserVars(Analytics.transformIdentityVars(vars) as IdentifyVars);
    }

    public track(eventName: EventName, eventProperties: { [key: string]: any } = {}) {
        FullStory.event(String(eventName), eventProperties);
        gtag(GTAG_EVENT_CMD, eventName);
    }

    public heap_track(eventName: EventName | MessageEventName, eventProperties: { [key: string]: string } = {}) {
        const {heap} = (window as any);
        if (heap && heap.hasOwnProperty('track')) {
            heap.track(eventName, eventProperties);
        }
    }

    public sendPageView() {
        gtag(GTAG_EVENT_CMD, EventName.PageView, {
            page_path: window.location.pathname,
            page_location: window.location.href,
            page_title: window.location.pathname.replace(/\d+/g, "id")
        });
    }

    public sendException(description: any, fatal: boolean = true) {
        gtag(GTAG_EVENT_CMD, EventName.Exception, {
            description: String(description),
            fatal
        });
    }

    public anonymize() {
        FullStory.anonymize();
    }

    private static transformIdentityVars(identityVars?: IdentifyVars) {
        if (!identityVars) {
            return;
        }

        return {
            ...identityVars,
            displayName: `${identityVars.firstName_str} ${identityVars.lastName_str}`.trim(),
        };
    }
}

export const analytics = new Analytics();
