import React from "react";
import PhoneInput from "react-phone-input-2";
import { Pane } from "evergreen-ui";
import { ColorPalette, defaultFontFamily } from "../context/Theme";
import { isPhoneNumberValid } from "../product/Utils";
import { LabelledTooltip } from "./LabelledTooltip";

import 'react-phone-input-2/lib/plain.css';
import { inputLabelStyle } from "./FormComponentStyles";

interface Props {
    value?: string | null;
    showToolTip?: boolean;
    onChange: (phoneNumber: string) => void;
}

export const PhoneInputField = ({onChange, value = "", showToolTip = true}: Props) => {
    return (
        <div style={{marginBottom: "24px"}}>
            <Pane style={inputLabelStyle}>
                <LabelledTooltip
                    label="Phone Number"
                    showToolTip={showToolTip}
                    description="Required for chat notifications to be delivered via SMS."
                />
            </Pane>
            <PhoneInput
                buttonStyle={{
                    borderRadius: "4px 0 0 4px",
                    borderColor: ColorPalette.inputBorderColor,
                }}
                inputStyle={{
                    borderRadius: "4px",
                    width: "100%",
                    fontFamily: defaultFontFamily,
                    fontSize: "12px",
                    color: ColorPalette.primaryText,
                    borderColor: ColorPalette.inputBorderColor,
                }}
                dropdownStyle={{
                    borderRadius: "4px",
                    fontFamily: defaultFontFamily
                }}
                country="us"
                preferredCountries={["us", "ca"]}
                preserveOrder={["preferredCountries"]}
                placeholder=""
                value={value}
                onChange={onChange}
                isValid={isPhoneNumberValid}
            />
        </div>
    )
};
