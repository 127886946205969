import { createContext, Dispatch, SetStateAction } from "react";

interface IStytchContext {
    isAuthenticated: boolean,
    setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
    isInvited: boolean,
    setIsInvited: Dispatch<SetStateAction<boolean>>;
    isLoading: boolean,
    checkAuth: () => Promise<void>
    logout: () => Promise<void>
}

export const StytchContext = createContext<IStytchContext>({
    isAuthenticated: false,
    setIsAuthenticated: (): never => {throw new Error("This function needs to be overriden")},
    isInvited: false,
    setIsInvited: (): never => {throw new Error("This function needs to be overriden")},
    isLoading: true,
    checkAuth: (): never => {throw new Error("This function needs to be overriden")},
    logout: (): never => {throw new Error("This function needs to be overriden")}
});