import { FC, useEffect, useState } from "react";
import { Pane } from "evergreen-ui";
import { formatDollarRange, formatUnitRange, getPricing } from "../../product/PricingUtils";
import { TileSelectorProps } from "./TileSelector";
import { CatalogOption } from "../../../../common/models";
import { TextBadge } from "../TextBadge";

import './styles/TileSelector.css';

export const ApparelTileSelector: FC<TileSelectorProps> = (props: TileSelectorProps) => {
    const { id, imageUrl, name, selected, onSelected, isPopular, isNew, catalogItem } = props;
    const [unitCost, setUnitCost] = useState<string>();
    const [msrp, setMsrp] = useState<string>();
    const [profit, setProfit] = useState<string>();
    const [sampleFee, setSampleFee] = useState<string>();
    const [moq, setMoq] = useState<string>();

    useEffect(() => {
        const pricing = getPricing(catalogItem);
        setUnitCost(formatDollarRange(pricing.map(({creatorCost}) => creatorCost)));
        setMsrp(formatDollarRange(pricing.map(({msrp}) => msrp)));
        setProfit(formatDollarRange(pricing.map(({creatorCost, msrp}) => msrp - creatorCost)));
        setSampleFee(formatDollarRange(pricing.map(({sampleFee}) => sampleFee)));
        setMoq(formatUnitRange(pricing.map(({moq}) => moq)));
    }, [catalogItem]);

    const renderTextBadge = (condition: boolean | undefined, text: string) => {
        return condition && (<TextBadge text={text} />)
    };

    const renderApparelInfo = () => {
        const {apparelPrinting, fabricColors } = catalogItem as CatalogOption;
        return (
            <div className="apparel-selector-apparel-info">
                <div className={"apparel-selector-row"}>
                    <span>{apparelPrinting}</span>
                </div>
                <div className={"apparel-selector-row"}>
                    <span>{fabricColors}</span>
                </div>
            </div>
        );
    };

    const renderPricingAndInfo = () => (
        <div className="apparel-selector-pricing-info">
            <div className={"apparel-selector-row"}>
                <span>{unitCost}</span>
            </div>
            <div className={"apparel-selector-row"}>
                <span>{msrp}</span>
            </div>
            <div className={"apparel-selector-row"}>
                <span>{profit}</span>
            </div>
            <div className={"apparel-selector-row"}>
                <span>{sampleFee}</span>
            </div>
            <div className={"apparel-selector-row"}>
                <span>{moq}</span>
            </div>
        </div>
    );

    return (
        <Pane
            id={id}
            className={`apparel-selector-container ${selected ? "selected" : ""}`}
            onClick={onSelected}
        >
            {renderTextBadge(isPopular, "Popular") || renderTextBadge(isNew, "New")}
            <Pane
                className="apparel-selector-image"
                style={{ backgroundImage: `url(${imageUrl}) , linear-gradient(rgba(0,0,0,0.03),rgba(0,0,0,0.03))` }}
            />
            <Pane className="apparel-tile-selector-bottom-container">
                <Pane
                    className="apparel-selector-bottom">
                    <Pane className="tile-selector-radio-button" />
                    <Pane className="tile-selector-bottom-right">
                        <span>{name}</span>
                    </Pane>
                </Pane>
                {renderApparelInfo()}
                {renderPricingAndInfo()}
            </Pane>
        </Pane>
    )
};
