import { Designer } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class DesignerMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [Designer.Abigail]: {
                label: Designer.Abigail,
                value: Designer.Abigail,
                color: "neutral"
            },
            [Designer.Arianne]: {
                label: Designer.Arianne,
                value: Designer.Arianne,
                color: "neutral"
            },
            [Designer.Arielle]: {
                label: Designer.Arielle,
                value: Designer.Arielle,
                color: "neutral"
            },
            [Designer.Gigi]: {
                label: Designer.Gigi,
                value: Designer.Gigi,
                color: "neutral"
            },
            [Designer.Haruka]: {
                label: Designer.Haruka,
                value: Designer.Haruka,
                color: "neutral"
            },
            [Designer.Helena]: {
                label: Designer.Helena,
                value: Designer.Helena,
                color: "neutral"
            },
            [Designer.Holly]: {
                label: Designer.Holly,
                value: Designer.Holly,
                color: "neutral"
            },
            [Designer.Jana]: {
                label: Designer.Jana,
                value: Designer.Jana,
                color: "neutral"
            },
            [Designer.Jeff]: {
                label: Designer.Jeff,
                value: Designer.Jeff,
                color: "neutral"
            },
            [Designer.Jillian]: {
                label: Designer.Jillian,
                value: Designer.Jillian,
                color: "neutral"
            },
            [Designer.Julia]: {
                label: Designer.Julia,
                value: Designer.Julia,
                color: "neutral"
            },
            [Designer.Kelsey]: {
                label: Designer.Kelsey,
                value: Designer.Kelsey,
                color: "neutral"
            },
            [Designer.Kristine]: {
                label: Designer.Kristine,
                value: Designer.Kristine,
                color: "neutral"
            },
            [Designer.Lou]: {
                label: Designer.Lou,
                value: Designer.Lou,
                color: "neutral"
            },
            [Designer.Lucy]: {
                label: Designer.Lucy,
                value: Designer.Lucy,
                color: "neutral"
            },
            [Designer.Michael]: {
                label: Designer.Michael,
                value: Designer.Michael,
                color: "neutral"
            },
            [Designer.Mirelys]: {
                label: Designer.Mirelys,
                value: Designer.Mirelys,
                color: "neutral"
            },
            [Designer.Natalie]: {
                label: Designer.Natalie,
                value: Designer.Natalie,
                color: "neutral"
            },
            [Designer.Nicole]: {
                label: Designer.Nicole,
                value: Designer.Nicole,
                color: "neutral"
            },
            [Designer.Roy]: {
                label: Designer.Roy,
                value: Designer.Roy,
                color: "neutral"
            },
            [Designer.Sarah]: {
                label: Designer.Sarah,
                value: Designer.Sarah,
                color: "neutral"
            },
            [Designer.Stephanie]: {
                label: Designer.Stephanie,
                value: Designer.Stephanie,
                color: "neutral"
            },
            [Designer.Stephen]: {
                label: Designer.Stephen,
                value: Designer.Stephen,
                color: "neutral"
            },
            [Designer.Sulah]: {
                label: Designer.Sulah,
                value: Designer.Sulah,
                color: "neutral"
            },
            [Designer.Victor]: {
                label: Designer.Victor,
                value: Designer.Victor,
                color: "neutral"
            }
        }
    }
}