import { Conversation, JSONObject } from "@twilio/conversations";
import { ConversationMetadata } from "../types";
import { MessageAdapter } from "./MessageAdapter";
import { User as TwilioUser } from "@twilio/conversations";

export class ConversationMetadataAdapter {
    private _conversation: Conversation;
    private _metadata: ConversationMetadata;

    constructor(conversation: Conversation) {
        this._conversation = conversation;
        this._metadata = {
            sid: conversation.sid,
            friendlyName: conversation.friendlyName ?? "Anonymous Conversation",
            type: (conversation.attributes as JSONObject).type as string,
            lastMessage: null,
            lastMessageAuthor: null,
            lastUpdated: conversation.lastMessage?.dateCreated ?? null,
            lastReadMessageIndex: conversation.lastReadMessageIndex,
            participants: [],
            unreadMessageCount: 0,
        }
    }
    
    /**
     * Get the latest metadata for the conversation.
     * @returns Updated Conversation metadata
     */
    async metadata(): Promise<ConversationMetadata> {
        // Fetch the last message so that we can display it as a message preview in the Conversation List
        const paginator = await this._conversation.getMessages(1);
        const lastMessage = paginator.items.length ? paginator.items[0] : null
        const transformedLastMessage = lastMessage ? await new MessageAdapter(lastMessage).adapt() : null;

        // Gather list of conversation participants
        const twilioParticipants = await this._conversation.getParticipants();

        // Name of the author of the last message
        let lastMessageAuthor: TwilioUser | null = null;
        if (lastMessage) {
            // Find the participant whose identity matches the lastMessage author's.
            // Participant.identity should be set to the user's email.
            const twilioParticipant = twilioParticipants.find(participant => participant.identity === lastMessage.author);
            lastMessageAuthor = await twilioParticipant?.getUser() ?? null;
        }

        // Map all the Twilio Participants to Twilio Users
        const twilioUsers = await Promise.all(twilioParticipants.map(twilioParticipant => twilioParticipant.getUser()));

        // Last updated is either the date of the last message, or date created.
        const lastUpdated: Date | null = lastMessage?.dateCreated ?? this._conversation.dateCreated;

        // Shows the number of unread messages in a conversation as a badge
        //
        // `conversation.getUnreadMessagesCount()` is a semi real-time function, which means
        // it is not always 100% accurate. If `lastReadMessageIndex` matches `lastMessage.index`
        // then don't bother checking the unread message count, you can assume it's 0
        // https://www.twilio.com/docs/conversations/best-practices-sdk-clients
        let unreadMessageCount: number = 0;
        if ((this._metadata.lastReadMessageIndex === null) ||
            (lastMessage && this._metadata.lastReadMessageIndex < lastMessage.index)) {
            unreadMessageCount = await this._conversation.getUnreadMessagesCount() ?? 0;
        }

        this._metadata.lastMessage = transformedLastMessage;
        this._metadata.lastMessageAuthor = lastMessageAuthor;
        this._metadata.lastUpdated = lastUpdated;
        this._metadata.participants = twilioUsers;
        this._metadata.unreadMessageCount = unreadMessageCount;
        return this._metadata;
    }
}