import { User as TwilioUser, Message as TwilioMessage, Media as TwilioMedia } from "@twilio/conversations";
import { MessageAttributes } from "../../../common/types";

export interface ConversationMetadata {
    sid: string;
    friendlyName: string;
    type: string | null;
    lastUpdated: Date | null;
    lastMessage: Message | null;
    lastMessageAuthor: TwilioUser | null;
    lastReadMessageIndex: number | null;
    participants: TwilioUser[];
    unreadMessageCount: number;
}

export interface MediaMetadata {
    sid: string;
    filename: string | null;
    initialUrl: string | null;
    content_type: string | null;
    twilioMedia: TwilioMedia
}

export interface Message {
    sid: string;
    participantSid: string | null;
    author: string | null;
    body: string | null;
    index: number;
    timestamp: Date | null;
    media: MediaMetadata[];
    ref: TwilioMessage;
    attributes: MessageAttributes;
}

export type Participant = {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    displayRole: string;
}

export enum SendResult {
    success,
    failure,
    ignored,
}