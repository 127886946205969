import { Link, Pane, Paragraph } from "evergreen-ui";
import { MessageAttributes } from "../../../../../common/types";
import Linkify from "linkify-react";
import * as linkify from "linkifyjs";

import '../Messages.css';

export interface InfoMessageViewProps {
    message: string;
    messageAttributes?: MessageAttributes;
    index: number;
    sid: string;
    messageTime: string | null;
    backgroundColor: string;
    title: string;
    icon: JSX.Element;
}

export const InfoMessageView: React.FC<InfoMessageViewProps> = (
    props: InfoMessageViewProps
) => {

    const {
        message,
        index,
        sid,
        backgroundColor,
        title,
        icon,
        messageTime,
    } = props;

    const renderTextMessage = () => {
        const options: linkify.Opts = {
            render: {
                url: ({ attributes, content }) => {
                    return <Link 
                        style={{
                            color: "var(--dark-green-primary)",
                            fontSize: "13px" 
                        }} 
                        target={"_blank"} 
                        href={attributes.href}>
                            {content}
                    </Link>
                },
            }
        }

        return message &&
            <Linkify options={options}>
                <Paragraph className="message-contents-text">
                    {message}
                </Paragraph>
            </Linkify>;
    };
    
    return (
        <Pane className="message-container-info" key={sid + index.toString()}
            backgroundColor={backgroundColor}
        >
            <Pane padding="15px" width="100%">
                <Pane className="message-title" marginBottom={10}>
                    {icon}
                    <span className="author"> {title} </span>
                    <span className="timestamp"> {messageTime} </span>
                </Pane>
                <Pane className="message-contents">
                    {renderTextMessage()}
                </Pane>
            </Pane>
        </Pane>
    )
}