import React, { FunctionComponent } from "react";
import { CaretDownIcon, Pane, Popover, Table } from "evergreen-ui";
import { AbstractTableBodyCellRenderer } from "./AbstractTableBodyCellRenderer";
import { SearchInput, SearchInputProps } from "../../../search/SearchInput";
import { TableBodyCellRendererInput } from "../input";

export class TableSearchableSingleSelectCellRenderer<T, SearchInput, SearchOutput> extends AbstractTableBodyCellRenderer<T> {
    private readonly searchInputProps: Pick<SearchInputProps<SearchInput, SearchOutput>, "query" | "queryInput" | "resultsMap" | "placeholderText">;

    constructor(
        input: TableBodyCellRendererInput<T>,
        searchInputProps: Pick<SearchInputProps<SearchInput, SearchOutput>, "query" | "queryInput" | "resultsMap" | "placeholderText">
    ) {
        super(input);
        this.searchInputProps = searchInputProps;
    }

    protected getMemoizedComponent(): FunctionComponent {
        return React.memo(() => {
            const { cellValue, columnConfig, onCellChanged, id } = this.input;
            return (
                <Popover
                    content={(
                        <Pane width={"max-content"} padding={8}>
                            <SearchInput
                                {...this.searchInputProps}
                                onSelectSearchResult={(label, value) => {
                                    if (onCellChanged) {
                                        onCellChanged(
                                            id,
                                            columnConfig.columnName as keyof T,
                                            value,
                                            label
                                        );
                                    }
                                }}
                            />
                        </Pane>
                    )}
                >
                    <Table.TextCell
                        className={"jc-body-cell dynamic-single-select"}
                        rightView={<CaretDownIcon size={12}/>}
                    >
                        {cellValue}
                    </Table.TextCell>
                </Popover>
            );
        });
    }
}
