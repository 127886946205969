import { LaunchType, ProductListingStatus } from "../../../common/enums";
import { Store, ProductListing, User } from "../../../common/models";

// Catalog Categories that render the sizing options
const categoriesRequiringSizing = ["Hoodies", "Sweatshirts", "T-Shirts", "Other Apparel"];

export const isProductListingComplete = (productListing: ProductListing, isPreOrderRestricted: boolean): boolean => {
    // If setting the launch type to Pre-Order for the ProductListing is restricted, then
    // submission should be blocked if it is
    if (isPreOrderRestricted && productListing.launchType === LaunchType.preOrder) {
        return false;
    }
    const categoryName = productListing.pricing.catalogOption.catalogProduct.catalogCategory.name ?? "";
    return !!productListing.pricing.catalogOption
        && !!productListing.description
        && (!categoryRequiresSizing(categoryName) || !!productListing.sizing)
        && !!productListing.shippingInfo
        && (productListing.mediaAssets.length > 0)
        && (productListing.mediaAssets.join("").length > 0) // Check for a single empty string.
        && (productListing.launchType !== LaunchType.unset);
};

export const isPhoneNumberValid = (phoneNumber?: string | null): boolean => {
    // Naively validate for a minimum of 10 numeric characters.
    return !!phoneNumber && phoneNumber.match(/^(\d{10,})$/) != null;
};

export const isAccountInfoComplete = (user?: User | null) => {
    return !!user && !!user.firstName && !!user.lastName && isPhoneNumberValid(user.phoneNumber);
};

export const areSocialsValid = (store: Store): boolean => {
    // At least one social media account must be provided.
    return (
        !!store.youtube ||
        !!store.instagram ||
        !!store.tiktok ||
        !!store.twitter ||
        !!store.twitch
    );
};

export const areRequiredStoreFieldsProvided = (store: Store): boolean => {
    return !!store.name && !!store.about && areSocialsValid(store);
};

export const categoryRequiresSizing = (category: string) => {
    return categoriesRequiringSizing.includes(category);
};

const editableProductListingStatuses = new Set<keyof typeof ProductListingStatus>([
    ProductListingStatus.draft,
    ProductListingStatus.requestedIntakeRevisions,
    ProductListingStatus.approvedWithRevisions,
]);

export const isCustomCatalogItem = (productListing?: ProductListing | null): boolean => {
    return !!productListing && productListing.pricing.catalogOption.isCustom;
};

export enum LockedProductReason {
    CustomProduct,
    Submitted,
    Cancelled,
}

export type LockedProductMetadata = {
    locked: boolean,
    reason?: LockedProductReason,
};

export const isProductListingLocked = (productListing?: ProductListing | null): LockedProductMetadata => {
    if (!productListing) {
        // Does not exist; usually means that the calling component's state is still initializing.
        return {
            locked: true,
        };
    }
    if (isCustomCatalogItem(productListing)) {
        // Custom catalog items can only be edited by an internal team member.
        return {
            locked: true,
            reason: LockedProductReason.CustomProduct,
        };
    }
    if (isProductListingCancelled(productListing)) {
        return {
            locked: true,
            reason: LockedProductReason.Cancelled,
        };
    }
    // `ProductListing.status` is only nullable temporarily until we backfill the column values.
    return {
        locked: !!productListing.status && !editableProductListingStatuses.has(productListing.status),
        reason: LockedProductReason.Submitted,
    };
};

export const productListingRequiresRevisions = (productListing: ProductListing): boolean => {
    return !!productListing.status && [
        ProductListingStatus.requestedIntakeRevisions,
        ProductListingStatus.approvedWithRevisions,
    ].includes(ProductListingStatus[productListing.status]);
};

export const isProductListingDraft = (productListing: ProductListing): boolean => {
    return productListing.status === ProductListingStatus.draft;
};

export const isProductListingCancelled = (productListing?: ProductListing | null): boolean => {
    return !!productListing
        && (!!productListing.status && (productListing.status === ProductListingStatus.cancelled));
};

export const isProductListingInDevelopment = (productListing: ProductListing) => {
    return !(
        productListing.status === ProductListingStatus.cancelled ||
        productListing.status === ProductListingStatus.completed ||
        productListing.status === ProductListingStatus.draft);
};

export interface ElapsedTimeDetails {
    time: number,
    unit: "second(s)" | "minute(s)" | "hour(s)" | "day(s)",
    direction: "past" | "future"
}

export const getElapsedTimeDetails = (date: Date): ElapsedTimeDetails => {
    const MILLIS_PER_SECOND: number = 1000;
    const SECONDS_PER_MINUTE: number = 60;
    const SECONDS_PER_HOUR: number = SECONDS_PER_MINUTE * 60;
    const SECONDS_PER_DAY: number = SECONDS_PER_HOUR * 24;
    const rawElapsedSeconds: number = Math.floor((Date.now() - date.getTime()) / MILLIS_PER_SECOND);
    const direction: "past" | "future" = rawElapsedSeconds < 0 ? "future" : "past";
    const elapsedSeconds: number = Math.abs(rawElapsedSeconds);

    if (elapsedSeconds < SECONDS_PER_MINUTE) {
        return {
            time: elapsedSeconds,
            unit: "second(s)",
            direction: direction
        };
    } else if (elapsedSeconds < SECONDS_PER_HOUR) {
        return {
            time: Math.floor(elapsedSeconds / SECONDS_PER_MINUTE),
            unit: "minute(s)",
            direction: direction
        };
    } else if (elapsedSeconds < SECONDS_PER_DAY) {
        return {
            time: Math.floor(elapsedSeconds / SECONDS_PER_HOUR),
            unit: "hour(s)",
            direction: direction
        };
    }

    return {
        time: Math.floor(elapsedSeconds / SECONDS_PER_DAY),
        unit: "day(s)",
        direction: direction
    };
};
