import { useState } from "react";

export const useFormValidation = () => {
    const [invalidFields, setInvalidFields] = useState<String[]>([]);

    const onInvalidChange = (name: string, invalid: boolean) => {
        if ((invalid && invalidFields.includes(name)) ||
            (!invalid && !invalidFields.includes(name))) {
            // Return early because either the invalid element has already been
            // added to the set of invalid elements, or the valid element doesn't
            // exist in the set of invalid elements. There is nothing to do in these
            // cases.
            return;
        }
        if (invalid) {
            setInvalidFields([...invalidFields, name])
        } else {
            const newInvalidElements = invalidFields.filter(e => e !== name);
            setInvalidFields(newInvalidElements);
        }
    };

    return {invalidFields, onInvalidChange};
};
