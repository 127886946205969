import {
    ChevronDownIcon,
    ChevronRightIcon,
    Heading,
    IconProps,
    Pane,
    PaneProps,
    Paragraph,
} from "evergreen-ui";
import React, { useState } from "react";
import { ColorPalette } from "../context/Theme";

type Props = {
    header: JSX.Element | string;
    subheading?: JSX.Element | string;
    chevronProps?: IconProps;
    paneProps?: PaneProps;
}

export const ExpandableView: React.FC<Props> = ({
    children,
    header,
    subheading,
    chevronProps,
    paneProps
}) => {
    const [expanded, setExpanded] = useState(true);

    const renderHeader = (): JSX.Element => {
        if (typeof header === "string") {
            return (
                <Heading size={600} fontWeight={400} marginLeft={10}>
                    {header}
                </Heading>
            );
        }
        return header;
    };

    const renderChevron = (): JSX.Element => {
        const ChevronIcon = expanded ? ChevronDownIcon : ChevronRightIcon;
        return (
            <ChevronIcon
                size={chevronProps?.size || 12}
                color={chevronProps?.color || ColorPalette.lightGreyText}
            />
        );
    };

    const renderSubHeadingIfNeeded = (): JSX.Element | null => {
        if (!subheading) {
            return null;
        }

        if (typeof subheading === "string") {
            return (
                <Pane {...paneProps}>
                    <Paragraph fontSize={11}>
                        {subheading}
                    </Paragraph>
                </Pane>
            );
        }
        return subheading;
    }

    return (
        <>
            <Pane {...paneProps} onClick={() => setExpanded(!expanded)}>
                {renderChevron()}
                {renderHeader()}
            </Pane>
            {expanded && (
                <>
                    {renderSubHeadingIfNeeded()}
                    {children}
                </>
            )}
        </>
    );
}
