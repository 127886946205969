import { toHyphenCase } from "../../../common/utils";
import { TileSelector } from "../common/selector/TileSelector";
import { TileSelectorGroupProps } from "../common/selector/TileSelectorGroup";
import { CatalogItem } from "../common/CatalogItem";

import '../common/selector/styles/TileSelectorGroup.css';

export function ProductWizardBasicTileSelectorGroup<T extends CatalogItem>(props: TileSelectorGroupProps<T>) {
    const {items = [], groupIdentifier = "", filter, selectedId, onSelect, tileSelectorType} = props;
    return (
        <div className="basic-tile-selector-group-container">
            {items.filter((item, index, array) => {
                return !filter || filter(item, index, array);
            }).map((item: T) => {
                const {name, imageUrl, id, displayName, description, shortDescription, isPopular, isNew} = item;
                return (
                    <TileSelector
                        id={toHyphenCase(groupIdentifier, displayName || name)}
                        key={id}
                        catalogItem={item}
                        isPopular={isPopular}
                        isNew={isNew}
                        selected={id === selectedId}
                        name={displayName || name}
                        description={description}
                        shortDescription={shortDescription}
                        imageUrl={imageUrl}
                        onSelected={() => onSelect(item)}
                        tileSelectorType={tileSelectorType}
                    />
                );
            })}
        </div>
    );
}
