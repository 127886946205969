import { useCallback, useContext, useEffect, useState } from "react";
import { DocumentNode } from "graphql";
import {
    ApolloClient,
    ApolloLink,
    ApolloQueryResult,
    createHttpLink,
    DefaultOptions,
    FetchResult,
    from,
    InMemoryCache,
    MutationOptions,
    OperationVariables,
    QueryOptions
} from "@apollo/client";
import { productOnboardingSelectionReactiveVar } from "./local/ProductOnboardingHelper";
import { analytics } from "../analytics";
import { StytchContext } from "../stytch/StytchContext";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_SERVER_URI + "/graphql",
    credentials: "include"
});

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = JSON.parse(JSON.stringify(operation.variables), (key: string, value: any) => (
            (key === '__typename') ? undefined : value
        ));
    }
    return forward(operation).map((data) => {
        return data;
    });
});

export const defaultFetchPolicy = "network-only";

const defaultOptions: DefaultOptions = {
    query: {
        fetchPolicy: defaultFetchPolicy,
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
    },
};

export const apolloClient = new ApolloClient({
    link: from([
        cleanTypeName,
        httpLink,
    ]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    productOnboardingSelection: {
                        read() {
                            return productOnboardingSelectionReactiveVar();
                        }
                    }
                }
            }
        }
    }),
    defaultOptions,
});

export const jssaQuery = <T = any>(
    options: QueryOptions<OperationVariables, T>,
): Promise<ApolloQueryResult<T>> => {
    return new Promise((resolve, reject) => {
        apolloClient.query<T>(options)
            .then((response) => {
                if (response.errors) {
                    reject(response.errors[0].message);
                } else {
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(`Server error: '${error}'`);
            });
    });
};

// TODO: refactor this and `jssaQuery` into a common base function with generics
export const jssaMutation = <T = any>(
    options: MutationOptions<T>,
): Promise<FetchResult<T>> => {
    return new Promise((resolve, reject) => {
        apolloClient.mutate<T>(options)
            .then(response => {
                if (response.errors) {
                    reject(response.errors[0].message);
                } else {
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(`Server error: '${error}'`);
            });
    });
};

export type BaseQueryResult<T> = { loading: boolean, error: any, data: T | null, refetch: (newVars?: any) => void };
export const useBaseQuery = <TResult, TResponse>(
    query: DocumentNode,
    variables: any,
    transform: (data: ApolloQueryResult<TResponse>) => TResult | null
): BaseQueryResult<TResult> => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<TResult | null>(null);

    const fetchData = useCallback((newVars: any = variables) => {
        setLoading(true);
        jssaQuery<TResponse>({query, variables: newVars})
            .then((response) => {
                setData(transform(response));
            })
            .catch((error) => {
                console.error(error);
                analytics.sendException(error);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // eslint-disable-next-line
    useEffect(fetchData, []);

    return {loading, error, data, refetch: fetchData};
};

export type BaseMutationTuple<InputType, ResultType> = [
    (variables: InputType) => void,
    { loading: boolean, error?: any, called: boolean, data?: ResultType | null }
];

export const useBaseMutation = <InputType, ResultType, >(
    mutation: DocumentNode,
    transformInput: (input: InputType) => any,
    transformOutput?: (output: any) => ResultType,
    onResponse?: (data: ResultType) => void,
): BaseMutationTuple<InputType, ResultType> => {
    const { checkAuth } = useContext(StytchContext);
    const [loading, setLoading] = useState(false);
    const [called, setCalled] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState<ResultType | null>(null);

    const callback = useCallback((input: InputType): void => {
        const executeMutation = () => {
            jssaMutation({
                mutation,
                variables: transformInput(input),
            }).then(({data}) => {
                data = transformOutput ? transformOutput(data) : data;
                setData(data);
                if (onResponse) {
                    onResponse(data);
                }
            }).catch((error) => {
                console.error(error);
                checkAuth();
                analytics.sendException(error);
                setError(error);
            }).finally(() => {
                setLoading(false);
            });
        };

        setLoading(true);
        setCalled(true);
        setError(null);
        setData(null);

        executeMutation();
    }, []);

    return [callback, {loading, error, called, data}];
};
