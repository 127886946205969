import { Pane } from "evergreen-ui";
import { ConversationPreferenceItem } from "./ConversationPreferenceItem";
import { NotificationOverridePreference } from "../../../../../common/models";
import { NotificationFrequencyDisplayOption, NotificationFrequencyDisplayOptions } from "../../../common/NotificationSettings";
import { ConversationStore } from "../../../native-messaging/state/NativeMessagingProvider";
import { getNotificationDestinationTypes } from "../../../../../common/utils/Notifications";

import '../styles/MultiTabSettingsPage.css';

type Props = {
    notificationOverridePreferences: NotificationOverridePreference[];
    conversationStore: ConversationStore;
    onClick: (NotificationOverridePreference: NotificationOverridePreference) => void;
    onClickDelete: (NotificationOverridePreference: NotificationOverridePreference) => void;
};

export const ConversationPreferenceContainer = ({ conversationStore, notificationOverridePreferences, onClick, onClickDelete }: Props) => {

    const getChannelFrequencyLabel = (notificationOverridePreference: NotificationOverridePreference): string => {
        const channels = getNotificationDestinationTypes(notificationOverridePreference);
        const frequencyLabel = NotificationFrequencyDisplayOptions[notificationOverridePreference.category].find(
            (notificationFrequencyDisplayOption: NotificationFrequencyDisplayOption) => {
                return notificationFrequencyDisplayOption.value === notificationOverridePreference.frequency;
            })!.shortLabel;
        return `${channels.join(" + ")} ${frequencyLabel}`;
    };

    return (
        <Pane>
            <Pane paddingBottom={12}>
                <span className={"notification-preference-heading"}>
                    Conversation-Specific Settings
                </span>
            </Pane>
            <Pane paddingBottom={12}>
                <p className={"notification-preference-description"}>
                    These notification settings only apply to these conversations.
                </p>
            </Pane>
            <Pane className={"conversation-preference-container"}>
                {notificationOverridePreferences
                    .filter((overridePreference: NotificationOverridePreference) => {
                        return overridePreference.overrideEntityId in conversationStore
                    })
                    .map((overridePreference: NotificationOverridePreference) => (
                        <ConversationPreferenceItem
                            conversationSid={overridePreference.overrideEntityId}
                            conversationName={conversationStore[overridePreference.overrideEntityId].friendlyName}
                            frequencyLabel={getChannelFrequencyLabel(overridePreference)}
                            onClick={() => onClick(overridePreference)}
                            onClickDelete={() => onClickDelete(overridePreference)}
                        />
                    ))}
            </Pane>
        </Pane>
    );
}