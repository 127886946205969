export enum OnlineListingState {
    ProductListing_GenerateSKU = "ProductListing_GenerateSKU",
    ProductListing_ProductPhotos = "ProductListing_ProductPhotos",
    ProductListing_ExternalPricing = "ProductListing_ExternalPricing",
    ProductListing_InternalPricing = "ProductListing_InternalPricing",
    ProductListing_ProductDescription = "ProductListing_ProductDescription",
    ProductListing_LaunchDate = "ProductListing_LaunchDate",
    ProductListing_WebsiteReady = "ProductListing_WebsiteReady",
    MerchShelf_InternalOperations = "MerchShelf_InternalOperations",
    MerchShelf_ExternalOperations = "MerchShelf_ExternalOperations",
    NotRequired = "NotRequired",
}
