export enum DqReason {
    under18 = "under18",
    lowEngagement = "lowEngagement",
    spam = "spam",
    unableToMeetCreatorDesire = "unableToMeetCreatorDesire", 
    abandoned = "abandoned",
    paused = "paused",
    termination = "termination",
    movedToPartnerships = "movedToPartnerships",
    priceMOQTooHigh = "priceMOQTooHigh",
    sampleIssues = "sampleIssues",
    designIssues = "designIssues",
    reducingNumberOfProducts = "reducingNumberOfProducts"
}