import { NotificationCategory } from "../../../../../common/enums"
import { MessageViewStyle, MESSAGE_VIEW_STYLES } from "../../../common/MessageViewStyle";
import { Message } from "../../types"
import { InfoMessageView } from "./InfoMessageView";
import { MediaAttachment, StandardMessageView } from "./StandardMessageView";

type MessageViewFactoryProps = {
    message: Message;
    participantName: string;
    participantIndex: number;
    messageTime: string;
    isFirstInGroup: boolean;
    openDeleteMessageDialog: (sid: string) => void;
};

export const MessageViewFactory = ({
    message,
    participantName,
    participantIndex,
    messageTime,
    isFirstInGroup,
    openDeleteMessageDialog
}: MessageViewFactoryProps) => {

    const { notificationCategory, notificationType, title } = message.attributes;

    const getMediaAttachments = (): MediaAttachment[] => {
        return message.media!.map(media => ({
            sid: media.sid,
            fileName: media.filename,
            initialFileUrl: media.initialUrl,
            contentType: media.content_type,
            twilioMedia: media.twilioMedia
        }));
    };

    // This needs a lot of work -- right now we've left everything as grey, but eventually we'll
    // need to either separate this by category or something else. So we'll leave it like this for now.
    const resolveMessageViewStyle = (notificationCategory: NotificationCategory): MessageViewStyle => {
        return MESSAGE_VIEW_STYLES[notificationCategory]!;
    };

    const getStandardMessageView = () => {
        return (
            <StandardMessageView
                participantIndex={participantIndex}
                sid={message.sid || ""}
                message={message.body || ""}
                messageAttributes={message.attributes}
                author={message.author || ""}
                authorName={participantName}
                index={message.index}
                messageTime={messageTime}
                isFirstInGroup={isFirstInGroup}
                mediaAttachments={getMediaAttachments()}
                openDeleteMessageDialog={openDeleteMessageDialog}
            />
        );
    };

    const getInfoMessageView = (messageViewStyle: MessageViewStyle) => {
        return (
            <InfoMessageView
                message={message.body || ""}
                index={message.index}
                sid={message.sid}
                backgroundColor={messageViewStyle.backgroundColor}
                title={title || ""}
                icon={messageViewStyle.icon}
                messageTime={messageTime}
            />
        );
    };

    switch (notificationCategory) {
        case (NotificationCategory.ConversationMessage):
            return getStandardMessageView();
        case (NotificationCategory.ProductDevUpdate):
        case (NotificationCategory.ProductDevConfirmation):
            const messageViewStyle = resolveMessageViewStyle(notificationCategory);
            return getInfoMessageView(messageViewStyle);
        default:
            return getStandardMessageView();
    };

}