import { CatalogOption } from "../../../common/models";
import { toHyphenCase } from "../../../common/utils";
import { TileSelectorGroupProps } from "../common/selector/TileSelectorGroup";
import { BuildIcon, CleanIcon, CogIcon, DollarIcon, DuplicateIcon, GridIcon, Icon, Pane, TagIcon, TintIcon } from "evergreen-ui";
import { ApparelTileSelector } from "../common/selector/ApparelSelector";

import '../common/selector/styles/TileSelectorGroup.css';

export function ProductWizardApparelSelectorGroup(props: TileSelectorGroupProps<CatalogOption>) {
    const {items = [], groupIdentifier = "", filter, selectedId, onSelect} = props;
    return (
        <div className="apparel-tile-selector-group-container">
            <Pane className={"apparel-selector-container no-border"}>
                <Pane className={"apparel-selector-image"} />
                <Pane className="apparel-tile-selector-bottom-container">
                    <Pane className={"apparel-selector-bottom"} />
                    <Pane className="apparel-selector-apparel-info">
                        <div className={"apparel-selector-row"}>
                            <span className="jc-wizard-pricing-label">
                                <Icon icon={TintIcon} size={12} />Apparel Printing
                            </span>
                        </div>
                        <div className={"apparel-selector-row"}>
                            <span className="jc-wizard-pricing-label">
                                <Icon icon={GridIcon} size={12} />Fabric Colours
                            </span>
                        </div>
                    </Pane>
                    <Pane className="apparel-selector-pricing-info" border="0px !important">
                        <div className={"apparel-selector-row"}>
                            <span className="jc-wizard-pricing-label">
                                <Icon icon={DollarIcon} size={12} />Unit Cost
                            </span>
                        </div>
                        <div className={"apparel-selector-row"}>
                            <span className="jc-wizard-pricing-label">
                                <Icon icon={TagIcon} size={12} />Suggested Retail
                            </span>
                        </div>
                        <div className={"apparel-selector-row"}>
                            <span className="jc-wizard-pricing-label">
                                <Icon icon={CleanIcon} size={12} />Your Profit per Unit
                            </span>
                        </div>
                        <div className={"apparel-selector-row"}>
                            <span className="jc-wizard-pricing-label">
                                <Icon icon={BuildIcon} size={12} />Sample Cost
                            </span>
                        </div>
                        <div className={"apparel-selector-row"}>
                            <span className="jc-wizard-pricing-label">
                                <Icon icon={CogIcon} size={12} />Minimum Order
                            </span>
                        </div>
                    </Pane>
                </Pane>
            </Pane>
            {items.filter((item, index, array) => {
                return !filter || filter(item, index, array);
            }).map((item: CatalogOption) => {
                const {name, imageUrl, id, displayName, description, shortDescription, isPopular, isNew} = item;
                return (
                    <ApparelTileSelector
                        id={toHyphenCase(groupIdentifier, displayName || name)}
                        key={id}
                        catalogItem={item}
                        isPopular={isPopular}
                        isNew={isNew}
                        selected={id === selectedId}
                        name={displayName || name}
                        description={description}
                        shortDescription={shortDescription}
                        imageUrl={imageUrl}
                        onSelected={() => onSelect(item)}
                    />
                );
            })}
        </div>
    );
}
