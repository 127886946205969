import { ManufacturingWorkstreamState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class ManufacturingWorkstreamStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [ManufacturingWorkstreamState.Invoice_ReadyToGenerate]: {
                label: "Invoice, Ready to Generate",
                value: ManufacturingWorkstreamState.Invoice_ReadyToGenerate,
                color: "neutral"
            },
            [ManufacturingWorkstreamState.Invoice_EditsRequired]: {
                label: "Invoice, Edits Required",
                value: ManufacturingWorkstreamState.Invoice_EditsRequired,
                color: "neutral"
            },
            [ManufacturingWorkstreamState.Invoice_Sent]: {
                label: "Invoice, Sent",
                value: ManufacturingWorkstreamState.Invoice_Sent,
                color: "neutral"
            },
            [ManufacturingWorkstreamState.Invoice_Paid]: {
                label: "Invoice, Paid",
                value: ManufacturingWorkstreamState.Invoice_Paid,
                color: "neutral"
            },
            [ManufacturingWorkstreamState.Manufacturing_ProductSizingAndDistribution]: {
                label: "Manufacturing, Product Sizing and Distribution",
                value: ManufacturingWorkstreamState.Manufacturing_ProductSizingAndDistribution,
                color: "neutral"
            },
            [ManufacturingWorkstreamState.Manufacturing_OrderSubmitted]: {
                label: "Manufacturing, Order Submitted",
                value: ManufacturingWorkstreamState.Manufacturing_OrderSubmitted,
                color: "neutral"
            },
            [ManufacturingWorkstreamState.Manufacturing_InProgress]: {
                label: "Manufacturing, In Progress",
                value: ManufacturingWorkstreamState.Manufacturing_InProgress,
                color: "neutral"
            },
            [ManufacturingWorkstreamState.Manufacturing_Completed]: {
                label: "Manufacturing, Completed",
                value: ManufacturingWorkstreamState.Manufacturing_Completed,
                color: "green"
            },
        };
    }
}