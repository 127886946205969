// These lengths can be verified by comparing the values in this file against
// the values in `server/prisma/schema.prisma`

export const EMAIL_MAX_LENGTH = 50;
export const FIRST_NAME_MAX_LENGTH = 50;
export const LAST_NAME_MAX_LENGTH = 50;

export const YOUTUBE_MAX_LENGTH = 100;
export const INSTAGRAM_MAX_LENGTH = 50;
export const TIKTOK_MAX_LENGTH = 50;
export const TWITTER_MAX_LENGTH = 50;
export const TWITCH_MAX_LENGTH = 50;
