import { FC, useEffect, useRef, useState } from "react";
import { check, logout as stytchLogout } from "../datastore/AuthDataStore";
import { StytchContext } from "./StytchContext";

export const StytchProvider: FC = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isInvited, setIsInvited] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const mounted = useRef(false);

    const checkAuth = (): Promise<void> => {
        setIsLoading(true);
        return check()
            .then(resp => {
                if (!mounted.current) {
                    // Component was unmounted
                    return;
                }
                if (resp.ok) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
                setIsLoading(false);
            });
    };

    const logout = (): Promise<void> => {
        return stytchLogout()
            .then(() => {
                if (!mounted.current) {
                    // Component was unmounted
                    return;
                }
                setIsInvited(false);
                setIsAuthenticated(false);
            });
    };

    useEffect(() => {
        mounted.current = true;
        checkAuth();
        return () => {mounted.current = false};
    }, []);

    return (
        <StytchContext.Provider value={{
            isAuthenticated: isAuthenticated,
            setIsAuthenticated: setIsAuthenticated,
            isInvited: isInvited,
            setIsInvited: setIsInvited,
            isLoading: isLoading,
            checkAuth: checkAuth,
            logout: logout,
        }}>
            {children}
        </StytchContext.Provider>
    );
}