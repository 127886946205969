import { FunctionComponent } from "react";

export abstract class AbstractRenderer {
    public render(): JSX.Element {
        const Component: FunctionComponent = this.getMemoizedComponent();
        return <Component key={this.getKey()} />;
    }

    protected abstract getMemoizedComponent(): FunctionComponent;

    protected abstract getKey(): string;
}
