import React from "react";
import { EmptyState, EmptyStateType } from "../common/EmptyState";
import { WarningSignIcon } from "evergreen-ui";

export const NotFound = () => {
    return (
        <EmptyState preset={EmptyStateType.notFound}>
            <WarningSignIcon size={60}/>
        </EmptyState>
    )
};
