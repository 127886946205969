import { Pane } from "evergreen-ui";
import { FC } from "react";
import { CatalogItem } from "../../common/CatalogItem";
import { useMobileMediaQuery } from "../../common/MediaQuery";
import { ProductWizardPricing } from "../ProductWizardPricing";
export interface ActionBarInfoProps {
    emptyText: string;
    availabilityText: string;
    catalogItem?: CatalogItem | null;
    groupIdentifier: string;
}

export const ActionBarInfo: FC<ActionBarInfoProps> = ({
    emptyText,
    availabilityText,
    catalogItem,
    groupIdentifier,
}) => {

    const isMobile = useMobileMediaQuery();

    return catalogItem ? (
        <Pane display={"flex"}>
            {(!isMobile || (groupIdentifier === "category")) && <Pane marginRight={48} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                <div className="jc-wizard-infobox-header">
                    {catalogItem.displayName || catalogItem.name}
                </div>
                {availabilityText !== "" ? <div>
                    <span>{availabilityText}</span>
                </div> : null}
            </Pane> }
            {(groupIdentifier !== "category") && (
                <ProductWizardPricing
                    catalogItem={catalogItem}
                />
            )}
        </Pane>
    ) : (<span>{emptyText}</span>);
}
