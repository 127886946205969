import { gql } from "@apollo/client";
import { BaseMutationTuple, jssaQuery, useBaseMutation } from "./index";
import { PayoutRule } from "../../../common/models";
import { payoutRuleAllFieldsFragment } from "./CommonQueries";
import { cleanObject } from "../../../common/utils";
import { PayoutRuleCreateInput } from "../../../common/inputs/PayoutRuleInput";

export const payoutRulesByStoreQuery = gql`
    ${payoutRuleAllFieldsFragment}
    query payoutRulesByStore($storeId: String!) {
        payoutRulesByStore(storeId: $storeId) {
            ...PayoutRuleAllFields    
        }
    }
`;

export const createPayoutRuleMutation = gql`
    ${payoutRuleAllFieldsFragment}
    mutation createPayoutRule($data: PayoutRuleCreateInput!) {
        createPayoutRule(data: $data) {
            ...PayoutRuleAllFields
        }
    }
`;

export const updatePayoutRuleMutation = gql`
    ${payoutRuleAllFieldsFragment}
    mutation updatePayoutRule($data: PayoutRuleUpdateInput!) {
        updatePayoutRule(data: $data) {
            ...PayoutRuleAllFields
        }
    }
`;

export const useCreatePayoutRuleMutation = (): BaseMutationTuple<PayoutRuleCreateInput, PayoutRule> => {
    const transformInput = (input: PayoutRuleCreateInput) => {
        return { data: input };
    };

    const transformOutput = (output: any) => {
        return output.createPayoutRule;
    };

    return useBaseMutation<PayoutRuleCreateInput, PayoutRule>(
        createPayoutRuleMutation,
        transformInput,
        transformOutput,
    );
};

export const useUpdatePayoutRuleMutation = (): BaseMutationTuple<PayoutRule, PayoutRule> => {
    const transformInput = (payoutRule: PayoutRule) => {
        return {
            data: cleanObject(payoutRule, {
                exclusions: ["createdAt", "updatedAt", "storeId"]
            })
        };
    };

    const transformOutput = (output: any) => {
        return output.updatePayoutRule;
    }

    return useBaseMutation<PayoutRule, PayoutRule>(
        updatePayoutRuleMutation,
        transformInput,
        transformOutput,
    );
};

export const queryPayoutRulesByStore = async (storeId: string): Promise<PayoutRule[]> => {
    const { data: { payoutRulesByStore } } = await jssaQuery({
        query: payoutRulesByStoreQuery,
        variables: {
            storeId: storeId,
        },
    });
    return payoutRulesByStore;
}
