import { Source } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class SourceMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [Source.googleAds]: {
                label: "Google Ads",
                value: Source.googleAds,
                color: "neutral"
            },
            [Source.facebookAds]: {
                label: "Facebook Ads",
                value: Source.facebookAds,
                color: "neutral"
            },
            [Source.outbound]: {
                label: "Outbound",
                value: Source.outbound,
                color: "neutral"
            },
            [Source.inbound]: {
                label: "Inbound",
                value: Source.inbound,
                color: "neutral"
            },
            [Source.inboundAssisted]: {
                label: "Inbound Assisted",
                value: Source.inboundAssisted,
                color: "neutral"
            },
            [Source.referral]: {
                label: "Referral",
                value: Source.referral,
                color: "neutral"
            },
            [Source.juniperPartnerships]: {
                label: "Juniper Partnerships",
                value: Source.juniperPartnerships,
                color: "neutral"
            },
            [Source.agencyReferral]: {
                label: "Agency Referral",
                value: Source.agencyReferral,
                color: "neutral"
            },
            [Source.tradeShow]: {
                label: "Trade Show",
                value: Source.tradeShow,
                color: "neutral"
            },
            [Source.upsell]: {
                label: "Upsell",
                value: Source.upsell,
                color: "neutral"
            },
            [Source.organicReturn]: {
                label: "Organic Return",
                value: Source.organicReturn,
                color: "neutral"
            },
            [Source.other]: {
                label: "Other",
                value: Source.other,
                color: "neutral"
            },
        };
    }
}