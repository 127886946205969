import { ManufacturingDrawingState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class ManufacturingDrawingStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [ManufacturingDrawingState.DesignerAssigned]: {
                label: "Designer Assigned",
                value: ManufacturingDrawingState.DesignerAssigned,
                color: "neutral"
            },
            [ManufacturingDrawingState.QuoteGiven]: {
                label: "Quote Given",
                value: ManufacturingDrawingState.QuoteGiven,
                color: "neutral"
            },
            [ManufacturingDrawingState.QuoteAccepted]: {
                label: "Quote Accepted",
                value: ManufacturingDrawingState.QuoteAccepted,
                color: "neutral"
            },
            [ManufacturingDrawingState.Designing]: {
                label: "Designing",
                value: ManufacturingDrawingState.Designing,
                color: "neutral"
            },
            [ManufacturingDrawingState.EditsRequired]: {
                label: "Edits Required",
                value: ManufacturingDrawingState.EditsRequired,
                color: "yellow"
            },
            [ManufacturingDrawingState.ClientApproved]: {
                label: "Client Approved",
                value: ManufacturingDrawingState.ClientApproved,
                color: "neutral"
            },
            [ManufacturingDrawingState.InternalApproved]: {
                label: "Internal Approved",
                value: ManufacturingDrawingState.InternalApproved,
                color: "green"
            },
        };
    }
}