import {
    CleanIcon,
    TickCircleIcon,
    FormIcon,
    Pane,
    Paragraph,
    ShopIcon
} from "evergreen-ui";

import './styles/StartCreatingPage.css';

export const StartCreating = () => {
    return (
        <Pane>
            <Pane className={"jc-wizard-container"}>
                <Pane textAlign={"center"}>
                    <Pane className={"jc-wizard-header"}>
                        <h1 className={"jc-wizard-title"}>
                            Start creating.
                        </h1>
                    </Pane>
                    <Pane className={"start-creating-card-container"}>
                        <Pane className={"start-creating-card"}>
                            <TickCircleIcon />
                            <Paragraph>
                                Browse our catalog and select the products you want to make.
                            </Paragraph>
                        </Pane>
                        <Pane className={"start-creating-card"}>
                            <CleanIcon />
                            <Paragraph>
                                Access the Juniper app, add payment details, and connect with a designer.
                            </Paragraph>
                        </Pane>
                        <Pane className={"start-creating-card"}>
                            <FormIcon />
                            <Paragraph>
                                Submit your designs so we can create a physical sample.
                            </Paragraph>
                        </Pane>
                        <Pane className={"start-creating-card"}>
                            <ShopIcon />
                            <Paragraph>
                                Approve your sample, launch your product, and track your sales.
                            </Paragraph>
                        </Pane>
                    </Pane>
                </Pane>
            </Pane>
        </Pane>
    );
};
