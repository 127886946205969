import { DesignInvoiceState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class DesignInvoiceStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [DesignInvoiceState.ReadyToGenerate]: {
                label: "Ready to Generate",
                value: DesignInvoiceState.ReadyToGenerate,
                color: "neutral"
            },
            [DesignInvoiceState.EditsRequired]: {
                label: "Edits Required",
                value: DesignInvoiceState.EditsRequired,
                color: "yellow"
            },
            [DesignInvoiceState.Sent]: {
                label: "Sent",
                value: DesignInvoiceState.Sent,
                color: "neutral"
            },
            [DesignInvoiceState.Paid]: {
                label: "Paid",
                value: DesignInvoiceState.Paid,
                color: "green"
            }
        };
    }
}