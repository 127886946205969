import React, { FunctionComponent } from "react";
import { Table } from "evergreen-ui";
import { AbstractTableBodyCellRenderer } from "./AbstractTableBodyCellRenderer";
import { genericMemo } from "../../../GenericMemo";

export class TableReadOnlyCellRenderer<T> extends AbstractTableBodyCellRenderer<T> {
    protected getMemoizedComponent(): FunctionComponent {
        const {cellValue} = this.input;
        return genericMemo(() => (
            <Table.TextCell className={"jc-body-cell read-only"}>
                {cellValue}
            </Table.TextCell>
        ));
    }
}
