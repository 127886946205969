import { Alert, Pane } from "evergreen-ui"
import { FC, useState } from "react";
import { useLocation } from "react-router-dom";

export const AnnouncementBarWrap: FC = ({ children }) => {
    const location = useLocation();
    // TODO: Replace initial state with true.
    const [showAlertBanner, setShowAlertBanner] = useState<boolean>(false);
    const fullScreenRegex = [
        "/login", "/registration", "/logout", "/products/new", "/products/.*/catalog/edit", "/new-user/.*"
    ].join("|");

    // TODO: Replace with actual title and body text.
    return (
        <Pane className={"jc-app-container"}>
            <Pane
                display={(showAlertBanner && !location.pathname.match(fullScreenRegex)) ? "block" : "none"}
            >
                <Alert
                    id="announcement-banner"
                    borderRadius={0}
                    padding={18}
                    intent={"info"}
                    hasIcon={true}
                    title={"Placeholder Title"}
                    isRemoveable
                    onRemove={() => {
                        setShowAlertBanner(false);
                    }}
                >
                    <p style={{ margin: "6px 0 0" }}>
                        Placeholder body text.
                    </p>
                </Alert>
            </Pane>
            <Pane
                className="jc-content-container"
                display="flex"
            >
                {children}
            </Pane>
        </Pane>
    );
};
