import { Checkbox, Table } from "evergreen-ui";
import React, { ChangeEvent, FunctionComponent } from "react";
import { AbstractTableBodyCellRenderer } from "./AbstractTableBodyCellRenderer";

export class TableCheckboxCellRenderer<T> extends AbstractTableBodyCellRenderer<T> {
    protected getMemoizedComponent(): FunctionComponent {
        const { columnConfig, cellValue, onCellChanged, id } = this.input;
        return React.memo(() => {
            return (
                <Table.Cell className={"jc-body-cell checkbox"}>
                    <Checkbox
                        checked={Boolean(cellValue)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (onCellChanged) {
                                onCellChanged(
                                    id,
                                    columnConfig.columnName as keyof T,
                                    e.target.checked,
                                );
                            }
                        }}
                    />
                </Table.Cell>
            )
        });
    }
}
