import { FC, useCallback } from "react";
import { ProductListing } from "../../../../../common/models";
import { useUpdateStoreMutation } from "../../../datastore/StoreDataStore";
import { useUpdateProductListingMutation } from "../../../datastore/ProductListingDataStore";
import { DefaultProductListingTableConfig } from "../tables/config/DefaultProductListingTableConfig";
import { ProductListingForAdmin } from "../../../../../common/tables/product/ProductListingForAdmin";
import { queryProductListingsForAdmin } from "../../../datastore/UserDataStore";
import { AbstractTableViewProvider } from "../../../common/tables/view/AbstractTableViewProvider";
import { TableCellFactory } from "../../../common/tables/factory";
import { ProductTableCellFactory } from "../tables/factory/ProductTableCellFactory";

const productTableCellFactory: TableCellFactory<ProductListingForAdmin, ProductListing> = new ProductTableCellFactory();

export const AdminProductListProvider: FC = ({ children }) => {
    const [
        updateProductListing,
        {
            loading: productMutationLoading,
            error: productMutationError,
            called: productMutationCalled,
        },
    ] = useUpdateProductListingMutation();

    const [
        updateStore,
        {
            loading: storeMutationLoading,
            error: storeMutationError,
            called: storeMutationCalled,
        },
    ] = useUpdateStoreMutation();

    const onUpdateData = useCallback((
        prevData: ProductListing[],
        id: string | number,
        columnName: keyof ProductListingForAdmin,
        value: string | number | boolean | null,
        label?: string,
    ): ProductListing[] => {
        return prevData.map((original: ProductListing) => {
            // Skip over all product listings that aren't being updated.
            if (id !== original.id) {
                return original;
            }

            let rowToBeUpdated: ProductListing;

            // Special case – the associated Store is being updated.
            if (columnName === "storeId") {
                updateProductListing({productListing: original, storeId: value as string});
                rowToBeUpdated = {
                    ...original,
                    store: {
                        ...original.store,
                        id: value as string,
                        name: label ?? "",
                    }
                };
            }

            // Special case – the `Store.domain` is being updated. Note that if there are
            // other `ProductListing`s in the table that are associated with the same `Store`,
            // these updates won't be reflected until the next reload.
            else if (columnName === "domain") {
                const storeToUpdate = {
                    ...original.store,
                    domain: value as string,
                };
                updateStore(storeToUpdate);
                rowToBeUpdated = {
                    ...original,
                    store: storeToUpdate,
                };
            }

            // All other columns are handled ordinarily.
            else {
                rowToBeUpdated = {...original, [columnName]: value};
                updateProductListing({productListing: rowToBeUpdated});
            }

            return rowToBeUpdated;
        })
    }, [updateProductListing, updateStore]);

    return (
        <AbstractTableViewProvider
            queryTableData={queryProductListingsForAdmin}
            onUpdateRow={onUpdateData}
            updateRowLoading={productMutationLoading || storeMutationLoading}
            updateRowCalled={productMutationCalled || storeMutationCalled}
            updateRowError={productMutationError || storeMutationError}
            tableCellFactory={productTableCellFactory}
            savedTableConfigProps={{
                tableName: "productListingsForAdmin",
                identifiers: ["v3"],
                defaultConfigValue: DefaultProductListingTableConfig,
            }}
        >
            {children}
        </AbstractTableViewProvider>
    );
}
