import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
    AbstractTableViewProvider
} from "../../../../common/tables/view/AbstractTableViewProvider";
import {
    queryPayoutRulesByStore, useCreatePayoutRuleMutation,
    useUpdatePayoutRuleMutation
} from "../../../../datastore/PayoutRuleDataStore";
import {
    PayoutRuleTableRow
} from "../../../../../../common/tables/store/payout-rules/PayoutRuleTableRow";
import { PayoutRule } from "../../../../../../common/models";
import { DefaultPayoutRulesTableConfig } from "../tables/config/DefaultPayoutRulesTableConfig";
import { PayoutRuleTableCellFactory } from "../factory/PayoutRulesTableCellFactory";
import { TableCellFactory } from "../../../../common/tables/factory";
import { PayoutRulesTableRowSideSheetForm } from "../tables/PayoutRulesTableRowSideSheetForm";
import { PayoutRuleType } from "../../../../../../common/enums";
import { PayoutRuleCreateInput } from "../../../../../../common/inputs/PayoutRuleInput";
import { TableView } from "../../../../common/tables/view/TableView";

const payoutRuleTableCellFactory: TableCellFactory<PayoutRuleTableRow, PayoutRule> = new PayoutRuleTableCellFactory();

export const PayoutRulesByStoreTableView: FC<{ storeId: string }> = ({ storeId }) => {
    const defaultPayoutRule: Partial<PayoutRule> = useMemo(() => {
        return {
            storeId: storeId,
            type: PayoutRuleType.vendor,
        };
    }, [storeId]);

    const [showCreateRowForm, setShowCreateRowForm] = useState<boolean>(false);
    const [payoutRuleCreateInput, setPayoutRuleCreateInput] =
        useState<Partial<PayoutRuleCreateInput>>(defaultPayoutRule);

    const queryTableData = useCallback(() => {
        return queryPayoutRulesByStore(storeId);
    }, [storeId]);

    const [
        createPayoutRule,
        {
            loading: createPayoutRuleLoading,
            error: createPayoutRuleError,
            called: createPayoutRuleCalled,
        }
    ] = useCreatePayoutRuleMutation();

    const [
        updatePayoutRule,
        {
            loading,
            called,
            error,
        }
    ] = useUpdatePayoutRuleMutation();

    const onOpenSideSheet = useCallback(() => {
        setShowCreateRowForm(true);
    }, []);

    const onSubmitSideSheet = useCallback(() => {
        createPayoutRule(payoutRuleCreateInput as PayoutRuleCreateInput);
    }, [createPayoutRule, payoutRuleCreateInput]);

    const onCancelSideSheet = useCallback(() => {
        setShowCreateRowForm(false);
    }, [setShowCreateRowForm]);

    const onUpdateRow = useCallback((
        payoutRules: PayoutRule[],
        id: string | number,
        columnName: keyof PayoutRuleTableRow,
        value: string | number | boolean | null,
        label?: string,
    ): PayoutRule[] => {
        return payoutRules.map((payoutRule: PayoutRule) => {
            // Skip over all PayoutRule rows that aren't being updated.
            if (payoutRule.id !== id) {
                return payoutRule;
            }

            const payoutRuleToUpdate = {
                ...payoutRule,
                [columnName]: value,
            };

            // Send the modified data to the server.
            updatePayoutRule(payoutRuleToUpdate);

            // Modify the PayoutRule object with the new cell value.
            return payoutRuleToUpdate;
        });
    }, []);

    const emptyViewProps = useMemo(() => {
        return {
            primaryText: "No payout rules found",
            secondaryText: "Add Payout Rule",
            onClickSecondaryText: () => setShowCreateRowForm(true),
        };
    }, []);

    const errorViewProps = useMemo(() => {
        return {
            primaryText: "Failed to load payout rules",
            secondaryText: "Refresh the page to try again",
        };
    }, []);

    useEffect(() => {
        if (!createPayoutRuleCalled) {
            return;
        }

        if (!createPayoutRuleLoading && !createPayoutRuleError) {
            setPayoutRuleCreateInput(defaultPayoutRule);
            setShowCreateRowForm(false);
        }
    }, [createPayoutRuleLoading, createPayoutRuleCalled, createPayoutRuleError]);

    return (
        <>
            <AbstractTableViewProvider
                queryTableData={queryTableData}
                onUpdateRow={onUpdateRow}
                updateRowLoading={loading}
                updateRowCalled={called}
                updateRowError={error}
                createRowCalled={createPayoutRuleCalled}
                createRowError={createPayoutRuleError}
                createRowLoading={createPayoutRuleLoading}
                tableCellFactory={payoutRuleTableCellFactory}
                savedTableConfigProps={{
                    tableName: "payoutRules",
                    identifiers: ["v1"],
                    defaultConfigValue: DefaultPayoutRulesTableConfig,
                }}
            >
                <TableView
                    errorViewProps={errorViewProps}
                    emptyViewProps={emptyViewProps}
                />
            </AbstractTableViewProvider>
            <PayoutRulesTableRowSideSheetForm
                isShown={showCreateRowForm}
                payoutRule={payoutRuleCreateInput}
                mutationLoading={createPayoutRuleLoading}
                mutationCalled={createPayoutRuleCalled}
                mutationError={createPayoutRuleError}
                onDismiss={onCancelSideSheet}
                onOpen={onOpenSideSheet}
                onSubmit={onSubmitSideSheet}
                onInputChanged={setPayoutRuleCreateInput}
            />
        </>
    );
};
