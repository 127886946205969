import React, { ComponentProps, FC } from "react";
import { useHistory } from "react-router-dom";

export const Logo: FC<ComponentProps<any>> = ({className, width = 40, height = 40, onClick}) => {
    const history = useHistory();
    return (
        <a className={className}
           href="/"
           onClick={(event) => {
               event.preventDefault();
               if (onClick) {
                   onClick();
               } else {
                   history.push("/");
               }
           }}
        >
            <svg width={width} height={height} viewBox="0 0 40 40" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.9978 0C8.95555 0 0 8.95749 0 20.0022C0 28.1274 4.9412 31.5938 9.59809 31.5938C13.662 31.5938 17.2694 27.7578 17.2694 23.6443H9.5094L16.5321 16.3279H10.9092L19.9978 6.85639L29.0881 16.3261H23.4661L30.488 23.6426H22.7262C22.7262 30.852 17.1694 36.7848 10.1189 37.391C13.1301 39.1064 16.5369 40.0057 20.0022 40C31.0445 40 40 31.0425 40 19.9978C40 8.95314 31.0401 0 19.9978 0Z"
                    fill="#33AA85"
                />
            </svg>
        </a>
    );
};
