import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Pane, PeopleIcon, SeriesSearchIcon, Tooltip } from "evergreen-ui";
import { EmptyState } from "../common/EmptyState";
import { useStoresWithAnalyticsEnabledQuery } from "../datastore/StoreDataStore";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { AnalyticsTab } from "../creator-analytics/AnalyticsTab";
import { Store } from "../../../common/models";
import { NavigationHelper } from "../navigation/PrevLocationState";
import { removeJcContentContainerStylesEffect } from "../common/removeJcContentContainerStylesEffect";
import { MultiTabView } from "../common/multitab/MultiTabView";

import './styles/Analytics.css';

export const Analytics = () => {
    const { data, loading } = useStoresWithAnalyticsEnabledQuery();
    const history = useHistory();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [stores, setStores] = useState<Store[]>([]);

    useEffect(() => {
        if (!loading && data) {
            setStores(data|| []);
        }
        return () => {
            setStores([]);
        }
    }, [data, loading]);

    useEffect(() => removeJcContentContainerStylesEffect(), []);

    const goToUserManagementPage = (event: React.MouseEvent<HTMLElement>, storeId: string) => {
        event.stopPropagation();
        history.push(NavigationHelper.createStoreTeamManagementPath(storeId));
    };

    const renderHeader = (): JSX.Element => {
        return (
            <Pane className={"analytics-heading"}>
                <h3 className={"analytics-heading"}>
                    Analytics
                </h3>
                <Tooltip content="Invite/remove other users to/from your Store.">
                    <Button
                        className={"analytics-heading"}
                        iconBefore={PeopleIcon}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            goToUserManagementPage(event, stores[selectedIndex].id);
                        }}
                    >
                        Manage Team
                    </Button>
                </Tooltip>
            </Pane>
        );
    };

    if (!loading && stores.length === 0) {
        return (
            <EmptyState
                title="Coming Soon"
                subtitle="This page will become available once you've launched your products."
            >
                <SeriesSearchIcon size={60} />
            </EmptyState>
        );
    }

    if (loading) {
        return <CenteredSpinner />;
    }

    return (
        <MultiTabView
            header={renderHeader()}
            selectedTabIndex={selectedIndex}
            onTabSelected={setSelectedIndex}
            tabs={stores.map((store: Store, index: number) => {
                return {
                    label: store.name,
                    tabId: store.id,
                    useDefaultLayout: false,
                    component: (
                        <AnalyticsTab
                            selectedIndex={selectedIndex}
                            tabIndex={index}
                            key={index}
                            store={store}
                        />
                    ),
                };
            })}
        />
    );
};
