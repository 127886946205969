import {
    Badge, Button, Dialog, ErrorIcon, FolderCloseIcon, Pane, PeopleIcon,
    PlusIcon, Table, TextInputField, toaster,
} from "evergreen-ui";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Store } from "../../../../common/models";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { EmptyState } from "../../common/EmptyState";
import { useStoreListQuery, useCreateStoreMutation } from "../../datastore/StoreDataStore";
import { NavigationHelper } from "../../navigation/PrevLocationState";

// TODO: Consolidate store dialog with StoreSelector
export const UserStoreListPage = () => {
    const history = useHistory();
    const [stores, setStores] = useState<Store[]>([]);
    const { loading, error, data } = useStoreListQuery();
    const [storeDialogShown, setStoreDialogShown] = useState<boolean>(false);
    const [newStoreName, setNewStoreName] = useState<string>("");

    const [
        createStore,
        {
            loading: createStoreLoading,
            error: createStoreError,
            called: createStoreCalled,
            data: createdStore,
        }
    ] = useCreateStoreMutation();

    useEffect(() => {
        if (!loading) {
            setStores(data || []);
        }
    }, [data, loading]);

    const updateStores = (store?: Store | null) => {
        if (store) {
            setStores((stores) => [...stores, store]);
        }
    };

    useEffect(() => {
        if (!createStoreLoading && createStoreCalled) {
            if (createStoreError) {
                toaster.warning("Server error! Unable to add user.");
            } else {
                setStoreDialogShown(false);
                setNewStoreName("")
                toaster.success(`Store was added successfully.`, { duration: 2 });
                updateStores(createdStore);
            }
        }
    }, [createStoreLoading, createStoreCalled, createStoreError, createdStore]);

    if (loading) {
        return <CenteredSpinner />;
    }

    if (error || !data) {
        return (
            <EmptyState
                title="Failed to load stores"
                subtitle="Refresh the page to try again"
            >
                <ErrorIcon size={60} />
            </EmptyState>
        );
    }

    const goToUserManagementPage = (event: React.MouseEvent<HTMLElement>, storeId: string) => {
        event.stopPropagation();
        history.push(NavigationHelper.createStoreTeamManagementPath(storeId));
    };

    const renderManageCollaboratorsButton = (storeId: string) => {
        return (
            <Button
                appearance={"minimal"}
                onClick={
                    (event: React.MouseEvent<HTMLElement>) => goToUserManagementPage(event, storeId)
                }
                iconBefore={PeopleIcon}>
                Manage Team
            </Button>
        );
    }

    const renderCreateStoreDialog = () => {

        const isLoading = createStoreCalled && createStoreLoading;
        const onStoreName = (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            setNewStoreName(value);
        };

        return (
            <Dialog
                width={360}
                isShown={storeDialogShown}
                title={"Create a Store"}
                onCloseComplete={() => {
                    setStoreDialogShown(false);
                }}
                isConfirmLoading={isLoading}
                isConfirmDisabled={!newStoreName}
                onConfirm={() => {
                    createStore({
                        storeName: newStoreName
                    })
                }}
                confirmLabel="Submit"
            >
                <Pane>
                    <TextInputField
                        name="storeName"
                        required
                        disabled={isLoading}
                        label="Store Name"
                        value={newStoreName}
                        onChange={onStoreName}
                    />
                </Pane>
            </Dialog>
        )
    };

    return (stores.length === 0) ? (
        <EmptyState
            title="You don’t have any stores right now. Click “New Store” to get started.
            This doesn’t apply for Partnerships stores."
            subtitle="New Store"
            displayAsButton={true}
            onClickSubtitle={() => {
                setStoreDialogShown(true);
            }}
        >
            <FolderCloseIcon size={60} />
            {renderCreateStoreDialog()}
        </EmptyState>
    ) : (
        <Pane className="jc-content-page">
            <Pane
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <h2>Stores</h2>
                <Button
                    appearance="default"
                    iconBefore={PlusIcon}
                    onClick={() => setStoreDialogShown(true)}
                >
                    New Store
                </Button>
            </Pane>
            <Table marginY={20}>
                <Table.Head height={40}>
                    <Table.TextHeaderCell>Store Name</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Collaborators</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {stores.map(({ id, name, isPartnershipsTier }) => {
                        return (
                            <Table.Row
                                key={id}
                                isSelectable={!isPartnershipsTier}
                                onSelect={() => {
                                    history.push(NavigationHelper.createStorePath(id));
                                }}
                            >
                                <Table.TextCell>
                                    {name}
                                    {isPartnershipsTier ? <Badge color="green" marginX={8}>
                                        Partnerships
                                    </Badge> : null}
                                </Table.TextCell>
                                <Table.TextCell>
                                    {renderManageCollaboratorsButton(id)}
                                </Table.TextCell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            {renderCreateStoreDialog()}
            <Pane display={"flex"} justifyContent={"center"} textAlign={"center"} marginY={"60px"}>
                <span>
                    <Pane marginBottom={"8px"}>
                        Click “New Store” to start a new store.
                    </Pane>
                    <Pane>
                        This doesn’t apply for Partnerships stores.
                    </Pane>
                </span>
            </Pane>
        </Pane>
    );
}
