import { useHistory, useLocation } from "react-router-dom";
import { TextButton } from "../common/TextButton";
import { NavigationHelper, PrevLocationState } from "../navigation/PrevLocationState";
import { StytchLoginForm } from "../navigation/StytchLoginForm";
import './styles/Login.css';

export const Registration = () => {
    const history = useHistory();
    const {state: backNavigation} = useLocation<PrevLocationState>();

    return (
        <div className="jc-registration-container">
            <StytchLoginForm
                forNewAccount={true}
                disableFormToggle={true}
            />
            {NavigationHelper.isFromOnboarding(backNavigation) ? (
                <TextButton
                    className="jc-registration-onboarding-back"
                    onClick={NavigationHelper.onBack(history, backNavigation)}
                    label="Go Back"
                />
            ) : null}
        </div>
    )
};
