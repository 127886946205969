import { Pane } from "evergreen-ui"

interface TextBadgeProps {
    text: string;
}

export const TextBadge = ({
    text
}: TextBadgeProps) => {
    return (
        <Pane
            width={60}
            height={20}
            fontSize={10}
            fontWeight={600}
            borderRadius={10}
            backgroundColor={"#2AAC8D"}
            color={"white"}
            position={"absolute"}
            textAlign={"center"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            top={10}
            left={10}
        >
            {text}
        </Pane>
    )
}