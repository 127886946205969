import { MessageAttributes } from "../types";

export class MessageAttributeSerializer {
    public static serialize(attributes: MessageAttributes, onError?: (error: any) => void): string {
        try {
            return JSON.stringify(attributes);
        } catch (e) {
            console.warn("Failed to serialize message attributes", e);
            if (onError) {
                onError(e);
            }
            return "{}";
        }
    }

    public static deserialize(raw: any, onError?: (error: any) => void): MessageAttributes {
        if (typeof raw === "object") {
            return raw as MessageAttributes;
        } else {
            try {
                return JSON.parse(raw) as MessageAttributes;
            } catch (e) {
                console.warn("Failed to deserialize message attributes", e);
                if (onError) {
                    onError(e);
                }
                return {} as MessageAttributes;
            }
        }
    }
}