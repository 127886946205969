import { Pane } from "evergreen-ui";
import React, { FC, ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FooterActionBar } from "../../common/FooterActionBar";
import { HeaderNavigationBar } from "../../common/HeaderNavigationBar";
import {
    removeJcContentContainerStylesEffect
} from "../../common/removeJcContentContainerStylesEffect";
import { Breadcrumb } from "../../product/Breadcrumb";

export interface NewUserState {
    phoneNumber?: string,
    agreementAccepted?: boolean,
    wasRedirected?: boolean
}

export enum NewUserPage {
    PhoneNumber,
    Agreement,
    Billing
}

export interface NewUserContainerProps {
    page: NewUserPage,
    primaryButtonText: string,
    children: ReactElement,
    continueEnabled: boolean,
    onContinue: () => void,
    infoComponent?: React.ReactNode,
    isLoading?: boolean,
    onSkip?: () => void
}

export const NewUserContainer: FC<NewUserContainerProps> = ({
    page,
    primaryButtonText,
    children,
    continueEnabled,
    onContinue,
    infoComponent,
    isLoading = false,
    onSkip
}) => {

    const history = useHistory<NewUserState>();
    const {
        phoneNumber,
        agreementAccepted,
        wasRedirected
    } = history.location.state || {};

    useEffect(removeJcContentContainerStylesEffect, []);

    const getPageTitle = (): string | undefined => {
        switch (page) {
            case NewUserPage.PhoneNumber:
                return "Phone Number";
            case NewUserPage.Agreement:
                return "Agreement";
            case NewUserPage.Billing:
                return "Billing";
            default:
                return undefined;
        }
    };

    const breadcrumbs: React.ReactNode[] = [
        <Breadcrumb
            key={"phone-number"}
            selectable={!wasRedirected || !phoneNumber}
            selected={page === NewUserPage.PhoneNumber}
            text={"Phone Number"}
            onClick={() => history.push("/new-user/phone-number", {
                phoneNumber: phoneNumber,
                agreementAccepted: agreementAccepted
            })}
        />,
        <Breadcrumb
            key={"agreement"}
            selectable={!!phoneNumber}
            selected={page === NewUserPage.Agreement}
            text={"Agreement"}
            onClick={() => history.push("/new-user/agreement", {
                phoneNumber: phoneNumber,
                agreementAccepted: agreementAccepted
            })}
        />,
        <Breadcrumb
            key={"creditcard"}
            selectable={!!phoneNumber && !!agreementAccepted}
            selected={page === NewUserPage.Billing}
            text={"Billing Info"}
            showCaret={false}
            onClick={() => history.push("/new-user/billing", {
                phoneNumber: phoneNumber,
                agreementAccepted: agreementAccepted
            })}
        />,
    ];

    return (
        <Pane>
            <HeaderNavigationBar
                pageTitle={getPageTitle()}
                breadcrumbs={breadcrumbs}
                contextTitle={"New Account"}
                showStepCounter={false}
                sidebarShown={true}
                closeButtonShown={false}
            />
            {children}
            <FooterActionBar
                sidebarShown={true}
                isPrimaryLoading={isLoading}
                primaryButtonDisabled={!continueEnabled}
                primaryButtonText={primaryButtonText}
                onPrimaryClick={onContinue}
                infoComponent={infoComponent}
                showSecondaryButton={false}
                tertiaryButtonText={!!onSkip ? "Skip For Now" : undefined}
                onTertiaryClick={onSkip}
            />
        </Pane>
    );
};
