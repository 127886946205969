import { AbstractRenderer } from "./AbstractRenderer";
import { TableBodyCellRendererInput } from "../input";

export abstract class AbstractTableBodyCellRenderer<T> extends AbstractRenderer {

    protected readonly input: TableBodyCellRendererInput<T>;

    constructor(input: TableBodyCellRendererInput<T>) {
        super();
        this.input = input;
    }

    protected getKey(): string {
        return `${String(this.input.columnConfig.columnName)}#${this.input.id}`;
    }
}
