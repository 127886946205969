import React, { FC } from "react";
import { TextButton } from "./TextButton";
import { Button, Pane, PaneProps } from "evergreen-ui";

export enum EmptyStateType {
    comingSoon,
    notFound,
}

interface EmptyStateProps extends PaneProps {
    title?: string;
    subtitle?: string;
    children: any;
    preset?: EmptyStateType
    onClickSubtitle?: () => void;
    displayAsButton?: boolean;
}

const presets: { [k: string]: { title: string, subtitle: string } } = {
    [EmptyStateType.comingSoon]: {
        title: "Coming soon",
        subtitle: "This page is currently under construction"
    },
    [EmptyStateType.notFound]: {
        title: "Page not found",
        subtitle: "Double check that your URL is correct",
    }
};

export const EmptyState: FC<EmptyStateProps> = (props: EmptyStateProps) => {
    const {
        title,
        subtitle,
        children,
        preset = EmptyStateType.notFound,
        onClickSubtitle,
        displayAsButton = false,
        ...paneProps
    } = props;

    const fontStyle = {fontSize: 13, fontWeight: 400};
    const titleStyle = {
        // The following is a way to get typescript to avoid treating
        // the property as a string type, otherwise it will generate
        // errors
        textAlign: "center" as const,
        maxWidth: "320px",
        marginLeft: "20px",
        marginRight: "20px",
        lineHeight: "1.5rem"
    };

    const renderSubtitle = () => {
        if (onClickSubtitle && subtitle) {
            return displayAsButton ? (
                <Button
                    appearance="default"
                    marginTop={10}
                    width={"fit-content"}
                    paddingY={18}
                    paddingX={40}
                    onClick={onClickSubtitle}
                >
                    {subtitle}
                </Button>
            ) : (
                <TextButton
                    style={fontStyle}
                    onClick={onClickSubtitle}
                    label={subtitle}
                />
            );
        }

        return (
            <span style={{...fontStyle, ...titleStyle}}>
                {subtitle || presets[preset].subtitle}
            </span>
        );
    };

    return (
        <Pane
            height={"100%"}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            {...paneProps}
        >
            {children}
            <p style={{fontSize: 16, fontWeight: 500, paddingTop: 10, ...titleStyle}}>
                {title || presets[preset].title}
            </p>
            {renderSubtitle()}
        </Pane>
    )
};
