import { useEffect } from "react";
import { useUserContext } from "../context/UserContext";

/**
 * This component uses the app context to reroute the app back to the login page. Providing this
 * route component allows users to logout via browser URL as a fallback in case the UI enters a
 * corrupt state and can't clear the session.
 */
export const Logout = () => {
    const {logout} = useUserContext();

    useEffect(() => {
        logout();
        // eslint-disable-next-line
    }, []);

    return null;
};
