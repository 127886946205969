import { createContext, FC, useContext, useEffect, useState } from "react";
import { User } from "../../../common/models";
import { jssaQuery } from "../datastore";
import {
    userQuery
} from "../datastore/UserDataStore";
import { analytics } from "../analytics";
import { moxtraTokenManager } from "../moxtra/MoxtraTokenManager";
import { StytchContext } from "../stytch/StytchContext";
import * as Sentry from "@sentry/react";

export enum UserContextLoadingState {
    Uninitialized,
    Loading,
    Loaded,
    LoadingFailed,
    LoggingOut,
    LoggedOut,
}

export const isUserContextProcessing = (loadingState: UserContextLoadingState): boolean => {
    return [
        UserContextLoadingState.Uninitialized,
        UserContextLoadingState.Loading,
        UserContextLoadingState.LoggingOut
    ].includes(loadingState);
};

export type IUserContext = {
    user?: User;
    updateUser: (user: User) => void;
    fetchUser: (forceRefresh?: boolean) => void;
    logout: () => void;
    loadingState: UserContextLoadingState;
};

const stub = (): never => {
    throw new Error("You forgot to wrap your component in the UserContextProvider.");
};

const defaultUserContext: IUserContext = {
    fetchUser: stub,
    updateUser: stub,
    logout: stub,
    loadingState: UserContextLoadingState.Uninitialized,
};

export const UserContext = createContext<IUserContext>(defaultUserContext);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider: FC = ({children}) => {
    const {
        isAuthenticated: stytchIsAuthenticated,
        isLoading: stytchIsLoading,
        logout: stytchLogout
    } = useContext(StytchContext);
    const [ user, setUser ] = useState<User | undefined>(defaultUserContext.user);
    const [ loadingState, setLoadingState ] = useState<UserContextLoadingState>(UserContextLoadingState.Uninitialized);

    const onUserRequestStart = () => {
        setLoadingState(UserContextLoadingState.Loading);
    };

    const onUserRequestFinished = (failed: boolean) => {
        setLoadingState(failed ? UserContextLoadingState.LoadingFailed : UserContextLoadingState.Loaded);
    };

    const updateUser = (user: User) => {
        setUser(user);
    };

    const fetchUser = (forceRefresh: boolean = false) => {
        if (loadingState === UserContextLoadingState.Loading) {
            return;  // A request is already in-flight.
        }

        if (forceRefresh) {
            setUser(undefined);
        }

        onUserRequestStart();

        jssaQuery({
            query: userQuery,
        }).then(({data: {user}}) => {
            if (user) {
                setUser(user);
                Sentry.setUser({ email: user.email })
                onUserRequestFinished(/* failed= */ false);
            } else {
                onUserRequestFinished(/* failed= */ true);
            }
        }).catch((error) => {
            console.error(error);
            analytics.sendException(error);
            onUserRequestFinished(/* failed= */ true);
        });
    };

    const logout = async () => {
        setLoadingState(UserContextLoadingState.LoggingOut);
        await moxtraTokenManager.revokeAccessToken();
        Sentry.setUser(null);
        await stytchLogout();
    };

    useEffect(() => {
        if (!stytchIsLoading && stytchIsAuthenticated) {
            fetchUser();
        }

        if (!stytchIsLoading && !stytchIsAuthenticated) {
            // User was just logged out via StytchContext, so clear the user.
            setUser(undefined);

            if (loadingState === UserContextLoadingState.LoggingOut) {
                analytics.anonymize();
                window.location.href = process.env.REACT_APP_LANDING_PAGE || "/";
            } else {
                // Only set state to LoggedOut for when the previous state is NOT LoggingOut.
                // This is because the LoggingOut state is intended to force a fullscreen
                // loading spinner in the app until it has redirected back to the landing page.
                // If the state were to be set to LoggedOut before the redirection completes,
                // then the app would redirect the user back to the LoginPage.
                setLoadingState(UserContextLoadingState.LoggedOut);
            }
        }
        // eslint-disable-next-line
    }, [stytchIsAuthenticated, stytchIsLoading]);

    useEffect(() => {
        if (user) {
            analytics.identify(user.email, {
                email: user.email,
                firstName_str: user.firstName,
                lastName_str: user.lastName,
                internalId_str: String(user.id),
            });
        }
    }, [user]);

    return (
        <UserContext.Provider
            value={{
                user,
                fetchUser,
                updateUser,
                logout,
                loadingState,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
