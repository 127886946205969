import { TableCellFactory } from "../../../../common/tables/factory";
import {
    PayoutRuleTableRow
} from "../../../../../../common/tables/store/payout-rules/PayoutRuleTableRow";
import { PayoutRule } from "../../../../../../common/models";
import { EnumResolver } from "../../../../common/tables/etc/EnumResolver";
import { PayoutRulesEnumMapper } from "../etc/PayoutRulesEnumMapper";

export class PayoutRuleTableCellFactory extends TableCellFactory<PayoutRuleTableRow, PayoutRule> {
    readonly enumResolver: EnumResolver<PayoutRuleTableRow> = new PayoutRulesEnumMapper();
}
