import { TableConfig } from "../../../../../common/tables/config/TableConfig";
import { PayoutRuleTableRow } from "../../../../../../../common/tables/store/payout-rules/PayoutRuleTableRow";
import { SortOrder } from "../../../../../../../common/query-filters";
import { CellType } from "../../../../../common/tables/config/CellType";

export const DefaultPayoutRulesTableConfig: TableConfig<PayoutRuleTableRow> = {
    columns: [
        {
            columnName: "name",
            displayName: "Name",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            sticky: true,
            width: "220px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "type",
            displayName: "Rule Type",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "130px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "vendorProfitSplitPercent",
            displayName: "Vendor Profit %",
            cellType: CellType.numberInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "140px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "retailPricePercentApplicable",
            displayName: "Retail Price %",
            cellType: CellType.numberInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "120px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "unitCostPercentApplicable",
            displayName: "Unit Cost %",
            cellType: CellType.numberInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "120px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "juniperServiceFeePercentApplicable",
            displayName: "Service Fee %",
            cellType: CellType.numberInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "120px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "fulfillmentFeePercentApplicable",
            displayName: "Fulfillment Fee %",
            cellType: CellType.numberInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "140px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "processingFeePercentApplicable",
            displayName: "Processing Fee %",
            cellType: CellType.numberInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "140px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "shippingSubsidyPercentApplicable",
            displayName: "Shipping Subsidy %",
            cellType: CellType.numberInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "150px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "createdAt",
            displayName: "Created At",
            cellType: CellType.readOnlyDate,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "140px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "updatedAt",
            displayName: "Updated At",
            cellType: CellType.readOnlyDate,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "140px",
            sortable: false,
            filterable: false,
        },
    ],
    // These are placeholders. Since PayoutRules per store are so few, we haven't bothered to
    // implement support for filters in the back-end.
    filters: {
        expressions: [],
        sortOrder: SortOrder.DESCENDING,
        sortColumn: "createdAt",
        pageSize: 10,
    },
};
