import React, { CSSProperties } from "react";
import { Pane } from "evergreen-ui";
import { ColumnConfig } from "../config/ColumnConfig";
import { genericMemo } from "../../GenericMemo";
import { LoadingShimmer } from "../../LoadingShimmer";
import { renderStickyCells } from "../etc/StickyCellUtils";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";

interface TableRowLoaderProps<T> {
    columns: ColumnConfig<T>[];
}

function TableRowLoaderInternal<T extends TableDataWithId>({columns}: TableRowLoaderProps<T>) {
    return (
        <Pane className={"jc-loader-wrapper"}>
            <Pane className={"jc-tr"}>
                {renderStickyCells(
                    columns,
                    (column: ColumnConfig<T>, style: CSSProperties, stickyClassNames: string) => (
                        <Pane
                            key={column.columnName as string}
                            style={style}
                            className={`jc-td jc-loading-cell-outer ${stickyClassNames}`}
                        >
                            <LoadingShimmer className={"jc-loading-cell-inner"}/>
                        </Pane>
                    ),
                )}
            </Pane>
        </Pane>
    );
}

export const TableRowLoader = genericMemo(TableRowLoaderInternal);
