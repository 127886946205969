import { registerPlugin, registerTokenPlugin, reset } from "linkifyjs";
import { keywordPlugin, registerKeywords, tokenPlugin } from "./keyword";

/**
 * THIS IS A FORKED VERSION OF THE KEYWORD PLUGIN BY LINKIFY.
 *
 * https://github.com/Hypercontext/linkifyjs/blob/main/packages/linkify-plugin-keyword/src/index.js
 *
 * The out-of-the-box linkify-plugin-keyword library only supports a single initialization of
 * keywords. This fork makes it possible to re-register keywords through an explicit `reset()`
 * call, which clears the previous keywords before providing new ones. This is necessary when
 * switching between conversations.
 */
export const registerConversationTags = (tagStrings: string[]) => {
    reset();
    registerKeywords(tagStrings);
    registerTokenPlugin('keyword', tokenPlugin);
    registerPlugin('keyword', keywordPlugin);
}
