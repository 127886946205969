import React, { useContext, useEffect, useState } from "react";
import { CornerDialog } from "evergreen-ui";
import { useUserContext } from "../context/UserContext";
import { StytchContext } from "../stytch/StytchContext";

export const InvitedUserDialog = () => {
    const { user } = useUserContext();
    const { isInvited, setIsInvited} = useContext(StytchContext);
    const [ showDialog, setShowDialog ] = useState<boolean>(false);

    useEffect(() => {
        setShowDialog(isInvited);
    }, [user, isInvited]);

    const onConfirm = () => {
        setIsInvited(false);
    };

    return (
        <CornerDialog
            title="Welcome to Juniper!"
            isShown={showDialog}
            hasClose={false}
            hasCancel={false}
            confirmLabel={"Accept"}
            onConfirm={onConfirm}
        >
            Another user invited you to access their product(s) and collaborate.
            You can now make shared contributions.
        </CornerDialog>
    );
};
