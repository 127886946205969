export enum CellType {
    textInput,
    textInputWithMenuButton,
    singleSelect,
    multiSelect,
    datePicker,
    readOnlyText,
    readOnlyDate,
    redirect,
    checkbox,
    numberInput,
}
