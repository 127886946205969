import {
    Button,
    InlineAlert,
    Link,
    Pane,
    ShareIcon,
    Table,
    TickCircleIcon
} from "evergreen-ui";
import { TundraStorefrontValidateCustomDomainInput } from "../../../../common/inputs/TundraStorefrontInput";
import { TundraStorefrontDomain } from "../../../../common/models";

import "./styles/StoreTundraAdminGetView.css";
import { isInternalDomain } from "./etc/DomainUtils";
import {
    StatusIndicator,
    TundraStorefrontStatus,
    TundraStorefrontStatusResolver
} from "./etc/TundraStorefrontStatusResolver";

export interface StoreTundraAdminDomainCardProps {
    domain: TundraStorefrontDomain;
    subDomain?: TundraStorefrontDomain;
    validateCustomDomain: (domain: TundraStorefrontValidateCustomDomainInput) => void;
    validateCustomDomainLoading: boolean;
}

export const StoreTundraAdminDomainCard = (props: StoreTundraAdminDomainCardProps): JSX.Element => {

    const {
        domain,
        subDomain,
        validateCustomDomain,
        validateCustomDomainLoading
    } = props;

    const renderRow = (title: string, indicator?: StatusIndicator | null): JSX.Element | null => {
        return indicator ? <Table.Row height={"auto"}>
            <Table.Cell flexBasis={"33%"} flexShrink={0} flexGrow={0}>
                <p>
                    <b>{title}</b>
                </p>
            </Table.Cell>
            <Table.Cell flexBasis={"20%"} flexShrink={0} flexGrow={0}>
                <InlineAlert
                    fontSize={8}
                    intent={indicator.intent}
                >
                    { indicator.status }
                </InlineAlert>
            </Table.Cell>
            <Table.Cell>
                <p>
                    { indicator.details }
                </p>
            </Table.Cell>
        </Table.Row> : null;
    };

    const showValidationButton = (domain: TundraStorefrontDomain, status: StatusIndicator | null): boolean => {
        if (isInternalDomain(domain.alias.alias)) {
            return false;
        }

        return status?.intent !== "success";
    };

    const status: TundraStorefrontStatus = new TundraStorefrontStatusResolver(domain, subDomain).status;
    return (
        <Pane
            className={"tundra-admin-domains-container-item"}
        >
            <div style={{
                display: "flex"
            }}>
                <div>
                    <Link href={`https://${domain.alias.alias}`} target={"_blank"}>
                        <div style={{
                            color: "var(--green-primary)",
                            display: "flex"
                        }}>
                            <h3
                                style={{
                                    color: "var(--green-primary)",
                                    margin: 0,
                                    paddingBottom: "8px",
                                    fontWeight: "bold"
                                }}
                            >
                                {domain.alias.alias}
                            </h3>
                            <ShareIcon marginLeft={8}/>
                        </div>
                    </Link>
                    { subDomain && <h5
                        style={{
                            margin: 0,
                            paddingBottom: "8px",
                            color: "grey"
                        }}
                    >
                        {subDomain.alias.alias}
                    </h5> }
                </div>
                { showValidationButton(domain, status.ssl.validationStatus) && <Button
                    appearance={"primary"}
                    intent={"none"}
                    className={"tundra-admin-button"}
                    style={{
                        margin: "0 8px 4px 32px"
                    }}
                    isLoading={validateCustomDomainLoading}
                    iconBefore={TickCircleIcon}
                    onClick={() => validateCustomDomain({
                        primaryDomain: domain.alias.alias
                    })}
                >
                    Validate Certificate?
                </Button> }
            </div>
            <Table>
                <Table.Head>
                    <Table.HeaderCell flexBasis={"33%"} flexShrink={0} flexGrow={0}>
                        Component
                    </Table.HeaderCell>
                    <Table.HeaderCell flexBasis={"20%"} flexShrink={0} flexGrow={0}>
                        Status
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Details
                    </Table.HeaderCell>
                </Table.Head>
                <Table.Body>
                    { renderRow(`DNS Record(s)`, status.dns.root) }
                    { renderRow(`Scan Status`, status.scan.root.status) }
                    { renderRow(`Scan Time`, status.scan.root.time) }
                    { renderRow(`www. DNS Record(s)`, status.dns.www) }
                    { renderRow(`www. Scan Status`, status.scan.www?.status) }
                    { renderRow(`www. Scan Time`, status.scan.www?.time) }
                    { renderRow("SSL Certificate Request Status", status.ssl.validationStatus) }
                    { renderRow("SSL Certificate Status", status.ssl.lastRenewStatus) }
                    { renderRow("SSL Certificate Last Renewal Time", status.ssl.lastRenewTime) }
                    { renderRow("SSL Certificate Renewal Workflow", status.ssl.renewWorkflow) }
                    { renderRow("SSL Certificate Expiration Time", status.ssl.expirationTime) }
                </Table.Body>
            </Table>
        </Pane>
    );

};
