import {ChevronRightIcon, Pane} from "evergreen-ui";


interface BreadcrumbProps {
    selectable: boolean | undefined;
    selected: boolean;
    text: string;
    onClick: () => any;
    showCaret?: boolean;
}

export const Breadcrumb = ({
    selected,
    selectable,
    text,
    onClick,
    showCaret = true
}: BreadcrumbProps) => {

    const getBreadcrumbStyle = () => {
        let styleString = "jc-wizard-breadcrumb-text";
        if (selected) {
            styleString += " black-unselectable";
        } else if (selectable) {
            styleString += " selectable";
        }
        return styleString;
    }

    return (
        <Pane onClick={selectable ? onClick : () => {}}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              marginLeft={0}>
            <span className={getBreadcrumbStyle()}>
                {text}
            </span>
            {showCaret &&
                <ChevronRightIcon color="muted" marginTop={2} size={12}/>
            }
        </Pane>
    )
}