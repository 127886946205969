import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Combobox, Menu, Pane, TickIcon } from "evergreen-ui";
import {
    FilterExpression,
    FilterExpressionType,
    SortOrder
} from "../../../../../../common/query-filters";
import { ColumnConfig } from "../../config/ColumnConfig";
import { Sort } from "../../state/TableProviderAPI";
import { TableCellHeaderChangedHandler } from "../../factory";
import { StyledSelectMenuItem } from "../../etc/StyledSelectMenuItem";

import "./MenuContent.css";
import { SearchInput, SearchInputProps } from "../../../search/SearchInput";
import { TableDataWithId } from "../../../../../../common/tables/TableDataWithId";

export interface MenuContentProps<
    TableDataType extends TableDataWithId,
    SearchInputType,
    SearchOutputType,
> {
    columnConfig: ColumnConfig<TableDataType>;
    filterExpressions: FilterExpression<TableDataType>[];
    sort: Sort<TableDataType>;
    filterOptions: StyledSelectMenuItem[];
    onChange: TableCellHeaderChangedHandler<TableDataType>;
    disableSort: boolean;
    disableFilters: boolean;
    dynamicSearchInputProps?: Pick<SearchInputProps<SearchInputType, SearchOutputType>, "query" | "queryInput" | "resultsMap">;
}

export function MenuContent<
    TableDataType extends TableDataWithId,
    SearchInputType,
    SearchOutputType,
>(props: MenuContentProps<TableDataType, SearchInputType, SearchOutputType>) {

    const {
        columnConfig,
        filterExpressions,
        sort,
        filterOptions,
        onChange,
        disableSort,
        disableFilters,
        dynamicSearchInputProps,
    } = props;

    const initialSortOrder: SortOrder | null = sort.column === columnConfig.columnName ? sort.order : null;
    const [ sortOrder, setSortOrder ] = useState<SortOrder | null>(initialSortOrder);

    const activeFilterItems: TableDataType[keyof TableDataType][] = filterExpressions
        .find((expression: FilterExpression<TableDataType>): boolean => expression.type === FilterExpressionType.equals)?.columnValue ?? [];
    const initialSelected: StyledSelectMenuItem[] = filterOptions.filter((item: StyledSelectMenuItem) => activeFilterItems.includes(item.value as any));
    const [ selected, setSelected ] = useState<StyledSelectMenuItem[]>(initialSelected);

    const onFilterSelect = (item: StyledSelectMenuItem) => {
        const newSelected: Set<StyledSelectMenuItem> = new Set<StyledSelectMenuItem>(selected);
        newSelected.add(item);
        setSelected(Array.from(newSelected));
        onChange(columnConfig.columnName, {
            columnValues: Array.from(newSelected).map(({value}: StyledSelectMenuItem) => {
                return value as unknown as TableDataType[keyof TableDataType];
            }),
        });
    };

    const onFilterDeselect = (deselectedItem: StyledSelectMenuItem): void => {
        const updatedSelection: StyledSelectMenuItem[] = selected.filter((option: StyledSelectMenuItem) => {
            return (option.label !== deselectedItem.label);
        });
        setSelected(updatedSelection);
        onChange(columnConfig.columnName, {
            columnValues: updatedSelection.map(({value}: StyledSelectMenuItem) => {
                return value as unknown as TableDataType[keyof TableDataType];
            }),
        });
    }

    const clearFilters = (): void => {
        setSelected([]);
        onChange(columnConfig.columnName, {columnValues: []});
    }

    const onSortSelect = (order: SortOrder): void => {
        setSortOrder(order);
        onChange(columnConfig.columnName, {sortOrder: order});
    }

    const createVerifiedLabelIfNeeded = (item: StyledSelectMenuItem | null): string => {
        if (!item) {
            return "";
        }
        const verifiedText = item.isVerified ? " (Verified by Juniper)" : "";
        return item.label + verifiedText;
    };


    useEffect(() => {
        if (!dynamicSearchInputProps) {
            return;
        }

        const initialSelected = filterExpressions.filter((expression: FilterExpression<TableDataType>) => {
            return expression.columnName === columnConfig.columnName;
        }).map((expression: FilterExpression<TableDataType>) => {
            return expression.columnValue;
        }).flat() as unknown as SearchInputType[keyof SearchInputType][];

        dynamicSearchInputProps.query({
            ...dynamicSearchInputProps.queryInput,
            expressions: [{
                columnName: "id" as keyof SearchInputType,
                columnValue: initialSelected,
                type: FilterExpressionType.equals,
                caseInsensitive: true,
            }],
        }).then((results: SearchOutputType[]) => {
            setSelected(results.map(dynamicSearchInputProps.resultsMap));
        });

    }, [dynamicSearchInputProps, filterExpressions]);

    return (
        <Pane
            // This is needed in order to prevent the entire menu from closing when the
            //  user clicks on an item in the Combobox's autocomplete dropdown.
            onClick={(event: any) => event.stopPropagation()}
        >
            <Pane>
                { !disableSort &&
                    <Menu.Group
                        title={`Sort by ${columnConfig.displayName}`}
                    >
                        <Menu.OptionsGroup
                            options={[
                                { label: "Sort A-Z", value: SortOrder.ASCENDING },
                                { label: "Sort Z-A", value: SortOrder.DESCENDING }
                            ]}
                            selected={sortOrder}
                            onChange={(value) => onSortSelect(value as SortOrder)}
                        />
                    </Menu.Group>
                }
                { !disableSort && !disableFilters && <Menu.Divider /> }
                { !disableFilters &&
                    <Menu.Group
                        title={"Filter by Values"}
                    >
                        {dynamicSearchInputProps ? (
                            <Pane className={"jc-multi-facet-menu-content"}>
                                <SearchInput<SearchInputType, SearchOutputType>
                                    query={dynamicSearchInputProps.query}
                                    queryInput={dynamicSearchInputProps.queryInput}
                                    resultsMap={dynamicSearchInputProps.resultsMap}
                                    onSelectSearchResult={(label: string, value: string) => {
                                        onFilterSelect({ label, value });
                                    }}
                                    textInputProps={{width: "100%"}}
                                />
                            </Pane>
                        ) : (
                            <Combobox
                                className={"jc-multi-facet-menu-content"}
                                openOnFocus
                                buttonProps={{
                                    className: "jc-multi-facet-menu-content"
                                }}
                                items={filterOptions}
                                itemToString={(item: StyledSelectMenuItem | null): string => createVerifiedLabelIfNeeded(item)}
                                selectedItem={null}
                                onChange={onFilterSelect}
                            />
                        )}
                        {
                            selected.map((item: StyledSelectMenuItem, index: number) => {
                                return (
                                    <Menu.Item
                                        key={index}
                                        icon={TickIcon}
                                        onClick={() => onFilterDeselect(item)}>
                                        {createVerifiedLabelIfNeeded(item)}
                                    </Menu.Item>
                                )
                            })
                        }
                        {
                            selected.length !== 0 ?
                                <Pane className={"jc-multi-facet-menu-clear-filter"}>
                                    <Button
                                        className={"jc-multi-facet-menu-clear-filter"}
                                        appearance={"minimal"}
                                        size={"small"}
                                        onClick={clearFilters}
                                    >
                                        Clear
                                    </Button>
                                </Pane> :
                                <></>
                        }
                    </Menu.Group>
                }
            </Pane>
        </Pane>
    );
}
