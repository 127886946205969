import { CreditCardIcon, toaster } from "evergreen-ui";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { EmptyState } from "../common/EmptyState";
import { useBillingAddressFormFields } from "./components/useBillingAddressFormFields";
import { useCreditCardFields } from "./components/useCreditCardFields";
import { usePaymentMethods } from "./components/usePaymentMethods";

import "./styles/CreditCardForm.css";

export const BillingForm = () => {

    const {
        renderPaymentMethods,
        hasPaymentMethods,
        refetchPaymentMethods,
        paymentMethodsLoading,
        paymentMethodsError
    } = usePaymentMethods();

    const {
        renderBillingAddressFields,
        billingAddress,
        isBillingAddressComplete
    } = useBillingAddressFormFields();

    const {
        renderCreditCardFields,
        isFormProcessing
    } = useCreditCardFields(billingAddress, isBillingAddressComplete, true, () => {
        toaster.success("Payment info successfully updated!");
        refetchPaymentMethods();
    });

    if (paymentMethodsLoading) {
        return <CenteredSpinner height="200px"/>;
    }

    if (paymentMethodsError) {
        return (
            <EmptyState
                title="Failed to load payment info"
                subtitle="Message your account manager for support."
            >
                <CreditCardIcon size={60}/>
            </EmptyState>
        );
    }

    return (
        <>
            {
                hasPaymentMethods() ?
                renderPaymentMethods() :
                <>
                    { renderBillingAddressFields({ disabled: isFormProcessing() }) }
                    { renderCreditCardFields({}) }
                </>
            }
        </>
    );
};
