import { StripeError } from "@stripe/stripe-js";
import { Button, Table, toaster } from "evergreen-ui";
import React, { useCallback, useState } from "react";
import { toTitleCase } from "../../../../common/utils";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { fetchPortalSessionUrl, useStripePaymentMethodsQuery } from "../index";

export const usePaymentMethods = (
    onError: (error: Error|StripeError) => void = () => {}
) => {

    const [ loadingPortal, setLoadingPortal ] = useState<boolean>(false);

    const {
        loading: paymentMethodsLoading,
        error: paymentMethodsError,
        data: paymentMethods,
        refetch: refetchPaymentMethods,
    } = useStripePaymentMethodsQuery();

    const hasPaymentMethods = (): boolean => {
        return (!!paymentMethods && paymentMethods.length > 0);
    };

    const renderPaymentMethods = useCallback(() => {
        const openCustomerPortal = async () => {
            let url;
            try {
                setLoadingPortal(true);
                url = await fetchPortalSessionUrl();
            } catch (error) {
                console.error(error);
            } finally {
                if (url) {
                    window.location.href = url;
                } else {
                    toaster.warning("Oops, we weren't able to open your payment management portal.");
                }
            }
        };

        if (paymentMethodsError) {
            onError(new Error("Failed to load payment methods for user."));
            return <></>;
        }

        if (paymentMethodsLoading) {
            return <CenteredSpinner />;
        }

        return(
            <>
                <Table marginY={30}>
                    <Table.Head height={40}>
                        <Table.TextHeaderCell>Store Name</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Expiry</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Card Number</Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body>
                        {paymentMethods!.map(({brand, expiry, last4}, index) => (
                            <Table.Row key={index} height={50}>
                                <Table.TextCell>{toTitleCase(brand)}</Table.TextCell>
                                <Table.TextCell>{expiry}</Table.TextCell>
                                <Table.TextCell>{"••••" + last4}</Table.TextCell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Button
                    appearance="primary"
                    width={120}
                    onClick={openCustomerPortal}
                    isLoading={loadingPortal}
                    disabled={loadingPortal}
                >
                    Manage
                </Button>
            </>
        )
    }, [
        loadingPortal,
        onError,
        paymentMethods,
        paymentMethodsError,
        paymentMethodsLoading
    ]);

    return {
        renderPaymentMethods,
        hasPaymentMethods,
        refetchPaymentMethods,
        paymentMethods,
        paymentMethodsLoading,
        paymentMethodsError
    }
};