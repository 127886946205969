import {
    NotificationCategory,
    NotificationChannel,
    NotificationFrequency
} from "../../../common/enums";
import { NotificationPreference } from "../../../common/models";

type NotificationFrequencyDisplayOptionsMap = {
    [category in NotificationCategory]: NotificationFrequencyDisplayOption[];
}

export type NotificationFrequencyDisplayOption = {
    label: string;
    shortLabel?: string;
    value: NotificationFrequency;
}

type NotificationCategoryTitles = {
    [category in NotificationCategory]: string;
}

type NotificationChannelToPreferenceKey = {
    [channel in NotificationChannel]: keyof Pick<NotificationPreference, "sms" | "email" | "push">;
};

type NotificationChannelDisplayOption = {
    label: NotificationChannel;
    name: keyof Pick<NotificationPreference, "sms" | "email" | "push">;
    checked: boolean;
};

export const NotificationFrequencyDisplayOptions: NotificationFrequencyDisplayOptionsMap = {
    [NotificationCategory.ConversationMessage]: [
        {
            label: 'Mentions Only – Notify me whenever I am mentioned / tagged in a message.',
            shortLabel: 'for mentions only',
            value: NotificationFrequency.MessageTagged
        },
        {
            label: 'All Messages – Notify me whenever I receive a new message.',
            shortLabel: 'for all messages',
            value: NotificationFrequency.MessageAll
        }
    ],
    [NotificationCategory.ProductDevUpdate]: [
        {
            label: 'Notify me with updates about my products. This includes things like approvals for manufacturing drawings or physical samples.',
            value: NotificationFrequency.ProductDevAll
        }
    ],
    // TODO: Update with right description
    [NotificationCategory.ProductDevConfirmation]: [
        {
            label: 'Notify me about submissions.',
            value: NotificationFrequency.ProductConfirmationAll
        }
    ],
    [NotificationCategory.StoreUpdate]: [
        {
            label: 'Notify me about store updates.',
            value: NotificationFrequency.StoreUpdateAll
        }
    ],
}

export const NotificationCategoryTitles: NotificationCategoryTitles = {
    [NotificationCategory.ConversationMessage]: "Incoming Messages",
    [NotificationCategory.ProductDevUpdate]: "Product Updates",
    [NotificationCategory.ProductDevConfirmation]: "Product Submissions",
    [NotificationCategory.StoreUpdate]: "Store Updates"
};

export const NotificationChannelMap: NotificationChannelToPreferenceKey = {
    [NotificationChannel.SMS]: "sms",
    [NotificationChannel.Email]: "email",
    [NotificationChannel.Push]: "push",
};

// TODO: Enable push when ready
export const createNotificationChannelDisplayOptions = (
    notificationPreference: NotificationPreference,
): NotificationChannelDisplayOption[] => {
    const {sms, email} = notificationPreference;
    return [
        {
            label: NotificationChannel.SMS,
            name: NotificationChannelMap[NotificationChannel.SMS],
            checked: sms
        },
        {
            label: NotificationChannel.Email,
            name: NotificationChannelMap[NotificationChannel.Email],
            checked: email
        }
    ];
};