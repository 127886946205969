import {
    Button,
    Dialog,
    Pane,
    Select,
    SendMessageIcon,
    Textarea,
    TextareaField,
    TextInput,
    TextInputField,
    toaster,
    TrashIcon,
} from "evergreen-ui";
import { ChangeEvent, useEffect, useState } from "react";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import {
    FulfillmentType,
    FulfillmentTypeDisplayValues,
    LaunchType,
    LaunchTypeDisplayValues,
    PreDesignReviewState,
    ProductLifecycleStatus,
} from "../../../../common/enums";
import { ProductListing } from "../../../../common/models";
import { useUserContext } from "../../context/UserContext";
import {
    deleteProductListing,
    useSubmitProductMutation,
    useUpdateProductListingMutation
} from "../../datastore/ProductListingDataStore";
import { NavigationHelper } from "../../navigation/PrevLocationState";
import {
    categoryRequiresSizing,
    isProductListingCancelled,
    isProductListingComplete,
    isProductListingDraft,
    isProductListingLocked,
    LockedProductReason
} from "../../product/Utils";
import { NotFound } from "../NotFound";
import { LabelledTooltip } from "../../common/LabelledTooltip";
import { StoreSelector } from "../../common/StoreSelector";
import { FooterActionBar } from "../../common/FooterActionBar";
import { SetupTabSubmitDialog } from "./etc/SetupTabSubmitDialog";

import './styles/ProductSetupTab.css';

const enum ProductListingFormStatus {
    incomplete,
    readyToSubmit,
    requiresRevisions,
    cancelled,
    unsavedChanges,
    launchTypeError,
    julyExcluded,
    none, // Used to hide the form status label.
}

const FormStatusToMessage: {[status: number]: string} = {
    [ProductListingFormStatus.incomplete]: "<span class=\"error\">You are missing required information. <br/>This product is <b>not yet submitted</b></span>.",
    [ProductListingFormStatus.readyToSubmit]: "Your product is ready to submit!",
    [ProductListingFormStatus.unsavedChanges]: "You have unsaved changes.",
    [ProductListingFormStatus.cancelled]: "This product has been cancelled.",
    [ProductListingFormStatus.julyExcluded]: "<span class=\"error\">We are currently at capacity and are not accepting new products.</span>",
    [ProductListingFormStatus.launchTypeError]: "<span class=\"error\">You can only develop 1 \"<b>Pre-Order</b>\" product at a time. <br/>Select \"<b>In Stock</b>\" as the Launch Type to submit.</span>",
};

export interface ProductSetupTabProps {
    initialProductListing: ProductListing;
    isTimelineReadyForSubmission: boolean;
    hasPaymentMethods: boolean;
    isPreOrderRestricted: boolean;
    showSubmitDialogOverride: boolean;
    setShowSubmitDialogOverride(value: boolean): void;
    reloadTimeline(): void;
    reloadSetup(redirectToMessages?: boolean): void;
    goToMenuTab(index: number): void;
}

/**
 * TODO: Retrofit once the Product onboarding work is completed.
 *  As per the discussion during the design stage -> https://docs.google.com/document/d/1UHz8ws1iXiZ0V4BOLtsdfL70qfvTewBFXa-Mf6aY7s8/edit#
 *  This is a direct port of the implementation in `ProductListingPage.tsx`, it's been modified slightly to fit in the tab view, but
 *  otherwise the functionality should be identical.
 */
export const ProductSetupTab = (props: ProductSetupTabProps) => {

    const {
        initialProductListing,
        isTimelineReadyForSubmission,
        hasPaymentMethods,
        isPreOrderRestricted,
        showSubmitDialogOverride,
        setShowSubmitDialogOverride,
        reloadTimeline,
        reloadSetup
    } = props;

    const history = useHistory();

    const [productListing, setProductListing] = useState<ProductListing | null>();
    const [formStatus, setFormStatus] = useState<ProductListingFormStatus>(ProductListingFormStatus.none);
    const [submitDialogIsShown, setSubmitDialogIsShown] = useState<boolean>(false);
    const [deleteDialogIsShown, setDeleteDialogIsShown] = useState<boolean>(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

    useEffect(() => {
        setProductListing(initialProductListing);
    }, [initialProductListing]);

    const [
        submitProduct,
        {
            loading: submitProductLoading,
            error: submitProductError,
            called: submitProductCalled,
        }
    ] = useSubmitProductMutation();

    const [
        updateProductListing,
        {
            error: mutationError,
            loading: mutationLoading,
            called: mutationCalled,
            data: mutatedProductListing,
        }
    ] = useUpdateProductListingMutation();

    const submitProductForScreening = async () => {
        if (!productListing) {
            return;
        }

        // First, we have to save any local changes to the product listing. This must be completed
        // before the submission, otherwise, some data may be overwritten (i.e. the
        // lifecycleStatus will be reverted to Draft if the update mutation is called after
        // the submission).
        if (hasUnsavedChanges) {
            await updateProductListing({productListing});
        }

        // Once the update above is complete, we can submit the product for screening, which will
        // trigger our Zapier, Twilio, and notification workflows.
        await submitProduct({productListingId: productListing.id});
    };

    const renderSubmitDialog = () => {
        return (
            <SetupTabSubmitDialog
                isShown={submitDialogIsShown || showSubmitDialogOverride}
                onCloseComplete={() => {
                    setSubmitDialogIsShown(false);
                    setShowSubmitDialogOverride(false);
                }}
                isConfirmLoading={submitProductLoading}
                onConfirm={submitProductForScreening}
                hasPaymentMethods={hasPaymentMethods}
            />
        );
    };

    const renderDeleteDialog = () => {
        return (
            <Pane>
                <Dialog
                    isShown={deleteDialogIsShown}
                    title="Delete this Product?"
                    onCloseComplete={() => setDeleteDialogIsShown(false)}
                    onConfirm={onDeleteProductListing}
                    confirmLabel="Delete"
                >
                    <p>
                        Are you sure you want to delete this product?
                        This is an irreversible action that will delete all information about the product.
                        This will NOT delete the Store.
                    </p>
                </Dialog>
            </Pane>
        );
    };

    const renderUnsavedChangesDialog = () => {
        return (
            <Prompt
                when={hasUnsavedChanges}
                message={"You have unsaved changes in the Setup Tab. Do you want to leave?"}
            />
        )
    };

    const onDeleteProductListing = () => {
        if (productListing) {
            const deletionFailedMessage = "Unable to delete product. Try again.";
            deleteProductListing(productListing.id)
                .then((success: boolean) => {
                    if (success) {
                        toaster.success("Product was deleted.");
                        history.push(NavigationHelper.createProductListViewPath());
                    } else {
                        toaster.warning(deletionFailedMessage);
                    }
                 })
                .catch((error) => {
                    setDeleteDialogIsShown(false);
                    console.error(error);
                    toaster.warning(deletionFailedMessage);
                });
        }
    };

    const onDiscardChanges = () => {
        if (hasUnsavedChanges) {
            setProductListing(initialProductListing);
        }
    };

    // Hook runs on product submission
    useEffect(() => {
        if (!submitProductLoading && submitProductCalled) {
            if (submitProductError) {
                toaster.warning("An error occurred. Try submitting again.");
            } else {
                toaster.success("Your product submission has been received!");
                reloadTimeline();
                reloadSetup(true);
            }
            setSubmitDialogIsShown(false);
        }
    }, [
        submitProductError,
        submitProductLoading,
        submitProductCalled,
    ]);

    useEffect(() => {
        if (!mutationLoading && mutationCalled) {
            if (mutationError) {
                toaster.warning("Something went wrong. Try again.");
            } else {
                if (mutatedProductListing) {
                    setProductListing(mutatedProductListing);
                    reloadSetup();
                }
                toaster.success("Product info updated!");
                reloadTimeline();
            }
        }
    }, [mutationLoading, mutationCalled, mutationError, mutatedProductListing]);

    useEffect(() => {
        if (!productListing) {
            return;
        }

        // Compare the initial and current product listings for any unsaved form changes.
        setHasUnsavedChanges(JSON.stringify(initialProductListing) !== JSON.stringify(productListing));

        if (productListing.lifecycleStatus) {
            if (productListing.lifecycleStatus === ProductLifecycleStatus.Cancelled) {
                setFormStatus(ProductListingFormStatus.cancelled);
            } else if (productListing.preDesignReviewState === PreDesignReviewState.EditsRequired) {
                setFormStatus(ProductListingFormStatus.requiresRevisions);
            } else if (productListing.lifecycleStatus === ProductLifecycleStatus.Draft) {
                setFormStatus(
                    isProductListingComplete(productListing, isPreOrderRestricted) ?
                        ProductListingFormStatus.readyToSubmit :
                        (hasLaunchTypeError() ?
                                ProductListingFormStatus.launchTypeError :
                                ProductListingFormStatus.incomplete
                        )
                );
            } else {
                setFormStatus(ProductListingFormStatus.none);
            }
        }

        if (!isJulyHibernationExcluded()) {
            setFormStatus(ProductListingFormStatus.julyExcluded);
        }
    }, [isPreOrderRestricted, productListing]);

    const onProductListingChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => {
        const eventName: string = event.target.name;
        let eventValue: string | string[] = event.target.value;

        if (eventName === "mediaAssets") {
            // Convert mediaAssets from a comma/space-separated string to an
            // array for storage on the backend
            eventValue = event.target.value.split(/(?:,\n|,|\n)/);
        }

        if (productListing) {
            setProductListing({
                ...productListing,
                [eventName]: eventValue,
            });
        }
    };

    const renderSampleProductSizing = () => {
        const category = productListing?.pricing.catalogOption.catalogProduct.catalogCategory.name ?? "";
        if (!categoryRequiresSizing(category)) {
            return;
        }
        const sizingOptions = [
            "",
            "Youth XS",
            "Youth S",
            "Youth M",
            "Youth L",
            "Adult 2XS",
            "Adult XS",
            "Adult S",
            "Adult M",
            "Adult L",
            "Adult XL",
            "Adult 2XL",
            "Adult 3XL"
        ];
        return (
            <>
                <h3>Sample Size *</h3>
                <div className="jc-dp-description">
                    <p>
                        What size should we create your sample?
                    </p>
                </div>
                <Select
                    className="jc-dp-select"
                    name="sizing"
                    onChange={onProductListingChanged}
                    marginBottom={20}
                    required={true}
                    isInvalid={!productListing?.sizing}
                    value={productListing?.sizing ?? ""}
                >
                    {sizingOptions.map((sizing, index) => (
                        <option key={index} value={sizing}>
                            {sizing}
                        </option>
                    ))}
                </Select>
            </>
        );
    };

    const renderProductSelectionComponents = () => {
        const {locked, reason} = isProductListingLocked(productListing);
        const inputCursorStyle = {cursor: locked ? "not-allowed" : "pointer"};

        const getHint = () => {
            switch (reason) {
                case LockedProductReason.CustomProduct:
                    return "This is a custom product. Contact your designer to modify your selection.";
                case LockedProductReason.Submitted:
                    return "Your product selection has been submitted. Contact your designer to make a change.";
                case LockedProductReason.Cancelled:
                default:
                    return "";
            }
        };

        const goToCatalogSelectionPage = () => {
            if (!locked) {
                history.push(NavigationHelper.editProductPath(productListing!.id));
            }
        };

        return productListing ? (
            <>
                <div className="jc-dp-section">
                    <div style={{maxWidth: "720px"}}>
                        <h3>Product Type</h3>
                        <div className="jc-dp-section-row">
                            <TextInputField
                                width={"100%"}
                                name="catalogOption"
                                label="Product Type"
                                disabled={locked}
                                readOnly
                                style={inputCursorStyle}
                                hint={locked ? (
                                    <span className="jc-dp-hint">{getHint()}</span>
                                ) : null}
                                value={productListing.pricing.catalogOption.name}
                                onClick={goToCatalogSelectionPage}
                            />
                    </div>
                </div>

                    { /** TODO: "Apparel Tier" and "Add-Ons" don't totally exist yet, and we can't deep link to them. **/ }
                    { /**  Leaving this like this for now until the next those tasks land. **/ }
                    { /**  1. https://app.asana.com/0/1202051573875308/1202974889600941/f **/ }
                    { /**  2. https://app.asana.com/0/1202051573875308/1202998349240722/f **/ }

                </div>
            </>
        ) : null;
    };

    const renderInfoComponent = () => {
        return FormStatusToMessage[formStatus] ? (
            <div
                className={"product-setup-form-status"}
                dangerouslySetInnerHTML={{__html: FormStatusToMessage[formStatus]}}
            />
        ) : null;
    };

    const isJulyHibernationExcluded = (): boolean => {
        return initialProductListing?.store.julySleepExcluded ?? false;
    };

    const isSubmissionDisabled = (): boolean => {
        return !isTimelineReadyForSubmission || formStatus !== ProductListingFormStatus.readyToSubmit || !isJulyHibernationExcluded();
    };

    const hasLaunchTypeError = (): boolean => {
        return isPreOrderRestricted && productListing?.launchType === LaunchType.preOrder;
    };

    if (!productListing) {
        return <NotFound/>;
    }

    return (
        <>
            <Pane className="jc-content-page" style={{margin: "unset", maxWidth: "calc(100% - 48px)"}}>
                <Pane>
                    <fieldset
                        className="jc-dp-fieldset"
                        disabled={isProductListingCancelled(productListing) || mutationLoading || submitProductLoading}
                    >
                        {renderProductSelectionComponents()}
                        <div className="jc-dp-section">
                            <div style={{maxWidth: "720px"}}>
                                <h3>Details</h3>
                                <div className="jc-dp-section-row">
                                    <TextInputField
                                        required={true}
                                        isInvalid={!productListing.name}
                                        width={"100%"}
                                        name={"name"}
                                        label={"Product Name"}
                                        value={productListing.name}
                                        onChange={onProductListingChanged}
                                    />
                                    <Pane className={"jc-dp-section-row-separator"}/>
                                    <StoreSelector
                                        width={"100%"}
                                        initialStore={productListing!.store ?? null}
                                        onStoreChanged={(newStoreName: string, existingStoreId?: string) => {
                                            if (existingStoreId) {
                                                updateProductListing({
                                                    productListing,
                                                    storeId: existingStoreId,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <TextareaField
                                     // The `required` prop doesn't work here because we're
                                     // using a component as the label. As a workaround, I've
                                     // manually added an asterisk.
                                    name={"description"}
                                    label={(
                                        <LabelledTooltip
                                            label={"Description *"}
                                            description={"If you’re stuck on this, please input, “discuss with a designer” or “N/A”."}
                                        />
                                    )}
                                    value={productListing.description || ""}
                                    isInvalid={!productListing.description}
                                    placeholder={"Tell us about your product (color codes, important features, similar brand/product inspiration, etc.)."}
                                    onChange={onProductListingChanged}
                                    marginBottom={20}
                                    display={"inline-block"}
                                    width={"100%"}
                                />
                                <h3>Media *</h3>
                                <div className="jc-dp-description">
                                    <p>
                                        Provide any links (i.e. Dropbox, Google Drive, etc.) to images,
                                        videos,
                                        or documents that would help us better understand the vision of this
                                        product.
                                    </p>
                                </div>
                                <Textarea
                                    required={true}
                                    isInvalid={productListing.mediaAssets.length === 0}
                                    name="mediaAssets"
                                    marginBottom={"20px"}
                                    value={productListing.mediaAssets.join("\n")}
                                    placeholder="https://photos.google.com/my_sample_picture&#10;https://youtube.com/my_inspo_video"
                                    onChange={onProductListingChanged}
                                />
                            </div>
                        </div>
                        <div className="jc-dp-section">
                            <div style={{maxWidth: "720px"}}>
                                {renderSampleProductSizing()}
                                <h3>Sample Shipping *</h3>
                                <div className="jc-dp-description">
                                    <p>
                                        Where should we ship the sample for this product?
                                    </p>
                                </div>
                                <TextInput
                                    required={true}
                                    isInvalid={!productListing.shippingInfo}
                                    name="shippingInfo"
                                    marginBottom={20}
                                    value={productListing.shippingInfo || ""}
                                    placeholder="Example: 123 Smith St. Ont. Canada"
                                    onChange={onProductListingChanged}
                                />
                            </div>
                        </div>
                        <div className={`jc-dp-section`}>
                            <div style={{maxWidth: "720px"}}>
                                <h3>Launch Type *</h3>
                                <div className="jc-dp-description">
                                    <p><b>{LaunchTypeDisplayValues[LaunchType.preOrder]}:</b> A limited time launch that must exceed
                                        a minimum threshold before an order is placed. Products
                                        are not fulfilled until the pre-order threshold is met
                                        and then manufacturing is complete.</p>
                                    <p><b>{LaunchTypeDisplayValues[LaunchType.inStock]}:</b> Requires that the minimum quantity
                                        be ordered and manufactured before launch.</p>
                                </div>
                                <Select
                                    className="jc-dp-select"
                                    required={true}
                                    name="launchType"
                                    marginBottom={20}
                                    onChange={onProductListingChanged}
                                    value={productListing.launchType}
                                    isInvalid={(productListing.launchType === LaunchType.unset) || hasLaunchTypeError()}
                                >
                                    {Object.values(LaunchType).map((launchType, index) => (
                                        <option key={index} value={launchType}>
                                            {LaunchTypeDisplayValues[launchType]}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className={`jc-dp-section ${isProductListingDraft(productListing) ? "" : "last"}`}>
                            <div style={{maxWidth: "720px"}}>
                                <h3>Fulfillment Type *</h3>
                                <div className="jc-dp-description">
                                    {/* TODO: What should the copy for these be? */}
                                    <p><b>{FulfillmentTypeDisplayValues[FulfillmentType.fulfilledByJuniper]}:</b> Fulfillment will be handled by Juniper.</p>
                                    <p><b>{FulfillmentTypeDisplayValues[FulfillmentType.fulfilledByCreator]}:</b> Fulfillment will be handled by the Creator.</p>
                                </div>
                                <Select
                                    className="jc-dp-select"
                                    required={true}
                                    name="fulfillmentType"
                                    marginBottom={20}
                                    onChange={onProductListingChanged}
                                    value={productListing.fulfillmentType}
                                    isInvalid={(productListing.fulfillmentType === LaunchType.unset)}
                                >
                                    {Object.values(FulfillmentType).map((fulfillmentType, index) => (
                                        <option key={index} value={fulfillmentType}>
                                            {FulfillmentTypeDisplayValues[fulfillmentType]}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        {isProductListingDraft(productListing) && (
                            <div className={"jc-dp-section last"}>
                                <h3>Delete</h3>
                                <div className={"jc-dp-description"}><p>This cannot be undone.</p></div>
                                <Button
                                    iconBefore={TrashIcon}
                                    size={"medium"}
                                    height={40}
                                    minWidth={120}
                                    borderRadius={10}
                                    marginBottom={20}
                                    onClick={() => setDeleteDialogIsShown(true)}
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                        <FooterActionBar
                            infoComponent={renderInfoComponent()}
                            isPrimaryLoading={mutationCalled && mutationLoading}
                            onPrimaryClick={() => setSubmitDialogIsShown(true)}
                            primaryButtonDisabled={isSubmissionDisabled()}
                            primaryButtonText={isProductListingDraft(productListing) ? "Submit" : "Submitted"}
                            primaryButtonIcon={isProductListingDraft(productListing) ? SendMessageIcon : undefined}
                            isSecondaryLoading={mutationCalled && mutationLoading}
                            onSecondaryClick={() => updateProductListing({productListing})}
                            secondaryButtonText={"Save"}
                            showSecondaryButton={!isProductListingCancelled(productListing)}
                            tertiaryButtonText={hasUnsavedChanges ? "Discard Changes" : ""}
                            onTertiaryClick={onDiscardChanges}
                            sidebarShown={true}
                        />
                    </fieldset>
                </Pane>
            </Pane>
            { renderSubmitDialog() }
            { renderDeleteDialog() }
            { renderUnsavedChangesDialog() }
        </>
    )
};
