import { Pane, Popover, PopoverProps } from "evergreen-ui";
import { useEffect, useState } from "react";

interface Props extends PopoverProps {
    isShown: boolean;
}

/**
 * This enhances the {@link Popover} to intercept outside clicks
 *  so that events don't get sent down to other elements unintentionally.
 *
 * @param props Inherited from {@link PopoverProps}.
 * @constructor
 */
export const PopoverWithOverlay = (props: Props) => {
    const {isShown, ...rest} = props;
    const [popoverIsShown, setPopoverIsShown] = useState(false);

    useEffect(() => {
        setPopoverIsShown(isShown);
    }, [isShown]);

    return (
        <>
            <Popover
                {...rest}
                isShown={popoverIsShown}
            >
                {props.children}
            </Popover>
            <Pane
                position={"fixed"}
                top={0}
                left={0}
                width={"100vw"}
                height={"100vh"}
                zIndex={9}
                overflow={"hidden"}
                display={popoverIsShown ? "block" : "none"}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setPopoverIsShown(false);
                }}
            />
        </>
    );
};
