import { CenteredSpinner } from "../../common/CenteredSpinner";
import { useProductListingsForUserQuery } from "../../datastore/UserDataStore";
import { useEffect } from "react";
import { NavigationHelper } from "../../navigation/PrevLocationState";
import { useHistory } from "react-router-dom";
import { ProductListingStatus } from "../../../../common/enums";

export const NewUserFirstProductRedirect = () => {
    const history = useHistory();
    const {data, loading, error} = useProductListingsForUserQuery();

    const hasNewlyOnboardedProduct = (): boolean => {
        // For extra safety measures, check for exactly one product and ensure that it's a draft.
        return !!data && (data.length === 1) && (data[0].status === ProductListingStatus.draft);
    }

    useEffect(() => {
        if (!loading) {
            if (!error && hasNewlyOnboardedProduct()) {
                history.replace(NavigationHelper.createProductPath(data![0].id, true));
            } else {  // Silently fail and go to the product list view.
                history.replace(NavigationHelper.createProductListViewPath());
            }
        }
    }, [data, loading, error, history]);

    return <CenteredSpinner/>;
}
