import React, { FC, useEffect } from "react";
import { Button, Heading, IconButton, Pane, PlusIcon, SearchIcon, Tooltip } from "evergreen-ui";
import { useMobileMediaQuery } from "../../MediaQuery";
import { removeJcContentContainerStylesEffect } from "../../removeJcContentContainerStylesEffect";
import { ColorPalette } from "../../../context/Theme";

interface TablePageHeaderProps {
    title: string;
    addButtonText: string;
    addButtonOnClick: () => void;
    searchBar?: React.ReactNode;
}

export const TablePageHeader: FC<TablePageHeaderProps> = (props) => {
    const isMobile = useMobileMediaQuery();

    // This removes the unwanted padding from the parent container.
    useEffect(() => removeJcContentContainerStylesEffect(false), []);

    return (
        <Pane width={"100%"} display={"flex"} alignItems={"center"}>
            <Heading
                size={800}
                style={{
                    margin: "24px",
                    width: "100%",
                    fontWeight: "unset",
                    color: `var(--grey-secondary-text)`
                }}
            >
                {props.title}
            </Heading>
            <Pane display={"flex"} gap={10}>
                {props.searchBar}
                {!isMobile ? (
                    <Button
                        marginRight={"24px"}
                        minWidth={"160px"}
                        iconBefore={PlusIcon}
                        size={"large"}
                        appearance={"primary"}
                        onClick={props.addButtonOnClick}
                    >
                        {props.addButtonText}
                    </Button>
                ) : (
                    <IconButton
                        marginRight={"24px"}
                        icon={<PlusIcon color={"white"}/>}
                        size={"large"}
                        appearance={"primary"}
                        onClick={props.addButtonOnClick}
                    />
                )}
            </Pane>
        </Pane>
    );
};
