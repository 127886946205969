import { gql } from "@apollo/client";
import { storeAllFieldsFragment, storeForAdminFieldsFragment, storeForReportingFieldsFragment } from "./CommonQueries";
import { BaseMutationTuple, BaseQueryResult, jssaQuery, useBaseMutation, useBaseQuery } from "./index";
import { Store, ProductListing } from "../../../common/models";
import { cleanObject } from "../../../common/utils";
import { StoreForAdmin } from "../../../common/tables/store/StoreForAdmin";
import { QueryInput } from "../../../common/query-filters";

const storeQuery = gql`
    ${storeAllFieldsFragment}
    query store($id: String!) {
        store(id: $id) {
            ...StoreAllFields
        }
    }
`;

export const storesForAdminQuery = gql`
    ${storeForAdminFieldsFragment}
    query storesForAdmin($options: StoresInput!) {
        storesForAdmin(options: $options) {
            ...StoreForAdminFields
        }
    }
`;

const storeListQuery = gql`
    ${storeAllFieldsFragment}
    query stores {
        stores {
            ...StoreAllFields
        }
    }
`;

const storesWithAnalyticsEnabledQuery = gql`
    ${storeForReportingFieldsFragment}
    query storesWithAnalyticsEnabled {
        storesWithAnalyticsEnabled {
            ...StoreForReportingFields
        }
    }
`;

const storesWithPayoutsEnabledQuery = gql`
    ${storeForReportingFieldsFragment}
    query storesWithPayoutsEnabled {
        storesWithPayoutsEnabled {
            ...StoreForReportingFields
        }
    }
`;

const updateStoreMutation = gql`
    ${storeAllFieldsFragment}
    mutation updateStore($data: StoreUpdateInput!) {
        updateStore(data: $data) {
            ...StoreAllFields
        }
    }
`;

const createStoreMutation = gql`
    ${storeAllFieldsFragment}
    mutation createStore($storeName: String!) {
        createStore(storeName: $storeName) {
            ...StoreAllFields
        }
    }
`

const addUserToStoreMutation = gql`
    ${storeAllFieldsFragment}
    mutation addUserToStore(
        $email: String!,
        $firstName: String!,
        $lastName: String!,
        $storeId: String!,
    ) {
        addUserToStore(
            storeId: $storeId,
            email: $email,
            firstName: $firstName,
            lastName: $lastName,
        ) {
            ...StoreAllFields
        }
    }
`;


const removeUserFromStoreMutation = gql`
    ${storeAllFieldsFragment}
    mutation removeUserFromStore(
        $email: String!,
        $storeId: String!,
    ) {
        removeUserFromStore(
            storeId: $storeId,
            email: $email,
        ) {
            ...StoreAllFields
        }
    }
`;

const productListingsForStoreQuery = gql`
    query productListingsForStore(
        $storeId: String!
    ) {
        productListingsForStore(storeId: $storeId) {
            id
            launchType
            status
        }
    }
`;

export const useStoreQuery = (id: string): BaseQueryResult<Store> => {
    return useBaseQuery<Store, { store: Store }>(
        storeQuery, { id }, ({ data }) => data.store);
};

type QueryStoresForAdminResponse = { storesForAdmin: Store[] };

export const useStoresForAdminQuery = (queryInput: QueryInput<StoreForAdmin>): BaseQueryResult<Store[]> => {
    return useBaseQuery<Store[], QueryStoresForAdminResponse>(
        storesForAdminQuery,
        { options: queryInput},
        (response) => response.data.storesForAdmin
    );
};

export const useStoreListQuery = (): BaseQueryResult<Store[]> => {
    return useBaseQuery<Store[], { stores: Store[] }>(
        storeListQuery, {}, ({ data }) => data.stores);
};

export const useStoresWithAnalyticsEnabledQuery = (): BaseQueryResult<Store[]> => {
    return useBaseQuery<Store[], { storesWithAnalyticsEnabled: Store[] }>(
        storesWithAnalyticsEnabledQuery, {}, ({ data }) => data.storesWithAnalyticsEnabled);
};

export const useStoresWithPayoutsEnabledQuery = (): BaseQueryResult<Store[]> => {
    return useBaseQuery<Store[], { storesWithPayoutsEnabled: Store[] }>(
        storesWithPayoutsEnabledQuery, {}, ({ data }) => data.storesWithPayoutsEnabled);
};

export const useUpdateStoreMutation = (): BaseMutationTuple<Store, Store> => {
    const transformInput = (store: Store) => {
        return {
            data: cleanObject(store, {
                exclusions: ["organizations", "createdAt", "updatedAt"]
            })
        };
    };
    const transformOutput = (output: any): Store => {
        return output.updateStore;
    };
    return useBaseMutation<Store, Store>(
        updateStoreMutation,
        transformInput,
        transformOutput,
    );
};

type CreateStoreInput = {
    storeName: string;
};

export const useCreateStoreMutation = (): BaseMutationTuple<CreateStoreInput, Store> => {
    return useBaseMutation<CreateStoreInput, Store>(
        createStoreMutation,
        (input: CreateStoreInput) => ({
            storeName: input.storeName
        }),
        (output: any): Store => output.createStore,
    );
};

type AddUserToStoreInput = {
    storeId: string;
    firstName: string;
    lastName: string;
    email: string;
};

export const useAddUserToStoreMutation = (): BaseMutationTuple<AddUserToStoreInput, Store> => {
    return useBaseMutation<AddUserToStoreInput, Store>(
        addUserToStoreMutation,
        (input: AddUserToStoreInput) => ({
            ...input,
            storeId: input.storeId,
        }),
        (output: any): Store => output.addUserToStore,
    );
};

type RemoveUserFromStoreInput = {
    storeId: string;
    email: string;
};

export const useRemoveUserFromStoreMutation = (): BaseMutationTuple<RemoveUserFromStoreInput, Store> => {
    return useBaseMutation<RemoveUserFromStoreInput, Store>(
        removeUserFromStoreMutation,
        (input: RemoveUserFromStoreInput) => ({
            ...input,
            storeId: input.storeId,
        }),
        (output: any): Store => output.removeUserFromStore,
    );
};

export const fetchProductListingsForStore = async (storeId: string): Promise<ProductListing[]> => {
    const { data: { productListingsForStore } } = await jssaQuery<{ productListingsForStore: ProductListing[] }>({
        query: productListingsForStoreQuery,
        variables: {
            storeId
        }
    });
    return productListingsForStore;
};

export const queryStoresForAdmin = async (
    queryInput: QueryInput<StoreForAdmin>,
): Promise<Store[]> => {
    const { data: { storesForAdmin } } = await jssaQuery<QueryStoresForAdminResponse>({
        query: storesForAdminQuery,
        variables: {
            options: queryInput,
        },
    });
    return storesForAdmin;
};
