import { FC } from "react";
import { CatalogItem } from "../../common/CatalogItem";
import { CatalogOption } from "../../../../common/models";
import { useMediaQuery } from "../../common/MediaQuery";
import { TileSelectorType } from "../../common/selector/TileSelector";
import { TileSelectorGroupProps } from "../../common/selector/TileSelectorGroup";
import { ProductWizardApparelSelectorGroup } from "../ProductWizardApparelTileSelectorGroup";
import { ProductWizardBasicTileSelectorGroup } from "../ProductWizardBasicTileSelectorGroup";

interface SelectionPageProps {
    title: string;
    description: string;
    identifier: string;
    catalogItems: CatalogItem[];
    onSelect: (selectedItem: CatalogItem) => void;
    selectedId: number | undefined | string;
    filter: (item: CatalogItem) => boolean;
    tileSelectorType?: TileSelectorType;
}

export const SelectionPage: FC<SelectionPageProps> = ({
    title,
    description,
    catalogItems,
    onSelect,
    filter,
    identifier,
    selectedId,
    tileSelectorType = TileSelectorType.basic
}) => {
    // We use a unique breakpoint based on the fixed widths of the tile and apparel selectors.
    const isMobile = useMediaQuery('(max-width: 1000px)');

    const renderTileSelectorGroup = (props: TileSelectorGroupProps<CatalogItem>) => {
        const tileSelectorTypeOverride = isMobile ? TileSelectorType.basic : tileSelectorType;
        switch (tileSelectorTypeOverride) {
            default:
            case TileSelectorType.basic:
                return <ProductWizardBasicTileSelectorGroup {...props} />;
            case TileSelectorType.apparel:
                const castedProps = props as TileSelectorGroupProps<CatalogOption>;
                return <ProductWizardApparelSelectorGroup {...castedProps} />;
        }
    };

    return (
        <div className={"jc-wizard-container"}>
            <div className={"jc-wizard-header"}>
                <h1 className={"jc-wizard-title"}>
                    {title}
                </h1>
                <span className={"jc-wizard-description"}>
                    {description}
                </span>
            </div>
            <div className={"jc-wizard-selector"}>
                {renderTileSelectorGroup({
                    groupIdentifier: identifier,
                    items: catalogItems,
                    filter: filter,
                    selectedId: selectedId,
                    onSelect: onSelect,
                    tileSelectorType: tileSelectorType
                })}
            </div>
        </div>
    )
}
