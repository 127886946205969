import * as H from "history";
import { ProductWizardPage } from "../product/ProductWizardPage";
import { NEW_USER_FIRST_PRODUCT_PATH } from "./Routes";

const onboardingPath = /\/products\/new/g;

export interface PrevLocationState {
    to: string;
    label: string;
}

export class NavigationHelper {
    public static readonly ProductListLabel: string = "All Products";
    public static readonly DefaultLabel: string = "Back";
    public static readonly StoreDetailsLabel: string = "Store Details";

    public to?: string;
    public label?: string;

    constructor(to?: string, label?: string) {
        this.to = to;
        this.label = label;
    }

    /**
     * Simple utility method that prevents pushing a duplicate URL pathname onto the stack.
     */
    public static pushPathname = (history: H.History, pathname: string): void => {
        if (pathname !== window.location.pathname) {
            history.push(pathname);
        }
    }

    public static onBack = (history: H.History, backNavigation?: PrevLocationState, nextState: PrevLocationState | {} = {}) => {
        return () => NavigationHelper.go(history, backNavigation, nextState);
    };

    public static go = (history: H.History, backNavigation?: PrevLocationState, nextState: PrevLocationState | {} = {}) => {
        const to = backNavigation?.to;
        if (to) {
            history.push(to, nextState);
        } else {
            history.goBack();
        }
    };

    public static createForProductOnboarding = (): PrevLocationState => {
        return {
            to: NavigationHelper.createNewProductPath(),
            label: NavigationHelper.DefaultLabel
        }
    }

    public static createForProductListView = (): PrevLocationState => {
        return {
            to: NavigationHelper.createProductListViewPath(),
            label: NavigationHelper.ProductListLabel,
        };
    }

    public static createForStoreInfoPage = (storeId: string): PrevLocationState => {
        return {
            to: NavigationHelper.createStorePath(storeId),
            label: NavigationHelper.StoreDetailsLabel
        };
    };

    public static isFromOnboarding = (backNavigation?: NavigationHelper): boolean => {
        return !!backNavigation && Boolean((backNavigation.to || "").match(onboardingPath));
    };

    public static createStartCreatingPath = (): string => {
        return '/start-creating';
    };

    public static createNewProductPath = (): string => {
        return '/products/new';
    }

    public static createStorePath = (storeId: string): string => {
        return `/stores/${storeId}`;
    }

    public static createStoreTeamManagementPath = (storeId: string): string => {
        return `/stores/${storeId}/team`;
    };

    public static createProductPath = (
        productListingId: string,
        goToSetupTab: boolean = false
    ): string => {
        let path = `/products/${productListingId}`;
        if (goToSetupTab) {
            path += "/setup";
        }
        return path;
    };

    public static createProductListViewPath = () => {
        return `/products`;
    }

    public static editProductPath = (
        productListingId: string,
        page?: ProductWizardPage
    ): string => {
        return `/products/${productListingId}/catalog/edit${page ? "?page=" + page : ""}`
    };

    public static createNewUserFirstProductPath = (): string => {
        return NEW_USER_FIRST_PRODUCT_PATH;
    }

    public static createMessagesPath = (conversationSid: string): string => {
        return `/messages/${conversationSid}`;
    }
}
