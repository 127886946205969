const internalEmailDomains = [
    "@hellojuniper.com",
    "@junipercreates.com"
];

export const isInternalEmail = (email: string): boolean => {
    return internalEmailDomains.some((domain: string) => {
        return email.trim().endsWith(domain);
    });
};
