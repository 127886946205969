import { Pane, Tab, TabNavigation } from "evergreen-ui"
import { matchPath, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { ColorPalette } from "../../context/Theme"
import { TabInfo } from "./TabInfo";
import { removeJcContentContainerStylesEffect } from "../removeJcContentContainerStylesEffect";

import './styles/MultiTabView.css';

export interface MultiTabViewProps {
    header: JSX.Element | string;
    tabs: TabInfo[];
    selectedTabIndex: number;
    onTabSelected: (index: number) => void;
    fullPathFormat?: string;
}

export const MultiTabView = <Params extends {tabId?: string}>({
    header,
    tabs,
    selectedTabIndex,
    onTabSelected,
    fullPathFormat,
}: MultiTabViewProps) => {
    const history = useHistory();

    useEffect(() => removeJcContentContainerStylesEffect(false), []);

    useEffect(() => {
        // When this prop isn't provided, it means that the URL path doesn't need to change when
        // navigating between tabs. In this case, we can skip subscribing the history listener.
        if (!fullPathFormat) {
            return;
        }

        // Subscribe to path changes to handle forward and back navigation between tabs.
        const unsubscribe = history.listen((location) => {
            // Deconstruct the URL path (i.e. /tabs/:tabId) into React router params.
            const result = matchPath<Params>(location.pathname, {
                path: fullPathFormat,
            });
            // Find the corresponding tab index based on the `tabId` param in the above result.
            const index = tabs.findIndex((tab: TabInfo) => {
                return (tab.tabId === result?.params?.tabId);
            });
            // Default to the zero-index if there is no match.
            onTabSelected((index !== -1) ? index : 0);
        });

        return () => {
            unsubscribe();
        };
    }, [fullPathFormat]);

    const renderHeader = (): JSX.Element => {
        if (typeof header === "string") {
            return (
                <h3 className={"multi-tab-heading"}>
                    {header}
                </h3>
            );
        }
        return header;
    };

    return (
        <Pane className={"multi-tab-container"}>
            <Pane className={"multi-tab-nav-container"}>
                {renderHeader()}
                <TabNavigation className={"multi-tab-nav"}>
                    {tabs.map((tab: TabInfo, index: number) => (
                        <Tab
                            appearance={"primary"}
                            color={tab.locked ?
                                ColorPalette.disabledText :
                                ColorPalette.primaryText
                            }
                            key={tab.label}
                            id={tab.label}
                            style={{
                                fontSize: "14px",
                                pointerEvents: tab.locked ? "none" : "auto"
                            }}
                            onSelect={() => {
                                onTabSelected(index);
                            }}
                            isSelected={index === selectedTabIndex}
                            aria-controls={`panel-${tab.label}`}
                            paddingX={0}
                            flexShrink={0}
                        >
                            {tab.label}
                        </Tab>
                    ))}
                </TabNavigation>
            </Pane>
            <Pane className={`multi-tab-content-container ${tabs[selectedTabIndex].useDefaultLayout ? "container-default-layout" : ""}`}>
                {tabs[selectedTabIndex].component}
            </Pane>
        </Pane>
    );
}
