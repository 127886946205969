import { OnlineListingState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class OnlineListingStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [OnlineListingState.ProductListing_GenerateSKU]: {
                label: "Product Listing, Generate SKU",
                value: OnlineListingState.ProductListing_GenerateSKU,
                color: "neutral"
            },
            [OnlineListingState.ProductListing_ProductPhotos]: {
                label: "Product Listing, Product Photos",
                value: OnlineListingState.ProductListing_ProductPhotos,
                color: "neutral"
            },
            [OnlineListingState.ProductListing_ExternalPricing]: {
                label: "Product Listing, External Pricing",
                value: OnlineListingState.ProductListing_ExternalPricing,
                color: "neutral"
            },
            [OnlineListingState.ProductListing_InternalPricing]: {
                label: "Product Listing, Internal Pricing",
                value: OnlineListingState.ProductListing_InternalPricing,
                color: "neutral"
            },
            [OnlineListingState.ProductListing_ProductDescription]: {
                label: "Product Listing, Product Description",
                value: OnlineListingState.ProductListing_ProductDescription,
                color: "neutral"
            },
            [OnlineListingState.ProductListing_LaunchDate]: {
                label: "Product Listing, Launch Date",
                value: OnlineListingState.ProductListing_LaunchDate,
                color: "neutral"
            },
            [OnlineListingState.ProductListing_WebsiteReady]: {
                label: "Product Listing, Website Ready",
                value: OnlineListingState.ProductListing_WebsiteReady,
                color: "neutral"
            },
            [OnlineListingState.MerchShelf_InternalOperations]: {
                label: "Merch Shelf, Internal Operations",
                value: OnlineListingState.MerchShelf_InternalOperations,
                color: "neutral"
            },
            [OnlineListingState.MerchShelf_ExternalOperations]: {
                label: "Merch Shelf, External Operations",
                value: OnlineListingState.MerchShelf_ExternalOperations,
                color: "green"
            },
            [OnlineListingState.NotRequired]: {
                label: "Not Required",
                value: OnlineListingState.NotRequired,
                color: "neutral"
            },
        };
    }
}