export enum Designer {
    // TODO: Replace this with a User relation that leverages Rampart groups
    Abigail = "Abigail",
    Arianne = "Arianne",
    Arielle = "Arielle",
    Gigi = "Gigi",
    Haruka = "Haruka",
    Helena = "Helena",
    Holly = "Holly",
    Jana = "Jana",
    Jeff = "Jeff",
    Jillian = "Jillian",
    Julia = "Julia",
    Kelsey = "Kelsey",
    Kristine = "Kristine",
    Lou = "Lou",
    Lucy = "Lucy",
    Michael = "Michael",
    Mirelys = "Mirelys",
    Natalie = "Natalie",
    Nicole = "Nicole",
    Roy = "Roy",
    Sarah = "Sarah",
    Stephanie = "Stephanie",
    Stephen = "Stephen",
    Sulah = "Sulah",
    Victor = "Victor",
}
