import { User } from "../models";

const toggles = {
    // Add your feature toggles here in key-value form
    // key = feature name
    // value = enabled {true | false}
    useRateLimiting: true,
    twilioChat: true,
    metabaseAnalytics: true,
    newProductUX: true,
    newUserUX: true,
    messageTagging: true,
    pinpoint: true,
    notificationsAPI: true,
    productAcceptedNotification: true,
    productCancelledNotification: true,
    productSubmissionNotification: true,
    invitedUserNotification: true,
    zapierProjectHook: true
};

// These user toggles should exist in the DB
export enum UserToggles {
    nativeMessagingEnabled = "nativeMessagingEnabled"
}

type Toggles = typeof toggles;

class FeatureToggles {
    private _toggles: Toggles;

    constructor(toggles: Toggles) {
        // Clone the toggles
        this.resetAll();
    }

    isFeatureEnabled(feature: keyof Toggles): boolean {
        return this._toggles[feature];
    }

    isUserFeatureEnabled(user: User, feature: UserToggles): boolean {
        return feature in user && user[feature];
    }

    enableFeature(feature: keyof Toggles): void {
        this._toggles[feature] = true;
    }

    disableFeature(feature: keyof Toggles): void {
        this._toggles[feature] = false;
    }

    reset(feature: keyof Toggles): void {
        this._toggles[feature] = toggles[feature];
    }

    resetAll(): void {
        this._toggles = Object.assign({}, toggles);
    }
};

export const featureToggles: FeatureToggles = new FeatureToggles(toggles);
