import { WarehousePricingVersion } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class WarehousePricingVersionMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [WarehousePricingVersion.static2022]: {
                label: "Static 2022",
                value: WarehousePricingVersion.static2022,
                color: "neutral"
            },
            [WarehousePricingVersion.dynamic2023]: {
                label: "Dynamic 2023",
                value: WarehousePricingVersion.dynamic2023,
                color: "neutral"
            },
            [WarehousePricingVersion.none]: {
                label: "None",
                value: WarehousePricingVersion.none,
                color: "neutral"
            }
        };
    }
}