import {
    Avatar,
    IconButton,
    CloudDownloadIcon,
    Pane,
    Paragraph,
    Spinner,
    TrashIcon,
    Link,
    Text
} from "evergreen-ui";
import Linkify from "linkify-react";
import * as linkify from "linkifyjs";
import { useEffect, useRef, useState } from "react";
import { Media as TwilioMedia } from '@twilio/conversations';
import { MessageAttributes, Tag } from "../../../../../common/types";
import { CenteredSpinner } from "../../../common/CenteredSpinner";
import { useUserContext } from "../../../context/UserContext";
import { isInternalEmail } from "../../../../../common/utils/isInternalEmail";
import { getAvatarColorForIndex } from "../../common/getAvatarColorForIndex";

import "../Messages.css";

export interface MediaAttachment {
    sid: string | null;
    fileName: string | null;
    initialFileUrl: string | null;
    contentType: string | null;
    twilioMedia: TwilioMedia
}

export interface StandardMessageViewProps {
    participantIndex: number;
    message: string | null;
    messageAttributes?: MessageAttributes;
    author: string;
    sid: string;
    index: number;
    authorName: string;
    isFirstInGroup: boolean;
    mediaAttachments: MediaAttachment[];
    messageTime: string | null;
    openDeleteMessageDialog: (sid: string) => void;
}

// Only Mime Types listed here will be displayed as
// images in the app; everything else is given the generic
// file display. Add/remove as necessary.
const SUPPORTED_IMAGE_MIME_TYPES = [
    "image/jpeg",
    "image/png",
    "image/tiff",
    "image/svg",
    "image/webp",
    "image/bmp",
    "image/gif"
];

// TODO: Need to add statuses, linking, and also attachments

export const StandardMessageView: React.FC<StandardMessageViewProps> = (
    props: StandardMessageViewProps
) => {
    const {
        participantIndex,
        message,
        messageAttributes,
        author,
        index,
        sid,
        authorName,
        isFirstInGroup,
        messageTime,
        mediaAttachments,
        openDeleteMessageDialog
    } = props;

    const [isHovering, setIsHovering] = useState<Boolean>(false);
    const { user } = useUserContext();

    const renderTextMessage = () => {
        const options: linkify.Opts = {
            render: {
                url: ({ attributes, content }) => {
                    return <Link style={{ fontSize: "13px", color: "var(--dark-green-primary)"}} target={"_blank"} href={attributes.href}>{content}</Link>
                },
                keyword: ({ attributes, content }) => {
                    let currentUserTag: Tag | undefined = undefined;
                    if (messageAttributes && messageAttributes.tags) {
                        currentUserTag = messageAttributes.tags.find((tag: Tag) => tag.data.userId === user?.email && tag.data.displayName === content);
                    }
                    return <Text style={{
                        fontSize: "13px",
                        padding: "2px",
                        color: `var(${currentUserTag ? '--dark-green-primary' : '--black-heading-text'})`,
                        backgroundColor: `var(${currentUserTag ? '--green-background-focus-bold' : '--grey-background-focus'})`,
                        borderRadius: "4px"
                    }}>{content}</Text>
                }
            }
        }

        return message &&
            <Linkify options={options}>
                <Paragraph className="message-contents-text">
                    {message}
                </Paragraph>
            </Linkify>;
    };

    const RenderMediaImage = (media: MediaAttachment, index: number) => {

        const [loaded, setLoaded] = useState<boolean>(false);
        const ref = useRef<HTMLImageElement>(null);

        const downloadImage = async () => {
            const blobToDataURL = (blob: Blob): Promise<string> => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result as string);
                    reader.onerror = () => reject(reader.error);
                    reader.onabort = () => reject(new Error("Read aborted"));
                    reader.readAsDataURL(blob);
                });
            };

            const url = await media.twilioMedia.getContentTemporaryUrl();
            if (url) {
                const file: Blob = await (await fetch(url)).blob();
                const link: HTMLAnchorElement = document.createElement("a");
                document.body.appendChild(link);
                link.download = media.fileName || "";
                link.href = await blobToDataURL(file);
                link.click();
                document.body.removeChild(link);
            }
        };

        const onLoad = () => setLoaded(true);
        useEffect(() => {
            if (ref.current && ref.current.complete) {
                onLoad();
            }
        });

        // If the URL is missing, then there isn't anything to show.
        if (!media.initialFileUrl) {
            return <></>;
        }

        // Render the fetched image into the placeholder
        return (
            <>
                <Pane className="media-message-contents media-message-contents-img loaded" key={media.sid + " " + index.toString()}>
                    <img
                        style={{
                            display: loaded ? "initial" : "none"
                        }}
                        ref={ref}
                        onLoad={onLoad}
                        className="media-message"
                        alt=""
                        src={media.initialFileUrl}
                        onClick={async () => window.open(await media.twilioMedia.getContentTemporaryUrl() || undefined, '_blank')}
                    />
                    <IconButton className="media-img-download-button" icon={CloudDownloadIcon} iconSize={20} onClick={downloadImage} />
                </Pane>
                {!loaded && <Pane className="media-message-contents media-message-contents-img" key={media.sid + " " + index.toString() + "_spin"}>
                    <CenteredSpinner />
                </Pane>}
            </>
        );
    }

    const renderMediaFile = (media: MediaAttachment, index: number) => {
        return (
            <Pane
                className="media-message-contents media-message-contents-file loaded"
                key={media.sid + index.toString()}
            >
                {media.initialFileUrl ?
                    <a
                        className="media-fileurl"
                        href={media.initialFileUrl}
                        download={media.fileName}
                    >
                        {renderMediaFilename(media)}
                        <Pane>
                            <CloudDownloadIcon color="grey" />
                        </Pane>
                    </a>
                    :
                    <>
                        {renderMediaFilename(media)}
                        <Pane>
                            <Spinner size={24} />
                        </Pane>
                    </>
                }
            </Pane>
        )
    }

    const renderMediaFilename = (media: MediaAttachment) => {
        const fileType = media.contentType!.split("/")[1].toUpperCase() ?? "Unknown File-Type";
        return (
            <Pane minWidth="0" display="flex" flexDirection="column" rowGap="5px">
                <span className="media-filename">
                    {media.fileName}
                </span>
                <span style={{ fontSize: "10px", fontWeight: "lighter", color: "var(--grey-secondary-text)" }}>{fileType}</span>
            </Pane>
        );
    }

    const isImageType = (media: MediaAttachment): boolean => {
        return SUPPORTED_IMAGE_MIME_TYPES.some((type: string) => {
            return media.contentType && media.contentType.startsWith(type);
        });
    }

    const renderMediaMessages = () => {
        return mediaAttachments.map((media, index) => {
            const renderFn = isImageType(media) ? RenderMediaImage : renderMediaFile;
            return renderFn(media, index);
        });
    }

    const renderMessageActions = () => {
        return user && author && user.email === author && isInternalEmail(user.email) && isHovering &&
            <Pane className="message-actions">
                <Pane display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                    <IconButton className="message-actions-button" icon={TrashIcon} color="grey" border="none" onClick={() => openDeleteMessageDialog(sid)} />
                </Pane>
            </Pane>
    }

    const renderFirstMessageView = () => {
        return (
            <>
                <Pane className="message-container-standard" marginTop={5} key={sid + index.toString()} onMouseOver={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                    {author === user?.email ?
                        <Pane marginLeft={"-1.5px"} borderRadius={"100%"} height={"47px"} padding={"2px"} border={"1px solid #2AAC8D"}>
                            <Avatar color={"green"} outline={"2px solid white"} name={authorName} size={41} />
                        </Pane> :
                        <Avatar color={getAvatarColorForIndex(participantIndex)} name={authorName} size={45} />}
                    <Pane className="message-title-contents-container">
                        <Pane className="message-title">
                            <span className="author"> {authorName} </span>
                            <span className="timestamp"> {messageTime} </span>
                        </Pane>
                        <Pane className="message-contents">
                            {renderTextMessage()}
                            {renderMediaMessages()}
                        </Pane>
                    </Pane>
                    {renderMessageActions()}
                </Pane>
            </>
        )
    }

    const renderLastMessageView = () => {
        return (
            <Pane className="message-container-standard" key={sid + index.toString()} onMouseOver={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                <Pane className="message-contents-no-avatar">
                    {renderTextMessage()}
                    {renderMediaMessages()}
                </Pane>
                {renderMessageActions()}
            </Pane>
        );
    }

    return (
        <>
            {isFirstInGroup ? renderFirstMessageView() : renderLastMessageView()}
        </>
    );
}
