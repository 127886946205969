export enum PackagingState {
    DesignerPicked = "DesignerPicked",
    DesignerAssigned = "DesignerAssigned",
    QuoteGiven = "QuoteGiven",
    QuoteAccepted = "QuoteAccepted",
    Designing = "Designing",
    EditsRequired = "EditsRequired",
    ClientApproved = "ClientApproved",
    InternalApproved = "InternalApproved",
    NotRequired = "NotRequired",
}
