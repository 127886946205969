import { ComponentProps, FunctionComponent } from "react";
import { Home } from "../pages/Home";
import { MultiTabProductPage } from "../pages/MultiTabProductPage";
import { NativeMessages } from "../pages/NativeMessages";
import { NewUserAgreementPage } from "../pages/NewUser/NewUserAgreementPage";
import { NewUserBillingPage } from "../pages/NewUser/NewUserBillingPage";
import { NewUserPhoneNumberPage } from "../pages/NewUser/NewUserPhoneNumberPage";
import { NewUserRootPage } from "../pages/NewUser/NewUserRootPage";
import { ProductListPage } from "../pages/ProductList/ProductListPage";
import { Analytics } from "../pages/Analytics";
import { LoginPage } from "../pages/LoginPage";
import { Registration } from "../pages/Registration";
import { Logout } from "../pages/Logout";
import { StoreListPage } from "../pages/StoreList/StoreListPage";
import { NewProductCreation } from "../pages/NewProductCreation";
import {EditProductPage} from "../pages/EditProductPage";
import { NewUserFirstProductRedirect } from "../pages/NewUser/NewUserFirstProductRedirect";
import { MultiTabSettingsPage } from "../pages/MultiTabSettingsPage";
import { MultiTabStorePage } from "../pages/Store/MultiTabStorePage";
import { Payouts } from "../pages/Payouts";

export type RouteProps = {
    path: string;
    component: FunctionComponent<any>;
    authenticated: boolean;
    gatedComponentEnabled?: boolean;
    gatedComponent?: FunctionComponent<any>;
    exact?: boolean;
    props?: ComponentProps<any>;
};

// The question mark denotes that the conversationSid param is optional.
export const MESSAGES_ROUTE_PATH = "/messages/:conversationSid?";
export const NEW_USER_FIRST_PRODUCT_PATH = "/new-user/first-product";

export const STORE_ROUTE_PATH = "/stores/:storeId/:tabId?";

export const SETTINGS_ROUTE_PATH = "/settings/:tabId?";

export const EDIT_PRODUCT_PATH = "/products/:productListingId/catalog/edit";

export const routes: RouteProps[] = [
    {
        path: "/login",
        component: LoginPage,
        authenticated: false
    },
    {
        path: "/logout",
        component: Logout,
        authenticated: false,
    },
    {
        path: "/registration",
        component: Registration,
        authenticated: false,
    },
    {
        path: "/products",
        component: ProductListPage,
        authenticated: true,
    },
    {
        path: "/products/new",
        component: NewProductCreation,
        authenticated: false
    },
    {
        path: "/products/:productListingId",
        component: MultiTabProductPage,
        authenticated: true,
    },
    {
        path: "/products/:productListingId/:focusedTab",
        component: MultiTabProductPage,
        authenticated: true,
    },
    {
        path: EDIT_PRODUCT_PATH,
        component: EditProductPage,
        authenticated: true
    },
    {
        path: "/new-user",
        component: NewUserRootPage,
        gatedComponent: NewUserRootPage,
        authenticated: true
    },
    {
        path: "/new-user/phone-number",
        component: NewUserPhoneNumberPage,
        authenticated: true
    },
    {
        path: "/new-user/agreement",
        component: NewUserAgreementPage,
        authenticated: true
    },
    {
        path: "/new-user/billing",
        component: NewUserBillingPage,
        authenticated: true
    },
    {
        path: NEW_USER_FIRST_PRODUCT_PATH,
        component: NewUserFirstProductRedirect,
        authenticated: true
    },
    {
        path: STORE_ROUTE_PATH,
        component: MultiTabStorePage,
        authenticated: true
    },
    {
        path: SETTINGS_ROUTE_PATH,
        component: MultiTabSettingsPage,
        authenticated: true,
    },
    {
        path: "/analytics",
        component: Analytics,
        authenticated: true,
    },
    {
        path: "/payouts",
        component: Payouts,
        authenticated: true,
    },
    {
        path: "/stores",
        component: StoreListPage,
        authenticated: true,
    },
    {
        path: MESSAGES_ROUTE_PATH,
        component: NativeMessages,
        authenticated: true,
    },
    {
        path: "/",
        component: Home,
        authenticated: true,
        exact: true,
    },
];
