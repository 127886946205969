import { MoxtraAccessToken } from '../../../common/moxtra/MoxtraAccessToken';
import { moxtraCtx } from './MoxtraContext';
import { fetchAccessToken, revokeAccessToken } from './MoxtraDataStore';

// Manages the lifetime of the Moxtra access token
class MoxtraTokenManager {
    static readonly MOXTRA_ACCESS_TOKEN = 'moxtraAccessToken';

    private get accessToken(): MoxtraAccessToken | null {
        const accessToken = localStorage.getItem(MoxtraTokenManager.MOXTRA_ACCESS_TOKEN);
        if (!accessToken) {
            return null;
        }
        const parsedAccessToken = JSON.parse(accessToken);
        return new MoxtraAccessToken(
            parsedAccessToken["_accessToken"],
            new Date(parsedAccessToken["_expiryTime"])
        );
    }

    private set accessToken(accessToken: MoxtraAccessToken | null) {
        if (!accessToken) {
            return;
        }
        localStorage.setItem(MoxtraTokenManager.MOXTRA_ACCESS_TOKEN, JSON.stringify(accessToken));
    }

    private linkAccessToken(newAccessToken: MoxtraAccessToken): Promise<MoxtraAccessToken> {
        return new Promise((resolve, reject) => {
            if (!moxtraCtx.isInitialized) {
                this.accessToken = newAccessToken;
                resolve(newAccessToken);
            }
            moxtraCtx.mepsdk.linkUserWithAccessToken(newAccessToken.accessToken)
                .then((result: any) => {
                    result.close();
                    this.accessToken = newAccessToken
                    resolve(newAccessToken);
                })
                .catch((err: any) => reject(new Error(err)));
        });
    }

    private updateAccessToken(): Promise<MoxtraAccessToken> {
        return new Promise(async (resolve, reject) => {
            try {
                const newAccessToken = await fetchAccessToken();
                await this.linkAccessToken(newAccessToken);
                resolve(newAccessToken);
            } catch(err) {
                reject(err);
            }
        });
    }

    async revokeAccessToken(): Promise<void> {
        if (!this.accessToken || this.accessToken.tokenExpired()) {
            return;
        }
        return revokeAccessToken(this.accessToken)
            .then(() => Promise.resolve())
            .catch(err => console.error(err))
            .finally(() => localStorage.removeItem(MoxtraTokenManager.MOXTRA_ACCESS_TOKEN));
    }

    async getAccessToken(): Promise<string> {
        if (this.accessToken && !this.accessToken.tokenExpired()) {
            return this.accessToken.accessToken;
        }

        // Moxtra access token does not exist or expired, so fetch a new one
        try {
            await this.updateAccessToken();
            return this.accessToken!.accessToken;
        } catch(err) {
            return Promise.reject("Access token cannot be updated: " + err);
        }
    }
}

export const moxtraTokenManager = new MoxtraTokenManager();