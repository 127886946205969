import {
    Pane,
    Table
} from "evergreen-ui";
import { TundraStorefrontRecord } from "../../../../common/models";

import "./styles/StoreTundraAdminGetView.css";

export interface StoreTundraAdminDetailsCardProps {
    title?: string;
    storefront: TundraStorefrontRecord;
}

export const StoreTundraAdminDetailsCard = (props: StoreTundraAdminDetailsCardProps): JSX.Element => {

    const {
        title,
        storefront
    } = props;

    const renderRow = (title: string, description?: string | number | boolean): JSX.Element => {
        return <Table.Row height={"auto"}>
            <Table.Cell flexBasis={"33%"} flexShrink={0} flexGrow={0}>
                <p>
                    <b>{title}</b>
                </p>
            </Table.Cell>
            <Table.Cell>
                <p>
                    { description ?? "-" }
                </p>
            </Table.Cell>
        </Table.Row>;
    };

    return (
        <Pane
            className={"tundra-admin-domains-container-item"}
        >
            <h3
                style={{
                    margin: 0,
                    paddingBottom: "8px",
                    fontWeight: "bold"
                }}
            >
                {title}
            </h3>
            <Table>
                <Table.Head>
                    <Table.HeaderCell flexBasis={"33%"} flexShrink={0} flexGrow={0}>
                        Tundra Detail
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Value
                    </Table.HeaderCell>
                </Table.Head>
                <Table.Body>
                    { renderRow(`Storefront ID`, storefront.storeId) }
                    { renderRow(`Service Tier`, storefront.tier) }
                    { renderRow(`Display Name`, storefront.displayName) }
                    { renderRow(`Short Name`, storefront.shortName) }
                    { renderRow(`Region`, storefront.region) }
                    { renderRow(`Shopify Domain`, storefront.shopifyDomain) }
                    { renderRow(`Theme Config Version`, storefront.themeConfigVersion) }
                    { renderRow(`Dev S3 Bucket`, storefront.developmentBucket) }
                    { renderRow(`Dev Theme Version`, storefront.devThemeVersion) }
                    { renderRow(`Dev Theme S3 Version`, storefront.devThemeS3ObjectVersion) }
                    { renderRow(`S3 Bucket`, storefront.productionBucket) }
                    { renderRow(`Theme Version`, storefront.prodThemeVersion) }
                    { renderRow(`Theme S3 Version`, storefront.prodThemeS3ObjectVersion) }
                    { renderRow(`Created By`, storefront.createdBy) }
                    { renderRow(`Theme Builder Disabled?`, storefront.themeBuilderDisabled) }
                </Table.Body>
            </Table>
        </Pane>
    );

};
