import { gql } from "@apollo/client";

export const userAllFieldsFragment = gql`
    fragment UserAllFields on User {
        id
        firstName
        lastName
        email
        moxtraId
        phoneNumber
        invitationPending
        loginStatus
        role
        nativeMessagingEnabled
        twilioUserId
        agreementAcceptedDate,
        rampartUniqueId,
        rampartGroups,
        clientTypes
    }
`;

export const organizationAllFieldsFragment = gql`
    fragment OrganizationAllFields on Organization {
        id
        stripeCid
        name
        isAgency
        createdAt
        updatedAt
    }
`;

export const storeAllFieldsFragment = gql`
    ${userAllFieldsFragment}
    ${organizationAllFieldsFragment}
    fragment StoreAllFields on Store {
        id
        name
        about
        avatar
        demographic
        brandColors
        similarBrands
        logoUrl
        youtube
        twitter
        instagram
        tiktok
        twitch
        createdAt
        updatedAt
        internalVendorName
        isPartnershipsTier
        domain
        conversationSid
        julySleepExcluded
        organizations {
            ...OrganizationAllFields
            members {
                ...UserAllFields
            }
        }
    }
`;

export const storeForAdminFieldsFragment = gql`
    fragment StoreForAdminFields on Store {
        id
        name
        internalVendorName
        isPartnershipsTier
        julySleepExcluded
        domain
        createdAt
        updatedAt
    }
`;

export const storeForReportingFieldsFragment = gql`
    fragment StoreForReportingFields on Store {
        id
        name
        internalVendorName
        isPartnershipsTier
    }
`;

// TODO: refactor this and `productListingAllFieldsFragment` to use common fragments.
export const productListingForAdminFieldsFragment = gql`
    fragment ProductListingForAdminFields on ProductListing {
        id
        createdAt
        updatedAt
        submissionDate
        qualifiedDate
        customerStageSheetId
        name
        description
        mediaAssets
        launchType
        fulfillmentType
        inventoryOwner
        sizing
        status
        modifier
        assignee
        designer
        dqReason
        dqDate
        dqStage
        sku
        source
        lead
        agency
        notes
        launchDate
        launchEnd
        warehousePricingVersion
        lifecycleStatus
        preDesignReviewState
        designConceptState
        mfgDrawingState
        samplingState
        designInvoiceState
        packagingState
        onlineListingState
        inventoryStatus
        storeWorkstreamState
        mfgWorkstreamState
        addOns {
            id
            name
        }
        pricing {
            catalogOption {
                id
                name
                displayName
            }
        }
        store {
            id
            name
            domain
        }
        twilioConversationId
        asanaTaskId
        shopifyProductId
        shippingInfo
    }
`;

export const productListingAllFieldsFragment = gql`
    fragment ProductListingAllFields on ProductListing {
        id
        name
        description
        createdAt
        updatedAt
        submissionDate
        mediaAssets
        launchType
        fulfillmentType
        inventoryOwner
        sizing
        status
        addOns {
            id
            name
        }
        pricing {
            catalogOption {
                id
                name
                displayName
                defaultOption
                isCustom
                pricing {
                    id
                    msrp
                    moq
                    agencyCost
                    creatorCost
                }
                catalogProduct {
                    id
                    name
                    isCustom
                    catalogCategory {
                        id
                        name
                        isCustom
                    }
                    catalogOptions {
                        id
                        name
                        defaultOption
                        displayName
                        imageUrl
                        pricing {
                            id
                            msrp
                            moq
                            agencyCost
                            creatorCost
                        }
                    }
                }
            }
        }
        store {
            id
            name
            about
            demographic
            brandColors
            similarBrands
            bypassPreOrderRestriction
            domain
            julySleepExcluded
        }
        customerStage {
            designer
            launched
            connectedToDesigner
            approvedDesign
            approveManufacturingDrawing
            approveSamplePhotos
            approvePhysicalSample
            domain
        }
        modifier
        assignee
        sku
        designer
        dqReason
        dqDate
        dqStage
        source
        lead
        agency
        notes
        launchDate
        launchEnd
        warehousePricingVersion
        lifecycleStatus
        preDesignReviewState
        designConceptState
        mfgDrawingState
        samplingState
        designInvoiceState
        packagingState
        onlineListingState
        inventoryStatus
        asanaTaskId
        shopifyProductId
        twilioConversationId
        shippingInfo
    }
`;

export const timelineStatusAllFieldsFragment = gql`
    fragment TimelineStatusAllFields on TimelineStatus {
        getStarted {
            status
            tabs {
                creditCard
                contactInfo
                storeDetails
                addProductDetails
                submitProductDetails
            }
        }
        design {
            status
            tabs {
                designer
                designConcept
                manufacturingDrawing
                packaging
                bannerArtwork
            }
        }
        sample {
            status
            tabs {
                photosReceived
                photosApproved
                sampleReceived
                sampleApproved
            }
        }
        preOrderLaunch {
            status
            tabs {
                paid
                storeDomain
                retailPricing
                productPhotos
                startSelling
                manufacture
            }
        }
        inStockLaunch {
            status
            tabs {
                manufacture
                productPhotos
                purchaseInventory
                retailPricing
                startSelling
                storeDomain
            }
        }
    }
`;

export const tundraStorefrontAllFieldsFragment = gql`
    fragment TundraStorefrontAllFields on TundraStorefront {
        storefront {
            createdBy
            devThemeS3ObjectVersion
            devThemeVersion
            developmentBucket
            displayName
            prodThemeS3ObjectVersion
            prodThemeVersion
            productionBucket
            region
            shopifyDomain
            shortName
            storeId
            themeBuilderDisabled
            themeConfigVersion
            tier
        }
        domains {
            alias {
                alias
                certificateKeyAuthorization
                customDomainCertificateId
                customDomainWorkflowId
                lastCertificateExpirationTime
                lastCertificateRenewal
                lastCertificateRenewalTime
                lastScannedStatus
                lastScannedTime
                storeId
            }
            certificate {
                domainNames
                primaryDomainName
                validationDnsRecords {
                    name
                    type
                    value
                }
                validationMethod
                validationStatus
            }
            dns {
                type
                values
            }
            renewal {
                cause
                error
                input
                name
                output
                startDate
                status
                workflowId
            }
        }
    }
`;

export const payoutRuleAllFieldsFragment = gql`
    fragment PayoutRuleAllFields on PayoutRule {
        id
        name
        type
        retailPricePercentApplicable
        unitCostPercentApplicable
        juniperServiceFeePercentApplicable
        fulfillmentFeePercentApplicable
        processingFeePercentApplicable
        shippingSubsidyPercentApplicable
        vendorProfitSplitPercent
        createdAt
        updatedAt
    }
`;
