import { EnumResolver } from "../../../../common/tables/etc/EnumResolver";
import {
    PayoutRuleTableRow
} from "../../../../../../common/tables/store/payout-rules/PayoutRuleTableRow";
import { EnumMapper } from "../../../../common/tables/etc/EnumMapper";
import { PayoutRuleTypeMapper } from "./mappers/PayoutRuleTypeMapper";

export class PayoutRulesEnumMapper extends EnumResolver<PayoutRuleTableRow> {
    public resolveEnum(columnName: keyof PayoutRuleTableRow): EnumMapper {
        switch (columnName) {
            case "type":
                return new PayoutRuleTypeMapper();
            default:
                return {} as EnumMapper;
        }
    }
}
