import { Heading, Pane, Paragraph, toaster } from "evergreen-ui";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { PhoneInputField } from "../../common/PhoneInputField";
import { useUserQuery } from "../../datastore/UserDataStore";
import { isPhoneNumberValid } from "../../product/Utils";
import { NewUserContainer, NewUserPage, NewUserState } from "./NewUserContainer";
import { NavigationHelper } from "../../navigation/PrevLocationState";

export const NewUserPhoneNumberPage = () => {

    const history = useHistory<NewUserState>();
    const {
        phoneNumber,
        agreementAccepted,
        wasRedirected
    } = history.location.state || {};

    const {
        loading: queryLoading,
        error: queryError,
        data: fetchedUser,
    } = useUserQuery();

    const [ textInput, setTextInput ] = useState<string>(phoneNumber || "");
    const [ showRedirectMessage, setShowRedirectMessage ] = useState<boolean>(wasRedirected || false);

    const onPhoneNumberChange = (newPhoneNumber: string) => {
        setTextInput(newPhoneNumber);
        history.replace("/new-user/phone-number", {
            phoneNumber: newPhoneNumber,
            agreementAccepted: agreementAccepted,
            wasRedirected: wasRedirected
        });
    };

    if (showRedirectMessage) {
        toaster.notify("Please complete onboarding steps to unlock the rest of the app.");
        setShowRedirectMessage(false);
    }

    if (queryError) {
        toaster.danger("Something went wrong. Try again.");
    }

    // If we find the user already has a phone number provided, we shouldn't be here,
    //  we should be in one of two places:
    if (!queryLoading && fetchedUser && fetchedUser.phoneNumber && !phoneNumber) {
        // 1. If they haven't accepted the user agreement, they need to go over there and do that.
        if (!fetchedUser.agreementAcceptedDate) {
            history.push("/new-user/agreement", {
                phoneNumber: fetchedUser.phoneNumber,
                agreementAccepted: !!fetchedUser.agreementAcceptedDate,
                wasRedirected: wasRedirected
            });

        // 2. If they have, then they shouldn't be here at all, so go home or to the first product.
        } else {
            history.push(NavigationHelper.createNewUserFirstProductPath());
        }
    }

    if (queryLoading) {
        return <CenteredSpinner />;
    }

    return (
        <NewUserContainer
            page={NewUserPage.PhoneNumber}
            primaryButtonText={"Continue"}
            continueEnabled={isPhoneNumberValid(textInput)}
            onContinue={() => {
                // This updates the original state if the user hits back immediately
                history.replace("/new-user/phone-number", {
                    phoneNumber: textInput,
                    agreementAccepted: agreementAccepted,
                });
                // This actually starts the normal state propagation
                history.push("/new-user/agreement", {
                    phoneNumber: textInput,
                    agreementAccepted: agreementAccepted,
                })
            }}
        >
            <Pane
                height={"100vh"}
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Pane
                    padding={"24px"}
                    maxWidth={"460px"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"left"}
                >
                    <Heading size={900} paddingBottom={"24px"} fontWeight={400}>
                        Welcome to Juniper!
                    </Heading>
                    <Paragraph size={500} paddingBottom={"24px"}>
                        Please enter your phone number so that we can send you notifications about messages and updates.
                    </Paragraph>
                    <PhoneInputField
                        value={textInput}
                        showToolTip={false}
                        onChange={onPhoneNumberChange} />
                </Pane>
            </Pane>
        </NewUserContainer>
    );
};
