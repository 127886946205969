import { TableConfig } from "../../../../common/tables/config/TableConfig";
import { CellType } from "../../../../common/tables/config/CellType";
import { SortOrder } from "../../../../../../common/query-filters";
import { StoreForAdmin } from "../../../../../../common/tables/store/StoreForAdmin";

export const DefaultStoreTableConfig: TableConfig<StoreForAdmin> = {
    columns: [
        {
            columnName: "name",
            displayName: "Name",
            cellType: CellType.textInputWithMenuButton,
            hidden: false,
            filtered: false,
            sticky: true,
            width: "180px",
            sortable: true,
            filterable: false,
        },
        {
            columnName: "id",
            displayName: "Internal ID",
            cellType: CellType.readOnlyText,
            hidden: false,
            filtered: false,
            sticky: true,
            width: "200px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "internalVendorName",
            displayName: "Vendor Name",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "160px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "isPartnershipsTier",
            displayName: "Partnerships Tier",
            cellType: CellType.checkbox,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "140px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "julySleepExcluded",
            displayName: "S/S Exception",
            cellType: CellType.checkbox,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "120px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "domain",
            displayName: "Domain",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "200px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "createdAt",
            displayName: "Created At",
            cellType: CellType.readOnlyDate,
            hidden: false,
            filtered: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "updatedAt",
            displayName: "Updated At",
            cellType: CellType.readOnlyDate,
            hidden: false,
            filtered: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
    ],
    filters: {
        expressions: [],
        sortOrder: SortOrder.ASCENDING,
        sortColumn: "name",
        pageSize: 50,
    },
}
