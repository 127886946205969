import React, { FC } from "react";
import { EndorsedIcon, Icon, Menu, Pane, Text } from "evergreen-ui";
import { ColorPalette } from "../../context/Theme";

interface SearchInputMenu {
    searchResults: { label: string, value: string, isVerified: boolean }[];
    loading: boolean;
    error: boolean;
    onSelectResult: (label: string, value: string) => void;
}

export const SearchInputMenu: FC<SearchInputMenu> = ({
    searchResults,
    loading,
    error,
    onSelectResult
}) => {
    const renderEmptyOrLoadingMenu = (message: string) => {
        return (
            <Pane display={"flex"} alignItems={"center"} height={32}>
                <Text className={"search-input-menu-text"}>
                    {message}
                </Text>
            </Pane>
        );
    };

    if (loading) {
        return renderEmptyOrLoadingMenu("Searching...");
    }

    if (error) {
        return renderEmptyOrLoadingMenu("An error occurred");
    }

    if (searchResults.length === 0) {
        return renderEmptyOrLoadingMenu("No results found");
    }

    return (
        <Menu>
            {searchResults.map(({ label, value, isVerified }) => {
                return (
                    <Menu.Item key={value} onSelect={() => onSelectResult(label, value)}>
                        {label}
                        {isVerified && (
                            <Icon
                                color={ColorPalette.primaryAccent}
                                icon={EndorsedIcon}
                                size={12}
                            />
                        )}
                    </Menu.Item>
                );
            })}
        </Menu>
    );
};
