import { Pane, PaneProps } from "evergreen-ui";
import { genericMemo } from "../../GenericMemo";
import { ColumnConfig } from "../config/ColumnConfig";
import { TableCellChangedHandler, TableCellFactory } from "../factory";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";

interface Props<T extends TableDataWithId, D extends TableDataWithId> extends PaneProps {
    data: D;
    column: ColumnConfig<T>;
    tableCellFactory: TableCellFactory<T, D>;
    onCellChanged: TableCellChangedHandler<T>;
}

function TableBodyCellInternal<T extends TableDataWithId, D extends TableDataWithId>({
    data,
    column,
    tableCellFactory,
    onCellChanged,
    style,
    className,
    ...paneProps
}: Props<T, D>) {
    return (
        <Pane
            className={`jc-td ${className}`}
            style={{
                width: column.width,
                ...style,
            }}
            {...paneProps}
        >
            {tableCellFactory.renderBodyCell(column, data, onCellChanged)}
        </Pane>
    );
}

export const TableBodyCell = genericMemo(TableBodyCellInternal);
