import { Heading, Link, ListItem, OrderedList, Pane, Paragraph, Strong } from "evergreen-ui";

import "./LegalAgreement.css";

const LAST_UPDATED_DATE = "December 7, 2022";
const PRIVACY_POLICY_URL = "https://shop.junipercreates.com/docs/privacy-policy";
const DCMA_NOTICE_AND_TAKEDOWN_URL = "https://copyrightalliance.org/education/copyright-law-explained/the-digital-millennium-copyright-act-dmca/dmca-notice-takedown-process/";

export interface LegalAgreementProps {
    noPadding?: boolean;
}

export const LegalAgreement = ({
    noPadding = false
}: LegalAgreementProps) => {
    return (
        <Pane padding={noPadding ? "0" : "24px"}>
            <Heading size={600} className={"block-section"}>
                Agreement
            </Heading>
            <Paragraph className={"block-section"}>
                <Strong>
                    Last Updated: { LAST_UPDATED_DATE }
                </Strong>
            </Paragraph>
            <Paragraph className={"block-section"}>
                These Juniper Terms + Conditions (this <Strong>“Agreement”</Strong>) are entered into between Imprint Creations Inc. d.b.a. Juniper
                (<Strong>“Juniper”</Strong>, <Strong>“we”</Strong>, <Strong>“us”</Strong> or <Strong>“our”</Strong>) and you (<Strong>“Client”</Strong>,
                <Strong>“you”</Strong> or <Strong>“your”</Strong>) and set forth the terms and conditions that apply to your
                access and use of the Juniper Platform (as defined below) and the related services (the “Services”) you order from Juniper from
                time to time by entering into one or more written or electronic order form(s) (each, an <Strong>“Order Form”</Strong>).
            </Paragraph>
            <Paragraph className={"block-section"}>
                BY USING THE JUNIPER PLATFORM OR THE SERVICES OR CLICKING ON THE “I ACCEPT” OR SIMILAR BUTTON, YOU ARE INDICATING YOUR ACCEPTANCE TO BE BOUND
                BY THIS AGREEMENT. IF YOU DO NOT ACCEPT THIS AGREEMENT, YOU MUST NOT USE THE JUNIPER PLATFORM OR THE SERVICES. IF YOU ARE DISSATISFIED WITH THIS
                AGREEMENT OR ANY RULES, POLICIES, GUIDELINES OR PRACTICES APPLICABLE TO THE JUNIPER PLATFORM OR THE SERVICES, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                DISCONTINUE USE OF THE JUNIPER PLATFORM AND THE SERVICES.
            </Paragraph>
            <Paragraph className={"block-section"}>
                IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THIS
                AGREEMENT, IN WHICH CASE THE TERMS “YOU” OR “YOUR” WILL REFER TO SUCH ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THIS AGREEMENT,
                YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE JUNIPER PLATFORM OR THE SERVICES.
            </Paragraph>
            <Paragraph className={"block-section"}>
                By accepting this Agreement, you agree to be bound by the terms and conditions of the Agreement, as well as our Privacy Policy
                located at <Strong><Link target={"_blank"} href={PRIVACY_POLICY_URL}>[{PRIVACY_POLICY_URL}]</Link></Strong> (the <Strong>“Privacy Policy”</Strong>), as it may be amended from time to time in the future.
            </Paragraph>
            <Paragraph className={"block-section"}>
                We may amend any part of this Agreement by adding, deleting or varying its terms from time-to-time in our discretion. We will provide you with notice
                of the proposed amendment by posting an amended version of this Agreement with a new “Last Updated” date. We will include a link to the previous version of this Agreement beneath the new “Last Updated” date.
            </Paragraph>
            <Paragraph className={"block-section"}>
                The amendments will take effect 30 days after the date on which the amended version is posted. Prior to that date, the previous version of this Agreement will continue to apply.
            </Paragraph>
            <Paragraph className={"block-section"}>
                If you disagree with any amendments, you may terminate this Agreement by ceasing to use the Juniper Platform and the Services at any time within the 30-day period before the amendments take effect.
                If the amendment increases your obligations under this Agreement, or decreases our obligations under this Agreement, then you can also terminate in the 30 days after the amendments take effect. In either
                case, there is no cost or penalty for terminating. If you do not cease using the Juniper Platform and the Services during that time, then by your continued use, you are considered to have accepted the proposed amendments.
            </Paragraph>
            <Paragraph className={"block-section"}>
                In order to enter into this Agreement, you must have reached the legal age of majority in your jurisdiction of residence, and be fully able and competent to enter into the terms, conditions, obligations, affirmations,
                representations and warranties set forth in this Agreement, and to abide by and comply with this Agreement. It is your responsibility to ensure that you are legally eligible to enter into this Agreement under any laws applicable to you.
                If you accept this Agreement, you represent that you have the capacity to be bound by it.
            </Paragraph>
            <OrderedList>
                <ListItem>
                    <Heading className={"block-section"}>Definitions</Heading>
                    <Paragraph className={"block-section"}>
                        When used in this Agreement, the following terms shall have the following meanings:
                    </Paragraph>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Client Designs”</Strong> means any designs, graphics, characters, content, work product and inventions that are provided by Client from time to time for use by Juniper in connection with the Services.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Client Marks”</Strong> means any trademarks, logos, trade dress, branding and any other distinctive brand features used, now or in the future, in connection with Client’s brand, that are provided by Client
                                from time to time for use by Juniper in connection with the Services.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Client Materials”</Strong> means, collectively, Client Designs, Client Marks and Client Personality Rights.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Client Personality Rights”</Strong> means Client’s (or the content creator(s) whom Client represents) name, image, likeness, speaking voice, performances, quotes, biography and other identifying characteristics
                                as provided by Client from time to time for use by Juniper in connection with the Services.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Confidential Information”</Strong> means any and all information disclosed by a party (<Strong>“Disclosing Party”</Strong>) to the other party (<Strong>“Receiving Party”</Strong>) under this Agreement that is
                                not public information and that is marked “confidential” or “proprietary” or which the Receiving Party knows or ought reasonably to know is regarded by the Disclosing Party as such, including information transmitted orally.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Costs of Goods”</Strong> consists of all costs incurred by Juniper associated with designing, sampling, manufacturing, packaging, product fulfillment and shipping the Products.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Effective Date”</Strong> means that date on which Client and Juniper enter into an Order Form for the first time.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“End Customer”</Strong> means the customer purchasing the Product on the StoreJuniper Platform, or a previously agreed upon third party platform.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Fees”</Strong> is defined in Section 6(a).
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“In-Stock Model”</Strong> means a Product merchandising model under which Products are purchased by Client and manufactured prior to launching the Store or new Product collection, in accordance with the minimum
                                thresholds and quantities set out in this Agreement or the applicable Order Form.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Intellectual Property Rights”</Strong> means, collectively, all proprietary rights provided or recognized under patent law, copyright law, trademark law, design patent or industrial design law, trade secret law
                                or any other applicable statutory provision otherwise arising at law or in equity anywhere in the world that may provide or recognize any right in any tangible or intangible thing.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Inventory”</Strong> means Products that have been produced or ordered to be manufactured, but have not yet been sold.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Juniper Platform”</Strong> means the online platform developed by Juniper to manage its clients’ merchandise projects.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Net Sales”</Strong> is defined in Section 4(a).
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Pre-Order Campaign Model”</Strong> means a Product merchandising model under which a Store or Product collection is launched with no Inventory in stock, and such Inventory is manufactured at the end of a pre-order
                                campaign that meets the minimum pre-order thresholds and quantities set out in this Agreement or the applicable Order Form.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Products”</Strong> means mass-produced, custom-designed products sourced by Juniper from third party manufacturers.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Recurring Fee”</Strong> is defined in Section 6(a)(iii).
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Store”</Strong> means an online store operated by Juniper as part of the Juniper Platform through which Products will be sold to End Customers on Client’s behalf.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Term”</Strong> is defined in Section 9.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                <Strong>“Variable Fees”</Strong> consists of all (i) taxes levied by government authorities in connection with the Products, (ii) returns of Products and refunds which are credited to End Customers, and (iii) payment
                                processing fees charged by payment processing partners.
                            </Paragraph>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Services and Client Responsibilities</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Subject to the terms and conditions of this Agreement, Juniper will provide the Services to Client during the Term. Except as expressly set forth in this Agreement, the manner and means by which Juniper provides the Services are under Juniper’s sole and exclusive control.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Client acknowledges and agrees that proper delivery of Services requires Client to provide timely cooperation and communication to Juniper and to carry out reasonable requests made by Juniper from time to time (<Strong>“Client Responsibilities“</Strong>).
                                Client will perform, and where applicable, ensure that the content creator(s) whom Client represents performs, Client Responsibilities during the Term.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Juniper will use commercially reasonable efforts to fix any bugs or issues with the Juniper Platform in a timely manner. Juniper will not be liable for any damages to Client if the Juniper Platform or Services are temporarily unavailable.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                From time to time during the Term, Juniper may make changes to the Services and/or Client Responsibilities in writing to Client. Juniper further reserves the right to modify, suspend or discontinue the
                                Juniper Platform and/or Services, or any portion thereof, at any time and for any reason, with or without notice.
                            </Paragraph>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Product Approval, Manufacturing and Delivery</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Client may submit requests and orders for Products from time to time during the Term. Any design for newly proposed Products will be subject to the mutual written approval of the parties. Juniper reserves the right to refuse to design or manufacture any Products for any reason.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Juniper will not add any Products to the Store without final approval of the Products by Client, which approval will not be unreasonably withheld, delayed or conditioned.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                For both the Pre-Order Campaign Model and the In-Stock Model, Client will be responsible for and maintain control over the prices and sales.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Once Client approves the Product sample through the Juniper Platform, Client will be liable for any costs associated with additional changes to the Product.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Juniper will use commercially reasonable efforts to ensure that the manufactured Products accurately match the originally approved sample. Client agrees that each Product will have an acceptable tolerance during
                                manufacturing and may not perfectly match the approved Product.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                When manufacturing Products that are apparel, the graphics will be, based on standard practice, scaled up or down for each size. Client agrees and understands that each size will have its own discrepancies and will not perfectly match the original sample.
                                If Client elects to utilize the In-Stock Model, Client is solely responsible for determining the sizing distribution for the Products, regardless of whether it elects to utilize Juniper’s recommendations.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                As a condition of the Services, Client must meet the following minimum order thresholds:
                            </Paragraph>
                            <ol type={"i"}>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        In-Stock Model: Requires that the minimum quantity of Products be ordered and manufactured before launch. The minimum quantity will vary per Product but is typically no less than a quantity of 300 Product units.
                                        Please verify the exact amount with your Client Success Specialist prior to starting a product; or
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        Pre-Order Campaign Model: A limited-time launch that must exceed a minimum threshold quantity of Products before an order is placed. Products are not manufactured or fulfilled until the pre-order threshold is met.
                                        The threshold will vary per Product but is typically no less than a quantity of 300 Product units. Please verify the exact amount with your Client Success Specialist.
                                    </Paragraph>
                                </ListItem>
                            </ol>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Juniper will use commercially reasonable efforts to manufacture and ship the Products within agreed-upon timelines, but Juniper shall not be responsible or liable for any delays in shipping or delivery.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Shipping charges will be passed on to the End Customer unless otherwise agreed to between the parties. Shipping is calculated on a per unit basis based on Product weight, and may be updated from time to time by Juniper to reflect the current market rate.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Juniper is not responsible for missed, delayed or lost deliveries due to incorrectly provided delivery addresses. All replacements and shipping costs resulting from such missed, delayed or lost deliveries will be charged at the End Customer’s or Client’s expense.
                            </Paragraph>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Net Sales Payments</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                As part of the Services, Juniper shall manage and fulfill direct-to-customer sale of Products, and remit to Client net sales in accordance with this Agreement (“Net Sales”). For the purposes of this Agreement, Net Sales shall be determined in the following manner:
                            </Paragraph>
                            <ol type={"i"}>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        Under the Pre-Order Campaign Model, Client will receive a payment for Products sold to End Customers equal to the retail cost of the Products, less Costs of Goods, shipping subsidies, and Variable Fees.
                                        This will be mutually agreed to by both parties in writing (communicated on the Juniper Platform) and on a Product-by-Product basis.
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        Under the In-Stock Model, Client will receive a payment for Products sold to an End Customer equal to the retail cost, less shipping subsidies, and less Variable Fees for the Products. This will be mutually agreed to by both parties in
                                        writing (communicated on the Juniper Platform) and on a Product-by-Product basis and is contingent on Client pre-paying the Inventory cost, which varies on a Product-by-Product basis.
                                    </Paragraph>
                                </ListItem>
                            </ol>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Juniper will remit to Client its Net Sales on a monthly basis, with payments occurring within the first ten business days of the second calendar month following any month in which Products are sold. By way of example, Client will receive its
                                payment for January Product sales by no later than March 10th. Juniper will provide Client with access to information regarding the sales of the Products in real-time, as well as a monthly report detailing the breakdown of the Net Sales for each monthly period.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                All payments will be transferred by Juniper to Client either via PayPal or by wire transfer to an account mutually agreed upon by both parties. Payments will be made in United States dollars. Juniper will not pay any taxes on behalf of Client in connection
                                with its payment to Client of Client’s portion of the Net Sales, and Client expressly agrees that it will be responsible for any and all tax obligations and liabilities applicable to the receipt of its portion of the Net Sales pursuant to this Agreement.
                            </Paragraph>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Returns and Refunds</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Pre-Order Campaigns
                            </Paragraph>
                            <ol type={"i"}>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        If a limited-time launch does not exceed the minimum order threshold established by Juniper before the campaign is ended, Client will have the option to purchase the remaining Product units at cost or refund the orders.
                                        Client will be required to pay for the remaining Inventory before the order is placed. If Client does not cover the additional cost within thirty (30) days, Juniper will automatically refund the orders.
                                    </Paragraph>
                                </ListItem>
                            </ol>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Replacements
                            </Paragraph>
                            <ol type={"i"}>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        2% of all Inventory purchased by Client will be held back for replacements due (wrong size, address changes, etc.).
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        All additional shipping costs associated with replacements or returns not caused by manufacturing defects will be passed on to the End Customer or covered by Client.
                                    </Paragraph>
                                </ListItem>
                            </ol>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Juniper Fees and Payment Terms</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                The fees for the Services (the <Strong>“Fees”</Strong>) shall be set out in an Order Form or such other pricing document agreed to between the parties (<Strong>“Pricing Sheet”</Strong>). Such Fees may include, but are not limited to:
                            </Paragraph>
                            <ol type={"i"}>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        <Strong>Professional services.</Strong> Fees for professional services for the development of Product and Store designs, to be charged on an hourly basis.
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        <Strong>Samples</Strong>. Fees for manufacturing Product samples which will vary by Product (each, a <Strong>“Sample Fee”</Strong>). Each Sample Fee entitles Client to one final sample. Any additional samples will be subject to availability, and will be subject to payment of an
                                        additional Sample Fee. If the third party manufacturer utilized by Juniper produces a sample that does not sufficiently match the manufacturing drawings, modifications to the sample will be included in the initial Sample Fee. Changes to the sample which
                                        require changes to the approved manufacturing drawing after the factory has completed the sample will result in an additional fees.
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        <Strong>Recurring Fees</Strong>. Recurring fees are charged by Juniper for its maintenance and upkeep of the website and sales dashboard, provision of an active customer support team, and recurring costs incurred by Juniper connected to the Services, including website hosting,
                                        e-commerce and warehousing costs (collectively, <Strong>“Recurring Fees”</Strong>). Recurring Fees will be charged on a monthly basis according to the Averaged Units Warehoused (<Strong>“AUW”</Strong>) during that month. The monthly charge is subject to change and will scale according to the number
                                        of Product units warehoused, as follows (or as otherwise set out in the Pricing Sheet):
                                    </Paragraph>
                                    <ol type={"1"}>
                                        <ListItem>
                                            <Paragraph className={"block-section"}>
                                                First 60 days will have no recurring fee
                                            </Paragraph>
                                        </ListItem>
                                        <ListItem>
                                            <Paragraph className={"block-section"}>
                                                Proceeding 60 days, inventory will be charged at  $0.20 USD * Average Units Warehoused per month.
                                            </Paragraph>
                                        </ListItem>
                                    </ol>
                                    <Paragraph className={"block-section"}>
                                        By the way of example, a Client with an average of 100 units warehoused with Juniper, will incur a charge of $20 USD  [100 units * 1 month * $0.20].  The credit card you provide to us or our third party payment processors will be charged for any Fees you incur (including both one-time
                                        Fees and Recurring Fees) plus any applicable taxes, and you agree that Juniper or our third party payment processors may store your credit card information for such purpose. If the credit card you provide expires and you do not provide new credit card information or cancel your account,
                                        you authorize Juniper to continue billing you and you agree to remain responsible for any uncollected Fees.
                                    </Paragraph>
                                </ListItem>
                            </ol>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>License + Intellectual Property Rights</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                As between Juniper and Client, the parties acknowledge and agree that Client owns and shall continue to own all right, title and interest, including all Intellectual Property Rights, in and to Client Materials.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Subject to Juniper’s compliance with the terms and conditions of this Agreement, during the Term, Client grants to Juniper a non-exclusive, royalty-free, worldwide right and license, with right of sublicense, to use, copy, display,
                                integrate and distribute Client Materials in connection with the Services. Any use of Client Materials by Juniper shall be subject to the approval of Client, provided however that: (i) any use of Client Materials in a manner contemplated
                                under the Statement of Work shall be deemed approved by Client; and (ii) if Juniper notifies Client of a proposed use of Client Materials and Client does not respond within five (5) business days following receipt of such notice, such proposed use will be deemed to be approved by Client.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                As between Juniper and Client, the parties acknowledge and agree that Juniper owns and shall continue to own all right, title and interest, including all Intellectual Property Rights, in and to Juniper’s technology platform, including without limitation its technical stack,
                                live services (including the Juniper Platform) and infrastructure, and any and all modifications, enhancements, improvements and derivative works of any of the foregoing.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Any and all work product, including all Intellectual Property Rights therein and thereto, created as part of the Services, but specifically excluding Client Materials, will be owned by Juniper.  Subject to Client’s compliance with the terms and conditions of this Agreement, during the Term,
                                Juniper grants to Client a non-exclusive, royalty-free, revocable, worldwide right and license to use Juniper’s name, branding and other trademarks (each in the form and subject to the usage restrictions provided by Juniper from time to time) solely for the purpose of performing Client Responsibilities,
                                including without limitation promoting, marketing, selling and supporting the Products.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Subject to Client’s compliance with the terms and conditions of this Agreement, during the Term, Juniper grants to Client a non-exclusive, royalty-free, revocable, worldwide right and license to use Juniper’s name, branding and other trademarks (each in the form and subject to
                                the usage restrictions provided by Juniper from time to time) solely for the purpose of performing Client Responsibilities, including without limitation promoting, marketing, selling and supporting the Products.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Client understands that it is responsible for the Products developed through the Services and represent and warrant that it has all necessary rights to the designs. If Juniper receives a Digital Millennium Copyright Act (DMCA) takedown notice for Client’s content or other
                                legal notice, takedown or cease and desist with respect to infringement of a third party Intellectual Property Right, Juniper reserves the right (but is not obligated to) to remove those Products, immediately cease the manufacture and design of such Product, terminate the
                                agreement or otherwise take measures it determines as appropriate to address the notice. Juniper will not be responsible or liable for any lost revenue due to these takedowns. Juniper respects Intellectual Property Rights and follows intellectual property laws.
                                More information about DMCA procedure is available <Link target={"_blank"} href={DCMA_NOTICE_AND_TAKEDOWN_URL}>here</Link>.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Juniper reserves the right to refuse to provide Services in the event any Client Material or Client’s requested designs for Products or Store contains dangerous, illegal, infringing or otherwise objectionable material, as determined by Juniper. Without limiting the
                                generality of the foregoing, you agree that you will not publish or make available any Client Content or use the Services to produce Products in a manner that:
                            </Paragraph>
                            <ol type={"i"}>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        is harmful or poses a danger to the health and safety of an End Customer;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        infringes, violates or misappropriates any third party’s intellectual property or proprietary rights;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        is misleading, deceptive or fraudulent or otherwise illegal or promotes illegal activities, including engaging in phishing or otherwise obtaining financial or other personal information in a misleading manner or for fraudulent or misleading purposes;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        is libelous or defamatory, or that is otherwise threatening, abusive, violent, harassing, malicious or harmful to any person or entity, or is invasive of another’s privacy;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        is harmful to minors in any way;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        is hateful or discriminatory based on race, color, sex, religion, nationality, ethnic or national origin, marital status, disability, sexual orientation or age or is otherwise objectionable, as reasonably determined by Juniper;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        impersonates a Juniper employee, or any other person, or falsely states or otherwise misrepresents your affiliation with any person or entity, or to obtain access to the Juniper Platform or Services or a portion thereof without proper authorization;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        facilitates the unlawful distribution of copyrighted content;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        interferes or attempts to interfere with the proper working of the Juniper Platform or Services, or in a manner that disrupts the normal flow of dialogue with an excessive number of messages
                                        (flooding attack) to the Juniper Platform, or that otherwise negatively affects other persons’ ability to use the Juniper Platform or Services;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        contains software viruses, Trojan horses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        uses any manual or automated means, including agents, robots, scripts, or spiders, to monitor or copy the Juniper Platform or Services or the content contained therein;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        except as expressly permitted by Juniper, licenses, sublicenses, rents or leases the Services to third parties, or uses the Services for third party training, commercial time-sharing or service bureau use;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        includes personal or identifying information about another person in a manner that employs misleading email or IP addresses, or forged headers or otherwise manipulated identifiers in order to disguise the origin of Client Material transmitted through the Juniper Platform or Services to users;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        constitutes or contains any form of advertising or solicitation to users who have requested not to be contacted about other services, products or commercial interests;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        stalks or otherwise harasses anyone;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        collects, uses or discloses data, including personal information, about users without their informed consent or for unlawful purposes or in violation of applicable law or regulations;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        requests, solicits or otherwise obtains access to usernames, passwords or other authentication credentials from any user of the Juniper Platform or Services for the purposes of automating logins to the Juniper Platform;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        attempts to gain unauthorized access to the computer systems of Juniper or engage in any activity that disrupts, diminishes the quality of, interferes with the performance of, or impairs the functionality of the Juniper Platform or Services;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        posts adult or pornographic content;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        decompiles or reverse engineers or attempts to access the source code of the software underlying the Juniper Platform, the Services or any other Juniper technology;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        copies, archives, stores, reproduces, rearranges, modifies, downloads, uploads, creates derivate works from, displays, performs, publishes, distributes, redistributes or disseminates all or any part of the Juniper Platform or Services;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        accesses the Juniper Platform or Services for the purposes of building a product using similar ideas, features, functions, interface or graphics as those found in the Juniper Platform or Services;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        accesses the Juniper Platform or Services for the purposes of monitoring its availability, performance or functionality, or for any other benchmarking or competitive purposes; or
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        accesses the Juniper Platform to upload any Client Materials or computer code for the purposes of: (i) causing a breach or override of security to the Juniper Platform or Services; (ii) interfering
                                        with the proper working, functionality or performance of the Juniper Platform or Services; or (iii) preventing others from accessing or using the Juniper Platform or Services.
                                    </Paragraph>
                                </ListItem>
                            </ol>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Indemnity</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Client will defend, indemnify and hold harmless Juniper and its respective shareholders, directors, officers, employees, agents and assignees (the <Strong>“Juniper Indemnitees”</Strong>) harmless from and against all third party claims, suits, demands or
                                actions (<Strong>“Claims”</Strong>), and shall indemnify the Juniper Indemnitees against all costs, expenses and resulting damages awarded (including reasonable outside attorneys’ fees) to the extent arising from or relating to: (i) Client’s use of the
                                Services and the Juniper Platform; (ii) Client’s breach of this Agreement; and/or (iii) any assertion that Client Materials, or the use of Client Materials by Juniper in accordance with this Agreement, infringe upon any Intellectual Property
                                Rights or other rights of any third party. In the event Client Materials, or Juniper’s use thereof, are subject to any infringement Claim, Client shall, at its sole expense and discretion, either (A) procure for Juniper the right to continue
                                using Client Materials in accordance with this Agreement, or (B) modify Client Materials so that they no longer infringe any third party rights; and if neither (A) nor (B) have been implemented by Client within 30 days following the date of
                                notice of the Claim, Juniper may, in its discretion, immediately terminate this Agreement upon written notice to Client.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                A Juniper Indemnitee seeking to be indemnified pursuant to this Section 8 must: (i) promptly notify Client of any Claim, and (ii) give the Client the sole control over the defense of such Claim. However, if an Juniper fails to notify the
                                Client promptly, the Client will be relieved of its obligations under this Section 8 only if and to the extent that its ability to defend the Claim is materially prejudiced by such failure. The Client may settle or compromise a Claim without
                                the Juniper’s prior approval of any such settlement or compromise only if (A) such settlement involves no finding or admission of any breach by an Juniper of any obligation to any third party, (B) such settlement has no effect on any other claim
                                that may be made against an Juniper or any defense that an Juniper may assert in any such claim, and (C) the sole relief provided in connection with such settlement is monetary damages that are paid in full by the Client. Upon the Client’s
                                assumption of the defense of such Claim, Juniper will cooperate with the Client in such defense, at the Client’s expense.
                            </Paragraph>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Term and Termination</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                This Agreement commences on the Effective Date and will continue in full force and effect until terminated by either party in accordance with the terms and conditions of this Agreement (the <Strong>“Term”</Strong>).
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                In addition to any other termination rights set forth in this Agreement, this Agreement may be terminated:
                            </Paragraph>
                            <ol type={"i"}>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        by a party for any reason by providing the other party with 30 days’ notice in writing;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        by a party if the other party is in default in the performance of any of its material obligations under this Agreement or otherwise commits any material breach of this Agreement, and such default continues after
                                        30 days’ written notice from the non-defaulting party to the defaulting party stating the particulars of such default;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        by a party upon written notice to the other party If the other party becomes insolvent, files or has filed against either party a petition in bankruptcy, reorganization, or for the adoption of an arrangement
                                        under any present or future bankruptcy, or if a receiver or trustee of all or substantially all of the other party’s property is appointed or if the other party discontinues its business.
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        by Juniper immediately upon written notice to Client in the event that Client, or the content creator(s) whom Client represents, does any act which is defamatory, dishonest, discriminatory, inflammatory,
                                        racist, obscene, sexually explicit, unlawful or which gives rise to civil or criminal liability or in the reasonable opinion of Juniper is likely to bring it, Client or the content creator(s) whom Client represents into
                                        disrepute or materially damage their reputation;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                     by Juniper with or without notice if Client fails to pay any Recurring Fee for 60 consecutive days; or
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        by Juniper with or without notice if a product is paused by Client for more than 180 days.
                                    </Paragraph>
                                </ListItem>
                            </ol>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Upon any termination of this Agreement:
                            </Paragraph>
                            <ol type={"i"}>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        Client will be responsible for arranging and assuming shipping costs, customs fees, and duties for the remaining Inventory. If the Inventory is not picked up by
                                        Client within 30 days following the termination date, Juniper reserves the right to dispose of any remaining Inventory without liability to Client.
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        Client will pay out the remainder of their monthly Fees or annual Fees in the case of a terminated contract. Juniper will deduct the remainder of the owed Fees from any Net Sales owed to Client and,
                                        if there are still amounts owing by Client in connection with the termination of this agreement, Client will prompt pay to Juniper such amounts via Paypal, by wire transfer or such other payment method
                                        directed by Juniper. Upon receipt of payment in full, Juniper will, at Client’s option and cost, either: (i) make the Inventory available for pickup by Client at Juniper’s designated warehouse; or
                                        (ii) ship the Inventory to Client. Client will be liable for any costs associated with shipping the Products to Client.
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        all rights and licenses granted hereunder will immediately and automatically terminate;
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        each party will promptly return to the other party (or destroy, at the other party’s discretion) all Confidential Information of the other party in its possession or control, and will certify in writing to such return (or destruction); and
                                    </Paragraph>
                                </ListItem>
                                <ListItem>
                                    <Paragraph className={"block-section"}>
                                        all domains created and managed by Juniper that were associated with Client will be canceled. If Client would prefer for the domains to be transferred to Client, it must provide a written transfer request to
                                        Juniper within 30 days of the termination date. Any such domain transfers will be subject to Juniper’s approval and performed at Client’s expense.
                                    </Paragraph>
                                </ListItem>
                            </ol>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Disclaimer of Warranty + Limitation of Liability</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, JUNIPER HEREBY DISCLAIMS ANY AND ALL WARRANTIES WITH RESPECT TO THE SERVICES INCLUDING, WITHOUT LIMITATION, EXPRESSED AND IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR
                                PURPOSE AND ANY IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OF TRADE OR COURSE OF PERFORMANCE. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT ANY PRODUCTS MANUFACTURED IN CONNECTION WITH THE SERVICES ARE MANUFACTURED BY THIRD PARTY MANUFACTURERS, AND
                                JUNIPER DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, REGARDING SUCH PRODUCTS. JUNIPER SHALL NOT BEAR ANY PRODUCT LIABILITY IN CONNECTION THEREWITH, AND JUNIPER SHALL NOT RESPONSIBLE FOR THE ACTS OR OMISSIONS OF, OR FOR
                                THE FAILINGS OF, ANY THIRD-PARTY MANUFACTURERS OR ANY OTHER THIRD PARTY SERVICE PROVIDER OF ANY CONTENT, SERVICE, NETWORK, SOFTWARE OR HARDWARE, INCLUDING BUT NOT LIMITED TO, SHIPPING AND DELIVERY SERVICE PROVIDERS, INTERNET SERVICE JUNIPERS, HOSTING SERVICES
                                UTILIZED BY JUNIPER, TELECOMMUNICATIONS JUNIPERS, CONTENT PROVIDED BY OTHER USERS OR ANY SOFTWARE OR HARDWARE NOT PROVIDED BY JUNIPER.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                IN NO EVENT SHALL JUNIPER, ITS AFFILIATES, ITS AND THEIR SUBCONTRACTORS, AND THE PERSONNEL OF EACH OF THEM, BE LIABLE TO CLIENT UNDER ANY THEORY OF LIABILITY FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, WHICH INCLUDES WITHOUT
                                LIMITATION DAMAGES FOR LOST PROFITS OR REVENUES, LOST BUSINESS OPPORTUNITIES, LOSS OF IMAGE OR LOST DATA, EVEN IF CLIENT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF JUNIPER, ITS AFFILIATES, ITS AND
                                THEIR SUBCONTRACTORS, AND THE PERSONNEL OF EACH OF THEM, FOR ALL CLAIMS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT EXCEED THE AGGREGATE AMOUNT OF FEES PAID OR PAYABLE BY CLIENT UNDER THIS AGREEMENT DURING THE 12 MONTHS PRIOR TO THE FIRST EVENT GIVING RISE TO SUCH CLAIMS.
                            </Paragraph>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>Confidentiality</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                The Receiving Party agrees (i) to hold the Disclosing Party’s Confidential Information in strict confidence, (ii) to limit access to the Disclosing Party’s Confidential Information to those of its employees, contractors or agents having a need to know and
                                who are bound by confidentiality obligations at least as restrictive as those contained herein, and (iii) not to use the Disclosing Party’s Confidential Information for any purpose except as expressly permitted hereunder. Notwithstanding the foregoing, the
                                Receiving Party will not be in violation of this Section 11(a) with regard to a disclosure that was in response to a valid court order, or the advice of outside legal counsel that such disclosure must be made by it in order that it not commit a violation of
                                law or requirement by a court or other governmental body, provided that the Receiving Party gives the Disclosing Party prior written notice of such disclosure in order to permit the Disclosing Party to seek confidential treatment of such Confidential Information.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                The restrictions on use and disclosure of Confidential Information set forth in Section 11(a) will not apply to any Confidential Information, or portion thereof, which (i) is or becomes a part of the public domain through no act or omission of the Receiving Party,
                                (ii) was in the Receiving Party’s lawful possession prior to the disclosure, as shown by the Receiving Party’s competent written records, (iii) is independently developed by the Receiving Party without reference to the Disclosing Party’s Confidential Information,
                                as shown by the Receiving Party’s competent written records, or (iv) is lawfully disclosed to the Receiving Party by a third party without restriction on disclosure.
                            </Paragraph>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem>
                    <Heading className={"block-section"}>General Provisions</Heading>
                    <ol type={"a"}>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Client acknowledges and agrees that Juniper may retain the services of independent contractors (<Strong>“Subcontractors”</Strong>) from time to time to provide, or to assist Juniper in providing, the Services. Any Subcontractors used by Juniper to provide
                                the Services shall remain under the direction and control of Juniper, and Juniper shall be responsible and liable for all acts or omissions of the Subcontractors.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Juniper and Client are independent contractors, and not agents, partners, joint venturers or employees of one another. Nothing in this Agreement shall make or be construed to make Juniper and Client partners or agents of each other or to
                                create any other relationship by which the acts of either party may bind the other or result in any liability to the other.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Client agrees that Juniper may utilize Client’s (and the content creator(s)’ whom Client represents) names, as well as images and descriptions of the Products, in listings of Juniper customers and product releases and in
                                connection with certain marketing materials that Juniper may disseminate to the public. The marketing materials may include without limitation brochures, websites, social media accounts and advertisements.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                No failure on the part of either party to exercise any right or remedy in respect of this Agreement will operate as a waiver thereof unless it is in writing and signed by the other party.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Any notice or other communication required or permitted under this Agreement and intended to have legal effect must be given in writing: (i) to Juniper, by email to shop@hellojuniper.com or by certified mail to
                                Juniper, Attn: Mikhail Peabody, 123 Edward Street, Suite 205, Toronto, ON, M5G 0A8, Canada; or (ii) to Client, by email or by certified mail at the addresses set forth in the Order Form.  A party may change its
                                address from time to time upon written notice to the other party of the new address.  Notices will be deemed to have been given upon receipt, or when delivery is refused.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                The validity and interpretation of this Agreement and the legal relations of the parties will be governed by and construed in accordance with the laws in force from time to time in the Province of British Columbia and the federal laws of Canada applicable therein,
                                without regard to conflict of laws principles.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                If any dispute or disagreement of any kind arises at any time with respect to this Agreement, its interpretation or application, its performance by the parties, or in respect of any defined legal relationship associated therewith or derived therefrom,
                                the parties agree that, such dispute or disagreement will be referred to and finally resolved by arbitration administered by the Vancouver International Arbitration Centre (VanIAC) pursuant to its applicable Rules. The fees and expenses of the arbitrator
                                will be borne equally between the parties. The arbitrator may order interest on any award and the arbitrator may award costs to either party. In the absence of any such award of costs, each of the parties will bear its own costs of the arbitration. The place of
                                arbitration shall be Vancouver, British Columbia, Canada and the language of the arbitration shall be English. The number of arbitrators shall be one. Juniper may suspend the performance of any or all of the Services during any period during which a dispute or disagreement remains unresolved.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                This Agreement states and comprises the entire agreement between the parties in connection with the subject matter of this Agreement. There are no representations, warranties, terms, conditions, undertakings or collateral agreements express or
                                implied between the parties other than expressly set forth in this Agreement.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Neither party shall be responsible for any failure to perform due to unforeseen circumstances or to causes beyond that party’s reasonable control, including but not limited to acts of God, war, riot, embargoes, acts of civil or military authorities, fire, floods, pandemics, epidemics,
                                pandemics, accidents, strikes, failure to obtain export licenses or shortages of transportation, facilities, fuel, energy, labour or materials (collectively, <Strong>“Force Majeure Events”</Strong>). Each party will use commercially reasonable efforts to minimize the disruption of
                                such Force Majeure Events and to remove such causes of non-performance.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Nothing in this Agreement will entitle any person other than the parties to any claim, cause of action, remedy or other rights of any kind in respect of the subject matter hereof.
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph className={"block-section"}>
                                Neither party will assign this Agreement or any part thereof without the prior express written consent of the other party. Notwithstanding the foregoing, Juniper may assign this Agreement and its rights, obligations and interests hereunder
                                to an affiliate or in connection with a merger, amalgamation, transfer of control, reorganization or sale of all or substantially all of its assets or equity interests; provided, in each case, that the assignee agrees to be bound by all of the
                                terms of this Agreement. Any assignment in violation of this Section 12(k) shall be void. Any assignment is conditional upon the assignee agreeing in writing to be bound to the terms of this Agreement which shall be binding upon and inure to the benefit of the parties’ successors and permitted assignees.
                            </Paragraph>
                        </ListItem>
                    </ol>
                </ListItem>
            </OrderedList>
        </Pane>
    );
};
