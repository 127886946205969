import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, ErrorIcon, Pane, SelectField, TextInputField, toaster } from "evergreen-ui";
import { User } from "../../../common/models";
import { useUpdateUserMutation, useUserQuery } from "../datastore/UserDataStore";
import { EmptyState } from "../common/EmptyState";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { NavigationHelper, PrevLocationState } from "../navigation/PrevLocationState";
import { isAccountInfoComplete, isPhoneNumberValid } from "../product/Utils";
import { analytics, EventName } from "../analytics";
import { PhoneInputField } from "../common/PhoneInputField";
import { LimitedTextInputField } from "../common/LimitedTextInputField";
import {
    FIRST_NAME_MAX_LENGTH,
    LAST_NAME_MAX_LENGTH
} from "../../../common/constants/InputFormFieldLengths";
import { useFormValidation } from "../common/FormValidation";
import { UserRole, UserRoleDisplayValues } from "../../../common/enums";
import { LabelledTooltip } from "../common/LabelledTooltip";
import "./styles/DetailsPage.css";

export const Profile = () => {
    const history = useHistory();
    const {state: prevState} = useLocation<PrevLocationState>();
    const {invalidFields, onInvalidChange} = useFormValidation();
    const [user, setUser] = useState<User | null>(null);
    const {
        loading: queryLoading,
        error: queryError,
        data: fetchedUser,
    } = useUserQuery();
    const [
        updateUser,
        {
            loading: mutationLoading,
            error: mutationError,
            called: mutationCalled
        }
    ] = useUpdateUserMutation();

    useEffect(() => {
        setUser(fetchedUser);
        onPhoneNumberChange(fetchedUser?.phoneNumber || "");
    }, [fetchedUser]);

    // TODO: refactor base mutation hooks to reduce boilerplate side effect implementations.
    useEffect(() => {
        if (!mutationLoading && mutationCalled) {
            if (mutationError) {
                toaster.warning("Something went wrong. Try again");
            } else {
                let successMessage;
                if (isAccountInfoComplete(user)) {
                    successMessage = "You've completed your Profile Info!";
                    NavigationHelper.go(history, prevState);
                    analytics.track(EventName.ProfileInfoCompleted);
                } else {
                    successMessage = "Updated! You have a bit more info to complete!";
                }
                toaster.success(successMessage, {duration: 3});
            }
        }
        // eslint-disable-next-line
    }, [mutationLoading, mutationCalled, mutationError]);

    const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        onFieldChange(name, value);
    };

    const onPhoneNumberChange = (phoneNumber: string) => {
        if (isPhoneNumberValid(phoneNumber)) {
            onInvalidChange("phoneNumber", false);
            onFieldChange("phoneNumber", phoneNumber);
        } else {
            onInvalidChange("phoneNumber", true);
        }
    };

    const onFieldChange = (key: string, value: string | number | string[]) => {
        if (user) {
            setUser({
                ...user,
                [key]: value,
            })
        }
    };

    // Display the loading state when query is in-flight or if query was
    // successful (no errors) but the user state has not yet been set.
    return queryLoading || ((user === null) && !queryError) ? (
        <CenteredSpinner/>
    ) : (user !== null) ? (
        <Pane>
            <TextInputField
                name="email"
                required
                disabled
                label="Email address"
                value={user.email}
                onChange={onChange}
            />
            <LimitedTextInputField
                required
                maxChars={FIRST_NAME_MAX_LENGTH}
                onInvalidChange={onInvalidChange}
                name="firstName"
                label="First name"
                value={user.firstName}
                onChange={onChange}
            />
            <LimitedTextInputField
                required
                maxChars={LAST_NAME_MAX_LENGTH}
                onInvalidChange={onInvalidChange}
                name="lastName"
                label="Last name"
                value={user.lastName}
                onChange={onChange}
            />
            <PhoneInputField
                value={user?.phoneNumber}
                onChange={onPhoneNumberChange}
            />
            <SelectField
                className="jc-dp-select"
                name="role"
                description={(
                    <LabelledTooltip
                        label="Role (Optional)"
                        description="Are you a creator or a manager?"
                    />
                )}
                onChange={onChange}
                value={user!.role}
            >
                {Object.values(UserRole).map((userRole, index) => (
                    <option key={index} value={userRole}>
                        {UserRoleDisplayValues[userRole]}
                    </option>
                ))}
            </SelectField>
            <Button
                disabled={invalidFields.length > 0}
                appearance="primary"
                width={150}
                isLoading={mutationCalled && mutationLoading}
                onClick={() => updateUser(user)}
            >
                Update
            </Button>
        </Pane>
        // Display the empty state since the user state is null and a query error was returned.
    ) : (
        <EmptyState
            title="Unable to load your profile data"
            subtitle="Try refreshing the page"
        >
            <ErrorIcon size={60}/>
        </EmptyState>
    );
};
