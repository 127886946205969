import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { analytics } from "./analytics";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from '@sentry/tracing';

analytics.init();

if (process.env.REACT_APP_ENV && (process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "prod")) {
    Sentry.init({
        dsn: "https://b0b5e1f7b8a84b0d9de5054e0a67b4ff@o461874.ingest.sentry.io/6653661",
        environment: process.env.REACT_APP_ENV,
        integrations: [new BrowserTracing()],
        release: process.env.REACT_APP_SENTRY_RELEASE,
        tracesSampler: (_ => {
            switch (process.env.REACT_APP_ENV) {
                // Send all errors we hit in the staging env as these will be few and far between
                case "staging":
                    return 1.0
                // Sample only some of the production traces as there are many of them
                case "prod":
                    return 0.2
                default:
                    return 0.0
            }
        })
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
