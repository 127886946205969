import React from "react";
import { Pane, PaneProps, Spinner } from "evergreen-ui";

interface Props extends PaneProps {
    height?: string;
    width?: string;
    size?: number;
}

export const CenteredSpinner = ({height = "100%", width = "100%", size = 36, ...rest}: Props) => {
    return (
        <Pane
            {...rest}
            display="flex"
            width={width}
            height={height}
            justifyContent="center"
            alignItems="center"
    >
            <Spinner size={size}/>
        </Pane>
    )
};
