import React, { PropsWithChildren, useEffect } from "react";
import { Button, Heading, Pane, Paragraph, SideSheet, toaster } from "evergreen-ui";

interface NewTableRowFormProps {
    isShown: boolean;
    headerTitle: string;
    mutationLoading: boolean;
    mutationCalled: boolean;
    mutationError: boolean;
    onDismiss: () => void;
    onSubmit: () => void;
    canSubmit: boolean;
}

export function TableRowSideSheetForm({
    isShown,
    headerTitle,
    mutationLoading,
    mutationCalled,
    mutationError,
    canSubmit,
    onDismiss,
    onSubmit,
    children,
}: PropsWithChildren<NewTableRowFormProps>) {
    useEffect(() => {
        if (!mutationLoading && mutationCalled) {
            if (mutationError) {
                toaster.danger("Failed to create row.");
            } else {
                toaster.success("Row created successfully!");
                onDismiss();
            }
        }
    }, [mutationCalled, mutationLoading, mutationError, onDismiss]);

    return (
        <SideSheet
            width={"360px"}
            isShown={isShown}
            onCloseComplete={onDismiss}
        >
            <Pane padding={"30px"}>
                <Heading marginBottom={"20px"}>{headerTitle}</Heading>
                {children}
                <Button
                    appearance="primary"
                    minWidth={"120px"}
                    size={"large"}
                    onClick={onSubmit}
                    isLoading={mutationLoading}
                    disabled={!canSubmit}
                >
                    Submit
                </Button>
                <Pane paddingTop={"20px"}>
                    <Paragraph fontSize={"13px"}>
                        Note that you may need to adjust your table filters to find this newly created row.
                    </Paragraph>
                </Pane>
            </Pane>
        </SideSheet>
    );
}
