import { useReactiveVar } from "@apollo/client";
import React, { FC, useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AddOn, AddOnGroup, CatalogCategory, CatalogOption, CatalogProduct } from "../../../common/models";
import { CatalogItem } from "../common/CatalogItem";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { convertCatalogNameToReadableId } from "../common/convertCatalogNameToReadableId";
import { useFormValidation } from "../common/FormValidation";
import { TileSelectorType } from "../common/selector/TileSelector";
import { useStateParam } from "../common/state/useStateParams";
import { UserContextLoadingState, useUserContext } from "../context/UserContext";
import {
    productOnboardingSelectionReactiveVar,
    productOnboardingHelper
} from "../datastore/local/ProductOnboardingHelper";
import { isCustomCategory, isCustomProduct, useCatalogCategoriesQuery } from "../datastore/ProductDataStore";
import { StytchContext } from "../stytch/StytchContext";
import { Breadcrumb } from "./Breadcrumb";
import { NewProductWizardActionBar } from "./NewProductWizardActionBar";
import { NewProductWizardNavigationBar } from "./NewProductWizardNavigationBar";
import { ActionBarInfo } from "./pages/ActionBarInfo";
import { AddOnsPage } from "./pages/AddOnsPage";
import { InfoSelectorPage } from "./pages/InfoSelectorPage";
import { SelectionPage } from "./pages/SelectionPage";
import { SignupPage } from "./pages/SignupPage";
import { StartCreating } from "./pages/StartCreatingPage";
import { ProductOnboardingSelection } from "./ProductOnboardingSelection";
import { ProductWizardPage } from "./ProductWizardPage";

import "./styles/ProductWizard.css";


export const enum ProductWizardSequence {
    NEW_PRODUCT = 1,
    EDIT_PRODUCT = 2,
    SIGNUP_PRODUCT = 3,
}

const ProductWizardSequencePages = {
    [ProductWizardSequence.NEW_PRODUCT]: [
        ProductWizardPage.Undefined,
        ProductWizardPage.Categories,
        ProductWizardPage.Products,
        ProductWizardPage.Options,
        ProductWizardPage.AddOns,
        ProductWizardPage.ProductStoreInfo
    ],
    [ProductWizardSequence.EDIT_PRODUCT]: [
        ProductWizardPage.Undefined,
        ProductWizardPage.Categories,
        ProductWizardPage.Products,
        ProductWizardPage.Options,
        ProductWizardPage.AddOns
    ],
    [ProductWizardSequence.SIGNUP_PRODUCT]: [
        ProductWizardPage.Undefined,
        ProductWizardPage.StartCreating,
        ProductWizardPage.Signup,
        ProductWizardPage.Categories,
        ProductWizardPage.Products,
        ProductWizardPage.Options,
        ProductWizardPage.AddOns,
        ProductWizardPage.ProductStoreInfo
    ]
};

type InitialSelections = {
    catalogCategory?: CatalogCategory,
    catalogProduct?: CatalogProduct,
    catalogOption?: CatalogOption,
    addOns: AddOn[],
    initialPage?: ProductWizardPage
};

export type PageInfo = {
    isCurrentPage: boolean;
    pageTitle: string;
    getPageComponent: () => React.ReactNode | undefined;
    getInfoComponent: () => React.ReactNode | undefined;
    getBreadcrumbComponent: () => React.ReactNode | undefined;
    onForward: () => ProductWizardPage;
    onPrevious: () => ProductWizardPage;
    isForwardDisabled: () => boolean;
    forwardButtonText?: string;
    showPrevious?: boolean;
};
export type Pages = Partial<Record<ProductWizardPage, PageInfo>>;

export interface ProductWizardProps {
    onSubmit: (productOnboardingSelection: ProductOnboardingSelection) => void;
    defaultProductOnboardingSelection?: ProductOnboardingSelection;
    productWizardSequence: ProductWizardSequence;
    navBarOnClose?: () => void;
}

export const NewProductWizard: FC<ProductWizardProps> = ({
    onSubmit,
    productWizardSequence,
    defaultProductOnboardingSelection = {},
    navBarOnClose
}) => {
    const { isAuthenticated } = useContext(StytchContext);
    const { loadingState } = useUserContext();
    const productOnboardingSelection = useReactiveVar<ProductOnboardingSelection>(productOnboardingSelectionReactiveVar);
    const { loading: queryLoading, error: queryError, data: fetchedProductCategories } = useCatalogCategoriesQuery();
    const { invalidFields, onInvalidChange } = useFormValidation();
    const [catalogCategories, setProductCategories] = useState<CatalogItem[] | null>(null);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [pageQueryParam, setPageQueryParam] = useStateParam("page");
    const [categoryQueryParam, setCategoryQueryParam] = useStateParam("category");
    const [productQueryParam, setProductQueryParam] = useStateParam("product");
    const [optionQueryParam, setOptionQueryParam] = useStateParam("option");
    const [addOnQueryParam, setAddOnQueryParam] = useStateParam("addOns");

    useEffect(() => {
        const page: ProductWizardPage = pageQueryParam ? parseInt(pageQueryParam) as ProductWizardPage : ProductWizardPage.Categories;
        productOnboardingHelper.setProductWizardPage(page);
    }, [pageQueryParam]);

    useEffect(() => {
        if (catalogCategories) {
            setProductQueryParam(null, false);
            setOptionQueryParam(null, false);
            setAddOnQueryParam(null, false);

            productOnboardingHelper.setCatalogProduct(undefined);
            productOnboardingHelper.setCatalogOption(undefined);
            productOnboardingHelper.setAddOns([]);

            const catalogItem: CatalogItem | undefined = catalogCategories.find(
                (catalogItem: CatalogItem) => convertCatalogNameToReadableId(catalogItem.name) === categoryQueryParam);
            productOnboardingHelper.setCatalogCategory(catalogItem as CatalogCategory);
        }
    }, [categoryQueryParam]);

    useEffect(() => {
        if (catalogCategories && catalogCategory) {
            const newCatalogProduct: CatalogProduct | undefined = getProductsFromSelectedCategory(catalogCategories, catalogCategory).find((product: CatalogProduct) => {
                return convertCatalogNameToReadableId(product.name) === productQueryParam;
            });
            productOnboardingHelper.setCatalogProduct(newCatalogProduct);

            // there may or may not be a default catalog option here, if there is we should set it.
            const options: CatalogOption[] = getOptionsFromSelectedProduct(catalogCategories, catalogCategory, newCatalogProduct);
            const defaultCatalogOption: CatalogOption | undefined = options.length === 1 ? options[0] : undefined;
            setOptionQueryParam(convertCatalogNameToReadableId(defaultCatalogOption?.name), false);
            productOnboardingHelper.setCatalogOption(defaultCatalogOption);

            // likewise, there could be default addOns for here, if there is then we need to set them.
            const defaultAddOns: AddOn[] = [];
            getAddOnGroupsFromSelectedProduct(catalogCategories, catalogCategory, newCatalogProduct).forEach((addOnGroup: AddOnGroup) => {
                addOnGroup.addOns.forEach((addOn: AddOn) => {
                    if (addOn.isDefault) {
                        defaultAddOns.push(addOn);
                    }
                });
            });
            setAddOnQueryParam(defaultAddOns.map((addOn: AddOn) => convertCatalogNameToReadableId(addOn.name)).join(","), false);
            productOnboardingHelper.setAddOns(defaultAddOns);
        }
    }, [productQueryParam]);

    useEffect(() => {
        if (catalogCategories && catalogCategory && catalogProduct) {
            const newCatalogOption: CatalogOption | undefined = getOptionsFromSelectedProduct(catalogCategories, catalogCategory, catalogProduct).find((option: CatalogOption) => {
                return convertCatalogNameToReadableId(option.name) === optionQueryParam;
            });
            productOnboardingHelper.setCatalogOption(newCatalogOption);
        }
    }, [optionQueryParam]);

    useEffect(() => {
        if (catalogCategories && catalogCategory && catalogProduct) {
            const queryParamAddOnNames: string[] = addOnQueryParam?.split(",") || [];
            const newAddOns: AddOn[] = [];
            getAddOnGroupsFromSelectedProduct(catalogCategories, catalogCategory, catalogProduct).forEach((addOnGroup: AddOnGroup) => {
                addOnGroup.addOns.forEach((addOn: AddOn) => {
                    if (queryParamAddOnNames.includes(convertCatalogNameToReadableId(addOn.name))) {
                        newAddOns.push(addOn);
                    }
                });
            });
            productOnboardingHelper.setAddOns(newAddOns);
        }
    }, [addOnQueryParam]);

    const resolveInitialSelections = (catalogItems: CatalogItem[] | null): InitialSelections => {
        const redirectToCategories = (): InitialSelections => {
            setPageQueryParam(`${ProductWizardPage.Categories}`, false);
            setCategoryQueryParam(null, false);
            setProductQueryParam(null, false);
            setOptionQueryParam(null, false);
            setAddOnQueryParam(null, false);
            return {
                initialPage: ProductWizardPage.Categories,
                addOns: []
            }
        };

        const initials: InitialSelections = {
            initialPage: defaultProductOnboardingSelection?.productWizardPage,
            catalogCategory: defaultProductOnboardingSelection?.catalogCategory,
            catalogProduct: defaultProductOnboardingSelection?.catalogProduct,
            catalogOption: defaultProductOnboardingSelection?.catalogOption,
            addOns: defaultProductOnboardingSelection?.addOns || []
        };

        if (!catalogItems) {
            return initials;
        }

        if (pageQueryParam) {
            initials.initialPage = parseInt(pageQueryParam) as ProductWizardPage;
        }

        if (categoryQueryParam) {
            const catalogItem: CatalogItem | undefined = catalogItems.find(
                (catalogItem: CatalogItem) => convertCatalogNameToReadableId(catalogItem.name) === categoryQueryParam
            );

            if (catalogItem) {
                initials.catalogCategory = catalogItem as CatalogCategory;
            }

            // invalid params being used here, start over
            if (!initials.catalogCategory) {
                return redirectToCategories();
            }
        }

        if (productQueryParam) {
            const products: CatalogProduct[] = getProductsFromSelectedCategory(catalogItems, initials.catalogCategory);
            const catalogProduct: CatalogProduct | undefined = products.find(
                (catalogProduct: CatalogProduct) => convertCatalogNameToReadableId(catalogProduct.name) === productQueryParam
            );

            if (catalogProduct) {
                initials.catalogProduct = catalogProduct as CatalogProduct;
            }

            // invalid params being used here, start over
            if (!initials.catalogProduct) {
                return redirectToCategories();
            }
        }

        const options: CatalogOption[] = getOptionsFromSelectedProduct(catalogItems, initials.catalogCategory, initials.catalogProduct);
        const addOnGroups: AddOnGroup[] = getAddOnGroupsFromSelectedProduct(catalogItems, initials.catalogCategory, initials.catalogProduct);
        if (options.length === 1) {
            initials.catalogOption = options[0];
            if (initials.initialPage === ProductWizardPage.Options && !pageQueryParam) {
                if (addOnGroups.length === 0) {
                    initials.initialPage = ProductWizardPage.Products;
                } else {
                    initials.initialPage = ProductWizardPage.AddOns;
                }
            }
        } else if (optionQueryParam) {
            const catalogOption: CatalogOption | undefined = options.find(
                (catalogOption: CatalogOption) => convertCatalogNameToReadableId(catalogOption.name) === optionQueryParam
            );

            if (catalogOption) {
                initials.catalogOption = catalogOption as CatalogOption;
            }

            // invalid params being used here, start over
            if (!initials.catalogOption) {
                return redirectToCategories();
            }
        }

        // The cursed triple nested for-loop.
        if (addOnGroups.length === 0) {
            initials.addOns = [];
            if (productWizardPage === ProductWizardPage.AddOns) {
                setPageQueryParam(`${ProductWizardPage.Products}`);
            }
        } else {
            const addOnIds: string[] = addOnQueryParam?.split(",") || [];
            if (addOnIds.length > 0) {
                addOnGroups.forEach((addOnGroup: AddOnGroup) => {
                    getAddOnsForAddOnGroup(addOnGroup, catalogItems, initials.catalogCategory, initials.catalogProduct).forEach((addOn: AddOn) => {
                        if (addOnIds.includes(convertCatalogNameToReadableId(addOn.name))) {
                            initials.addOns = [...initials.addOns, addOn];
                        }
                    });
                });
            }
        }

        // set initial page query param
        setPageQueryParam(`${initials.initialPage}`, false);
        return initials;
    }

    const {
        catalogCategory,
        catalogProduct,
        catalogOption,
        addOns,
        newProductName,
        newStoreName,
        existingStoreId,
        productWizardPage,
        firstName,
        lastName,
        email
    } = productOnboardingSelection;

    const isApparelOption = (): boolean => {
        // TODO: Add `isApparel` field to ProductCategory. This is error prone if people start changing category names
        return !!productOnboardingSelection.catalogCategory && ["Hoodies", "Sweatshirts", "T-Shirts", "Other Apparel"].includes(productOnboardingSelection.catalogCategory.name);
    }

    const getProductsFromSelectedCategory = (catalogItems: CatalogItem[] | null = catalogCategories,
                                             selectedCategory: CatalogCategory | undefined = productOnboardingSelection.catalogCategory): CatalogProduct[] => {
        return (catalogItems?.find(({id}) =>
            selectedCategory?.id === id
        ) as CatalogCategory)?.catalogProducts || [];
    };

    const getOptionsFromSelectedProduct = (catalogItems: CatalogItem[] | null = catalogCategories,
                                           selectedCategory: CatalogCategory | undefined = productOnboardingSelection.catalogCategory,
                                           selectedProduct: CatalogProduct | undefined = productOnboardingSelection.catalogProduct): CatalogOption[] => {
        return getProductsFromSelectedCategory(catalogItems, selectedCategory).find(({id}) =>
            (selectedProduct?.id === id))?.catalogOptions.filter((catalogOption: CatalogOption) =>
                !catalogOption.defaultOption) || [];
    };

    const getAddOnGroupsFromSelectedProduct = (catalogItems: CatalogItem[] | null = catalogCategories,
                                               selectedCategory: CatalogCategory | undefined = productOnboardingSelection.catalogCategory,
                                               selectedProduct: CatalogProduct | undefined = productOnboardingSelection.catalogProduct): AddOnGroup[] => {
        return getProductsFromSelectedCategory(catalogItems, selectedCategory).find(({ id }) =>
            selectedProduct?.id === id
        )?.addOnGroups || [];
    }

    const getAddOnsForAddOnGroup = (addOnGroup: AddOnGroup,
                                     catalogItems: CatalogItem[] | null = catalogCategories,
                                     selectedCategory: CatalogCategory | undefined = productOnboardingSelection.catalogCategory,
                                     selectedProduct: CatalogProduct | undefined = productOnboardingSelection.catalogProduct): AddOn[] => {
        return getAddOnGroupsFromSelectedProduct(catalogItems, selectedCategory, selectedProduct)
            .filter((currAddOnGroup) =>
                currAddOnGroup.id === addOnGroup.id
            )[0].addOns;
    }

    const isCatalogOptionValid = () => getOptionsFromSelectedProduct().map((catalogOption) => catalogOption.id).includes(catalogOption!.id);

    useEffect(() => {
        setProductCategories(fetchedProductCategories);
        const initialSelections: InitialSelections = resolveInitialSelections(fetchedProductCategories);
        productOnboardingHelper.initialize({
            ...defaultProductOnboardingSelection,
            catalogCategory: initialSelections.catalogCategory,
            catalogProduct: initialSelections.catalogProduct,
            catalogOption: initialSelections.catalogOption,
            addOns: initialSelections.addOns,
            productWizardPage: initialSelections.initialPage
        });
    }, [fetchedProductCategories]);

    const isInvalidText = (text: string | undefined | null) => {
        return text === null || text === undefined || text === "" || text.trim().length === 0;
    }

    const isBreadcrumbSelectable = (page: ProductWizardPage): boolean => {
        // A breadcrumb becomes selectable as soon as its prerequisites are satisfied. In the
        // case of Categories, this is always selectable since there are no prerequisites.
        switch (page) {
            case ProductWizardPage.Signup:
                return productOnboardingHelper.isProductInfoComplete();
            case ProductWizardPage.ProductStoreInfo:
                // Add-Ons can be optional, therefore only Options are a prerequisite here.
                return productOnboardingHelper.isOptionSelected();
            case ProductWizardPage.AddOns:
                // An Option without Add-Ons shouldn't have a selectable Add-Ons breadcrumb. This
                // is an edge case that is handled outside of this basic helper function.
                return productOnboardingHelper.isOptionSelected();
            case ProductWizardPage.Options:
                // A Product with only a default Option shouldn't have a selectable
                // Options breadcrumb. This is an edge case that is handled outside of this
                // basic helper function.
                return productOnboardingHelper.isProductSelected();
            case ProductWizardPage.Products:
                return productOnboardingHelper.isCategorySelected();
            case ProductWizardPage.Categories:
            default:
                return true;
        }
    }

    const completeOnboardingFlow = (): ProductWizardPage => {
        onSubmit(productOnboardingSelection);
        setSubmitting(true);
        return ProductWizardPage.Undefined
    }

    const allPages: Pages = {

        [ProductWizardPage.StartCreating]: {
            isCurrentPage: productWizardPage === ProductWizardPage.StartCreating,
            pageTitle: "Start Creating",
            getPageComponent: () => (
                <StartCreating />
            ),
            getInfoComponent: () => null,
            getBreadcrumbComponent: () => null,
            onForward: () => ProductWizardPage.Categories,
            isForwardDisabled: () => false,
            onPrevious: () => ProductWizardPage.Undefined,
            showPrevious: false
        },

        [ProductWizardPage.Categories]: {
            isCurrentPage: productWizardPage === ProductWizardPage.Categories,
            pageTitle: "Category",
            getPageComponent: () => (<SelectionPage
                title="Choose a category."
                description="What are you interested in creating? You can always change this later."
                catalogItems={catalogCategories ?? []}
                identifier={"catalog-category"}
                onSelect={(selectedCategory: CatalogItem) => {
                    setCategoryQueryParam(convertCatalogNameToReadableId(selectedCategory.name), false);
                }}
                selectedId={catalogCategory?.id}
                filter={(category: CatalogItem) =>
                    ((category as CatalogCategory)?.catalogProducts?.length > 0) && !isCustomCategory(category as CatalogCategory)}
            ></SelectionPage>),
            getInfoComponent: () => (
                <ActionBarInfo
                    groupIdentifier={"category"}
                    availabilityText={`${getProductsFromSelectedCategory().length} products are available.`}
                    catalogItem={catalogCategory}
                    emptyText="Select a category to continue." />),
            getBreadcrumbComponent: () => (
                <Breadcrumb
                    selectable={isBreadcrumbSelectable(ProductWizardPage.Categories)}
                    selected={productWizardPage === ProductWizardPage.Categories}
                    key="category"
                    text={(catalogCategory && catalogCategory.name) || "Category"}
                    onClick={() => setPageQueryParam(`${ProductWizardPage.Categories}`)}
                />
            ),
            onForward: () => ProductWizardPage.Products,
            isForwardDisabled: () => !catalogCategory,
            showPrevious: productWizardSequence === ProductWizardSequence.SIGNUP_PRODUCT,
            onPrevious: () => isAuthenticated ? ProductWizardPage.Undefined : ProductWizardPage.StartCreating,
        },

        [ProductWizardPage.Products]: {
            isCurrentPage: productWizardPage === ProductWizardPage.Products,
            pageTitle: "Product",
            getPageComponent: () => (<SelectionPage
                title="Pick a product type."
                description="Alright, let's get more specific! You can change this later too."
                catalogItems={getProductsFromSelectedCategory() ?? []}
                identifier={"catalog-products"}
                onSelect={(selectedProduct: CatalogItem) => {
                    setProductQueryParam(convertCatalogNameToReadableId(selectedProduct.name), false);
                }}
                selectedId={productOnboardingSelection?.catalogProduct?.id}
                filter={(product: CatalogItem) => !isCustomProduct(product as CatalogProduct)}
            ></SelectionPage>),
            getInfoComponent: () => (
                <ActionBarInfo
                    groupIdentifier={"products"}
                    // We say no options available to avoid confusion about default options.
                    // TODO: When we remove all the excess options from the DB, we can filter correctly by
                    // defaultOption and set this to === 0.
                    availabilityText={getOptionsFromSelectedProduct().length > 1 ?
                        `${getOptionsFromSelectedProduct().length} options are available.` :
                        ""
                    }
                    catalogItem={catalogProduct}
                    emptyText="Select a product to continue." />),
            getBreadcrumbComponent: () => (
                <Breadcrumb
                    selectable={isBreadcrumbSelectable(ProductWizardPage.Products)}
                    selected={productWizardPage === ProductWizardPage.Products}
                    key="product"
                    text={(catalogProduct && catalogProduct.name) || "Product"}
                    onClick={() => setPageQueryParam(`${ProductWizardPage.Products}`)}
                />
            ),
            onForward: () => {
                // If there's only one option, we can just skip the default option
                if (getOptionsFromSelectedProduct().length === 1) {
                    setOptionQueryParam(convertCatalogNameToReadableId(getOptionsFromSelectedProduct()[0].name), false);
                    if (getAddOnGroupsFromSelectedProduct().length > 0) {
                        return ProductWizardPage.AddOns;
                    } else if ((productWizardSequence === ProductWizardSequence.EDIT_PRODUCT)) {
                        onSubmit(productOnboardingSelection);
                        setSubmitting(true);
                        return ProductWizardPage.Undefined;
                    } else {
                        return ProductWizardPage.ProductStoreInfo;
                    }
                } else {
                    return ProductWizardPage.Options;
                }
            },
            onPrevious: () => ProductWizardPage.Categories,
            isForwardDisabled: () => !catalogCategory || !catalogProduct,
            forwardButtonText: getOptionsFromSelectedProduct().length === 1
                && getAddOnGroupsFromSelectedProduct().length === 0
                && productWizardSequence === ProductWizardSequence.EDIT_PRODUCT ? "Save" : "Continue"
        },

        [ProductWizardPage.Options]: {
            isCurrentPage: productWizardPage === ProductWizardPage.Options,
            pageTitle: isApparelOption() ? "Apparel Tier" : "Option",
            //TODO: Add an apparel tier page that will be rendered based on the catalog product, or we can just create a separate page?
            getPageComponent: () => (<SelectionPage
                title={isApparelOption() ? "Choose an apparel tier." : "Choose a product option."}
                description={isApparelOption() ?
                    `We can make apparel in various tiers. Let us know what you’re interested in so we can kick off the product in the right direction. 
                    You can change this during the design process.` :
                    `We can make this product in a different number of styles. 
                    Let us know what you’re interested in so we can kick off the product in the right direction. 
                    You can change this during the design process.`
                }
                catalogItems={getOptionsFromSelectedProduct()}
                identifier={"catalog-options"}
                onSelect={(selectedOption: CatalogItem) => {
                    setOptionQueryParam(convertCatalogNameToReadableId(selectedOption.name), false);
                }}
                selectedId={productOnboardingSelection?.catalogOption?.id}
                filter={() => true}
                tileSelectorType={isApparelOption() ? TileSelectorType.apparel : TileSelectorType.basic}
            ></SelectionPage>),
            getInfoComponent: () => (
                <ActionBarInfo
                    groupIdentifier={"options"}
                    availabilityText={""}
                    catalogItem={catalogOption}
                    emptyText="Select an option to continue." />
            ),
            getBreadcrumbComponent: () => (
                <Breadcrumb
                    selectable={isBreadcrumbSelectable(ProductWizardPage.Options) && getOptionsFromSelectedProduct().length > 1}
                    selected={productWizardPage === ProductWizardPage.Options}
                    key="option"
                    text={getOptionsFromSelectedProduct().length > 1 ?
                        (catalogOption && catalogOption.displayName) || (isApparelOption() ? "Apparel Tier" : "Option") : "Option"}
                    onClick={() => setPageQueryParam(`${ProductWizardPage.Options}`)}
                />
            ),
            onForward: () => {
                if (getAddOnGroupsFromSelectedProduct().length > 0) {
                    return ProductWizardPage.AddOns;
                } else if ((productWizardSequence === ProductWizardSequence.EDIT_PRODUCT)) {
                    onSubmit(productOnboardingSelection);
                    setSubmitting(true);
                    return ProductWizardPage.Undefined;
                } else {
                    return ProductWizardPage.ProductStoreInfo;
                }
            },
            isForwardDisabled: () => !catalogCategory || !catalogProduct || !catalogOption || !isCatalogOptionValid(),
            forwardButtonText: getAddOnGroupsFromSelectedProduct().length === 0 && productWizardSequence === ProductWizardSequence.EDIT_PRODUCT ? "Save" : "Continue",
            onPrevious: () => ProductWizardPage.Products,
        },

        [ProductWizardPage.AddOns]: {
            isCurrentPage: productWizardPage === ProductWizardPage.AddOns,
            pageTitle: "Add-Ons",
            getPageComponent: () => (
                <AddOnsPage
                    title="Select your add-ons."
                    description="Add-ons are completely optional. You can always choose to change these during the design process."
                    addOnGroups={getAddOnGroupsFromSelectedProduct()}
                    identifier={"catalog-add-ons"}
                    onSelect={(selectedAddOn: AddOn) => {
                        let newAddOns: AddOn[] = [];
                        if (selectedAddOn.addOnGroup.isMultiselect) {
                            const isAddOnSelected = addOns?.map((addOn) => addOn.id)?.includes(selectedAddOn.id);
                            if (isAddOnSelected) {
                                newAddOns = addOns!.filter((addOn) => addOn.id !== selectedAddOn.id);
                            } else {
                                newAddOns = [...addOns!, selectedAddOn]
                            }
                        } else {
                            // Remove all add-ons that are in the excluded category compare by Ids
                            const excludedAddOnIds = getAddOnsForAddOnGroup(selectedAddOn.addOnGroup).map((addOn) => addOn.id);
                            newAddOns = [...addOns!.filter((addOn) => !excludedAddOnIds.includes(addOn.id)), selectedAddOn];
                        }
                        setAddOnQueryParam(newAddOns.map((addOn: AddOn) => convertCatalogNameToReadableId(addOn.name)).join(","), false);
                    }}
                    selectedIds={addOns?.map((addOn) => addOn.id) || []}
                >
                </AddOnsPage>
            ),
            getInfoComponent: () => (
                <ActionBarInfo
                    groupIdentifier={"add-ons"}
                    availabilityText={""}
                    catalogItem={catalogOption}
                    emptyText="Select an option to continue." />
            ),
            getBreadcrumbComponent: () => (
                <Breadcrumb
                    selectable={isBreadcrumbSelectable(ProductWizardPage.AddOns) && getAddOnGroupsFromSelectedProduct().length > 0 && catalogOption !== undefined}
                    selected={productWizardPage === ProductWizardPage.AddOns}
                    key="add-ons"
                    text={"Add-Ons"}
                    onClick={() => setPageQueryParam(`${ProductWizardPage.AddOns}`)}
                    showCaret={productWizardSequence !== ProductWizardSequence.EDIT_PRODUCT}
                />
            ),
            onForward: () => {
                if (productWizardSequence === ProductWizardSequence.EDIT_PRODUCT) {
                    onSubmit(productOnboardingSelection);
                    setSubmitting(true);
                    return ProductWizardPage.Undefined
                } else {
                    return ProductWizardPage.ProductStoreInfo;
                }
            },
            isForwardDisabled: () => !catalogCategory || !catalogProduct || !catalogOption,
            forwardButtonText: (productWizardSequence === ProductWizardSequence.EDIT_PRODUCT ? "Save" : "Continue"),
            onPrevious: () => getOptionsFromSelectedProduct().length > 1 ? ProductWizardPage.Options : ProductWizardPage.Products,
        },

        [ProductWizardPage.ProductStoreInfo]: {
            isCurrentPage: productWizardPage === ProductWizardPage.ProductStoreInfo,
            pageTitle: "Product Info",
            //TODO: Update this to have the Store Selector and update onSubmit when onForward
            getPageComponent: () => (
                <InfoSelectorPage
                    title="Give your product a name."
                    description="A unique, descriptive name helps us distinguish this product in the platform. You can change the display name when you go live."
                    topInputProps={{
                        label: "Product Name",
                        placeholderText: "What do you want to call this product?",
                        name: "product_name",
                        value: newProductName || "",
                        onChange: (value: string) => {
                            productOnboardingHelper.setNewProductName(value);
                        }
                    }}
                >
                </InfoSelectorPage>
            ),
            getInfoComponent: () => (
                <ActionBarInfo
                    groupIdentifier={"product-store"}
                    availabilityText={""}
                    catalogItem={catalogProduct}
                    emptyText="Select a product to continue." />
            ),
            getBreadcrumbComponent: () => (
                <Breadcrumb
                    selectable={isBreadcrumbSelectable(ProductWizardPage.ProductStoreInfo)}
                    selected={productWizardPage === ProductWizardPage.ProductStoreInfo}
                    key="product-info"
                    text={"Product Info"}
                    onClick={() => setPageQueryParam(`${ProductWizardPage.ProductStoreInfo}`)}
                    showCaret={!isAuthenticated}
                />
            ),
            onForward: () => isAuthenticated ? completeOnboardingFlow() : ProductWizardPage.Signup,
            isForwardDisabled: () => isInvalidText(newProductName) || (isInvalidText(newStoreName) && !existingStoreId),
            onPrevious: () => {
                if (getAddOnGroupsFromSelectedProduct().length > 0) {
                    return ProductWizardPage.AddOns;
                } else if (getOptionsFromSelectedProduct().length > 1) {
                    return ProductWizardPage.Options;
                } else {
                    return ProductWizardPage.Products;
                }
            },
        },

        [ProductWizardPage.Signup]: {
            isCurrentPage: productWizardPage === ProductWizardPage.Signup,
                pageTitle: "Sign Up",
                getPageComponent: () => (
                    <SignupPage
                        firstNameProps={{
                            value: firstName || "",
                            onChange: (value: string) => {
                                productOnboardingHelper.setFirstName(value);
                            }
                        }}
                        lastNameProps={{
                            value: lastName || "",
                            onChange: (value: string) => {
                                productOnboardingHelper.setLastName(value);
                            }
                        }}
                        emailProps={{
                            value: email || "",
                            onChange: (value: string) => {
                                productOnboardingHelper.setEmail(value);
                            }
                        }}
                        onInvalidChange={onInvalidChange}
                    />
                ),
                getInfoComponent: () => (<span> Enter name and email to continue! </span>),
                getBreadcrumbComponent: () => (
                    <Breadcrumb
                        selectable={isBreadcrumbSelectable(ProductWizardPage.Signup)}
                        selected={productWizardPage === ProductWizardPage.Signup}
                        key="sign-up"
                        text={"Sign Up"}
                        showCaret={false}
                        onClick={() => setPageQueryParam(`${ProductWizardPage.Signup}`)}
                    />
                ),
                forwardButtonText: "Sign up",
                onForward: completeOnboardingFlow,
                isForwardDisabled: () => invalidFields.length >= 1,
                onPrevious: () => ProductWizardPage.ProductStoreInfo,
        }
    };

    const scrollContentToTop = (): void => {
        const container = document.getElementById("jc-content-container");
        if (container) {
            container.scrollTo(0, 0);
        }
    }

    const pages = ProductWizardSequencePages[productWizardSequence]
        .filter((page: ProductWizardPage) => {
            return page !== ProductWizardPage.Undefined;
        })
        .reduce((pages: Pages, page: ProductWizardPage) => {
            return {
                ...pages,
                [page]: allPages[page]
            };
        }, {});

    // July Hibernation redirect
    if (loadingState !== UserContextLoadingState.Loading && loadingState !== UserContextLoadingState.Uninitialized) {
        if (!isAuthenticated) { // New users get redirected, existing users can still create stuff
            window.location.href = "https://www.junipercreates.com/"; // We're outta here.
        }
    }

    return (queryLoading && (catalogCategories === null || !queryError)) || submitting ? (
        <CenteredSpinner />
    ) : (catalogCategories !== null) ? (
        <div>
            <NewProductWizardNavigationBar
                pages={pages}
                productWizardSequence={productWizardSequence}
                onClose={navBarOnClose}
            />
            {pages[productWizardPage!]?.getPageComponent()}
            <NewProductWizardActionBar
                infoComponent={pages[productWizardPage!]?.getInfoComponent()}
                isSubmitting={submitting}
                showPrevious={pages[productWizardPage!]?.showPrevious}
                forwardButtonDisabled={pages[productWizardPage!]?.isForwardDisabled() || false}
                onNext={() => {
                    scrollContentToTop();
                    setPageQueryParam(`${pages[productWizardPage!]?.onForward()}`);
                }}
                onPrev={() => {
                    scrollContentToTop();
                    setPageQueryParam(`${pages[productWizardPage!]?.onPrevious()}`);
                }}
                forwardButtonText={pages[productWizardPage!]?.forwardButtonText}
            />
        </div>
    ) : (
        <div>Error</div>
    );
};
