import { DqStage } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class DqStageMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [DqStage.preDesign]: {
                label: "Pre-Design",
                value: DqStage.preDesign,
                color: "neutral"
            },
            [DqStage.postDesign]: {
                label: "Post-Design",
                value: DqStage.postDesign,
                color: "neutral"
            }
        };
    }
}