import { EnumMapper, ResolvedEnum } from "../../../../../common/tables/etc/EnumMapper";
import { PayoutRuleType } from "../../../../../../../common/enums";
import { toTitleCase } from "../../../../../../../common/utils";

export class PayoutRuleTypeMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [PayoutRuleType.vendor]: {
                label: toTitleCase(PayoutRuleType.vendor),
                value: PayoutRuleType.vendor,
                color: "neutral"
            },
            [PayoutRuleType.commission]: {
                label: toTitleCase(PayoutRuleType.commission),
                value: PayoutRuleType.commission,
                color: "neutral"
            },
            [PayoutRuleType.referral]: {
                label: toTitleCase(PayoutRuleType.referral),
                value: PayoutRuleType.referral,
                color: "neutral"
            },
        }
    }
}
