export enum LaunchType {
    unset = "unset",
    preOrder = "preOrder",
    inStock = "inStock"
}

export const LaunchTypeDisplayValues = {
    [LaunchType.unset]: "",
    [LaunchType.preOrder]: "Pre-Order",
    [LaunchType.inStock]: "In Stock",
};
