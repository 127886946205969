import { useLayoutEffect, useState } from "react";

export const useHeightChangeListener = (minDeltaPx: number = 10) => {
    const [height, setHeight] = useState<number>(window.innerHeight);

    useLayoutEffect(() => {
        const listener = () => {
            if (Math.abs(height - window.innerHeight) >= minDeltaPx) {
                setHeight(window.innerHeight);
            }
        };
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
        // eslint-disable-next-line
    }, []);

    return height;
};
