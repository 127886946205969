import { ChatIcon, Pane } from "evergreen-ui"
import { useEffect } from "react";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { EmptyState } from "../common/EmptyState";
import { useMobileMediaQuery } from "../common/MediaQuery";
import { removeJcContentContainerStylesEffect } from "../common/removeJcContentContainerStylesEffect";
import { ConversationListView } from "../native-messaging/conversation-list-view";
import { ConversationView } from "../native-messaging/conversation-view";
import { useMessagingClientAPIContext, useMessagingDataContext } from "../native-messaging/state/NativeMessagingProvider";
import { useConversationSidParam } from "../native-messaging/state/ConversationSidParamHook";

export const NativeMessages = () => {

    const {
        clientLoaded,
        clientError,
        activeConversationSid,
        conversationStoreLoaded,
        conversationStore,
    } = useMessagingDataContext();

    const { updateActiveConversation } = useMessagingClientAPIContext();

    const { conversationSidParam } = useConversationSidParam();

    const isMobile = useMobileMediaQuery();

    useEffect(() => {
        if (!clientLoaded || clientError) {
            return;
        }
        // Avoid updating the active conversation to the same one that's already active
        if (activeConversationSid === conversationSidParam) {
            return;
        }
        updateActiveConversation(conversationSidParam);
    }, [conversationSidParam, clientLoaded, clientError, activeConversationSid, updateActiveConversation]);

    // this removes the unwanted padding from the parent container.
    useEffect(() => removeJcContentContainerStylesEffect(false), []);

    const getErrorState = () => {
        return (
            <EmptyState
                title="Our chat servers encountered a problem."
                subtitle="Try refreshing the page. If the issue persists, contact engineering@hellojuniper.com for assistance.">
                <ChatIcon size={60} />
            </EmptyState>
        );
    };

    // If we have no conversations or it's not loaded yet, show spinner.
    if (!clientLoaded || !conversationStoreLoaded || Object.keys(conversationStore).length === 0) {
        return <CenteredSpinner />;
    };

    return clientError ?
        getErrorState() : (
            <Pane
                overflow={"clip"}
                width={"100%"}
                height={`100%`}>
                <ConversationListView isMobile={isMobile} />
                <ConversationView isMobile={isMobile} />
            </Pane>
        );
};
