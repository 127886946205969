export enum Source {
    googleAds = 'googleAds',
    facebookAds = 'facebookAds',
    outbound = 'outbound',
    inbound = 'inbound',
    inboundAssisted = 'inboundAssisted',
    referral = 'referral',
    juniperPartnerships = 'juniperPartnerships',
    agencyReferral = 'agencyReferral',
    tradeShow = 'tradeShow',
    upsell = 'upsell',
    organicReturn = 'organicReturn',
    other = 'other'
}