import { Dialog, Pane, Paragraph } from "evergreen-ui";
import { useEffect, useState } from "react";
import { useActiveConversationAPIContext, useMessagingDataContext } from "../state/NativeMessagingProvider";
import { ColorPalette } from "../../context/Theme";
import { User as TwilioUser } from "@twilio/conversations";
import { MessageViewFactory } from "./message-view/MessageViewFactory";

export interface MessagesListProps {
    participants: TwilioUser[];
    // The message index after which we will render the "New Messages" line
    newMessagesLineIndex: number | null;
}

function getMessageTime(date: Date | null): string {
    if (!date) {
        return "";
    }
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const timeStr = date.toLocaleString('default', { hour: "numeric", minute: "numeric" }).toLowerCase();
    if (date.toDateString() === today.toDateString()) {
        return `Today at ${timeStr}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
        return `Yesterday at ${timeStr}`;
    } else if (date.getFullYear() === today.getFullYear()) {
        return `${date.toLocaleString('default', { month: 'short', day: "numeric" })} at ${timeStr}`;
    } else {
        return `${date.toLocaleString('default', { year: "numeric", month: 'short', day: "numeric" })} at ${timeStr}`;
    }
}

export const MessagesList = ({
    participants,
    newMessagesLineIndex
}: MessagesListProps) => {
    const { activeConversationSid, activeConversationMessageStore } = useMessagingDataContext();
    const { deleteMessage } = useActiveConversationAPIContext();
    const [messageSidToDelete, setMessageSidToDelete] = useState<string | null>(null);
    const [deleteMessageLoading, setDeleteMessageLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!messageSidToDelete || !deleteMessageLoading) {
            return;
        }
        const messageToDelete = activeConversationMessageStore.find(message => message.sid === messageSidToDelete);
        if (!messageToDelete) {
            setMessageSidToDelete(null);
        }
    }, [activeConversationMessageStore, deleteMessageLoading, messageSidToDelete]);

    // isNext: True -> compare curr + next, isNext: False -> compare curr and prev
    const isMessageSameAuthor = (messageIndex: number, isNext: boolean) => {
        const current = activeConversationMessageStore[messageIndex];
        const nextOrPrev = isNext ? activeConversationMessageStore[messageIndex - 1] : activeConversationMessageStore[messageIndex + 1];
        return current && nextOrPrev && current.author === nextOrPrev.author;
    };

    const getMessageTimestamp = (messageIndex: number) => {
        return activeConversationMessageStore[messageIndex].timestamp;
    }

    /* This function is a bit messy because there's 4 states that can exist for a message that make sense:
    FIRST, MIDDLE, LAST, SINGLE. SINGLE is a sandwiched message between two different authors. This state
    exists because it's technically a first and a last message, however it's confusing to assign it both.
    I've created the SINGLE message state to reflect this and also display information correctly based on
    this state.
    */
    const isMessageFirstInGroup = (messageIndex: number) => {
        // If message is oldest message in the conversation store, it will be the top message rendered in the
        // ConversationView, meaning there are no previous messages to render.
        if (messageIndex === activeConversationMessageStore.length - 1) {
            return true;
        }
        // If previous message has a different author, then current message is in a different message grouping
        if (!isMessageSameAuthor(messageIndex, false)) {
            return true;
        }
        // If messages differ by their minute interval, then group messages separately
        const currMessageTimestamp = getMessageTimestamp(messageIndex);
        const prevMessageTimestamp = getMessageTimestamp(messageIndex + 1);
        if (!currMessageTimestamp || !prevMessageTimestamp) {
            // If timestamp doesn't exist for one of the messages, then group them separately
            return true;
        }
        return currMessageTimestamp.getUTCFullYear() !== prevMessageTimestamp.getUTCFullYear() ||
            currMessageTimestamp.getMonth() !== prevMessageTimestamp.getMonth() ||
            currMessageTimestamp.getUTCDate() !== prevMessageTimestamp.getUTCDate() ||
            currMessageTimestamp.getUTCHours() !== prevMessageTimestamp.getUTCHours() ||
            currMessageTimestamp.getUTCMinutes() !== prevMessageTimestamp.getUTCMinutes();
    };

    const openDeleteMessageDialog = (sid: string) => {
        setMessageSidToDelete(sid);
    }

    const renderMessages = () => {
        if (!activeConversationSid) {
            return null;
        }
        let alreadyRenderedNewMessagesLine = false;
        return activeConversationMessageStore.map((message, index) => {
            // Render "New Message" line once right before the first message with an index greater
            // than the `newMessagesLineIndex`
            const shouldRenderNewMessagesLine = !alreadyRenderedNewMessagesLine && newMessagesLineIndex !== null && message.index <= newMessagesLineIndex;
            alreadyRenderedNewMessagesLine = alreadyRenderedNewMessagesLine || shouldRenderNewMessagesLine;

            let participantIndex = 0;
            let participant = participants.find((participant, index) => {
                if (participant.identity === message.author) {
                    participantIndex = index;
                    return true;
                }
                return false;
            });
            let participantName = participant?.friendlyName ?? message.author ?? participant?.identity ?? "Unknown User";

            return (
                <>
                    {shouldRenderNewMessagesLine && <ReadHorizonLine />}
                    <MessageViewFactory
                        message={message}
                        key={message.sid + index.toString()}
                        participantIndex={participantIndex}
                        participantName={participantName}
                        messageTime={getMessageTime(message.timestamp)}
                        isFirstInGroup={isMessageFirstInGroup(index)}
                        openDeleteMessageDialog={openDeleteMessageDialog}
                    />
                </>
            );
        });
    }

    const ReadHorizonLine = () => {
        return (
            <Pane display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Pane flexGrow={1} height={"1px"} backgroundColor={ColorPalette.primaryAccent}/>
                <Pane flexGrow={0} paddingLeft={"8px"}>
                    <Paragraph color={ColorPalette.primaryAccent}>{ "New" }</Paragraph>
                </Pane>
            </Pane>
        );
    }

    return(
        <>
            { renderMessages() }
            <Dialog
                isShown={messageSidToDelete !== null}
                title="Delete message?"
                intent="danger"
                hasClose={false}
                onCancel={() => setMessageSidToDelete(null)}
                onCloseComplete={() => {
                    setDeleteMessageLoading(false);
                    setMessageSidToDelete(null)
                }}
                confirmLabel="Delete"
                hasCancel={!deleteMessageLoading}
                onConfirm={() => {
                    setDeleteMessageLoading(true);
                    deleteMessage(messageSidToDelete)
                }}
                isConfirmLoading={deleteMessageLoading}
            >
                Are you sure you want to delete this message? This cannot be undone.
            </Dialog>
        </>
    );
}
