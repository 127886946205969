import React, { CSSProperties, MouseEventHandler } from "react";
import "./styles/TextButton.css";

interface TextButtonProps {
    label: string;
    onClick: MouseEventHandler<HTMLSpanElement>
    style?: CSSProperties;
    id?: string;
    className?: string;
}

export const TextButton = ({
    id = "",
    className = "",
    style,
    label,
    onClick
}: TextButtonProps) => {
    return (
        <span
            id={id}
            style={style}
            className={`jc-text-button ${className}`}
            onClick={onClick}
        >
            {label}
        </span>
    )
};
