import { ProductOnboardingSelection } from "../product/ProductOnboardingSelection";

const AUTH_ENDPOINT = process.env.REACT_APP_SERVER_URI + "/auth";

export const login = (email: string): Promise<Response> => {
    return fetch(AUTH_ENDPOINT + "/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            email: email,
        })
    });
};

export const signup = (email: string, firstName: string, lastName: string, productOnboardingSelection?: ProductOnboardingSelection) => {
    return fetch(AUTH_ENDPOINT + "/register", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            email: email,
            firstName: firstName,
            lastName: lastName,
            productOnboardingSelection: productOnboardingSelection
        })
    });
};

export const authenticate = (methodId: string, code: string) => {
    return fetch(AUTH_ENDPOINT + `?method_id=${methodId}&code=${code}`, {
        method: "GET",
        credentials: "include"
    });
};

export const check = () => {
    return fetch(AUTH_ENDPOINT + "/check", {
        method: "GET",
        credentials: "include"
    });
};

export const logout = () => {
    return fetch(AUTH_ENDPOINT + "/logout", {
        method: "POST",
        credentials: "include"
    });
};
