import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Pane, SelectField, TextInputField } from "evergreen-ui";
import { useScript } from "../ScriptLoader";
import { SUPPORTED_COUNTRIES } from "./supportedCountries";
import './styles/AddressFormFields.css';

const apiKey = process.env.REACT_APP_GOOGLE_PLACES_KEY;
const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;

export interface Address {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
}

export interface AddressFormFieldsProps {
    disabled: boolean;
    address: Address,
    setAddress: (address: Address) => void
}

export const AddressFormFields: FC<AddressFormFieldsProps> = ({disabled, address, setAddress}) => {
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    const apartmentInputRef = useRef<HTMLInputElement>(null);

    useScript(src, {
        onReady: () => {
            const autoComplete = new (window as any).google.maps.places.Autocomplete(
                autoCompleteRef.current,
                {types: ["address"]}
            );
            autoComplete.setFields(["address_components", "formatted_address"]);
            autoComplete.addListener("place_changed", () => {
                let newAddress: Address = {};
                let tempStreet = "";

                // The address component schema is defined here: http://goo.gle/3l5i5Mr.

                // The `street_number` isn't guaranteed to come before `route`, therefore, it
                // is recommended to build the full street value as documented here:
                // https://developers.google.com/maps/documentation/javascript/places-autocomplete#get-place-information

                const {address_components: addressComponents} = autoComplete.getPlace();
                if (addressComponents) {
                    for (const {long_name, short_name, types} of addressComponents) {
                        switch (types[0]) {
                            case "street_number": {
                                tempStreet = `${long_name} ${tempStreet}`;
                                break;
                            }
                            case "route": {
                                tempStreet += long_name;
                                break;
                            }
                            case "postal_code": {
                                newAddress.postalCode = long_name;
                                break;
                            }
                            case "locality": {
                                newAddress.city = long_name;
                                break;
                            }
                            case "administrative_area_level_1": {
                                newAddress.state = long_name;
                                break;
                            }
                            case "country": {
                                // Stripe requires a 2-letter country code.
                                // https://stripe.com/docs/api/payment_methods/object#payment_method_object-billing_details-address-country
                                newAddress.country = short_name;
                                break;
                            }
                        }
                    }

                    setAddress({
                        ...newAddress,
                        line1: tempStreet,
                    });

                    // Set the cursor to the second address line for the user to fill out if needed.
                    apartmentInputRef?.current?.focus();
                }
            });
        }
    });

    const onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setAddress({
            ...address,
            [name]: value,
        });
    };

    return (
        <Pane className="jc-address-container">
            <fieldset className="jc-address-container" disabled={disabled}>
                <TextInputField
                    className="jc-address-input"
                    ref={autoCompleteRef}
                    label="Street"
                    name="line1"
                    onChange={onChange}
                    value={address.line1 || ""}
                />
                <TextInputField
                    className="jc-address-input"
                    ref={apartmentInputRef}
                    label="Apartment, Suite, etc. (optional)"
                    name="line2"
                    onChange={onChange}
                    value={address.line2 || ""}
                />
                <Pane className="jc-address-row">
                    <TextInputField
                        width="100%"
                        label="City"
                        name="city"
                        onChange={onChange}
                        value={address.city || ""}
                    />
                    <Pane width="20px"/>
                    <TextInputField
                        width="100%"
                        label="State"
                        name="state"
                        onChange={onChange}
                        value={address.state || ""}
                    />
                </Pane>
                <Pane className="jc-address-row">
                    <SelectField
                        className="jc-address-country"
                        width="100%"
                        label="Country"
                        name="country"
                        onChange={onChange}
                        value={address.country || ""}
                    >
                        {Object.entries(SUPPORTED_COUNTRIES).map(([countryName, countryCode]: [string, string]) => (
                            <option key={countryCode} value={countryCode}>
                                {countryName}
                            </option>
                        ))}
                    </SelectField>
                    <Pane width="20px"/>
                    <TextInputField
                        width="100%"
                        label="Postal Code"
                        name="postalCode"
                        onChange={onChange}
                        value={address.postalCode || ""}
                    />
                </Pane>
            </fieldset>
        </Pane>
    );
};
