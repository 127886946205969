import React, { CSSProperties } from "react";
import { Pane, PaneProps } from "evergreen-ui";
import { genericMemo } from "../../GenericMemo";
import { ColumnConfig } from "../config/ColumnConfig";
import { TableCellChangedHandler, TableCellFactory } from "../factory";
import { TableBodyCell } from "./TableBodyCell";
import { renderStickyCells } from "../etc/StickyCellUtils";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";

interface Props<T extends TableDataWithId, D extends TableDataWithId> extends Omit<PaneProps, "children"> {
    rowData: D;
    columns: ColumnConfig<T>[];
    tableCellFactory: TableCellFactory<T, D>;
    onCellChanged: TableCellChangedHandler<T>;
}

function TableRowInternal<T extends TableDataWithId, D extends TableDataWithId>({
    rowData,
    columns,
    tableCellFactory,
    onCellChanged,
    ...props
}: Props<T, D>) {
    return (
        <Pane className={"jc-row-wrapper"}>
            <Pane className={"jc-tr"} {...props}>
                {renderStickyCells<T>(
                    columns,
                    (column: ColumnConfig<T>, style: CSSProperties, stickyClassNames: string) => (
                        <TableBodyCell
                            key={`${rowData.id}#${String(column.columnName)}`}
                            style={style}
                            className={stickyClassNames}
                            column={column}
                            data={rowData}
                            tableCellFactory={tableCellFactory}
                            onCellChanged={onCellChanged}
                        />
                    ),
                )}
            </Pane>
        </Pane>
    );
}

export const TableRow = genericMemo(TableRowInternal);
