import {
    DesignConceptState,
    DesignInvoiceState,
    ManufacturingDrawingState,
    OnlineListingState,
    PackagingState,
    PreDesignReviewState,
    SamplingState
} from "../enums";

export type SequentialState =
    DesignConceptState |
    DesignInvoiceState |
    ManufacturingDrawingState |
    OnlineListingState |
    PackagingState |
    PreDesignReviewState |
    SamplingState;

export const inOrBeyondState = (
    baseEnum: any,
    productListingState: SequentialState,
    state: SequentialState
): boolean => {
    return !!productListingState && !!state &&
        Object.keys(baseEnum).indexOf(productListingState) >= Object.keys(baseEnum).indexOf(state);
};