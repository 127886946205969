import React from "react";

export const isPrintableCharacterEvent = (event: React.KeyboardEvent<any>): boolean => {
    const code = event.keyCode;
    return (code > 47 && code < 58)   || // number keys
           code === 32 || code === 13 || // spacebar or enter
           code === 8 || code === 127 || // delete + backspace
           (code > 64 && code < 91)   || // letter keys
           (code > 95 && code < 112)  || // numpad keys
           (code > 185 && code < 193) || // ;=,-./` (in order)
           (code > 218 && code < 223);   // [\]' (in order)
};