import {
    Button, CrossIcon,
    Dialog,
    GlobeNetworkIcon,
    LabTestIcon,
    ListDetailViewIcon,
    Pane,
    PlusIcon,
    TextInput,
} from "evergreen-ui";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
    TundraStorefrontCreateCustomDomainInput,
    TundraStorefrontValidateCustomDomainInput
} from "../../../../common/inputs/TundraStorefrontInput";
import { TundraStorefront, TundraStorefrontDomain } from "../../../../common/models";
import { isInternalDomain } from "./etc/DomainUtils";
import { StoreTundraAdminDetailsCard } from "./StoreTundraAdminDetailsCard";
import { StoreTundraAdminDomainCard } from "./StoreTundraAdminDomainCard";

import "./styles/StoreTundraAdminGetView.css";

export interface StoreTundraAdminGetViewProps {
    storefront: TundraStorefront;
    createCustomDomain: (input: TundraStorefrontCreateCustomDomainInput) => void;
    createCustomDomainLoading: boolean;
    createCustomDomainCalled: boolean;
    validateCustomDomain: (domain: TundraStorefrontValidateCustomDomainInput) => void;
    validateCustomDomainLoading: boolean;
    deleteStorefront: (domain: string) => void;
}

export const StoreTundraAdminGetView = (props: StoreTundraAdminGetViewProps): JSX.Element => {

    const {
        storefront,
        createCustomDomain,
        createCustomDomainLoading,
        createCustomDomainCalled,
        validateCustomDomain,
        validateCustomDomainLoading,
        deleteStorefront
    } = props;

    const [
        showCustomDomainForm,
        setShowCustomDomainForm
    ] = useState<boolean>(false);
    const customDomainRef: MutableRefObject<string> = useRef<string>("");

    // Separate the Juniper domains from the custom ones
    const juniperDomains: TundraStorefrontDomain[] = [];
    const customDomains: TundraStorefrontDomain[] = [];
    for (let i: number = 0; i < storefront.domains.length; i++) {
        const domain: string = storefront.domains[i].alias.alias;
        if (isInternalDomain(domain)) {
            juniperDomains.push(storefront.domains[i]);
        } else {
            customDomains.push(storefront.domains[i]);
        }
    }

    // For custom ones, also separate www.* domains and the rest
    const filteredCustomDomains: TundraStorefrontDomain[] = [];
    const wwwCustomDomains: TundraStorefrontDomain[] = [];
    for (let i: number = 0; i < customDomains.length; i++) {
        const domain: string = customDomains[i].alias.alias;
        if (domain.startsWith("www.")) {
            wwwCustomDomains.push(customDomains[i]);
        } else {
            filteredCustomDomains.push(customDomains[i]);
        }
    }

    const renderAdminDomainCard = (domain: TundraStorefrontDomain): JSX.Element => {
        const subdomain: TundraStorefrontDomain | undefined = wwwCustomDomains.find(
            (wwwDomain: TundraStorefrontDomain): boolean => wwwDomain.alias.alias === `www.${domain.alias.alias}`);
        return <StoreTundraAdminDomainCard
            domain={domain}
            subDomain={subdomain}
            validateCustomDomain={validateCustomDomain}
            validateCustomDomainLoading={validateCustomDomainLoading}
        />;
    }

    const goToThemeBuilderLocation = (version?: number): void => {
        const root: string = `https://admin.shopify.com/store/junipersales/apps/taiga-theme-builder/themes/${storefront.storefront.storeId}`;
        window.open(version ? `${root}/${version}` : root, "_blank");
    };

    const submitCreateCustomDomain = (): void => {
        createCustomDomain({
            existingStorefrontId: storefront.storefront.storeId,
            primaryDomain: customDomainRef.current
        });
    };

    useEffect((): void => {
        if (createCustomDomainCalled && !createCustomDomainLoading) {
            setShowCustomDomainForm(false);
        }
    }, [createCustomDomainCalled, createCustomDomainLoading]);

    return (
        <Pane>
            <Pane
                className={"tundra-admin-domains-container-item"}
                borderBottom={"1px solid var(--grey-outline)"}
            >
                <Pane className={"tundra-admin-domains-container"}>
                    <Button
                        appearance={"default"}
                        intent={"none"}
                        className={"tundra-admin-button"}
                        iconBefore={ListDetailViewIcon}
                        onClick={() => goToThemeBuilderLocation()}
                    >
                        View Themes
                    </Button>
                    <Button
                        appearance={"default"}
                        intent={"none"}
                        className={"tundra-admin-button"}
                        iconBefore={LabTestIcon}
                        onClick={() => goToThemeBuilderLocation(storefront.storefront.devThemeVersion)}
                    >
                        Edit Preview Theme
                    </Button>
                    <Button
                        appearance={"primary"}
                        intent={"success"}
                        className={"tundra-admin-button"}
                        iconBefore={GlobeNetworkIcon}
                        onClick={() => goToThemeBuilderLocation(storefront.storefront.prodThemeVersion)}
                    >
                        Edit Live Theme
                    </Button>
                    <Button
                        intent={"danger"}
                        className={"tundra-admin-button"}
                        iconBefore={CrossIcon}
                        onClick={() => deleteStorefront(storefront.storefront.storeId)}
                    >
                        Delete Storefront
                    </Button>
                </Pane>
            </Pane>
            <Pane
                className={"tundra-admin-domains-container-item"}
                borderBottom={"1px solid var(--grey-outline)"}
            >
                <Pane className={"tundra-admin-domains-container"}>
                    { juniperDomains.map(renderAdminDomainCard) }
                </Pane>
            </Pane>
            <Pane
                className={"tundra-admin-domains-container-item"}
                borderBottom={"1px solid var(--grey-outline)"}
            >
                <Button
                    appearance={"primary"}
                    className={"tundra-admin-button"}
                    iconBefore={PlusIcon}
                    onClick={() => setShowCustomDomainForm(true)}
                >
                    Add New Custom Domain
                </Button>
            </Pane>
            <Pane
                className={"tundra-admin-domains-container-item"}
                borderBottom={"1px solid var(--grey-outline)"}
            >
                <Pane
                    className={"tundra-admin-domains-container-full"}
                >
                    { filteredCustomDomains.map(renderAdminDomainCard) }
                </Pane>
            </Pane>
            <Pane
                className={"tundra-admin-domains-container-item"}
                borderBottom={"1px solid var(--grey-outline)"}
            >
                <StoreTundraAdminDetailsCard storefront={storefront.storefront} />
            </Pane>
            <Dialog
                isShown={showCustomDomainForm}
                title={"Add New Custom Domain"}
                confirmLabel={"Add Domain"}
                cancelLabel={"Cancel"}
                isConfirmLoading={createCustomDomainLoading}
                onCloseComplete={() => setShowCustomDomainForm(false)}
                onConfirm={submitCreateCustomDomain}
            >
                <Pane>
                    <TextInput
                        width={"100%"}
                        onChange={(e: any) => customDomainRef.current = e.target.value}
                        placeholder={"custom-domain.example"}
                    />
                </Pane>
            </Dialog>
        </Pane>
    );
}
