import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Pane, ThemeProvider } from "evergreen-ui";
import { SideNavigation } from "./navigation/SideNavigation";
import { apolloClient } from "./datastore";
import { theme } from "./context/Theme";
import { PrivateRoute } from "./navigation/PrivateRoute";
import { routes } from "./navigation/Routes";
import { NotFound } from "./pages/NotFound";
import { UserContextProvider } from "./context/UserContext";
import { PageViewListener } from "./analytics/PageViewListener";
import { InvitedUserDialog } from "./navigation/InvitedUserDialog";
import { StytchProvider } from "./stytch/StytchProvider";
import { AnnouncementBarWrap } from "./common/AnnouncementBarWrap";
import { NativeMessagingProvider } from "./native-messaging/state/NativeMessagingProvider";

import './styles/App.css';

const App = () => {
    return (
        <ApolloProvider client={apolloClient}>
            <ThemeProvider value={theme}>
                <StytchProvider>
                    <BrowserRouter>
                        <UserContextProvider>
                            <NativeMessagingProvider>
                                <AnnouncementBarWrap>
                                    <SideNavigation/>
                                    <Pane id="jc-content-body">
                                        <Switch>
                                            {routes.map((route, index) => {
                                                const {path, component, gatedComponent, gatedComponentEnabled, authenticated, exact = true} = route;
                                                const Component = authenticated ? PrivateRoute : Route;
                                                return (
                                                    <Component
                                                        key={index}
                                                        path={path}
                                                        exact={exact}
                                                        gatedComponent={gatedComponent}
                                                        gatedComponentEnabled={gatedComponentEnabled}
                                                        component={component}
                                                    />
                                                );
                                            })}
                                            <Route component={NotFound}/>
                                        </Switch>
                                        <InvitedUserDialog/>
                                    </Pane>
                                </AnnouncementBarWrap>
                            </NativeMessagingProvider>
                        </UserContextProvider>
                        <PageViewListener/>
                    </BrowserRouter>
                </StytchProvider>
            </ThemeProvider>
        </ApolloProvider>
    );
};

export default App;
