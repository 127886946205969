import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AbstractTableViewProvider } from "../../common/tables/view/AbstractTableViewProvider";
import { Pane } from "evergreen-ui";
import { DefaultStoreTableConfig } from "./tables/config/DefaultStoreTableConfig";
import {
    queryStoresForAdmin,
    useCreateStoreMutation,
    useUpdateStoreMutation
} from "../../datastore/StoreDataStore";
import { Store } from "../../../../common/models";
import { StoreForAdmin } from "../../../../common/tables/store/StoreForAdmin";
import { TableCellFactory } from "../../common/tables/factory";
import { StoreTableCellFactory } from "./tables/factory/StoreTableCellFactory";
import { TablePageHeader } from "../../common/tables/view/TablePageHeader";
import { TableView } from "../../common/tables/view/TableView";
import { StoreTableRowSideSheetForm } from "./StoreTableRowSideSheetForm";
import { StoreSearchInput } from "../Store/StoreSearchInput";

const storeTableCellFactory: TableCellFactory<StoreForAdmin, Store> = new StoreTableCellFactory();

export const AdminStoreTableView: FC = () => {
    const [showCreateRowForm, setShowCreateRowForm] = useState<boolean>(false);
    const [createStoreInput, setCreateStoreInput] = useState<{ storeName: string }>({ storeName: "" });

    const [
        createStore,
        {
            loading: createStoreLoading,
            error: createStoreError,
            called: createStoreCalled,
        }
    ] = useCreateStoreMutation();

    const [
        updateStore,
        {
            loading,
            error,
            called,
        },
    ] = useUpdateStoreMutation();

    const onUpdateStore = useCallback((
        prevData: Store[],
        id: string | number,
        columnName: keyof StoreForAdmin,
        value: string | number | boolean | null,
    ): Store[] => {
        return prevData.map((original: Store) => {
            // Skip over all Store rows that aren't being updated.
            if (id !== original.id) {
                return original;
            }

            // Modify the Store object with the new cell value.
            const storeToUpdate: Store = {
                ...original,
                [columnName]: value
            };

            // Update the server with the modified cell value.
            updateStore(storeToUpdate);

            // Update the React state with the modified cell value.
            return storeToUpdate;
        });
    }, [updateStore]);

    const onCancelSideSheet = useCallback(() => {
        setShowCreateRowForm(false);
    }, [setShowCreateRowForm]);

    const onSubmitSideSheet = useCallback(() => {
        createStore(createStoreInput);
    }, [createStore, createStoreInput]);

    const emptyViewProps = useMemo(() => {
        return {
            primaryText: "No stores found",
            secondaryText: "Add Store",
            onClickSecondaryText: () => setShowCreateRowForm(true),
        };
    }, []);

    const errorViewProps = useMemo(() => {
        return {
            primaryText: "Failed to load stores",
            secondaryText: "Refresh the page to try again",
        };
    }, []);

    useEffect(() => {
        if (!createStoreCalled) {
            return;
        }

        if (!createStoreLoading && !createStoreError) {
            setCreateStoreInput({ storeName: "" });
            setShowCreateRowForm(false);
        }
    }, [createStoreLoading, createStoreCalled, createStoreError]);

    return (
        <Pane height={"100%"} display={"flex"} flexDirection={"column"}>
            <TablePageHeader
                title={"Stores"}
                addButtonText={"Add Store"}
                addButtonOnClick={() => setShowCreateRowForm(true)}
                searchBar={(
                    <StoreSearchInput
                        placeholderText={"Search for store"}
                        textInputProps={{ fontSize: 14, height: 40, paddingLeft: 36, borderRadius: 8, width: 200 }}
                        leftIconContainerProps={{ width: 36 }}
                        onSelectSearchResult={(label: string, value: string) => {
                            window.open(`/stores/${value}`, "_blank");
                        }}
                    />
                )}
            />
            <AbstractTableViewProvider
                queryTableData={queryStoresForAdmin}
                onUpdateRow={onUpdateStore}
                updateRowLoading={loading}
                updateRowCalled={called}
                updateRowError={error}
                createRowCalled={createStoreCalled}
                createRowError={createStoreError}
                createRowLoading={createStoreLoading}
                tableCellFactory={storeTableCellFactory}
                savedTableConfigProps={{
                    tableName: "storesForAdmin",
                    identifiers: ["v1"],
                    defaultConfigValue: DefaultStoreTableConfig,
                }}
            >
                <TableView
                    emptyViewProps={emptyViewProps}
                    errorViewProps={errorViewProps}
                />
            </AbstractTableViewProvider>
            <StoreTableRowSideSheetForm
                isShown={showCreateRowForm}
                mutationLoading={createStoreLoading}
                mutationCalled={createStoreCalled}
                mutationError={createStoreError}
                input={createStoreInput}
                onInputChanged={setCreateStoreInput}
                onCancel={onCancelSideSheet}
                onSubmit={onSubmitSideSheet}
            />
        </Pane>
    );
};
