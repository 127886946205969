import { IconButton, Menu, MoreIcon, Pane, Position } from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { useLongPress } from "../../../common/mobile/LongPress";
import { ColorPalette } from "../../../context/Theme";
import { PopoverWithOverlay } from "../../../common/PopoverWithOverlay";
import { NavigationHelper } from "../../../navigation/PrevLocationState";

interface Props {
    conversationName: string;
    conversationSid: string;
    frequencyLabel: string;
    onClick: () => void;
    onClickDelete: () => void;
};

export const ConversationPreferenceItem = ({ conversationSid, conversationName, frequencyLabel, onClick, onClickDelete }: Props) => {
    const history = useHistory();
    const [moreIconIsShown, setMoreIconIsShown] = useState<boolean>(false);
    const [popoverIsShown, setPopoverIsShown] = useState<boolean>(false);
    const longPressHandlers = useLongPress(useCallback(() => {
        setPopoverIsShown(true);
    }, []));

    const hidePopoverAndMoreIcon = (): void => {
        setPopoverIsShown(false);
        setMoreIconIsShown(false);
    };

    const shouldShowMoreIcon = (): boolean => {
        return moreIconIsShown || popoverIsShown;
    };

    const onClickGoToMessages = () => {
        history.push(NavigationHelper.createMessagesPath(conversationSid));
    };

    return (
        <Pane
            className={"conversation-preference-item-container"}
            backgroundColor={(moreIconIsShown || popoverIsShown) ? ColorPalette.greyBackground : "unset"}
            onMouseOver={() => {
                setMoreIconIsShown(true);
            }}
            onMouseOut={() => {
                // Only set to false if the popover is not currently shown.
                setMoreIconIsShown(popoverIsShown);
            }}
            onClick={onClick}
            cursor={"pointer"}
            {...longPressHandlers}
        >
            <Pane>
                <div className={"conversation-preference-header"}>
                    {conversationName}
                </div>
                <div className={"conversation-preference-byline-text"}>
                    {frequencyLabel}
                </div>
            </Pane>
            <PopoverWithOverlay
                key={conversationSid}
                isShown={popoverIsShown}
                shouldCloseOnExternalClick={true}
                position={Position.TOP_RIGHT}
                onClose={() => {
                    hidePopoverAndMoreIcon();
                }}
                content={(
                    <Menu>
                        <Menu.Group>
                            <Menu.Item onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                hidePopoverAndMoreIcon();
                                onClick();
                            }}>
                                Edit
                            </Menu.Item>
                            <Menu.Item onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                hidePopoverAndMoreIcon();
                                onClickDelete(); 
                            }}>
                                Delete
                            </Menu.Item>
                            <Menu.Item onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                hidePopoverAndMoreIcon();
                                onClickGoToMessages();
                            }}>
                                Go to Conversation
                            </Menu.Item>
                        </Menu.Group>
                    </Menu>
                )}
            >
                <Pane display={shouldShowMoreIcon() ? "flex" : "none"} className={"conversation-preference-show-more"}>
                    <IconButton
                        icon={MoreIcon}
                        size={"medium"}
                        borderColor={ColorPalette.grey}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            setPopoverIsShown(true);
                        }}
                    />
                </Pane>
            </PopoverWithOverlay>
        </Pane>
    )
}