import React, { ChangeEvent, CSSProperties, FC, useEffect, useState } from "react";
import {
    Dialog,
    Pane,
    PaneProps,
    SelectMenu,
    SelectMenuItem,
    TextInputField,
    toaster
} from "evergreen-ui";
import { useStoreListQuery, useCreateStoreMutation } from "../datastore/StoreDataStore";
import { Store } from "../../../common/models";

// TODO: Consolidate store dialog with StoresList
interface Props extends PaneProps {
    /**
     * Callback that notifies the parent with store data when the selector value changes.
     */
    onStoreChanged: (newStoreName: string, existingStoreId?: string) => void;
    /**
     * Callback that notifies the parent when the selector value has been finalized and will no
     * longer be changing.
     */
    onStoreConfirmed?: () => void;

    initialStore?: Store;
    inputStyle?: CSSProperties;
    required?: boolean;
}

const CREATE_STORE_TEXT = "+ New Store";
const CREATE_STORE_VALUE = "new_store_value";

// This is the default height of a SelectMenu item that is set by Evergreen.
const ROW_HEIGHT_PX = 33;

// The Evergreen `SelectMenu` component doesn't allow us to set a class or id on the
// element, nor does it have a `maxHeight`, so we have to calculate the `height` ourselves.
const MAX_ROW_COUNT = 10;

export const StoreSelector: FC<Props> = (props: Props) => {
    const {
        onStoreChanged,
        onStoreConfirmed,
        initialStore = null,
        inputStyle,
        required = false,
        ...paneProps
    } = props;
    const { data, loading } = useStoreListQuery();
    const [stores, setStores] = useState<Store[]>([]);
    const [storeName, setStoreName] = useState<string>("");
    const [selectedStore, setSelectedStore] = useState<Store | null>(initialStore);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const [
        createStore,
        {
            loading: createStoreLoading,
            error: createStoreError,
            called: createStoreCalled,
            data: createdStore,
        }
    ] = useCreateStoreMutation();

    const updateStores = (store?: Store | null) => {
        if (store) {
            setStores((stores) => [...stores, store]);
        }
    };

    useEffect(() => {
        if (!createStoreLoading && createStoreCalled) {
            if (createStoreError) {
                toaster.warning("Server error! Unable to add user.");
            } else {
                setShowDialog(false);
                setStoreName("");
                toaster.success(`Store was added successfully.`, { duration: 2 });
                updateStores(createdStore);
                if (createdStore) {
                    // Explicitly set the selected store, so that the checkmark is rendered on the row.
                    setSelectedStore(createdStore);
                    // Notify the listener that a store was selected.
                    onStoreChanged(createdStore.name, createdStore.id);
                    // Notify the listener that the new store dialog has been confirmed.
                    onStoreConfirmed && onStoreConfirmed();
                }
            }
        }
    }, [createStoreLoading, createStoreCalled, createStoreError, createdStore]);

    useEffect(() => {
        if (!loading) {
            setStores(data || []);
        }
    }, [data, loading]);

    const renderNewStoreNameField = (small: boolean = false) => {
        return (
            <Pane>
                <TextInputField
                    inputHeight={small? 35 : 50}
                    style={{
                        fontSize: 12,
                        ...inputStyle,
                    }}
                    label={"Store Name"}
                    required={required}
                    placeholder="What do you want to call your store?"
                    value={storeName}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newStoreName = event.target.value;
                        setStoreName(newStoreName);
                        onStoreChanged(newStoreName);
                    }}
                />
            </Pane>
        );
    };

    const renderDialog = () => {
        return (
            <Dialog
                title={"Create a new store"}
                width={400}
                isShown={showDialog}
                hasClose={false}
                confirmLabel="Create"
                onCloseComplete={() => setShowDialog(false)}
                onConfirm={() => {
                    createStore({
                        storeName: storeName
                    })
                    setShowDialog(false);
                }}
            >
                {renderNewStoreNameField(true)}
            </Dialog>
        )
    };

    const renderClickableText = () => {
        return (
            <TextInputField
                style={{
                    cursor: "pointer",
                    ...inputStyle
                }}
                placeholder={loading ? "Loading..." : "Click to select a Store"}
                label={"Store Name"}
                readOnly
                required={required}
                value={selectedStore?.name || ""}
            />
        );
    };

    if (!loading && stores.length === 0) {
        return renderNewStoreNameField();
    }

    const calculateSelectMenuHeightPx = (): number => {
        // We add an extra "+ New Store" row to the list of stores.
        const rowCount = stores.length + 1;

        // Cap the scrollable height, otherwise we run into issues with the popover element,
        // particularly for internal users who are connected to many stores.
        return ROW_HEIGHT_PX * Math.min(MAX_ROW_COUNT, rowCount);
    };

    const buildOptionsList = (): SelectMenuItem[] => {
        const optionsList = stores.map((store: Store) => ({label: store.name, value: store.id}));
        const createNewStoreItem = {label: CREATE_STORE_TEXT, value: CREATE_STORE_VALUE};

        // Insert the "+ New Store" item at the front of the list if the view is scrollable, since
        // users may not know to scroll all the way to the bottom to find this button.
        return (stores.length >= MAX_ROW_COUNT) ?
            [createNewStoreItem, ...optionsList] :
            [...optionsList, createNewStoreItem];
    };

    return (
        <Pane {...paneProps}>
            {loading ? renderClickableText() : (
                <SelectMenu
                    closeOnSelect
                    hasFilter={false}
                    hasTitle={false}
                    height={calculateSelectMenuHeightPx()}
                    options={buildOptionsList()}
                    onSelect={(item) => {
                        if (item.value === CREATE_STORE_VALUE) {
                            setSelectedStore(null);
                            setShowDialog(true);
                        } else {
                            setSelectedStore(stores.find((store: Store) => store.id === item.value) ?? null);
                            onStoreChanged(item.label, String(item.value));
                        }
                    }}
                >
                    {renderClickableText()}
                </SelectMenu>
            )}
            {renderDialog()}
        </Pane>
    );
};
