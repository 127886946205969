import { gql } from '@apollo/client';
import { HTTPMethod } from "../common/HTTPMethod";
import { moxtraTokenManager } from "./MoxtraTokenManager";
import { MOXTRA_BASE_URL } from "../../../common/constants";
import { MoxtraAccessToken } from "../../../common/moxtra/MoxtraAccessToken";
import { jssaQuery, useBaseMutation } from "../datastore";
import { toNumber } from "../../../common/utils";

export const MEP_SDK_URL = MOXTRA_BASE_URL + 'web/websdk/dist/mepsdk.js';

const MOXTRA_ACCESS_TOKEN_QUERY = gql`
    query {
        moxtraToken {
            accessToken
            expiryTime
        }
    }
`;

const moxtraRequest = async (method: HTTPMethod, path: string): Promise<any> => {
    const url = MOXTRA_BASE_URL + 'v1' + path;
    return moxtraTokenManager.getAccessToken()
        .then(accessToken => {
            const headers = new Headers({
                'Authorization': 'Bearer ' + accessToken
            });
            return fetch(url, {
                method: method,
                headers: headers
            });
        })
        .then(response => response.json());
};

export const getMoxtraBinderId = (): Promise<string> => {
    return moxtraRequest(HTTPMethod.GET, "/me/binders")
        .then(response => response["data"]["binders"][0]["binder"]["id"]);
};

export const fetchAccessToken = (): Promise<MoxtraAccessToken> => {
    return jssaQuery({query: MOXTRA_ACCESS_TOKEN_QUERY}).then(response => {
        const expiryTime = new Date(response.data.moxtraToken.expiryTime);
        return new MoxtraAccessToken(response.data.moxtraToken.accessToken, expiryTime);
    });
};

export const revokeAccessToken = (accessToken: MoxtraAccessToken): Promise<any> => {
    const urlParams = new URLSearchParams({
        access_token: accessToken.accessToken
    });
    const url = MOXTRA_BASE_URL + `v1/oauth/revoke?` + urlParams;
    const headers = new Headers({
        'Authorization': 'Bearer ' + accessToken.accessToken
    });
    return fetch(url, {
        method: HTTPMethod.POST,
        headers: headers,
    }).then(response => response.json());
};
