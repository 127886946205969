import React, { FC } from "react";
import { FooterActionBar } from "../common/FooterActionBar";

export interface NewProductWizardActionBarProps {
    isSubmitting: boolean;
    showPrevious?: boolean;
    forwardButtonText?: string;
    forwardButtonDisabled: boolean;
    onNext: () => void;
    onPrev: () => void;
    infoComponent: React.ReactNode | undefined;
}

export const NewProductWizardActionBar: FC<NewProductWizardActionBarProps> = ({
    isSubmitting,
    onNext,
    onPrev,
    forwardButtonDisabled,
    showPrevious = true,
    forwardButtonText = "Continue",
    infoComponent
}) => {
    return (
        <FooterActionBar
            isPrimaryLoading={isSubmitting}
            showSecondaryButton={showPrevious}
            primaryButtonText={forwardButtonText}
            primaryButtonDisabled={forwardButtonDisabled}
            onPrimaryClick={onNext}
            onSecondaryClick={onPrev}
            infoComponent={infoComponent} />
    )
};
