import React, { FunctionComponent } from "react";
import { AbstractTableBodyCellRenderer } from "./AbstractTableBodyCellRenderer";
import { TableBodyCellRendererInput } from "../input";
import { FloatingMenuButton, FloatingMenuButtonPopoverItems } from "../../../FloatingMenuButton";
import { TableInputCell } from "../../view/TableInputCell";

export class TablePopoverCellRenderer<T> extends AbstractTableBodyCellRenderer<T> {
    protected readonly popoverMenuItems: FloatingMenuButtonPopoverItems[];

    constructor(input: TableBodyCellRendererInput<T>, popoverMenuItems: FloatingMenuButtonPopoverItems[]) {
        super(input);
        this.popoverMenuItems = popoverMenuItems;
    }

    protected getMemoizedComponent(): FunctionComponent {
        const {columnConfig, cellValue, onCellChanged, id} = this.input;

        return React.memo(() => (
            <FloatingMenuButton height={"100%"} width={"100%"} menuItems={this.popoverMenuItems}>
                <TableInputCell
                    columnConfig={columnConfig}
                    id={id}
                    cellValue={cellValue}
                    onCellChanged={onCellChanged}
                    inputType={"text"}
                />
            </FloatingMenuButton>
        ));
    }
}
