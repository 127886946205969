import React, { useCallback, useState } from "react";
import { IconButton, Menu, MoreIcon, Pane, PaneProps, Popover } from "evergreen-ui";
import { useLongPress } from "./mobile/LongPress";

import './styles/FloatingMenuButton.css';

export type FloatingMenuButtonPopoverItems = {
    label: string,
    disabled?: boolean,
    onSelect: () => void,
};

interface Props extends PaneProps {
    menuItems: FloatingMenuButtonPopoverItems[];
}

export const FloatingMenuButton = ({menuItems, children, ...props}: Props) => {
    const [popoverIsShown, setPopoverIsShown] = useState<boolean>(false);
    const [buttonIsShown, setButtonIsShown] = useState<boolean>(false);

    const hideAll = useCallback(() => {
        setPopoverIsShown(false);
        setButtonIsShown(false);
    }, [setPopoverIsShown, setButtonIsShown]);

    const onLongPress = useCallback(() => {
        setPopoverIsShown(true);
    }, [setPopoverIsShown]);

    const longPressHandlers = useLongPress(onLongPress);

    // TODO: Ideally the menu button should appear when hovering over the row, as opposed the cell.
    //       This can be accomplished through a `TableRowContextProvider` to manage state
    //       bidirectionally between a row and its cells.

    return (
        <Popover
            shouldCloseOnExternalClick={true}
            isShown={popoverIsShown}
            onClose={hideAll}
            content={(
                <Menu>
                    <Menu.Group>
                        {menuItems.map(({disabled = false, onSelect, label}) => (
                            <Menu.Item
                                key={label}
                                disabled={disabled}
                                onSelect={() => {
                                    hideAll();
                                    onSelect();
                                }}
                            >
                                {label}
                            </Menu.Item>
                        ))}
                    </Menu.Group>
                </Menu>
            )}
        >
            <Pane
                {...props}
                className={`jc-fmb-container ${buttonIsShown ? "active" : "hidden"}`}
                onMouseOver={() => setButtonIsShown(true)}
                onMouseLeave={() => setButtonIsShown(popoverIsShown)}
                {...longPressHandlers}
            >
                {children}
                <Pane className={"jc-fmb-button-container"}>
                    <IconButton
                        className={"jc-fmb-button"}
                        icon={MoreIcon}
                        size={"medium"}
                        onClick={() => setPopoverIsShown(true)}
                    />
                </Pane>
            </Pane>
        </Popover>
    );
}
