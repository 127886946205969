import React, { FunctionComponent } from "react";
import { Table } from "evergreen-ui";
import { AbstractTableHeaderCellRenderer } from "./AbstractTableHeaderCellRenderer";

export class TableReadOnlyHeaderCellRenderer<T> extends AbstractTableHeaderCellRenderer<T> {
    protected getMemoizedComponent(): FunctionComponent {
        const {columnConfig: {displayName}} = this.input;
        return React.memo(() => (
            <Table.TextHeaderCell className={"jc-header-cell"}>
                {displayName}
            </Table.TextHeaderCell>
        ));
    }
}
