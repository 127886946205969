import { gql } from "@apollo/client";
import { useBaseQuery } from "./index";
import {
    CUSTOM_CATEGORY_NAME,
    CUSTOM_PRODUCT_NAME,
    CatalogCategory,
    CatalogProduct
} from "../../../common/models";

export const catalogCategoriesQuery = gql`
    query catalogCategories {
        catalogCategories {
            id
            name
            description
            imageUrl
            isPopular
            isNew
            catalogProducts {
                id
                name
                description
                shortDescription
                imageUrl
                isPopular
                isNew
                addOnGroups {
                    id
                    name
                    displayName
                    isMultiselect
                    addOns {
                        id
                        name
                        displayName
                        imageUrl
                        description
                        shortDescription
                        isDefault
                        addOnGroup {
                            isMultiselect
                            id
                        }
                    }
                }
                catalogOptions {
                    id
                    displayName
                    name
                    imageUrl
                    description
                    shortDescription
                    defaultOption
                    isPopular
                    isNew
                    apparelPrinting
                    fabricColors
                    apparelLabel
                    pricing {
                        moq
                        msrp
                        creatorCost
                        sampleFee
                    }
                }
            }
        }
    }
`;

export const useCatalogCategoriesQuery = () =>
    useBaseQuery<CatalogCategory[], {catalogCategories: CatalogCategory[]}>(
        catalogCategoriesQuery,
        {},
        ({data: {catalogCategories}}) => catalogCategories,
    );

const isCustom = (customName: string, categoryOrProduct?: CatalogCategory | CatalogProduct) => (
    (categoryOrProduct !== undefined) && (categoryOrProduct.name === customName)
);

export const isCustomCategory = (category?: CatalogCategory): boolean => (
    isCustom(CUSTOM_CATEGORY_NAME, category)
);

export const isCustomProduct = (product?: CatalogProduct): boolean => (
    isCustom(CUSTOM_PRODUCT_NAME, product)
);
