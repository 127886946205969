import { LaunchType } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class LaunchTypeMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [LaunchType.unset] : {
                label: "Not Set",
                value: LaunchType.unset,
                color: "yellow"
            },
            [LaunchType.preOrder] : {
                label: "Pre-Order",
                value: LaunchType.preOrder,
                color: "neutral"
            },
            [LaunchType.inStock] : {
                label: "In Stock",
                value: LaunchType.inStock,
                color: "neutral"
            }
        };
    }
}