import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { usePersistentState } from "../../state/usePersistentState";
import { TableConfig } from "../config/TableConfig";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";

export function useSavedTableConfig<T extends TableDataWithId>(
    tableName: string,
    identifiers: string[] = [],
    defaultValue: TableConfig<T>,
): [TableConfig<T>, Dispatch<SetStateAction<TableConfig<T>>>] {
    const {persistentState} = usePersistentState([
            "table",
            tableName,
            ...identifiers.filter((id: string) => !!id),  // Remove potential empty strings.
        ],
        JSON.stringify(defaultValue),
    );

    const savedTableConfig = useMemo(() => {
        return JSON.parse(persistentState[0]);
    }, [persistentState]);

    const setSavedTableConfig: Dispatch<SetStateAction<TableConfig<T>>> = useCallback(
        (setStateAction: SetStateAction<TableConfig<T>>): void => {
            const setPersistentState: Dispatch<SetStateAction<string>> = persistentState[1];
            setPersistentState((prevState: string): string => {
                if (typeof setStateAction === "function") {
                    return JSON.stringify(setStateAction(JSON.parse(prevState)));
                } else {
                    return JSON.stringify(setStateAction);
                }
            });
        }, [persistentState]);

    return [
        savedTableConfig,
        setSavedTableConfig,
    ];
}
