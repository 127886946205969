import { useEffect, useState, useCallback } from "react";
import {
    EditIcon,
    Heading, Icon,
    IconButton,
    Menu,
    MoreIcon,
    Pane,
    Paragraph,
    Pill,
    Position,
} from "evergreen-ui";
import { usePersistentState } from "../../common/state/usePersistentState";
import { ColorPalette } from "../../context/Theme";
import { useMobileMediaQuery } from "../../common/MediaQuery";
import { useLongPress } from "../../common/mobile/LongPress";
import { PopoverWithOverlay } from "../../common/PopoverWithOverlay";
import { useMessagingClientAPIContext, useMessagingDataContext } from "../state/NativeMessagingProvider";
import { ConversationMetadata } from "../types";
import React from "react";

import './ConversationListEntry.css';

export const ConversationListEntryView = React.memo(({
    sid,
    friendlyName,
    lastUpdated,
    lastMessage,
    lastMessageAuthor,
    unreadMessageCount,
}: ConversationMetadata) => {
    const { getPersistedValue } = usePersistentState([sid, "draft", "message"]);
    const [moreIconIsShown, setMoreIconIsShown] = useState<boolean>(false);
    const [popoverIsShown, setPopoverIsShown] = useState<boolean>(false);
    const [subtitleText, setSubtitleText] = useState<string>("");
    const [subtitleIsDraft, setSubtitleIsDraft] = useState<boolean>(getPersistedValue().length > 0);
    const { activeConversationSid } = useMessagingDataContext();
    const { markConversationAsReadOrUnread } = useMessagingClientAPIContext();

    useEffect(() => {
        const lastMessageAuthorName = lastMessageAuthor ? `${lastMessageAuthor.friendlyName}` : lastMessage && lastMessage.author ? `${lastMessage.author}` : "Unknown User";
        let subtitle = "";
        if (!lastMessage) {
            setSubtitleText(subtitle);
            return;
        }
        const draftMessage: string = getPersistedValue();
        if (draftMessage.length > 0 && sid !== activeConversationSid) {
            setSubtitleText(draftMessage);
            setSubtitleIsDraft(true);
            return;
        } else {
            setSubtitleIsDraft(false);
        }
        if (lastMessage.media.length >= 1) {
            if (lastMessage.media.length === 1) {
                subtitle = `${lastMessageAuthorName} has sent a media attachment`;
            } else {
                subtitle = `${lastMessageAuthorName} has sent ${lastMessage.media.length} media attachments`;
            }
        } else {
            // No media attachments
            subtitle = `${lastMessageAuthorName}: ${lastMessage.body ?? ""}`;
        }
        setSubtitleText(subtitle);
    }, [lastMessage, lastMessageAuthor, activeConversationSid]);

    const isMobile = useMobileMediaQuery();

    const longPressHandlers = useLongPress(useCallback(() => {
        setPopoverIsShown(true);
    }, []));

    // Twilio will give a max value of 1000 for unread messages.
    // For 1000 unread messages we can display "1000+".
    const toFormattedUnreadMessageCount = (count: number): string => {
        return count < 1000 ? count.toString() : "1000+";
    };

    const toFormattedLastUpdated = (date: Date): string => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        if (date.toDateString() === today.toDateString()) {
            return date.toLocaleString('default', { hour: "numeric", minute: "numeric" })
        } else if (date.toDateString() === yesterday.toDateString()) {
            return `Yesterday`;
        } else if (date.getFullYear() === today.getFullYear()) {
            return date.toLocaleString('default', { month: 'short', day: "numeric" });
        } else {
            return date.toLocaleString('default', { year: "numeric", month: 'short', day: "numeric" });
        }
    };

    const timestampWidth: number = 70;
    const textRowHeight: number = 20;
    const pillMaxWidth: number = unreadMessageCount ? 50 : 0;

    const hidePopoverAndMoreIcon = (): void => {
        setPopoverIsShown(false);
        setMoreIconIsShown(false);
    };

    const isUnread = (): boolean => {
        return unreadMessageCount > 0;
    };

    // TODO: Don't use the client directly to set the messages as read/unread, but instead
    // create and expose a method inside the NativeMessaingProvider that you call from here
    // to do that
    const onClickMarkAsReadOrUnread = async (e: React.MouseEvent) => {
        e.stopPropagation();
        hidePopoverAndMoreIcon();

        await markConversationAsReadOrUnread(sid);
    };

    const shouldShowMoreIcon = (): boolean => {
        return !isMobile && (moreIconIsShown || popoverIsShown);
    };

    return (
        <PopoverWithOverlay
            key={sid}
            isShown={popoverIsShown}
            shouldCloseOnExternalClick={true}
            bringFocusInside={true}
            position={Position.BOTTOM_RIGHT}
            onClose={() => {
                hidePopoverAndMoreIcon();
            }}
            content={(
                <Menu>
                    <Menu.Group>
                        <Menu.Item onClick={onClickMarkAsReadOrUnread}>
                            Mark as {isUnread() ? "read" : "unread"}
                        </Menu.Item>
                    </Menu.Group>
                </Menu>
            )}
        >
            <Pane
                className={"cle-outer-container"}
                backgroundColor={(moreIconIsShown || popoverIsShown) ? ColorPalette.greyBackground : "unset"}
                onMouseOver={() => {
                    setMoreIconIsShown(true);
                }}
                onMouseOut={() => {
                    // Only set to false if the popover is not currently shown.
                    setMoreIconIsShown(popoverIsShown);
                }}
                {...longPressHandlers}
            >
                <Pane className={"cle-inner-container"}>
                    <Pane
                        display={"flex"}
                        alignItems={"center"}
                        height={textRowHeight}
                    >
                        <Heading
                            style={{
                                fontSize: 12,
                                width: `calc(100% - ${timestampWidth}px)`
                            }}
                            fontWeight={600}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {subtitleIsDraft && <Icon icon={EditIcon} paddingRight={"4px"} size={10}/>}
                            {friendlyName}
                        </Heading>
                        {lastUpdated && (
                            <Paragraph
                                display={"inline"}
                                style={{
                                    width: timestampWidth,
                                    fontSize: 11,
                                }}
                                float={"right"}
                                fontWeight={unreadMessageCount ? 500 : 400}
                                textAlign={"end"}
                                verticalAlign={"end"}
                                color={ColorPalette.primaryText}
                            >
                                {toFormattedLastUpdated(lastUpdated)}
                            </Paragraph>
                        )}
                    </Pane>
                    <Pane
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        height={textRowHeight}
                    >
                        {subtitleText && (
                            <Paragraph
                                display={"inline"}
                                style={{
                                    width: `calc(100% - ${pillMaxWidth}px)`,
                                    fontSize: 11,
                                    color: unreadMessageCount ?
                                        ColorPalette.primaryAccent :
                                        ColorPalette.primaryText
                                }}
                                float={"left"}
                                fontWeight={500}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {
                                    subtitleIsDraft ?
                                        <span style={{fontStyle: "italic", fontSize: "11px"}}>{subtitleText}</span> :
                                        <>{subtitleText}</>
                                }
                            </Paragraph>
                        )}
                        {(unreadMessageCount > 0) && (
                            <Pill
                                float={"right"}
                                backgroundColor={ColorPalette.primaryAccent}
                                style={{color: ColorPalette.white}}
                            >
                                {toFormattedUnreadMessageCount(unreadMessageCount)}
                            </Pill>
                        )}
                    </Pane>
                </Pane>
                <Pane
                    className={"cle-show-more"}
                    display={shouldShowMoreIcon() ? "flex" : "none"}
                >
                    <IconButton
                        className="cle-more-button"
                        icon={MoreIcon}
                        size={"medium"}
                        borderRadius={"50%"}
                        borderColor={ColorPalette.grey}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            setPopoverIsShown(true);
                        }}
                    />
                </Pane>
            </Pane>
        </PopoverWithOverlay>
    );
});
