import { useEffect, useRef, useState } from "react";
import { Pane, SeriesSearchIcon } from "evergreen-ui";
import * as Sentry from "@sentry/react";
import { Store } from "../../../common/models";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { fetchDashboardUrl } from "../datastore/AnalyticsDataStore";
import { EmptyState } from "../common/EmptyState";
import { ColorPalette } from "../context/Theme";

export interface AnalyticsTabProps {
    selectedIndex: number;
    tabIndex: number;
    store: Store;
};

export const AnalyticsTab = (props: AnalyticsTabProps) => {
    const { selectedIndex, tabIndex, store } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [iframeSrc, setIframeSrc] = useState<string>("");
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (selectedIndex !== tabIndex) {
            // This instance is not currently selected.
            return;
        }

        if (iframeSrc) {
            // The dashboard URL has already been loaded.
            return;
        }

        // This instance is now active, so fetch the dashboard URL.
        fetchDashboardUrl(store.id)
            .then((dashboardUrl: string) => {
                if (mountedRef.current) {
                    setIframeSrc(dashboardUrl);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                if (mountedRef.current) {
                    setError(true);
                }
            });
    }, [selectedIndex, tabIndex]);


    const renderLoadingState = () => {
        // The loading spinner must be mounted overtop the iframe so that it can continue
        // to be displayed until the iframe's DOM has loaded (indicated via `iframe.onload`).
        return (loading && !error) && (
            <Pane
                position={"absolute"}
                backgroundColor={ColorPalette.white}
                width={"100%"}
                height={"100%"}
                top={"0"}
                left={"0"}
            >
                <CenteredSpinner />
            </Pane>
        );
    };

    const renderIframeOrErrorState = () => {
        return error ? (
            <EmptyState
                title={"Failed to load dashboard."}
                subtitle={"Contact engineering@hellojuniper.com for assistance."}
            >
                <SeriesSearchIcon size={60} />
            </EmptyState>
        ) : (
            <iframe
                className="metabase-iframe"
                src={iframeSrc}
                onLoad={() => {
                    setLoading(false);
                }}
                onError={(error) => {
                    Sentry.captureException(error);
                    setError(true);
                }}
            />
        );
    };

    return (
        <Pane
            width={"100%"}
            position={"relative"}
            height={"100%"}
            id={`panel-${store.name}`}
            role="tabpanel"
            key={`panel-${store.name}`}
            aria-labelledby={store.name}
            aria-hidden={tabIndex !== selectedIndex}
            display={(tabIndex === selectedIndex) ? 'block' : 'none'}
        >
            {renderLoadingState()}
            {renderIframeOrErrorState()}
        </Pane>
    );
};
