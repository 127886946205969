import React, { ChangeEvent, useEffect } from "react";
import { TextareaField, TextInputField } from "evergreen-ui";
import { LabelledTooltip } from "../../common/LabelledTooltip";
import {
    INSTAGRAM_MAX_LENGTH,
    TIKTOK_MAX_LENGTH,
    TWITCH_MAX_LENGTH,
    TWITTER_MAX_LENGTH,
    YOUTUBE_MAX_LENGTH
} from "../../../../common/constants/InputFormFieldLengths";
import { Store } from "../../../../common/models";
import { LimitedTextInputField } from "../../common/LimitedTextInputField";
import { MultiColorPicker } from "../../common/MultiColorPicker";
import { useFormValidation } from "../../common/FormValidation";
import { DetailsPageSection, ContentPosition } from "../../common/DetailsPageSection";
import { areSocialsValid, areRequiredStoreFieldsProvided } from "../../product/Utils";
import { StoreTabInputState } from "./StoreTabInputState";

interface Props {
    disabled?: boolean;
    store: Store;
    onChange: (store: Store) => void;
    onFormStateChange: (storeTabInputState: StoreTabInputState) => void;
    contentPosition?: ContentPosition;
}

export const StoreSetupForm = ({
    disabled = false,
    store,
    onChange,
    onFormStateChange,
    contentPosition,
}: Props) => {
    const { invalidFields, onInvalidChange } = useFormValidation();

    const missingFieldsMessage = (
        <span className={"jc-dp-info-component-error"}>
            The store name, description, and <b>at least one</b> social media link are required.
        </span>
    );

    const invalidFieldsMessage = (
        <span className={"jc-dp-info-component-error"}>
            Please fix the invalid field(s) above.
        </span>
    );

    useEffect(() => {
        let infoComponent: React.ReactNode;
        const hasInvalidFields = invalidFields.length > 0;
        const missingRequiredFields = !areRequiredStoreFieldsProvided(store);

        // An invalid field error (i.e. a `LimitedTextInputField` char length is exceeded) takes
        // precedence over missing required fields, since the form cannot actually be
        // submittable for the invalid field error case, due to a database schema constraint.
        if (hasInvalidFields) {
            infoComponent = invalidFieldsMessage;
        } else if (missingRequiredFields) {
            infoComponent = missingFieldsMessage;
        }

        onFormStateChange({ infoComponent, missingRequiredFields, hasInvalidFields });
    }, [store, invalidFields]);

    const onChangeInternal = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;
        onFieldChange(name, value);
    };

    const onBrandColorChange = (brandColors: string[]) => {
        onFieldChange("brandColors", brandColors);
    };

    const onFieldChange = (key: string, value: string | number | string[]) => {
        onChange({
            ...store,
            [key]: value,
        });
    };

    return (
        <fieldset disabled={disabled}>
            <DetailsPageSection
                heading={"Details"}
                contentPosition={contentPosition}
            >
                <TextInputField
                    name={"name"}
                    label={"Store Name"}
                    value={store.name}
                    required={true}
                    isInvalid={!store.name}
                    maxWidth={"300px"}
                    onChange={onChangeInternal}
                />
                <TextareaField
                    name="about"
                    label="Description"
                    style={{ maxWidth: "100%", resize: "vertical" }}
                    placeholder={"Tell us about the store you're designing for."}
                    value={store.about || ""}
                    required={true}
                    isInvalid={!store.about}
                    onChange={onChangeInternal}
                />
                <TextInputField
                    name="demographic"
                    label={"Audience Demographics"}
                    placeholder={"How would you describe your fans or customers?"}
                    value={store.demographic || ""}
                    onChange={onChangeInternal}
                />
                <TextInputField
                    name="similarBrands"
                    label={(
                        <LabelledTooltip
                            label="Similar Stores/Brands"
                            description="What are some stores/brands you take inspiration from?"
                        />
                    )}
                    placeholder="Example: thehouseofdrew.com"
                    value={store.similarBrands || ""}
                    onChange={onChangeInternal}
                />
            </DetailsPageSection>
            <DetailsPageSection
                className={areSocialsValid(store) ? "" : "jc-dp-section-error"}
                heading={"Social Media *"}
                description={(
                    <p style={{ margin: "0 0 20px" }}>
                        Provide a username or a link to <b>at least one</b> social media account
                        that you manage. If none of these apply, then type <b>n/a</b> in any one of
                        the fields.
                    </p>
                )}
                contentPosition={contentPosition}
            >
                <LimitedTextInputField
                    maxChars={YOUTUBE_MAX_LENGTH}
                    onInvalidChange={onInvalidChange}
                    name="youtube"
                    label="YouTube"
                    placeholder="@username or https://www.youtube.com/user/channel_name"
                    value={store.youtube || ""}
                    onChange={onChangeInternal}
                />
                <LimitedTextInputField
                    maxChars={INSTAGRAM_MAX_LENGTH}
                    onInvalidChange={onInvalidChange}
                    name="instagram"
                    label="Instagram"
                    placeholder="@instagram_handle"
                    value={store.instagram || ""}
                    onChange={onChangeInternal}
                />
                <LimitedTextInputField
                    maxChars={TIKTOK_MAX_LENGTH}
                    onInvalidChange={onInvalidChange}
                    name="tiktok"
                    label="TikTok"
                    placeholder="@tiktok_handle"
                    value={store.tiktok || ""}
                    onChange={onChangeInternal}
                />
                <LimitedTextInputField
                    maxChars={TWITTER_MAX_LENGTH}
                    onInvalidChange={onInvalidChange}
                    name="twitter"
                    label="Twitter"
                    placeholder="@twitter_handle"
                    value={store.twitter || ""}
                    onChange={onChangeInternal}
                />
                <LimitedTextInputField
                    maxChars={TWITCH_MAX_LENGTH}
                    onInvalidChange={onInvalidChange}
                    name="twitch"
                    label="Twitch"
                    placeholder="@username"
                    value={store.twitch || ""}
                    onChange={onChangeInternal}
                />
            </DetailsPageSection>
            <DetailsPageSection
                heading={"Branding"}
                hideBorder={true}
                contentPosition={contentPosition}
            >
                <TextInputField
                    name="logoUrl"
                    label={(
                        <LabelledTooltip
                            label="Logo"
                            description="Used for your website and product tags"
                        />
                    )}
                    placeholder="Provide a link to any image assets"
                    value={store.logoUrl || ""}
                    onChange={onChangeInternal}
                />
                <MultiColorPicker
                    label="Store/Brand Colors"
                    description="Provide at least one color"
                    colors={store.brandColors || []}
                    onChange={onBrandColorChange}
                />
            </DetailsPageSection>
        </fieldset>
    );
};
