import React, {useEffect, useState} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {toaster} from "evergreen-ui";
import {productOnboardingHelper} from "../datastore/local/ProductOnboardingHelper";
import {ProductListing} from "../../../common/models";
import {CenteredSpinner} from "../common/CenteredSpinner";
import {ProductOnboardingSelection} from "../product/ProductOnboardingSelection";
import {useProductListingQuery, useUpdateProductListingMutation} from "../datastore/ProductListingDataStore";
import { NavigationHelper, PrevLocationState } from "../navigation/PrevLocationState";
import {categoryRequiresSizing} from "../product/Utils";
import {NewProductWizard, ProductWizardSequence} from "../product/NewProductWizard";
import {ProductWizardPage} from "../product/ProductWizardPage";

export const EditProductPage = () => {
    const history = useHistory();
    const {state: prevState} = useLocation<PrevLocationState>();
    const {productListingId} = useParams<{ productListingId: string }>();

    const {
        loading: queryLoading,
        error: queryError,
        data: queriedProductListing,
    } = useProductListingQuery(productListingId);

    const [
        updateProductListing,
        {
            loading: mutationLoading,
            called: mutationCalled,
            error: mutationError,
        }
    ] = useUpdateProductListingMutation();

    const [productListing, setProductListing] = useState<ProductListing | null>(null);
    const [productListingNotFound, setProductListingNotFound] = useState<boolean>(false);

    useEffect(() => {
        if (!queryLoading) {
            if (queryError) {
                setProductListingNotFound(true);
            } else {
                setProductListingNotFound(false);
                setProductListing(queriedProductListing);
            }
        }
    }, [queriedProductListing, queryLoading, queryError]);

    useEffect(() => {
        if (!mutationLoading && mutationCalled) {
            if (mutationError) {
                toaster.warning("Failed to update product selection. Try again");
            } else {
                toaster.success("Your Product Selection was updated!");
                productOnboardingHelper.reset();
                history.push(`/products/${productListingId}/setup`);
            }
        }
    }, [mutationError, mutationCalled, mutationLoading]);

    const getProductOnboardingSelection = () => {
        if (productListing) {
            const {pricing: {catalogOption}, addOns } = productListing;
            let productWizardPage =  ProductWizardPage.Options;

            let options = catalogOption.catalogProduct.catalogOptions.filter(item => !item.defaultOption);
            if (options.length === 1) {
                productWizardPage = ProductWizardPage.Products;
            }

            return {
                productWizardPage,
                addOns,
                catalogCategory: catalogOption.catalogProduct.catalogCategory,
                catalogProduct: catalogOption.catalogProduct,
                catalogOption
            };
        }
    };

    if (queryLoading || !productListing) {
        return <CenteredSpinner/>;
    }

    return (
        <NewProductWizard
            navBarOnClose={() => history.push(`/products/${productListingId}/setup`)}
            defaultProductOnboardingSelection={getProductOnboardingSelection()}
            productWizardSequence={ProductWizardSequence.EDIT_PRODUCT}
            onSubmit={(productOnboardingSelection: ProductOnboardingSelection) => {
                const categoryName = productOnboardingSelection.catalogCategory?.name ?? "";
                updateProductListing({
                    addOnIds: productOnboardingSelection.addOns?.map((addOn) => addOn.id),
                    productListing: {
                        ...productListing,
                        sizing: categoryRequiresSizing(categoryName) ? productListing.sizing : null
                    },
                    selectedCatalogOption: productOnboardingSelection.catalogOption,
                });
            }}
        />
    );
};
