import React, { FunctionComponent } from "react";
import { Redirect, RouteProps } from "react-router";
import { Route, useHistory, useLocation } from "react-router-dom";
import { isInternalEmail } from "../../../common/utils/isInternalEmail";
import {
    UserContextLoadingState,
    isUserContextProcessing,
    useUserContext
} from "../context/UserContext";
import { EmptyState } from "../common/EmptyState";
import { UserIcon } from "evergreen-ui";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { ClientType } from "../../../common/enums";
import { CLIENT_TYPE_ROUTES } from "./ClientTypeRoutes";
import { isNuxRequired } from "../../../common/utils/isNuxRequired";

type ConditionalRouteProps = {
    gatedComponentEnabled?: boolean;
    gatedComponent?: FunctionComponent<any>;
}

export const PrivateRoute: FunctionComponent<RouteProps & ConditionalRouteProps> = ({ path, component, gatedComponent, gatedComponentEnabled }) => {
    const history = useHistory();
    const location = useLocation();
    const { user, loadingState } = useUserContext();

    if (isUserContextProcessing(loadingState)) {
        return <CenteredSpinner />;
    }

    if (loadingState === UserContextLoadingState.Loaded) {

        // If a user hasn't accepted the legal agreement for whatever reason, we want to redirect them to the /new-user flow
        // Note that `!location.pathname.match("/new-user")` is to avoid an infinite render loop. caused by this redirect being in a PrivateRoute
        if (isNuxRequired(user) && !location.pathname.match("/new-user")) {
            return <Redirect to={{
                pathname: "/new-user/phone-number",
                state: {
                    from: location,
                    wasRedirected: true // We could use this to show a toast or something in the redirect case.
                }
            }} />
        }

        // Check for feature toggles
        if (gatedComponentEnabled) {
            return <Route path={path} component={gatedComponent} />;
        }

        // Check if the user has access to this route based on their client type
        if (user && !isInternalEmail(user.email) && user.clientTypes) {
            const userAllowedRoutes = user.clientTypes.flatMap(type => CLIENT_TYPE_ROUTES[type as ClientType] || []);
            const hasAccess = isSubPath(location.pathname, userAllowedRoutes);

            if (!hasAccess) {
                if (user.clientTypes.includes(ClientType.SelfServe)) {
                    return <Redirect to="/products" />;
                } else if (user.clientTypes.includes(ClientType.Partnership)) {
                    return <Redirect to="/analytics" />;
                } else {
                    return <Redirect to="/settings" />;
                }
            }
        }

        return <Route path={path} component={component} />;
    }

    if (loadingState === UserContextLoadingState.LoadingFailed) {
        return (
            <EmptyState
                title="Failed to initialize your account"
                subtitle="Click to re-attempt your login"
                onClickSubtitle={() => history.push("/logout")}
            >
                <UserIcon size={60} />
            </EmptyState>
        );
    }

    // loadingState === LoadingState.LoggedOut
    return <Redirect to={{
        pathname: "/login",
        state: { from: location }
    }} />;
};

const isSubPath = (path: string, allowedPaths: string[]): boolean => {
    return allowedPaths.some(allowedPath =>
        path === allowedPath || path.startsWith(`${allowedPath}/`)
    );
};
