import { majorScale } from "evergreen-ui";
import React from "react";

export const baseLabelStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "11px"
};

export const inputLabelStyle: React.CSSProperties = {
    ...baseLabelStyle,
    marginBottom: majorScale(1)
}
