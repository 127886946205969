import React, { FC, useEffect, useState } from "react";
import { ArrowRightIcon, Dialog, DuplicateIcon, GridIcon, Icon, Pane, TintIcon } from "evergreen-ui";
import { useMobileMediaQuery } from "../MediaQuery";
import { TextBadge } from "../TextBadge";
import { Catalog, CatalogItem, getCatalogItemType } from "../CatalogItem";
import { formatDollarRange, getPricing } from "../../product/PricingUtils";
import { ProductWizardPricing } from "../../product/ProductWizardPricing";
import { AddOn, CatalogOption } from "../../../../common/models";

import './styles/TileSelector.css';

export enum TileSelectorType {
    basic,
    apparel
}

export interface TileSelectorProps {
    id?: string;
    name: string;
    imageUrl?: string;
    description?: string;
    shortDescription?: string;
    selected: boolean;
    isPopular?: boolean;
    isNew?: boolean;
    isDefault?: boolean;
    isAddOn?: boolean;
    
    onSelected: () => void;
    catalogItem?: CatalogItem;
    addOn?: AddOn;
    tileSelectorType?: TileSelectorType;
}

export const TileSelector: FC<TileSelectorProps> = (props: TileSelectorProps) => {
    const { id, imageUrl, name, description, shortDescription, selected, onSelected, isPopular, isDefault, isNew, catalogItem, tileSelectorType = TileSelectorType.basic } = props;
    const [descriptionIsShowing, setDescriptionIsShowing] = useState<boolean>(false);
    const [unitCost, setUnitCost] = useState<string>();
    const isMobile = useMobileMediaQuery();

    const isProductOrOption = getCatalogItemType(catalogItem) === Catalog.PRODUCT || getCatalogItemType(catalogItem) === Catalog.OPTION;
    const isCategory = getCatalogItemType(catalogItem) === Catalog.CATEGORY;

    useEffect(() => {
        if (isProductOrOption) {
            setUnitCost(formatDollarRange(getPricing(catalogItem).map(({creatorCost}) => creatorCost)));
        } else {
            // In this case it's an add-on, so we set it to Free.
            setUnitCost("Free");
        }
    }, [catalogItem]);

    const renderShortDescription = () => {
        return (
            <div className="tile-selector-short-description">
                {shortDescription}
            </div>
        );
    };

    const renderDialog = () => {
        const title = (
            <div className="tile-selector-dialog-title">{name}</div>
        );

        return hasMoreInfo() && (
            <Dialog
                title={title}
                isShown={descriptionIsShowing}
                onCloseComplete={() => setDescriptionIsShowing(false)}
                hasFooter={false}
                preventBodyScrolling
                width={isMobile ? 350 : 400}
            >
                <div className="tile-selector-ctr">
                    {(tileSelectorType === TileSelectorType.apparel) ? (
                        <div>
                            <div className="jc-wizard-pricing-row-ctr">
                                <span className="jc-wizard-pricing-label">
                                    <Icon icon={TintIcon} size={12} />Apparel Printing
                                </span>
                                <span className="apparel-tier-metadata-value">
                                    {(catalogItem as CatalogOption).apparelPrinting}
                                </span>
                            </div>
                            <div className="jc-wizard-pricing-row-ctr">
                                <span className="jc-wizard-pricing-label">
                                    <Icon icon={GridIcon} size={12} />Fabric Colours
                                </span>
                                <span className="apparel-tier-metadata-value">
                                    {(catalogItem as CatalogOption).fabricColors}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="tile-selector-description"
                            dangerouslySetInnerHTML={{ __html: description ? description : "" }}
                        />
                    )}
                    <ProductWizardPricing
                        catalogItem={catalogItem}
                        isDetailed={true}
                    />
                </div>
            </Dialog>
        );
    };

    const renderTextBadge = (condition: boolean | undefined, text: string) => {
        return condition && (<TextBadge text={text} />)
    };

    const isBlank = (description: string | undefined) => {
        return !(description && description?.trim().length !== 0);
    }

    const hasMoreInfo = (): boolean => {
        return (tileSelectorType === TileSelectorType.apparel) || !isBlank(description);
    };

    const renderPricingAndInfo = () => !isCategory && (
        <div className="tile-selector-pricing-info">
            <div>
                <span className="cost-text">{unitCost}</span>
                {isProductOrOption && <span className="unit-text"> / Unit</span>}
            </div>
            {hasMoreInfo() && (
                <div>
                    <button className="tile-selector-more-info-button"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation();
                                setDescriptionIsShowing(true)
                            }}>
                        <span className="text">More Info</span>
                        <ArrowRightIcon size={14}></ArrowRightIcon>
                    </button>
                </div>
            )}
        </div>
    );

    return (
        <>
            <Pane
                height={(!isCategory) ? "366px" : "250px"}
                id={id}
                className={`tile-selector-container ${selected ? "selected" : ""}`}
                onClick={onSelected}
                position={"relative"}
            >
                {renderTextBadge(isPopular, "Popular") || renderTextBadge(isDefault, "Default") || renderTextBadge(isNew, "New")}
                <Pane
                    className="tile-selector-image"
                    height={(!isCategory) ? "55%" : "80%"}
                    style={{ backgroundImage: `url(${imageUrl}) , linear-gradient(rgba(0,0,0,0.03),rgba(0,0,0,0.03))` }}
                />
                <Pane>
                    <Pane
                        height={shortDescription ? "35%" : "20%"}
                        className="tile-selector-bottom">
                        <Pane className="tile-selector-radio-button" />
                        <Pane className="tile-selector-bottom-right">
                            <span>{name}</span>
                        </Pane>
                    </Pane>
                    <Pane>
                        {renderShortDescription()}
                    </Pane>
                    <Pane>
                        {renderPricingAndInfo()}
                    </Pane>
                </Pane>
            </Pane>
            {renderDialog()}
        </>
    )
};
