import { gql } from "@apollo/client";
import { jssaQuery } from "../datastore";

const TWILIO_ACCESS_TOKEN_QUERY = gql`
    query {
        twilioAccessToken
    }
`;

export const fetchAccessToken = async () => {
    const response = await jssaQuery<{ twilioAccessToken: string }>({query: TWILIO_ACCESS_TOKEN_QUERY});
    return response.data.twilioAccessToken;
}
