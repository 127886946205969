import { useEffect, useState } from "react";
import { Pane, SeriesSearchIcon } from "evergreen-ui";
import { EmptyState } from "../common/EmptyState";
import { useStoresWithPayoutsEnabledQuery } from "../datastore/StoreDataStore";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { Store } from "../../../common/models";
import { removeJcContentContainerStylesEffect } from "../common/removeJcContentContainerStylesEffect";
import { MultiTabView } from "../common/multitab/MultiTabView";

import './styles/Payouts.css';
import { PayoutsTab } from "../payouts/PayoutsTab";

export const Payouts = () => {
    const { data, loading } = useStoresWithPayoutsEnabledQuery();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [stores, setStores] = useState<Store[]>([]);

    useEffect(() => {
        if (!loading && data) {
            setStores(data|| []);
        }
        return () => {
            setStores([]);
        }
    }, [data, loading]);

    useEffect(() => removeJcContentContainerStylesEffect(), []);

    const renderHeader = (): JSX.Element => {
        return (
            <Pane className={"payouts-heading"}>
                <h3 className={"payouts-heading"}>
                    Payouts
                </h3>
            </Pane>
        );
    };

    if (!loading && stores.length === 0) {
        return (
            <EmptyState
                title="Coming Soon"
                subtitle="This page will become available once you've launched your products."
            >
                <SeriesSearchIcon size={60} />
            </EmptyState>
        );
    }

    if (loading) {
        return <CenteredSpinner />;
    }

    return (
        <MultiTabView
            header={renderHeader()}
            selectedTabIndex={selectedIndex}
            onTabSelected={setSelectedIndex}
            tabs={stores.map((store: Store, index: number) => {
                return {
                    label: store.name,
                    tabId: store.id,
                    useDefaultLayout: false,
                    component: (
                        <PayoutsTab
                            selectedIndex={selectedIndex}
                            tabIndex={index}
                            key={index}
                            store={store}
                        />
                    ),
                };
            })}
        />
    );
};
