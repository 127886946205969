import { FC } from "react";
import { RampartAuthorized } from "../../../rampart/RampartAuthorized";
import { PayoutRulesByStoreTableView } from "./context/PayoutRulesByStoreTableView";

interface StorePayoutRulesTabProps {
    storeId: string;
}

export const StorePayoutRulesTab: FC<StorePayoutRulesTabProps> = ({ storeId }) => {
    return (
        <RampartAuthorized requiredGroups={["juniper.self-serve.admin"]}>
            <PayoutRulesByStoreTableView storeId={storeId} />
        </RampartAuthorized>
    );
};
