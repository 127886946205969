import { StyledSelectMenuItem } from "./StyledSelectMenuItem";
import { EnumMapper, ResolvedEnum } from "./EnumMapper";

export abstract class EnumResolver<T> {
    public abstract resolveEnum(columnName: keyof T): EnumMapper;
    public resolve(columnName: keyof T): StyledSelectMenuItem[] {
        const columnEnum: ResolvedEnum = this.resolveEnum(columnName).map();
        return Object.keys(columnEnum).map((key: string) => columnEnum[key]);
    }
}
