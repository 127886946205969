import { CrossIcon, Heading, IconButton, Pane, Text } from "evergreen-ui";
import React, { FC } from "react";
import { ColorPalette } from "../context/Theme";
import { useGoHomeOrBack } from "./GoHomeOrBack";
import { useMobileMediaQuery } from "./MediaQuery";

export interface HeaderNavigationBarProps {
    breadcrumbs?: React.ReactNode[];
    logo?: React.ReactNode;
    contextTitle?: string;
    showStepCounter?: boolean;
    currentStepNumber?: number;
    pageTitle?: string;
    sidebarShown?: boolean;
    closeButtonShown?: boolean;
    onClose?: () => void;
}

export const HeaderNavigationBar: FC<HeaderNavigationBarProps> = ({
    breadcrumbs = [],
    logo,
    contextTitle,
    showStepCounter = true,
    currentStepNumber,
    pageTitle,
    sidebarShown = false,
    closeButtonShown = true,
    onClose
}) => {

    const isMobile = useMobileMediaQuery();
    const goHomeOrBack = useGoHomeOrBack();
    const onCloseHandler = onClose ? onClose : goHomeOrBack;

    return (
        !isMobile ? <Pane width="100%">
                <Pane className={`jc-wizard-navigation-bar ${sidebarShown ? "jc-wizard-sidebar-offset" : ""}`}>
                    <div className="jc-wizard-breadcrumbs-container">
                        { logo || <Pane paddingLeft={"24px"} /> }
                        <span className="jc-wizard-new-product-text">
                            <span> { contextTitle } </span>
                        </span>
                        { breadcrumbs }
                    </div>
                    { closeButtonShown && <IconButton
                        className={"jc-wizard-cancel-button"}
                        icon={CrossIcon}
                        appearance="minimal"
                        onClick={onCloseHandler}
                    /> }
                </Pane>
            </Pane> :
            <Pane width="100%">
                <Pane className={"jc-wizard-navigation-bar"}>
                    <div className="jc-wizard-breadcrumbs-container">
                        { closeButtonShown && <IconButton
                            className={"jc-wizard-cancel-button"}
                            icon={CrossIcon}
                            appearance="minimal"
                            onClick={onCloseHandler}
                        /> }
                        <Heading width={"100%"} paddingLeft={10}>
                            { pageTitle || contextTitle }
                        </Heading>
                        { showStepCounter && currentStepNumber && <Text
                            paddingRight={"24px"}
                            whiteSpace={"nowrap"}
                            fontWeight={"bold"}
                            size={300}
                            color={ColorPalette.disabledText}>
                                { `Step ${currentStepNumber} of ${breadcrumbs.length}` }
                        </Text> }
                    </div>
                </Pane>
            </Pane>
    );
};
