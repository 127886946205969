import { ColumnConfig } from "../config/ColumnConfig";
import { CSSProperties } from "react";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";

export function renderStickyCells<T extends TableDataWithId>(
    columns: ColumnConfig<T>[],
    renderCell: (column: ColumnConfig<T>, cellStyle: CSSProperties, stickyClassNames: string) => JSX.Element,
): JSX.Element[] {

    // To explain this function, it's best to use an example of a table with four columns:
    //      [
    //          {width: "100px", sticky: true},
    //          {width: "50px",  sticky: true},
    //          {width: "50px",  sticky: true},
    //          {width: "50px",  sticky: false},
    //      ]
    //
    // column[0] is sticky (left offset of 0px).
    // column[1] is sticky (left offset of 100px).
    // column[2] is sticky (left offset of 150px).
    // column[3] is not sticky. No left offset is needed because it uses the default CSS position.

    let runningOffset = 0;

    return columns.map((column: ColumnConfig<T>, index: number) => {
        const cellStyles: CSSProperties = {width: column.width};
        const stickyClassNameList: string[] = [];

        let currentOffset = 0;

        if (column.sticky) {
            // The current column is stuck to a specified left offset position.
            stickyClassNameList.push("sticky");

            // Set the current column's offset based on the running total.
            currentOffset = runningOffset;
            cellStyles.left = currentOffset;

            // Remove "px" units
            const width = parseInt(column.width);

            // Update the running total based on the current column's width.
            runningOffset += (isNaN(width) ? 0 : width);

            const isNextColumnSticky = columns[index + 1] && !columns[index + 1].sticky
            if (isNextColumnSticky) {
                // This is the last sticky column, so this className will apply a right border.
                stickyClassNameList.push("last");
            }
        }

        return renderCell(column, cellStyles, stickyClassNameList.join(" "));
    });
}
