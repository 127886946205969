import { FunctionComponent, useMemo } from "react";
import { ShareIcon, Table } from "evergreen-ui";
import { AbstractTableBodyCellRenderer } from "./AbstractTableBodyCellRenderer";
import { genericMemo } from "../../../GenericMemo";
import { TableBodyCellRendererInput } from "../input";
import { ColorPalette } from "../../../../context/Theme";

export class TableRedirectCellRenderer<T> extends AbstractTableBodyCellRenderer<T> {
    protected readonly redirectUrl: string;

    constructor(input: TableBodyCellRendererInput<T>, redirectUrl: string) {
        super(input);
        this.redirectUrl = redirectUrl;
    }

    protected getMemoizedComponent(): FunctionComponent {
        const {cellValue} = this.input;

        return genericMemo(() => {
            const rightView = useMemo(() => (
                <ShareIcon
                    color={ColorPalette.lightGreyText}
                    size={12}
                />
            ), []);

            return (
                <a className={"jc-body-cell-anchor"}
                   href={this.redirectUrl}
                   target={"_blank"}
                   rel="noreferrer"
                >
                    <Table.TextCell className={"jc-body-cell"} rightView={rightView}>
                        {cellValue}
                    </Table.TextCell>
                </a>
            );
        });
    }
}
