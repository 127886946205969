import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { SetupIntentResult, StripeError } from "@stripe/stripe-js";
import { Button, Pane } from "evergreen-ui";
import React, { useEffect, useCallback, useState } from "react";
import { Address } from "../../common/addresses/AddressFormFields";
import { ColorPalette } from "../../context/Theme";
import { useUserContext } from "../../context/UserContext";
import { createStripeSetupIntent } from "../index";

export enum CreditCardFormState {
    IDLE,
    PROCESSING,
    SUCCEEDED,
    FAILED,
}

export const useCreditCardFields = (
    billingAddress: Address,
    isBillingAddressComplete: () => boolean,
    useEmbeddedSubmit: boolean = false,
    onSubmitSuccess: () => void = () => {},
    onSubmitError: (error: Error|StripeError) => void = () => {}
) => {

    const [ formState, setFormState ] = useState<CreditCardFormState>(CreditCardFormState.IDLE);
    const [ errorMessage, setErrorMessage ] = useState<string|null>(null);

    const stripe = useStripe();
    const elements = useElements();
    const { user } = useUserContext();

    useEffect(() => {
        if (formState !== CreditCardFormState.FAILED) {
            setErrorMessage(null);
        }
    }, [formState]);

    const isFormProcessing = useCallback((): boolean => {
        return (formState === CreditCardFormState.PROCESSING);
    }, [formState]);

    const isFormDisabled = useCallback((): boolean => {
        return !stripe || !elements || isFormProcessing();
    }, [stripe, elements, isFormProcessing]);

    const isSubmitButtonDisabled = useCallback((): boolean => {
        return isFormDisabled() || !isBillingAddressComplete();
    }, [isFormDisabled, isBillingAddressComplete]);

    const onElementChange = () => setFormState(CreditCardFormState.IDLE);

    const submitBillingInfo = useCallback(() => {
        if (isFormDisabled()) {
            return;
        }

        const cardNumberElement = elements!.getElement(CardNumberElement);
        if (!cardNumberElement) {
            onSubmitError(new Error("Could not find card number element, are you sure it's mounted still?"));
            return;
        }

        setFormState(CreditCardFormState.PROCESSING);

        createStripeSetupIntent()
            .then((clientSecret: string) => {
                return stripe!.confirmCardSetup(clientSecret, {
                    payment_method: {
                        card: cardNumberElement,
                        billing_details: {
                            name: `${user?.firstName} ${user?.lastName}`,
                            email: user?.email,
                            address: {
                                line1: billingAddress.line1,
                                line2: billingAddress.line2,
                                city: billingAddress.city,
                                state: billingAddress.state,
                                country: billingAddress.country,
                                postal_code: billingAddress.postalCode,
                            }
                        }
                    }
                });
            })
            .then(({error}: SetupIntentResult) => {
                // The SetupIntent API resolves an error rather than rejects the promise.
                if (error) {
                    console.error(error);
                    setErrorMessage(error.message ?? "");
                    setFormState(CreditCardFormState.FAILED);
                    onSubmitError(error);
                } else {
                    setFormState(CreditCardFormState.SUCCEEDED);
                    onSubmitSuccess();
                }
            })
            .catch((error) => {
                console.error("Something went wrong. Contact your account manager.", error);
                setErrorMessage(error.message ?? "");
                setFormState(CreditCardFormState.FAILED);
                onSubmitError(error);
            });
    }, [
        billingAddress.city,
        billingAddress.country,
        billingAddress.line1,
        billingAddress.line2,
        billingAddress.postalCode,
        billingAddress.state,
        elements,
        stripe,
        isFormDisabled,
        onSubmitError,
        onSubmitSuccess,
        user?.email, 
        user?.firstName,
        user?.lastName
    ]);

    const renderCreditCardFields = useCallback(({hide, disabled}: {hide?: boolean, disabled?: boolean}) => {

        const options = {
            style: {
                base: {
                    fontSize: "12px",
                    color: ColorPalette.primaryText,
                    letterSpacing: "0.025em",
                    lineHeight: "2em",
                    padding: "8px",
                    fontFamily: "Inter, sans-serif",
                    "::placeholder": {
                        color: ColorPalette.disabledText,
                        fontSize: "12px",
                    }
                },
                invalid: {
                    color: "red",
                }
            }
        };

        const renderErrorMessage = () => {
            return (formState === CreditCardFormState.FAILED) && errorMessage ? (
                <span className="jc-cc-error">
                {errorMessage}
            </span>
            ) : null;
        };

        return(
            <Pane display={hide ? "none" : "initial"}>
                <h3>Credit Card</h3>
                { renderErrorMessage() }
                <div className="jc-cc-form">
                    <fieldset disabled={isFormDisabled() || disabled}>
                        <label>
                            Card number
                            <div>
                                <CardNumberElement
                                    onChange={onElementChange}
                                    options={options}
                                />
                            </div>
                        </label>
                        <div className="jc-cc-row">
                            <label className="jc-cc-expiry">
                                Expiration date
                                <CardExpiryElement
                                    onChange={onElementChange}
                                    options={options}
                                />
                            </label>
                            <div className="jc-cc-row-separator"/>
                            <label className="jc-cc-cvc">
                                CVC
                                <CardCvcElement
                                    onChange={onElementChange}
                                    options={options}
                                />
                            </label>
                        </div>
                     </fieldset>
                 </div>
                { useEmbeddedSubmit && <Button
                    width={120}
                    marginTop={10}
                    appearance="primary"
                    disabled={isSubmitButtonDisabled()}
                    isLoading={isFormProcessing()}
                    onClick={submitBillingInfo}
                >
                    Save
                </Button> }
            </Pane>
        );
    }, [
        isFormDisabled,
        useEmbeddedSubmit,
        isSubmitButtonDisabled,
        isFormProcessing,
        submitBillingInfo,
        formState,
        errorMessage
    ]);

    return {
        renderCreditCardFields,
        isFormProcessing,
        submitBillingInfo
    }
};