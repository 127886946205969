import { StripeError } from "@stripe/stripe-js";
import { Dialog, Pane, Paragraph, toaster } from "evergreen-ui";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { useMobileMediaQuery } from "../../common/MediaQuery";
import { useUserQuery } from "../../datastore/UserDataStore";
import { useBillingAddressFormFields } from "../../stripe/components/useBillingAddressFormFields";
import { useCreditCardFields } from "../../stripe/components/useCreditCardFields";
import { usePaymentMethods } from "../../stripe/components/usePaymentMethods";
import { StripeElementsProvider } from "../../stripe/StripeElementsProvider";
import { NewUserContainer, NewUserPage, NewUserState } from "./NewUserContainer";
import { NavigationHelper } from "../../navigation/PrevLocationState";

export const NewUserBillingPage = () => {
    return (
        <StripeElementsProvider>
            <NewUserBillingPageInternal />
        </StripeElementsProvider>
    );
}

const NewUserBillingPageInternal = () => {

    const history = useHistory<NewUserState>();
    const {
        phoneNumber,
        agreementAccepted
    } = history.location.state || {};

    const isMobile = useMobileMediaQuery();
    const {
        loading: queryLoading,
        error: queryError,
        data: user,
    } = useUserQuery();

    const {
        paymentMethods,
        paymentMethodsLoading,
        paymentMethodsError
    } = usePaymentMethods();

    const {
        renderBillingAddressFields,
        billingAddress,
        isBillingAddressComplete
    } = useBillingAddressFormFields();

    const {
        renderCreditCardFields,
        isFormProcessing,
        submitBillingInfo
    } = useCreditCardFields(billingAddress, isBillingAddressComplete, false, () => {
        toaster.success("Card successfully saved!");
        onContinuePastNux();
    }, (error: Error|StripeError) => {
        if (error.message) {
            toaster.danger(error.message);
        }
    });

    const [showSkipDialog, setShowSkipDialog] = useState<boolean>(false);

    const onContinuePastNux = () => {
        history.push(NavigationHelper.createNewUserFirstProductPath());
    }

    // Already has a payment method attached, we can just be done here.
    if (!paymentMethodsLoading && !paymentMethodsError && paymentMethods && paymentMethods.length > 0) {
        onContinuePastNux();
    }

    // If there is no phone number present, then we need to go back and get one from the user
    if (!queryLoading && user && !queryError) {
        if (!user.phoneNumber && !phoneNumber) {
            history.push("/new-user/phone-number", history.location.state);
        } else if (!user.agreementAcceptedDate && !agreementAccepted) {
            history.push("/new-user/agreement", history.location.state);
        }
    }

    if (queryLoading || paymentMethodsLoading) {
        return <CenteredSpinner />;
    }

    const renderSkipDialog = () => {
        return (
            <Pane>
                <Dialog
                    isShown={showSkipDialog}
                    title="Skip Billing Info?"
                    onCloseComplete={() => {
                        setShowSkipDialog(false);
                    }}
                    onConfirm={onContinuePastNux}
                    confirmLabel={"Skip Billing Info"}
                    topOffset={"auto"}
                    width={"460px"}
                >
                    <Paragraph>
                        If you don't add your billing info, you will be asked to add your billing info before
                        you can submit products. You can also add billing info from your billing settings.
                    </Paragraph>
                </Dialog>
            </Pane>
        );
    };

    return (
        <NewUserContainer
            page={NewUserPage.Billing}
            primaryButtonText={"Add Billing Info"}
            continueEnabled={isBillingAddressComplete()}
            isLoading={isFormProcessing()}
            onContinue={submitBillingInfo}
            onSkip={() => setShowSkipDialog(true)}
            infoComponent={
             <p>
                 You won't be charged until after you complete or cancel a product.
             </p>
            }
        >
            <Pane
                overflow={"auto"}
                height={"calc(100vh - 180px)"}
                width={"100%"}
                marginTop={isMobile ? "70px" : "80px"}
                marginBottom={"120px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Pane
                    height={"100%"}
                    maxWidth={"460px"}
                    padding={"16px"}
                    flexDirection={"column"}
                    alignItems={"left"}
                >
                    <Pane
                        paddingTop={isMobile ? "0" : "24px"}
                    >
                        <div className={"jc-wizard-header"}>
                            <h1 className={"jc-wizard-title"}>
                                Billing
                            </h1>
                            <span className={"jc-wizard-description"}>
                                You can enter your billing info to save time later. Billing info is
                                required to submit product info, but you won’t be charged until
                                after you complete or cancel a product.
                            </span>
                        </div>
                        { renderCreditCardFields({}) }
                        { renderBillingAddressFields({ disabled: isFormProcessing() }) }
                    </Pane>
                </Pane>
                { renderSkipDialog() }
            </Pane>
        </NewUserContainer>
    );
}
