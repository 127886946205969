import { TouchEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { vibrateIfSupported } from "./Vibrate";

export type LongPressEventHandlers = {
    onTouchStart: TouchEventHandler;
    onTouchEnd: TouchEventHandler;
    onTouchMove: TouchEventHandler;
};

/**
 * A React hook that handles long press gestures.
 *
 * @param onLongPress Callback that's triggered when the long press event is fired.
 * @param duration Threshold (in milliseconds) for the long press event.
 */
export const useLongPress = (onLongPress: () => void, duration: number = 300): LongPressEventHandlers => {
    const [longPressStarted, setLongPressStarted] = useState<boolean>(false);
    const timeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (longPressStarted) {
            timeout.current = setTimeout(() => {
                vibrateIfSupported();
                onLongPress();
            }, duration);
        } else if (timeout?.current) {
            clearTimeout(timeout.current);
        }
        return () => {
            if (timeout?.current) {
                clearTimeout(timeout.current);
            }
        }
    }, [onLongPress, duration, longPressStarted]);

    const started = useCallback((_: React.TouchEvent) => {
        setLongPressStarted(true);
    }, []);

    const stopped = useCallback((_: React.TouchEvent) => {
        setLongPressStarted(false);
    }, []);

    return {
        onTouchStart: started,
        onTouchEnd: stopped,
        onTouchMove: stopped,
    };
};
