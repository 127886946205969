import { generatePath } from "react-router-dom";
import { ProductListing, Store } from "../../../../../../common/models";
import { FloatingMenuButtonPopoverItems } from "../../../../common/FloatingMenuButton";
import { CellType } from "../../../../common/tables/config/CellType";
import { ColumnConfig } from "../../../../common/tables/config/ColumnConfig";
import { EnumResolver } from "../../../../common/tables/etc/EnumResolver";
import {
    TableBodyCellRendererInput,
    TableCellChangedHandler,
    TableCellFactory,
    TableCellHeaderChangedHandler,
} from "../../../../common/tables/factory";
import {
    ProductPreloadSingleSelectCellRenderer
} from "../../../../common/tables/factory/renderers/ProductPreloadSingleSelectCellRenderer";
import {
    TableFilterableHeaderCellRenderer
} from "../../../../common/tables/factory";
import { Sort } from "../../../../common/tables/state/TableProviderAPI";
import { StyledSelectMenuItem, SupportedColor } from "../../../../common/tables/etc/StyledSelectMenuItem";
import { ProductListingForAdmin } from "../../../../../../common/tables/product/ProductListingForAdmin";
import { EDIT_PRODUCT_PATH } from "../../../../navigation/Routes";
import {
    FilterExpression,
    FilterExpressionType,
    SortOrder
} from "../../../../../../common/query-filters";
import { AdminProductListEnumResolver } from "../../etc/AdminProductListEnumResolver";
import { queryStoresForAdmin } from "../../../../datastore/StoreDataStore";
import { StoreForAdmin } from "../../../../../../common/tables/store/StoreForAdmin";
import {
    TableSearchableSingleSelectCellRenderer
} from "../../../../common/tables/factory/renderers/TableSearchableSingleSelectCellRenderer";

type RendererInput = TableBodyCellRendererInput<ProductListingForAdmin>;

export class ProductTableCellFactory extends TableCellFactory<ProductListingForAdmin, ProductListing> {

    readonly enumResolver: EnumResolver<ProductListingForAdmin> = new AdminProductListEnumResolver();

    public renderHeaderCell(
        columnConfig: ColumnConfig<ProductListingForAdmin>,
        filterExpressions: FilterExpression<ProductListingForAdmin>[],
        sort: Sort<ProductListingForAdmin>,
        onCellChanged: TableCellHeaderChangedHandler<ProductListingForAdmin>,
    ): JSX.Element {
        if (columnConfig.columnName === "storeId") {
            return new TableFilterableHeaderCellRenderer<
                ProductListingForAdmin, StoreForAdmin, Store
            >(
                {columnConfig},
                filterExpressions,
                sort,
                onCellChanged,
                [],
                {
                    query: queryStoresForAdmin,
                    queryInput: {
                        expressions: [{
                            columnName: "name",
                            columnValue: [""],
                            type: FilterExpressionType.contains,
                            caseInsensitive: false,
                        }],
                        page: 0,
                        pageSize: 8,
                        sortOrder: SortOrder.ASCENDING,
                        sortColumn: "name"
                    },
                    resultsMap: (store: Store) => ({
                        label: store.name,
                        value: store.id,
                        isVerified: !!store.internalVendorName,
                    })
                }
            ).render();
        }

        return super.renderHeaderCell(columnConfig, filterExpressions, sort, onCellChanged);
    }

    public renderBodyCell(
        columnConfig: ColumnConfig<ProductListingForAdmin>,
        rowData: ProductListing,
        onCellChanged: TableCellChangedHandler<ProductListingForAdmin>,
    ): JSX.Element {
        if (columnConfig.columnName === "storeId") {
            const rendererInput: RendererInput = {
                columnConfig: columnConfig,
                cellValue: this.resolveCellData(columnConfig, rowData),
                id: rowData.id,
                onCellChanged: onCellChanged
            };
            return new TableSearchableSingleSelectCellRenderer<ProductListingForAdmin, StoreForAdmin, Store>(
                rendererInput,
                {
                    query: queryStoresForAdmin,
                    queryInput: {
                        expressions: [{
                            columnName: "name",
                            columnValue: [""],
                            type: FilterExpressionType.contains,
                            caseInsensitive: true,
                        }],
                            page: 0,
                                pageSize: 20,
                            sortOrder: SortOrder.ASCENDING,
                            sortColumn: "internalVendorName"
                    },
                    resultsMap: (store: Store) => ({
                        label: store.name,
                        value: store.id,
                        isVerified: !!store.internalVendorName,
                    }),
                    placeholderText: "Search for store name..."
                },
            ).render();
        }

        return super.renderBodyCell(columnConfig, rowData, onCellChanged);
    }

    protected resolveCellBadgeColor(
        columnConfig: ColumnConfig<ProductListingForAdmin>,
        productListing: ProductListing,
    ): SupportedColor {
        if (columnConfig.columnName === "storeId") {
            return;
        }

        return super.resolveCellBadgeColor(columnConfig, productListing);
    }

    protected resolveCellData(
        columnConfig: ColumnConfig<ProductListingForAdmin>,
        productListing: ProductListing,
    ): string {
        if (columnConfig.columnName === "storeId") {
            return productListing.store.name;
        }

        if (columnConfig.columnName === "productType") {
            return productListing.pricing.catalogOption.name ?? "";
        }

        if (columnConfig.columnName === "domain") {
            return productListing.store.domain ?? "";
        }

        return super.resolveCellData(columnConfig, productListing);
    }

    protected resolvePopoverCellMenuItems(rowData: ProductListing): FloatingMenuButtonPopoverItems[] {
        return [
            {
                label: "Product details",
                onSelect: () => {
                    this.openUrl(`/products/${rowData.id}`);
                },
            },
            {
                label: "Asana task",
                onSelect: () => {
                    this.openUrl(`/0/0/${rowData.asanaTaskId}`, "https://app.asana.com");
                },
                disabled: !rowData.asanaTaskId,  // No Asana task available.
            },
            {
                label: "Go to messages",
                onSelect: () => {
                    this.openUrl(`/messages/${rowData.twilioConversationId}`)
                },
                disabled: !rowData.twilioConversationId, // No conversation available.
            },
        ];
    }

    protected resolveRedirectUrl(
        columnConfig: ColumnConfig<ProductListingForAdmin>,
        productListing: ProductListing
    ): string {
        if (columnConfig.cellType !== CellType.redirect || columnConfig.columnName !== "productType") {
            return "";
        }

        return window.location.origin + generatePath(EDIT_PRODUCT_PATH, {
            productListingId: productListing.id,
        });
    }
}
