import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorIcon, toaster } from "evergreen-ui";
import { useStoreQuery, useUpdateStoreMutation } from "../../datastore/StoreDataStore";
import { Store } from "../../../../common/models";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { EmptyState, EmptyStateType } from "../../common/EmptyState";
import { FooterActionBar } from "../../common/FooterActionBar";
import { StoreSetupForm } from "./StoreSetupForm";
import { StoreTabInputState } from "./StoreTabInputState";

export const StoreSetupTab = () => {
    const { storeId } = useParams<{ storeId: string }>();

    const [store, setStore] = useState<Store | null>(null);
    const [lastSavedStore, setLastSavedStore] = useState<Store | null>(null);
    const [storeTabInputState, setStoreTabInputState] = useState<StoreTabInputState>({});
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

    const {
        loading: queryLoading,
        error: queryError,
        data: queriedData,
    } = useStoreQuery(storeId);

    const [
        updateStore,
        {
            loading: mutationLoading,
            error: mutationError,
            called: mutationCalled,
            data: mutatedStore,
        }
    ] = useUpdateStoreMutation();

    const onDiscardChanges = () => {
        if (hasUnsavedChanges) {
            setStore(lastSavedStore);
        }
    };

    const renderInfoComponent = (): React.ReactNode => {
        if (storeTabInputState.missingRequiredFields || storeTabInputState.hasInvalidFields) {
            return storeTabInputState.infoComponent;
        }
        if (hasUnsavedChanges) {
            return <p>You have unsaved changes.</p>;
        }
        return null;
    };

    useEffect(() => {
        setStore(queriedData);
        setLastSavedStore(queriedData);
    }, [queriedData]);

    useEffect(() => {
        setHasUnsavedChanges(JSON.stringify(lastSavedStore) !== JSON.stringify(store));
    }, [store, lastSavedStore]);

    useEffect(() => {
        if (mutatedStore) {
            // The data just finished mutating on the server, so the local store
            // states must be reset here, which will clear the unsaved changes state.
            setLastSavedStore(mutatedStore);
            setStore(mutatedStore);
        }
    }, [mutatedStore]);

    // TODO: refactor this into a reusable hook
    useEffect(() => {
        if (!mutationLoading && mutationCalled) {
            if (mutationError) {
                toaster.warning("Something went wrong. Try again");
            } else {
                toaster.success("Store info saved.", { duration: 2 });
            }
        }
        // eslint-disable-next-line
    }, [mutationLoading, mutationCalled, mutationError]);

    if (queryLoading) {
        return <CenteredSpinner />;
    }

    if (queryError || !store) {
        return (
            <EmptyState preset={EmptyStateType.notFound}>
                <ErrorIcon size={60} />
            </EmptyState>
        )
    }

    return (
        <>
            <StoreSetupForm
                disabled={mutationCalled && mutationLoading}
                store={store}
                onChange={setStore}
                onFormStateChange={setStoreTabInputState}
            />
            <FooterActionBar
                primaryButtonText={"Save"}
                primaryButtonDisabled={!hasUnsavedChanges || storeTabInputState.hasInvalidFields}
                isPrimaryLoading={mutationCalled && mutationLoading}
                onPrimaryClick={() => updateStore(store)}
                showSecondaryButton={false}
                infoComponent={renderInfoComponent()}
                tertiaryButtonText={hasUnsavedChanges ? "Discard Changes" : ""}
                onTertiaryClick={onDiscardChanges}
            />
        </>
    );
};
