import { BadgeProps } from "evergreen-ui";
import {
    DesignConceptState,
    PreDesignReviewState,
    ProductLifecycleStatus,
    SamplingState
} from "../../../../../common/enums";
import { ProductListing } from "../../../../../common/models";
import { inOrBeyondState } from "../../../../../common/utils/productListingStateHelpers";
import { MiniStatusBadge } from "../../../MiniStatusBadge";

interface Props extends BadgeProps {
    productListing: ProductListing;
}

const Badges: { [status: string]: BadgeProps } = {
    Draft: {
        color: "neutral",
        children: "Not Submitted",
    },
    PendingApproval: {
        color: "yellow",
        children: "Under Review",
    },
    New: {
        color: "yellow",
        children: "New",
    },
    Designing: {
        color: "blue",
        children: "Designing",
    },
    Sampling: {
        color: "blue",
        children: "Sampling",
    },
    Completed: {
        color: "green",
        children: "Completed"
    },
    Approved: {
        color: "green",
        children: "Approved",
    },
    Cancelled: {
        color: "red",
        children: "Cancelled",
    },
    Rejected: {
        color: "red",
        children: "Not Approved",
    }
};

export const ProductStageBadge = ({ productListing }: Props): JSX.Element => {
    const getBadgeProps = () => {
        const status: ProductLifecycleStatus | undefined = productListing.lifecycleStatus as ProductLifecycleStatus;
        if (status === ProductLifecycleStatus.Cancelled) {
            return Badges.Cancelled;
        }
        if (status === ProductLifecycleStatus.Draft) {
            return Badges.Draft;
        }
        if (status === ProductLifecycleStatus.Completed) {
            return Badges.Completed;
        }

        const preDesignState: PreDesignReviewState | undefined = productListing.preDesignReviewState as PreDesignReviewState;
        if (preDesignState === PreDesignReviewState.Submitted ||
            preDesignState === PreDesignReviewState.EditsRequired) {
            return Badges.PendingApproval;
        }
        if (preDesignState === PreDesignReviewState.Rejected) {
            return Badges.Rejected
        }

        const designConcept: DesignConceptState | undefined = productListing.designConceptState as DesignConceptState;
        const samplingState: SamplingState | undefined = productListing.samplingState as SamplingState;
        if (inOrBeyondState(SamplingState, samplingState, SamplingState.Physical_ClientApproved)) {
            return Badges.Approved;
        }
        if (inOrBeyondState(DesignConceptState, designConcept, DesignConceptState.ClientApproved) &&
            inOrBeyondState(SamplingState, samplingState, SamplingState.Sampling)) {
            return Badges.Sampling;
        }
        if (inOrBeyondState(DesignConceptState, designConcept, DesignConceptState.DesignerAssigned)) {
            return Badges.Designing;
        }

        return Badges.New;
    }

    return <MiniStatusBadge {...getBadgeProps()} />;
}
