import { useEffect, useState, RefObject } from "react";
import { RichTextareaHandle } from "rich-textarea";
import { usePersistentState } from "../../../common/state/usePersistentState";
import { useMessagingDataContext } from "../../state/NativeMessagingProvider";
import { Tag } from "../../../../../common/types/messaging/Tag";
import { SendResult } from "../../types";

type TaggedInputStateMethods = [
    string,
    Tag[],
    Tag[],
    (inputText: string) => void,
    (tag: Tag) => void,
    (text: string) => Promise<SendResult>
];

export const useTaggedInputState = (
    textAreaHandle: RefObject<RichTextareaHandle>,
    onSubmit: (text: string, tags?: Tag[]) => Promise<SendResult>,
    initialMessageContents?: string,
): TaggedInputStateMethods => {

    const { activeConversationSid, conversationTagManager } = useMessagingDataContext();
    const [ messageContents, setMessageContents ] = usePersistentState([activeConversationSid!, "draft", "message"], initialMessageContents).persistentState;
    const [ tagValues, setTagValues ] = useState<Tag[]>([]);
    const [ possibleTagValues, setPossibleTagValues ] = useState<Tag[]>([]);

    const getCurrentSelection = (): {start: number, end: number} | null => {
        if (textAreaHandle.current) {
            return {
                start: textAreaHandle.current.selectionStart,
                end: textAreaHandle.current.selectionEnd
            }
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (conversationTagManager && possibleTagValues.length === 0) {
            conversationTagManager.getAllPossibleTags().then((tags: Tag[]) => {
                setPossibleTagValues(tags);
            });
        }
    }, [conversationTagManager]);

    // Main effect, handles all the guts of typing
    useEffect(() => {
        const cursorIdx = getCurrentSelection();
        if (conversationTagManager && cursorIdx) {
            conversationTagManager.onInputChange(messageContents, cursorIdx, (tagValues: Tag[]): void => {
                setTagValues(tagValues);
            });
        }
    }, [messageContents]);

    const onInputChanged = (inputText: string): void => {
        setMessageContents(inputText);
    }

    const commitTag = (tag: Tag): void => {
        if (textAreaHandle.current) {
            // Our replacement text here is whatever the displayName is, plus a space character,
            // this is so after the autocomplete occurs there is a fresh space added and the conversation
            // text can proceed smoothly.
            textAreaHandle.current.setRangeText(
                `${tag.data.displayName} `,
                tag.pos.startIdx,
                textAreaHandle.current.selectionEnd,
                "end");
            textAreaHandle.current.focus();
        }

        if (conversationTagManager) {
            conversationTagManager.onTagCommitted(tag);
        }
    }

    const submitMessage = (text: string): Promise<SendResult> => {

        // We're going to normalize/commit the tags only on message send events
        let committedTags: Tag[] = [];
        if (possibleTagValues) {

            // We need to check for any possible tag available
            for (let i = 0; i < possibleTagValues.length; i++) {
                const currentMentionTag: Tag = possibleTagValues[i];

                // Each tag can technically be in a string multiple times
                let endIndex = 0;
                while (endIndex < text.length) {

                    // If we find an instance of it, we commit it and mark where we found it..
                    const startIndex = text.indexOf(currentMentionTag.data.displayName, endIndex);
                    if (startIndex === -1) {
                        break;
                    }

                    // ..while also looking for another possible instance
                    endIndex = startIndex + currentMentionTag.data.displayName.length;
                    committedTags.push({
                        ...currentMentionTag,
                        pos: {
                            startIdx: startIndex,
                            endIdx: endIndex
                        }
                    })

                }
            }

        }

        // Once we've sorted out tag commits, we can send the message
        return onSubmit(text, committedTags);
    }

    return [messageContents, tagValues, possibleTagValues, onInputChanged, commitTag, submitMessage];
}
