export const vibrateIfSupported = (pattern: number | number[] = 10) => {
    navigator.vibrate = navigator.vibrate ||
        (navigator as any).webkitVibrate ||
        (navigator as any).mozVibrate ||
        (navigator as any).msVibrate;

    if (navigator.vibrate) {
        navigator.vibrate(pattern);
    }
};
