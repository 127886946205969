import { Badge, OptionsListProps, Paragraph, SelectMenuItem, SelectMenuOption, SelectMenuProps } from "evergreen-ui";
import React from "react";
import { AbstractTableSingleSelectCellRenderer } from "./AbstractTableSingleSelectCellRenderer";
import { StyledSelectMenuItem } from "../../etc/StyledSelectMenuItem";

import "../../styles/TableSingleSelectCellRenderer.css";

export class TableSingleSelectCellRenderer<T> extends AbstractTableSingleSelectCellRenderer<T> {
    protected resolveSelectMenuProps(): Omit<SelectMenuProps, 'children'> {
        const {cellValue, columnConfig, onCellChanged, id} = this.input;

        return {
            hasTitle: false,
            closeOnSelect: true,
            selected: cellValue,
            options: this.options,
            onSelect: ({value}: SelectMenuItem) => {
                if (onCellChanged) {
                    onCellChanged(
                        id,
                        columnConfig.columnName as keyof T,
                        value,
                    );
                }
            },
            itemRenderer: (props: {
                key: SelectMenuOption['value']
                label: SelectMenuOption['label']
                item: SelectMenuOption
                style: object
                height: NonNullable<OptionsListProps['optionSize']>
                onSelect: () => void
                onDeselect: () => void
                isSelectable: boolean
                isSelected: boolean
                disabled: SelectMenuOption['disabled']
            }) => {
                return <Paragraph
                    className={"jc-single-select-enum-dropdown-row"}
                    onClick={props.onSelect}
                >
                    { props.label === "" ?
                        <>
                            { cellValue }
                        </> :
                        <Badge
                            textTransform={"none"}
                            fontWeight={"normal"}
                            borderRadius={8}
                            paddingX={8}
                            cursor={"pointer"}
                            flexShrink={0}
                            color={(props.item as StyledSelectMenuItem).color}
                        >
                            { props.label }
                        </Badge> }
                </Paragraph>

            }
        };
    }
}
