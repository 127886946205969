import { isDeepStrictEqual } from 'util';
import { NotificationCategoryPreference, NotificationPreference } from '../models';

export const toNotificationCategoryPreferences = (notificationPreferences: NotificationPreference[]): NotificationCategoryPreference[] => {
    return notificationPreferences.map((notificationPreference) => {
        return {
            category: notificationPreference.category,
            frequency: notificationPreference.frequency,
            sms: notificationPreference.sms,
            email: notificationPreference.email,
            push: notificationPreference.push
        }
    });
};

export const isEqualCategoryPreferences = (notificationPreferences: NotificationPreference[], otherNotificationPreference: NotificationPreference[]) => {
    return JSON.stringify(toNotificationCategoryPreferences(notificationPreferences)) 
        === JSON.stringify(toNotificationCategoryPreferences(otherNotificationPreference));
}