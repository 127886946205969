import { gql } from "@apollo/client";
import { TimelineStatus } from "../../../common/models/TimelineStatus";
import { productListingAllFieldsFragment, timelineStatusAllFieldsFragment } from "./CommonQueries";
import { CatalogOption, ProductListing } from "../../../common/models";
import {
    apolloClient,
    BaseMutationTuple,
    BaseQueryResult,
    jssaMutation,
    useBaseMutation,
    useBaseQuery
} from "./index";
import { cleanObject, toNumber } from "../../../common/utils";
import { ProductOnboardingSelection } from "../product/ProductOnboardingSelection";
import { DocumentNode } from "graphql";
import { ProductListingCreateInput } from "../../../common/inputs/ProductListingInput";

const productListingQuery = gql`
    ${productListingAllFieldsFragment}
    query productListing($id: String!) {
        productListing(id: $id) {
            ...ProductListingAllFields
        }
    }
`;

const timelineStatusQuery = gql`
    ${timelineStatusAllFieldsFragment}
    query timelineStatus($id: String!) {
        timelineStatus(id: $id) {
            ...TimelineStatusAllFields
        }
    }
`;

const addProductListingToNewStoreMutation = gql`
    ${productListingAllFieldsFragment}
    mutation addProductListingToNewStore($productData: ProductListingCreateInput!, $storeName: String!) {
        addProductListingToNewStore(productData: $productData, storeName: $storeName) {
            ...ProductListingAllFields
        }
    }
`;

const addProductListingToExistingStoreMutation = gql`
    ${productListingAllFieldsFragment}
    mutation addProductListingToExistingStore($productData: ProductListingCreateInput!, $storeId: String!) {
        addProductListingToExistingStore(productData: $productData, storeId: $storeId) {
            ...ProductListingAllFields
        }
    }
`;

const submitProductListingMutation = gql`
    mutation submitProductListing($productListingId: String!) {
        submitProductListing(productListingId: $productListingId)
    }
`;

const updateProductListingMutation = gql`
    ${productListingAllFieldsFragment}
    mutation updateProductListing($data: ProductListingUpdateInput!) {
        updateProductListing(data: $data) {
            ...ProductListingAllFields
        }
    }
`;

const removeProductListingMutation = gql`
    mutation deleteProductListing($id: String!) {
        deleteProductListing(id: $id)
    }
`;

export const useProductListingQuery = (id: string): BaseQueryResult<ProductListing> => {
    return useBaseQuery<ProductListing, { productListing: ProductListing }>(
        productListingQuery,
        {id},
        ({data}) => data.productListing,
    );
};

export const useTimelineStatusQuery = (id: string): BaseQueryResult<TimelineStatus> => {
    return useBaseQuery<TimelineStatus, { timelineStatus: TimelineStatus }>(
        timelineStatusQuery,
        {id},
        ({data}) => data.timelineStatus
    );
}

export const makeProductCreateInput = (productOnboardingSelection: ProductOnboardingSelection): ProductListingCreateInput => {
    const { catalogOption, newProductName, addOns } = productOnboardingSelection;
    if (!catalogOption || !newProductName) {
        throw new Error("CatalogOption and NewProductName fields must not be empty");
    }
    return {
        addOnIds: (addOns || []).map((addOn) => addOn.id),
        catalogOptionId: catalogOption.id,
        name: newProductName,
    };
};

export const addProduct = (
    productOnboardingSelection: ProductOnboardingSelection,
): Promise<ProductListing> => {
    const storeVariable: any = {};
    let responseKey: string;
    let mutation: DocumentNode;
    const { newStoreName, existingStoreId } = productOnboardingSelection;
    if (existingStoreId) {
        mutation = addProductListingToExistingStoreMutation;
        storeVariable.storeId = existingStoreId;
        responseKey = "addProductListingToExistingStore";
    } else {
        mutation = addProductListingToNewStoreMutation;
        storeVariable.storeName = newStoreName;
        responseKey = "addProductListingToNewStore";
    }
    return new Promise((resolve, reject) => {
        apolloClient.mutate({
            mutation,
            variables: {
                productData: makeProductCreateInput(productOnboardingSelection),
                ...storeVariable,
            }
        }).then(({ data: { [responseKey]: productListing } }) => {
            resolve(productListing);
        }).catch((error) => {
            reject(error);
        });
    });
};

type SubmitProductListingInput = {
    productListingId: string;
}

export const useSubmitProductMutation = (): BaseMutationTuple<SubmitProductListingInput, Boolean> => {
    return useBaseMutation<SubmitProductListingInput, Boolean>(
        submitProductListingMutation,
        (input: SubmitProductListingInput) => input,
        (output: any): Boolean => output.submitProduct
    );
};

export type UpdateProductListingInputType = {
    productListing: ProductListing,
    selectedCatalogOption?: CatalogOption,
    addOnIds?: string[],
    storeId?: string,
};
export type UpdateProductListingMutationTuple = [
    (input: UpdateProductListingInputType) => void,
    { loading: boolean, error?: any, called: boolean, data?: ProductListing | null }
];

export const useUpdateProductListingMutation = (): UpdateProductListingMutationTuple => {
    const transformInput = (input: UpdateProductListingInputType) => ({
        data: {
            ...cleanObject(input.productListing, {
                inclusions: [
                    "sizing"
                ],
                exclusions: [
                    "createdAt",
                    "customerStage",
                    "updatedAt",
                    "catalogOptionConfirmed",
                    "pricing",
                    "status",
                    "addOns",
                    "submissionDate",
                    "customerStageSheetId",
                    "asanaTaskId",
                    "storeId",
                    "store",
                    "twilioConversationId",
                ]
            }),
            addOnIds: input.addOnIds,
            catalogOptionId: input.selectedCatalogOption?.id,
            mediaAssets: input.productListing.mediaAssets.filter(asset => asset),
            storeId: input.storeId,
            shippingInfo: input.productListing.shippingInfo
        },
    });
    const transformOutput = (output: any): ProductListing => output.updateProductListing;
    return useBaseMutation<UpdateProductListingInputType, ProductListing>(
        updateProductListingMutation,
        transformInput,
        transformOutput,
    );
};

export const deleteProductListing = (productListingId: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        jssaMutation({
            mutation: removeProductListingMutation,
            variables: {
                id: productListingId,
            }
        }).then(({data: {deleteProductListing}}) => {
            resolve(deleteProductListing);
        }).catch((error) => {
            reject(error);
        });
    });
};
