import { toaster } from "evergreen-ui";
import { useState } from "react";
import { Store, TundraStorefront } from "../../../../common/models";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import {
    deleteTundraStorefront,
    useCreateCustomDomainMutation,
    useCreateStorefrontMutation,
    useGetStorefrontQuery,
    useValidateCustomDomainMutation
} from "../../datastore/TundraStorefrontDataStore";
import { StoreTundraAdminCreateView } from "./StoreTundraAdminCreateView";
import { StoreTundraAdminGetView } from "./StoreTundraAdminGetView";

export interface StoreTundraAdminPageProps {
    store: Store;
}

export const StoreTundraAdminPage = (props: StoreTundraAdminPageProps) => {

    const {
        store
    } = props;

    const {
        data: getStorefrontData,
        loading: getStorefrontLoading
    } = useGetStorefrontQuery(store.domain ?? "");

    const [
        createCustomDomain,
        {
            loading: createCustomDomainLoading,
            called: createCustomDomainCalled,
            data: createdCustomDomainData
        }
    ] = useCreateCustomDomainMutation();

    const [
        validateCustomDomain,
        {
            loading: validateCustomDomainLoading,
            data: validatedCustomDomainData
        }
    ] = useValidateCustomDomainMutation();

    const [
        createStorefront,
        {
            loading: createStorefrontLoading,
            called: createStorefrontCalled,
            data: createdStorefrontData
        }
    ] = useCreateStorefrontMutation();

    const [
        deletingStorefront,
        setDeletingStorefront
    ] = useState<boolean>(false);

    const deleteStorefront = async (domain: string): Promise<void> => {
        if (!window.confirm("Are you sure you want to delete this storefront? It will take some time to recreate it.")) {
            return; // Don't delete if the user selects cancel.
        }
        setDeletingStorefront(true);
        if (await deleteTundraStorefront(domain)) {
            window.location.reload(); // Let's just refresh this
        } else {
            toaster.danger("Failed to delete storefront - check logs for more information.");
        }
        setDeletingStorefront(false);
    };

    const renderStorefrontView = (storefront: TundraStorefront): JSX.Element => {
        return <StoreTundraAdminGetView
            storefront={storefront}
            createCustomDomain={createCustomDomain}
            createCustomDomainLoading={createCustomDomainLoading}
            createCustomDomainCalled={createCustomDomainCalled}
            validateCustomDomain={validateCustomDomain}
            validateCustomDomainLoading={validateCustomDomainLoading}
            deleteStorefront={deleteStorefront}
        />;
    };

    const renderCreateStorefrontView = (): JSX.Element => {
        return <StoreTundraAdminCreateView
            createStorefront={createStorefront}
            createStorefrontLoading={createStorefrontLoading}
            storeId={store.id}
        />;
    };

    if (getStorefrontLoading || deletingStorefront) {
        return <CenteredSpinner/>;
    }

    if (createStorefrontCalled && !createStorefrontLoading && !createdStorefrontData) {
        toaster.danger("Failed to create storefront - check inputs and try again.");
    }

    if (createdCustomDomainData && getStorefrontData &&
        createdCustomDomainData.domains.length === getStorefrontData.domains.length) {
        toaster.danger("Failed to create custom domain - check logs for more information.");
    }

    // Newly validated storefront
    if (validatedCustomDomainData) {
        return renderStorefrontView(validatedCustomDomainData);
    }

    // Newly created custom domain
    if (createdCustomDomainData) {
        return renderStorefrontView(createdCustomDomainData);
    }

    // Simple storefront view
    if (getStorefrontData) {
        return renderStorefrontView(getStorefrontData);
    }

    // Newly created storefront
    if (createdStorefrontData) {
        return renderStorefrontView(createdStorefrontData);
    }

    // Create storefront view
    return renderCreateStorefrontView();
};
