import React, {useContext, useEffect, useState} from "react";
import {useHistory } from "react-router-dom";
import {toaster} from "evergreen-ui";
import {ProductOnboardingSelection} from "../product/ProductOnboardingSelection";
import {addProduct} from "../datastore/ProductListingDataStore";
import {ProductListing} from "../../../common/models";
import {CenteredSpinner} from "../common/CenteredSpinner";
import {useUserContext} from "../context/UserContext";
import {NavigationHelper} from "../navigation/PrevLocationState";
import {analytics, EventName} from "../analytics";
import {StytchContext} from "../stytch/StytchContext";
import {NewProductWizard, ProductWizardSequence} from "../product/NewProductWizard";
import {ProductWizardPage} from "../product/ProductWizardPage";

export const NewProductCreation = () => {
    const history = useHistory();
    const { isAuthenticated } = useContext(StytchContext);
    const { fetchUser } = useUserContext();
    const [mutating, setMutating] = useState<boolean>(false);

    useEffect(() => {
        analytics.track(EventName.CreationStarted, {
            authenticated_bool: isAuthenticated,
        });
        // eslint-disable-next-line
    }, []);

    const initialPage = isAuthenticated ? ProductWizardPage.Categories : ProductWizardPage.StartCreating;
    const productWizardSequence = isAuthenticated ?  ProductWizardSequence.NEW_PRODUCT : ProductWizardSequence.SIGNUP_PRODUCT;

    return mutating ? (
        <CenteredSpinner />
    ) :
        (
            <NewProductWizard
                navBarOnClose={() => history.push("/products")}
                defaultProductOnboardingSelection={{
                    productWizardPage: initialPage
                }}
                productWizardSequence={productWizardSequence}
                onSubmit={(productOnboardingSelection: ProductOnboardingSelection) => {
                    if (!isAuthenticated) {
                        const {firstName, lastName, email} = productOnboardingSelection;
                        analytics.track(EventName.SignUpStarted);
                        const path: string = "/registration";
                        const params: URLSearchParams = new URLSearchParams();
                        params.set("isNewAccount", "true");
                        if (email) {
                            params.set("email", email);
                        }
                        if (firstName) {
                            params.set("firstName", firstName);
                        }
                        if (lastName) {
                            params.set("lastName", lastName);
                        }
                        history.push({pathname: path, search: params.toString()}, NavigationHelper.createForProductOnboarding());
                        return;
                    }

                    const successMessage = "Product was successfully created!";
                    const errorMessage = "Something went wrong. Please try again";

                    setMutating(true);
                    addProduct(productOnboardingSelection)
                        .then((productListing: ProductListing) => {
                            toaster.success(successMessage);
                            history.push(NavigationHelper.createProductPath(productListing.id, true));
                            fetchUser(/* forceRefresh= */ true);
                        })
                        .catch((error) => {
                            console.error(error);
                            analytics.sendException(error);
                            toaster.danger(errorMessage);
                            history.push(NavigationHelper.createNewProductPath());
                        });
                }}
            >
            </NewProductWizard>
        )
};
