import { PackagingState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class PackagingStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [PackagingState.DesignerPicked]: {
                label: "Designer Picked",
                value: PackagingState.DesignerPicked,
                color: "neutral"
            },
            [PackagingState.DesignerAssigned]: {
                label: "Designer Assigned",
                value: PackagingState.DesignerAssigned,
                color: "neutral"
            },
            [PackagingState.QuoteGiven]: {
                label: "Quote Given",
                value: PackagingState.QuoteGiven,
                color: "neutral"
            },
            [PackagingState.QuoteAccepted]: {
                label: "Quote Accepted",
                value: PackagingState.QuoteAccepted,
                color: "neutral"
            },
            [PackagingState.Designing]: {
                label: "Designing",
                value: PackagingState.Designing,
                color: "neutral"
            },
            [PackagingState.EditsRequired]: {
                label: "Edits Required",
                value: PackagingState.EditsRequired,
                color: "yellow"
            },
            [PackagingState.ClientApproved]: {
                label: "Client Approved",
                value: PackagingState.ClientApproved,
                color: "neutral"
            },
            [PackagingState.InternalApproved]: {
                label: "Internal Approved",
                value: PackagingState.InternalApproved,
                color: "green"
            },
            [PackagingState.NotRequired]: {
                label: "Not Required",
                value: PackagingState.NotRequired,
                color: "neutral"
            },
        };
    }
}