import { Table } from "evergreen-ui";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { AbstractTableBodyCellRenderer } from "./AbstractTableBodyCellRenderer";

import "react-datepicker/dist/react-datepicker.css";
import "../../styles/TableView.css";

export class TableDatePickerCellRenderer<T> extends AbstractTableBodyCellRenderer<T> {
    protected getMemoizedComponent(): React.FunctionComponent {
        const {
            columnConfig,
            cellValue,
            onCellChanged,
            id
        } = this.input;
        return React.memo(() => {
            let initialDate: Date | null;
            try {
                initialDate = cellValue === "" ? null : new Date(cellValue);
            } catch (e: any) {
                initialDate = null;
            }

            return (
                <Table.Cell>
                    <ReactDatePicker
                        className={"jc-date-picker-cell"}
                        selected={initialDate}
                        dateFormat={"MMM d, yyyy"}
                        onChange={(date: Date | null) => {
                            if (onCellChanged) {
                                onCellChanged(
                                    id,
                                    columnConfig.columnName as keyof T,
                                    date?.toISOString() ?? null,
                                );
                            }
                        }}
                        popperClassName={"jc-date-picker-popover"}
                        calendarClassName={"jc-date-picker-popover-calendar"}
                    />
                </Table.Cell>
            );
        });
    }
}