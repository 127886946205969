import { Media as TwilioMedia, Message as TwilioMessage } from "@twilio/conversations";
import { MessageAttributeSerializer } from "../../../../common/utils/MessageAttributeSerializer";
import { analytics, MessageEventName } from "../../analytics";
import { MediaMetadata, Message } from "../types";
import { AbstractDataAdapter } from "./AbstractDataAdapter";

export class MessageAdapter extends AbstractDataAdapter<TwilioMessage, Message> {
    async adapt(): Promise<Message> {
        const twilioMedia: Promise<MediaMetadata>[] = this.data.attachedMedia!.map(async (media: TwilioMedia): Promise<MediaMetadata> => {
            return {
                filename: media.filename,
                initialUrl: await media.getContentTemporaryUrl(),
                sid: media.sid,
                content_type: media.contentType,
                twilioMedia: media
            }
        });

        const resolvedTwilioMedia: MediaMetadata[] = await Promise.all(twilioMedia);

        return {
            sid: this.data.sid,
            participantSid: this.data.participantSid,
            body: this.data.body,
            author: this.data.author,
            timestamp: this.data.dateCreated,
            index: this.data.index,
            media: resolvedTwilioMedia,
            ref: this.data,
            attributes: MessageAttributeSerializer.deserialize(this.data.attributes, (e) => {
                analytics.heap_track(MessageEventName.MessageAttributeSerializationFail, { error: e.toString() });
            })
        }
    }
}