export class MoxtraAccessToken {
    private _accessToken: string;
    private _expiryTime: Date;

    constructor(accessToken: string, expiryTime: Date) {
        this._accessToken = accessToken;
        this._expiryTime = expiryTime;
    }

    get accessToken(): string {
        return this._accessToken;
    }

    get expiryTime(): Date {
        return this._expiryTime;
    }

    tokenExpired(): boolean {
        let currentTime = new Date();
        // We lie about the current time so that we can update the token 5 minutes
        // before it expires. This avoids the case where we return an access token
        // that is just about to expire, and then 
        currentTime.setMinutes(currentTime.getMinutes() + 5);
        if (currentTime > this._expiryTime) {
            return true;
        }
        return false;
    }
}
