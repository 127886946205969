import { SamplingState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class SamplingStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [SamplingState.Submitted]: {
                label: "Submitted",
                value: SamplingState.Submitted,
                color: "neutral"
            },
            [SamplingState.Sampling]: {
                label: "Sampling",
                value: SamplingState.Sampling,
                color: "neutral"
            },
            [SamplingState.EditsRequired]: {
                label: "Edits Required",
                value: SamplingState.EditsRequired,
                color: "yellow"
            },
            [SamplingState.Photos_InternalApproved]: {
                label: "Photos, Internal Approved",
                value: SamplingState.Photos_InternalApproved,
                color: "neutral"
            },
            [SamplingState.Photos_ClientApproved]: {
                label: "Photos, Client Approved",
                value: SamplingState.Photos_ClientApproved,
                color: "neutral"
            },
            [SamplingState.Shipped]: {
                label: "Shipped",
                value: SamplingState.Shipped,
                color: "neutral"
            },
            [SamplingState.Physical_ClientApproved]: {
                label: "Physical, Client Approved",
                value: SamplingState.Physical_ClientApproved,
                color: "green"
            }
        };
    }
}