import { Pane } from "evergreen-ui";
import { ChangeEvent } from "react";
import { NotificationPreference } from "../../../../../common/models";
import { CheckboxGroup } from "../../../common/CheckboxGroup";
import { createNotificationChannelDisplayOptions } from "../../../common/NotificationSettings";

import '../styles/MultiTabSettingsPage.css';

interface Props {
    title: string;
    notificationPreference: NotificationPreference;
    onChange: (notificationPreference: NotificationPreference) => void;
}

export const NotificationChannelSelector = ({ title, notificationPreference, onChange }: Props) => {
    return (
        <Pane marginBottom={"16px"}>
            <Pane className={"notification-preference-selector-heading"}>
                {title}
            </Pane>
            <CheckboxGroup
                className={"notification-preference-selector-contents"}
                checkboxes={createNotificationChannelDisplayOptions(notificationPreference)}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                    const {name, checked} = event.target;
                    onChange({
                        ...notificationPreference,
                        [name]: checked
                    })
                }}

            />
        </Pane>
    )
};