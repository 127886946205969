import { DocumentNode, gql } from "@apollo/client";
import { Participant } from "../native-messaging/types";
import { jssaMutation, jssaQuery } from "./index";

const participantsQuery: DocumentNode = gql`
    query conversationParticipants($twilioConversationId: String!) {
        conversationParticipants(twilioConversationId: $twilioConversationId) {
            firstName
            lastName
            role
            email
            displayRole
        }
    }
`

const addParticipantMutation = gql`
    mutation addParticipantToConversation($email: String!, $twilioConversationId: String!) {
        addParticipantToConversation(email: $email, twilioConversationId: $twilioConversationId) {
            firstName
            lastName
            email
            role
            displayRole
        }
    }
`

const removeParticipantMutation = gql`
    mutation removeParticipantFromConversation($email: String!, $twilioConversationId: String!) {
        removeParticipantFromConversation(email: $email, twilioConversationId: $twilioConversationId) {
            firstName
            lastName
            email
            role
            displayRole
        }
    }
`

const isConversationDisabledQuery = gql`
    query isConversationDisabled($twilioConversationId: String!) {
        isConversationDisabled(twilioConversationId: $twilioConversationId)
    }
`

export const getParticipants = (twilioConversationId: string): Promise<Participant[]> => {
    return new Promise<Participant[]>((resolve, reject) => {
        jssaQuery({
            query: participantsQuery,
            variables: {
                twilioConversationId
            }
        }).then(({data: {conversationParticipants}}) => {
            resolve(conversationParticipants);
        }).catch((error) => {
            reject(error);
        });
    });
};

export const addParticipant = (email: string, twilioConversationId: string): Promise<Participant> => {
    return new Promise<Participant>((resolve, reject) => {
        jssaMutation({
            mutation: addParticipantMutation,
            variables: {
                email,
                twilioConversationId
            }
        }).then(({data: {addParticipantToConversation}}) => {
            resolve(addParticipantToConversation);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const removeParticipant = (email: string, twilioConversationId: string): Promise<Participant> => {
    return new Promise<Participant>((resolve, reject) => {
        jssaMutation({
            mutation: removeParticipantMutation,
            variables: {
                email,
                twilioConversationId
            }
        }).then(({data: {removeParticipantFromConversation}}) => {
            resolve(removeParticipantFromConversation);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const isConversationDisabled = (twilioConversationId: string | null): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        if (twilioConversationId === null) {
            return resolve(false);
        }
        jssaQuery({
            query: isConversationDisabledQuery,
            variables: {
                twilioConversationId
            }
        }).then(({data: { isConversationDisabled }}) => {
            resolve(isConversationDisabled);
        }).catch((error) => {
            reject(error);
        });
    })
};

