import { Dialog, Pane, Paragraph } from "evergreen-ui";
import React, { useState } from "react";
import { useBillingAddressFormFields } from "../../../stripe/components/useBillingAddressFormFields";
import { useCreditCardFields } from "../../../stripe/components/useCreditCardFields";
import { StripeElementsProvider } from "../../../stripe/StripeElementsProvider";

export interface SetupTabSubmitDialogProps {
    isShown: boolean;
    onCloseComplete: () => void;
    isConfirmLoading: boolean;
    onConfirm: () => void;
    hasPaymentMethods: boolean;
}

enum SubmitDialogState {
    READY_TO_SUBMIT,
    NEEDS_ADDRESS,
    NEEDS_CREDIT_CARD
}

export const SetupTabSubmitDialog = (props: SetupTabSubmitDialogProps) => {
    return(
        <StripeElementsProvider>
            <SetupTabSubmitDialogInternal
                isShown={props.isShown}
                onCloseComplete={props.onCloseComplete}
                isConfirmLoading={props.isConfirmLoading}
                onConfirm={props.onConfirm}
                hasPaymentMethods={props.hasPaymentMethods}
            />
        </StripeElementsProvider>
    );
}

const SetupTabSubmitDialogInternal = (props: SetupTabSubmitDialogProps) => {

    const {
        isShown,
        onCloseComplete,
        isConfirmLoading,
        onConfirm,
        hasPaymentMethods
    } = props;

    const [ viewState, setViewState ] = useState<SubmitDialogState>(
        hasPaymentMethods ? SubmitDialogState.READY_TO_SUBMIT : SubmitDialogState.NEEDS_ADDRESS);

    const {
        renderBillingAddressFields,
        billingAddress,
        isBillingAddressComplete
    } = useBillingAddressFormFields();

    const {
        renderCreditCardFields,
        isFormProcessing,
        submitBillingInfo
    } = useCreditCardFields(billingAddress, isBillingAddressComplete, false, onConfirm);

    const transitionToAddress = () => {
        setViewState(SubmitDialogState.NEEDS_ADDRESS);
    }

    const transitionToCreditCard = () => {
        setViewState(SubmitDialogState.NEEDS_CREDIT_CARD);
    }

    const getTitle = (): string => {
        switch (viewState) {
            case SubmitDialogState.READY_TO_SUBMIT:
                return "Submit your Product 📝";
            case SubmitDialogState.NEEDS_ADDRESS:
            case SubmitDialogState.NEEDS_CREDIT_CARD:
                return "Billing Info Required";
            default:
                return "";
        }
    };

    const getConfirmLabel = (): string => {
        switch (viewState) {
            case SubmitDialogState.NEEDS_ADDRESS:
                return "Next Step"
            case SubmitDialogState.READY_TO_SUBMIT:
            case SubmitDialogState.NEEDS_CREDIT_CARD:
            default:
                return "Submit";
        }
    };

    const getCancelLabel = (): string => {
        switch (viewState) {
            case SubmitDialogState.NEEDS_CREDIT_CARD:
                return "Go Back";
            case SubmitDialogState.READY_TO_SUBMIT:
            case SubmitDialogState.NEEDS_ADDRESS:
            default:
                return "Cancel";
        }
    };

    const onConfirmInternal = (): void => {
        switch (viewState) {
            case SubmitDialogState.READY_TO_SUBMIT:
                return onConfirm();
            case SubmitDialogState.NEEDS_ADDRESS:
                return transitionToCreditCard();
            case SubmitDialogState.NEEDS_CREDIT_CARD:
                return submitBillingInfo();
            default:
                return onCloseCompleteInternal();
        }
    };

    const onCancelInternal = (): void => {
        switch (viewState) {
            case SubmitDialogState.NEEDS_CREDIT_CARD:
                return transitionToAddress();
            case SubmitDialogState.READY_TO_SUBMIT:
            case SubmitDialogState.NEEDS_ADDRESS:
            default:
                return onCloseCompleteInternal();
        }
    };

    const confirmDisabled = (): boolean => {
        switch (viewState) {
            case SubmitDialogState.READY_TO_SUBMIT:
                return false;
            case SubmitDialogState.NEEDS_CREDIT_CARD:
                return false;
            case SubmitDialogState.NEEDS_ADDRESS:
                return !isBillingAddressComplete();
            default:
                return true;
        }
    };

    const onCloseCompleteInternal = (): void => {
        onCloseComplete();
        setViewState(hasPaymentMethods ? SubmitDialogState.READY_TO_SUBMIT : SubmitDialogState.NEEDS_ADDRESS);
    }

    const getReadyToSubmitContent = () => {
        return(
            <>
                <Paragraph>
                    Our team will review the info within one business day before matching you
                    with a designer. You won't be able to modify the product selection(s) until
                    they've reviewed them, but you can still edit some of the text details.
                </Paragraph>
                <br />
                <Paragraph>
                    You will <b>not</b> be charged for any sampling or design fees until
                    your product has been completed. Your designer will provide a quote for
                    you to approve before proceeding.
                </Paragraph>
            </>
        );
    }

    const BillingInfoHeader = () => {
        return(
            <Paragraph paddingBottom={"20px"}>
                Please enter your billing info in order to submit your product!
                You won't be charged until after you complete or cancel a product.
            </Paragraph>
        );
    }

    const getCreditCardContent = () => {
        return(
            <>
                <BillingInfoHeader />
                { renderCreditCardFields({ hide: viewState === SubmitDialogState.NEEDS_ADDRESS }) }
                { renderBillingAddressFields({ hide: viewState === SubmitDialogState.NEEDS_CREDIT_CARD }) }
            </>
        );
    }

    return(
        <Pane>
            <Dialog
                topOffset={"auto"}
                isShown={isShown}
                title={getTitle()}
                hasClose={false}
                onCloseComplete={onCloseCompleteInternal}
                onCancel={onCancelInternal}
                cancelLabel={getCancelLabel()}
                isConfirmLoading={isConfirmLoading || isFormProcessing()}
                isConfirmDisabled={confirmDisabled()}
                onConfirm={onConfirmInternal}
                confirmLabel={getConfirmLabel()}
            >
                <>
                    { viewState === SubmitDialogState.READY_TO_SUBMIT && getReadyToSubmitContent() }
                    { viewState !== SubmitDialogState.READY_TO_SUBMIT && getCreditCardContent() }
                </>
            </Dialog>
        </Pane>
    );
}