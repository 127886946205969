import { TextInputField, TextInputFieldProps } from "evergreen-ui"
import { useEffect, useState } from "react";
import { ColorPalette } from "../context/Theme";

export interface Props extends TextInputFieldProps {
    name: string;
    maxChars: number;
    onInvalidChange: (name: string, invalid: boolean) => void;
    useEmailValidation?: boolean;
}

/* 99.9% of emails should be validated https://stackabuse.com/validate-email-addresses-with-regular-expressions-in-javascript/ */
const emailRegex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")" +
    "@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

export const LimitedTextInputField = (props: Props) => {
    const [isError, setIsError] = useState<boolean>(false);
    // Set if you go past the maxChars limit
    const [isTooLong, setIsTooLong] = useState<boolean>(false);
    // Set if a required field is empty
    // NOTE: For empty required fields, we only disable the submit button
    // but don't mark the field as invalid
    const [isMissing, setIsMissing] = useState<boolean>(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
    const {
        useEmailValidation,
        maxChars,
        onInvalidChange,
        name,
        value,
        isInvalid,
        validationMessage,
        required,
        display,
        ...rest
    } = props;

    useEffect(() => {
        setIsTooLong(!!value && typeof value === 'string' && value.length > maxChars);
        setIsMissing(!!required && typeof value === 'string' && value.length === 0);

        if (useEmailValidation) {
            setIsInvalidEmail(
                !!value && typeof value === 'string' && !emailRegex.test(value)
            )
        }

    }, [value, required, maxChars, useEmailValidation]);

    useEffect(() => {
        // Bit of a hack to purge the invalidFields list of this component if it is not being displayed
        // anymore. Would be nice to get this to happen on component unmounting, but it causes an
        // infinite loop
        if (display && display === "none") {
            onInvalidChange(name, false);
            return;
        }
        onInvalidChange(name, isInvalid || isTooLong || isMissing || isInvalidEmail);
        setIsError(isInvalid || isTooLong || isInvalidEmail);
    }, [isInvalid, isMissing, isTooLong, name, onInvalidChange, display, isInvalidEmail])

    const renderValidationMessage = () => {
        if (!isError) {
            return {
                validationMessage: validationMessage || undefined
            }
        }
        let msg: string = "";
        if (isTooLong) {
            msg = `This field needs to be under ${maxChars} characters length`;
        }

        if (isInvalidEmail) {
            msg = "Email is invalid";
        }

        return {
            validationMessage: msg || validationMessage,
            style: {backgroundColor: ColorPalette.inputFieldInvalid}
        };
    };

    return (
        <TextInputField
            name={name}
            value={value}
            isInvalid={isError}
            required={required}
            display={display}
            {...renderValidationMessage()}
            {...rest}
        />
    );
};
