import { majorScale, Pane } from "evergreen-ui";
import { ColorPickerInputField } from "./ColorPickerInputField";
import { nanoid } from "nanoid";
import { LabelledTooltip } from "./LabelledTooltip";
import { inputLabelStyle } from "./FormComponentStyles";
import "react-color-palette/lib/css/styles.css";

const MAX_COLORS = 5;

interface Props {
    label: string;
    description: string;
    colors: string[];
    onChange: (colors: string[]) => void;
}

export const MultiColorPicker = ({label, description, colors, onChange}: Props) => {
    const onAddColor = (newColor: string, _: number) => {
        const newColors = colors.map(color => color);
        newColors.push(newColor);
        onChange(newColors);
    };
    const onUpdateColor = (newColor: string, idx: number) => {
        const newColors = colors.map(color => color);
        newColors[idx] = newColor;
        onChange(newColors);
    };
    const onDeleteColor = (idx: number) => {
        const newColors = colors.map(color => color);
        newColors.splice(idx, 1);
        onChange(newColors);
    }

    const renderColorPickerInputFields = () => {
        let colorPickerInputFields = colors.map((color, idx) =>
            <ColorPickerInputField
                key={nanoid()}
                initColor={color}
                colorIdx={idx}
                onUpdateColor={onUpdateColor}
                onDeleteColor={onDeleteColor}
            />
        );
        if (colorPickerInputFields.length < MAX_COLORS) {
            colorPickerInputFields.push(
                <ColorPickerInputField
                    key={-1}
                    initColor={""}
                    colorIdx={colorPickerInputFields.length}
                    onUpdateColor={onAddColor}
                    onDeleteColor={onDeleteColor}
                />
            );
        }
        return colorPickerInputFields;
    }

    return (
        <Pane
            marginBottom={majorScale(4)}
        >
            <Pane style={inputLabelStyle}>
                <LabelledTooltip label={label} description={description}/>
            </Pane>
            {renderColorPickerInputFields()}
        </Pane>
    );
}
