import { User } from "../models";
import { ClientType } from "../enums";
import { isInternalEmail } from "./isInternalEmail";

export const isNuxRequired = (user?: User) => {
    // 0. Undefined user, so NUX isn't possible.
    if (!user) {
        return false;
    }

    // 1. The user is a part of a Partnerships store, so automatically bypass the NUX.
    if ((user.clientTypes ?? []).includes(ClientType.Partnership)) {
        return false;
    }

    // 2. The user is a Juniper employee, so bypass the NUX.
    if (isInternalEmail(user.email)) {
        return false;
    }

    // 3. The user is an ordinary user who has already completed the onboarding steps.
    if (user.phoneNumber && user.agreementAcceptedDate) {
        return false;
    }

    // 4. The user is an ordinary user who hasn't completed the onboarding steps.
    return true;
};
