import {
    CrossIcon,
    FullCircleIcon,
    IconButton,
    majorScale,
    Pane,
    Popover,
    Position,
    TextInput
} from "evergreen-ui";
import { ColorPicker, useColor } from "react-color-palette";
import { ColorPalette } from "../context/Theme";

interface Props {
    initColor: string;
    colorIdx: number;
    onUpdateColor: (newColor: string, idx: number) => void;
    onDeleteColor: (idx: number) => void;
};

export const ColorPickerInputField = ({initColor, colorIdx, onUpdateColor, onDeleteColor}: Props) => {
    const [color, setColor] = useColor("hex", initColor);

    const onChange = () => {
        onUpdateColor(color.hex, colorIdx);
    }

    const renderDeleteButton = () => {
        return initColor ? (
                <IconButton
                    icon={CrossIcon}
                    onClick={() => onDeleteColor(colorIdx)}
                />
            ) : null;
    }

    return (
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(1)}
        >
            <Popover
                position={Position.BOTTOM}
                onClose={onChange}
                content={
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column">
                        <ColorPicker
                            width={450}
                            height={250}
                            color={color}
                            onChange={setColor}
                            hideHSV
                            hideRGB
                        />
                    </Pane>
                }
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    width={"100%"}
                >
                    <FullCircleIcon
                        style={{
                            cursor: "pointer",
                            stroke: ColorPalette.inputBorderColor,
                            overflow: "visible"
                        }}
                        marginRight={majorScale(1)}
                        size={30}
                        color={initColor ? color.hex : ColorPalette.white}
                    />
                    <TextInput
                        style={{ cursor: "pointer" }}
                        placeholder={"Color code"}
                        readOnly
                        key={colorIdx}
                        value={initColor}
                        width={"100%"}
                    />
                </Pane>
            </Popover>
            {renderDeleteButton()}
        </Pane>
    );
};
