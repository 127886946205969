import { Pane, TextInputField } from "evergreen-ui";
import React, { ChangeEvent, FC, useState } from "react";
import {LimitedTextInputField} from "../../common/LimitedTextInputField";
import {
    EMAIL_MAX_LENGTH,
    FIRST_NAME_MAX_LENGTH,
    LAST_NAME_MAX_LENGTH
} from "../../../../common/constants/InputFormFieldLengths";
import {useFormValidation} from "../../common/FormValidation";

interface SignupPageInputProps {
    value: string;
    onChange: (value: string) => void;
}

interface SignupPageProps {
    firstNameProps: SignupPageInputProps;
    lastNameProps: SignupPageInputProps;
    emailProps: SignupPageInputProps;
    onInvalidChange: (name: string, invalid: boolean) => void;
}

// TODO: We should send an event every time they click continue and an email is added
export const SignupPage: FC<SignupPageProps> = ({
    firstNameProps,
    lastNameProps,
    emailProps,
    onInvalidChange
}) => {
    return (
        <div className={"jc-wizard-container-info-page"}>
            <div className={"jc-wizard-header"}>
                <h1 className={"jc-wizard-title green"}>
                    Create an account to continue.
                </h1>
                <span className={"jc-wizard-description"}>
                    We’ll email you a one-time code to sign in. Then you can finish setting up your product and submit it!
                </span>
            </div>
            <Pane>
                <LimitedTextInputField
                    inputHeight={50}
                    style={{
                        fontSize: 12
                    }}
                    maxChars={FIRST_NAME_MAX_LENGTH}
                    name="firstName"
                    label={"First Name"}
                    placeholder="Felix"
                    required
                    value={firstNameProps.value}
                    onChange={e => firstNameProps.onChange(e.target.value)}
                    onInvalidChange={onInvalidChange}
                    type="text"
                />
                <LimitedTextInputField
                    inputHeight={50}
                    style={{
                        fontSize: 12
                    }}
                    maxChars={LAST_NAME_MAX_LENGTH}
                    name="lastName"
                    label={"Last Name"}
                    placeholder="Kjellberg"
                    required
                    value={lastNameProps.value}
                    onChange={e => lastNameProps.onChange(e.target.value)}
                    onInvalidChange={onInvalidChange}
                    type="text"
                />
                <LimitedTextInputField
                    inputHeight={50}
                    style={{
                        fontSize: 12
                    }}
                    maxChars={EMAIL_MAX_LENGTH}
                    name="email"
                    label="Email Address"
                    placeholder="email@example.com"
                    required
                    value={emailProps.value}
                    onChange={e => emailProps.onChange(e.target.value.trim().toLowerCase())}
                    onInvalidChange={onInvalidChange}
                    type="email"
                    useEmailValidation={true}
                />
            </Pane>
        </div>
    )
}
