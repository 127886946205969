import { Pane, PaneProps } from "evergreen-ui";
import { NotificationPreference } from "../../../../../common/models";
import { ExpandableView } from "../../../common/ExpandableView";
import { NotificationPreferenceItem } from "./NotificationPreferenceItem";

type Props = {
    header: JSX.Element | string;
    subheading?: JSX.Element | string;

    notificationPreference: NotificationPreference;
    showChannelSelector?: boolean;
    showFrequencySelector?: boolean;
    paneProps?: PaneProps;
    expandableProps?: PaneProps;
    onChange: (notificationPreference: NotificationPreference) => void;
}

export const NotificationPreferenceContainer = ({
    header,
    subheading,
    notificationPreference,
    paneProps,
    expandableProps,
    showChannelSelector = true,
    showFrequencySelector = true,
    onChange
}: Props) => {
    return (
        <ExpandableView
            header={header}
            subheading={subheading}
            paneProps={expandableProps}
        >
            <Pane {...paneProps}>
                <NotificationPreferenceItem
                    notificationPreference={notificationPreference}
                    onChange={onChange}
                    showChannelSelector={showChannelSelector}
                    showFrequencySelector={showFrequencySelector}
                />
            </Pane>
        </ExpandableView>
    )
}