export enum FulfillmentType {
    unset = "unset",
    fulfilledByJuniper = "fulfilledByJuniper",
    fulfilledByCreator = "fulfilledByCreator"
}

export const FulfillmentTypeDisplayValues = {
    [FulfillmentType.unset]: "",
    [FulfillmentType.fulfilledByJuniper]: "Fulfilled by Juniper",
    [FulfillmentType.fulfilledByCreator]: "Fulfilled by Creator"
}