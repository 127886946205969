export enum ChatType {
    Product = "PRODUCT",
    Main = "MAIN"
};

export type ChatDescriptionsType = Partial<Record<ChatType, string>>;

export const ChatDescriptions: ChatDescriptionsType = {
    [ChatType.Product]: "Use this conversation to design, develop, and launch this specific product.",
    [ChatType.Main]: "Announcements, updates, and general conversations that aren't related to a specific product."
};