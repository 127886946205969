import { CatalogCategory, CatalogProduct, CatalogOption, AddOn } from "../../../common/models";

export type CatalogItem = CatalogCategory | CatalogProduct | CatalogOption;

export enum Catalog {
    UNDEFINED = 0,
    CATEGORY = 1,
    PRODUCT = 2,
    OPTION = 3,
    ADDON = 4
}

export const getCatalogItemType = (catalogItem: CatalogItem | AddOn | undefined) => {
    if (!catalogItem) {
        return Catalog.UNDEFINED;
    }

    if ('isDefault' in catalogItem) {
        return Catalog.ADDON;
    } else if ('catalogOptions' in catalogItem) {
        // item is a CatalogProduct
        return Catalog.PRODUCT;
    } else if ('pricing' in catalogItem) {
        // item is a CatalogOption
        return Catalog.OPTION;
    } else {
        // item is a CatalogCategory
        return Catalog.CATEGORY;
    }
}