export enum ManufacturingWorkstreamState {
    Invoice_ReadyToGenerate = "Invoice_ReadyToGenerate",
    Invoice_EditsRequired = "Invoice_EditsRequired",
    Invoice_Sent = "Invoice_Sent",
    Invoice_Paid = "Invoice_Paid",
    Manufacturing_ProductSizingAndDistribution = "Manufacturing_ProductSizingAndDistribution",
    Manufacturing_OrderSubmitted = "Manufacturing_OrderSubmitted",
    Manufacturing_InProgress = "Manufacturing_InProgress",
    Manufacturing_Completed = "Manufacturing_Completed",
}
