import { CatalogOption } from './CatalogOption';
import { CatalogCategory } from './CatalogCategory';
import { AddOnGroup } from './AddOnGroup';

// Temporary hack to indicate a ProductOption is custom without requiring server schema changes.
export const CUSTOM_PRODUCT_NAME: string = "Custom Product Option";
export interface CatalogProduct {
    id: number;
    name: string;
    displayName?: string;
    imageUrl?: string;
    description?: string;
    shortDescription?: string;
    catalogCategory: CatalogCategory;
    catalogOptions: CatalogOption[];
    addOnGroups: AddOnGroup[];
    isCustom: boolean;
    rank: number;
    isPopular: boolean;
    isNew: boolean;
}