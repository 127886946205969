import React, { ChangeEvent, FC, PropsWithChildren } from "react";
import { TableRowSideSheetForm } from "../../common/tables/view/TableRowSideSheetForm";
import { TextInputField } from "evergreen-ui";

interface StoreTableRowSideSheetFormProps {
    isShown: boolean;
    mutationLoading: boolean;
    mutationCalled: boolean;
    mutationError: boolean;
    input: { storeName: string };
    onInputChanged: (input: { storeName: string }) => void;
    onCancel: () => void;
    onSubmit: () => void;
}

export const StoreTableRowSideSheetForm: FC<StoreTableRowSideSheetFormProps> = ({
    isShown,
    mutationLoading,
    mutationCalled,
    mutationError,
    input,
    onInputChanged,
    onCancel,
    onSubmit,
}: PropsWithChildren<StoreTableRowSideSheetFormProps>) => {

    return (
        <TableRowSideSheetForm
            isShown={isShown}
            headerTitle={"Add Store"}
            mutationLoading={mutationLoading}
            mutationCalled={mutationCalled}
            mutationError={mutationError}
            canSubmit={!!input.storeName}
            onDismiss={onCancel}
            onSubmit={onSubmit}
        >
            <TextInputField
                name={"storeName"}
                label={"Store Name"}
                value={input.storeName || ""}
                required={false}
                maxWidth={"300px"}
                isInvalid={!input.storeName}
                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                    onInputChanged({ storeName: value });
                }}
            />
        </TableRowSideSheetForm>
    )
}
