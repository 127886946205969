import { FC } from "react";
import { Pane, PaneProps } from "evergreen-ui";
import { useMobileMediaQuery } from "./MediaQuery";
import { ColorPalette } from "../context/Theme";

interface Props extends PaneProps {
    heading?: JSX.Element | string;
    description?: JSX.Element | string;
    hideBorder?: boolean;
    contentPosition?: ContentPosition;
}

export enum ContentPosition {
    left = "left",
    center = "center",
}

export const DetailsPageSection: FC<Props> = ({
    heading,
    description,
    hideBorder = false,
    contentPosition = ContentPosition.left,
    children,
    ...paneProps
}: Props) => {
    const isMobile = useMobileMediaQuery();

    const renderHeading = (): JSX.Element | null => {
        if (!heading) {
            return null;
        }
        if (typeof heading === "string") {
            return (
                <h3 style={{marginTop: 0}}>
                    {heading}
                </h3>
            );
        }
        return heading;
    };

    const renderDescription = () => {
        if (!description) {
            return null;
        }
        if (typeof description === "string") {
            return (
                <div className={"jc-dp-description"}>
                    <p>{description}</p>
                </div>
            );
        }
        return description;
    };

    const getMarginProps = (): {marginX?: string, marginY?: string} => {
        switch (contentPosition) {
            case ContentPosition.center:
                return {marginX: "auto", marginY: "0"};
            case ContentPosition.left:
            default:
                return {};
        }
    };

    return (
        <Pane borderBottom={hideBorder ? "none" : `1px solid ${ColorPalette.borderColor}`}>
            <Pane
                paddingX={isMobile ? "24px" : "36px"}
                paddingY={"28px"}
                width={"100%"}
                maxWidth={"680px"}
                {...getMarginProps()}
                {...paneProps}
            >
                {renderHeading()}
                {renderDescription()}
                {children}
            </Pane>
        </Pane>
    );
};
