export const enum ProductWizardPage {
    Undefined = -1,
    StartCreating = 1,
    Categories = 2,
    Products = 3,
    Options = 4,
    AddOns = 5,
    ProductStoreInfo = 6,
    Signup = 7
}
