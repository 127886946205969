import { NotificationCategory, NotificationFrequency } from '../enums';
import { NotificationCategoryPreference } from '../models';

export const DEFAULT_NOTIFICATION_PREFERENCES = new Map<NotificationCategory, NotificationCategoryPreference>([
    [NotificationCategory.ConversationMessage, {
        category: NotificationCategory.ConversationMessage,
        frequency: NotificationFrequency.MessageTagged,
        sms: true,
        email: true,
        push: false
    }],
    [NotificationCategory.ProductDevUpdate, {
        category: NotificationCategory.ProductDevUpdate,
        frequency: NotificationFrequency.ProductDevAll,
        sms: true,
        email: true,
        push: false
    }],
    [NotificationCategory.ProductDevConfirmation, {
        category: NotificationCategory.ProductDevConfirmation,
        frequency: NotificationFrequency.ProductConfirmationAll,
        sms: false,
        email: true,
        push: false
    }],
    [NotificationCategory.StoreUpdate, {
        category: NotificationCategory.StoreUpdate,
        frequency: NotificationFrequency.StoreUpdateAll,
        sms: false,
        email: true,
        push: false
    }]
]);

// TODO: Continue to update this for new categories to set the right defaults.
export const getDefaultNotificationPreference = (
    category: NotificationCategory
): NotificationCategoryPreference => {
    const defaultNotificationPreference = DEFAULT_NOTIFICATION_PREFERENCES.get(category);
    if (defaultNotificationPreference) {
        return defaultNotificationPreference;
    } else {
        throw Error("No default NotificationPreference set for this NotificationCategory.");
    }
};