import { Checkbox, CheckboxProps, Pane, PaneProps } from "evergreen-ui";
import { ChangeEvent, useRef } from "react";

interface CheckboxGroupProps extends PaneProps {
    checkboxes: CheckboxProps[];
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxGroup = (props: CheckboxGroupProps) => {
    const {checkboxes, onChange, ...paneProps} = props;
    
    const checkboxGroup = useRef<HTMLDivElement>(null);
    
    return (
        <Pane ref={checkboxGroup} {...paneProps} >
            {checkboxes.map((checkBoxProps: CheckboxProps, index) => (
                <Checkbox checked={checkBoxProps.checked} key={index} onChange={onChange} {...checkBoxProps} />
            ))}
        </Pane>
    );
};