import { Pane } from "evergreen-ui";
import { BillingForm } from "../../../stripe";
import { StripeElementsProvider } from "../../../stripe/StripeElementsProvider";

export const BillingTab = () => {
    return (
        <Pane>
            <StripeElementsProvider>
                <BillingForm/>
            </StripeElementsProvider>
        </Pane>
    );
};
