import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

/**
 * useState<string> drop-in replacement backed persistently by Local Storage.
 * @param ids any number of ids that together make up a unique storage key
 * @param initialValue the initial value to set this hook to
 *
 * @returns
 *   getPersistentValue
 *     method to get the saved string from local storage
 *   persistentState
 *     [state, useState] just the same as returned via useState<string>();
 */
export const usePersistentState = (
    ids: string[],
    initialValue?: string
): {
    getPersistedValue: () => string,
    persistentState: [string, Dispatch<SetStateAction<string>>]
} => {

    if (ids.length === 0) {
        throw new Error("Cannot use persistent state without providing a storage key.");
    }

    const getPrefixedKey = (ids: string[]): string => "juniper." + ids.join(".");

    const prefixedKey: string = getPrefixedKey(ids);
    const existingValue: string | null = window ? window.localStorage.getItem(prefixedKey) : null;
    const [state, setState] = useState<string>(existingValue ? existingValue : initialValue ? initialValue : "");

    useEffect(() => {
        if (!window) {
            return;
        }

        if (state && state.length > 0) {
            window.localStorage.setItem(prefixedKey, state);
        } else {
            window.localStorage.removeItem(prefixedKey);
        }
    }, [state]);

    const getPersistedValue = useCallback((): string => {
        if (!window) {
            return "";
        }
        return window.localStorage.getItem(getPrefixedKey(ids)) ?? "";
    }, []);

    const persistentState = useMemo((): [string, Dispatch<SetStateAction<string>>] => {
        return [state, setState];
    }, [state, setState]);

    return {
        getPersistedValue: getPersistedValue,
        persistentState: persistentState,
    };
};
