import { gql } from "@apollo/client";
import {
    TundraStorefrontCreateCustomDomainInput,
    TundraStorefrontCreateFromExistingInput, TundraStorefrontValidateCustomDomainInput
} from "../../../common/inputs/TundraStorefrontInput";
import { TundraStorefront } from "../../../common/models";
import { tundraStorefrontAllFieldsFragment } from "./CommonQueries";
import {
    BaseMutationTuple,
    BaseQueryResult,
    jssaMutation,
    useBaseMutation,
    useBaseQuery
} from "./index";

const createStorefrontMutation = gql`
    ${tundraStorefrontAllFieldsFragment}
    mutation createStorefront($input: TundraStorefrontCreateFromExistingInput!) {
        createStorefrontFromExisting(input: $input) {
            ...TundraStorefrontAllFields
        }
    }
`;

export const useCreateStorefrontMutation = (): BaseMutationTuple<TundraStorefrontCreateFromExistingInput, TundraStorefront> => {
    return useBaseMutation<TundraStorefrontCreateFromExistingInput, TundraStorefront>(
        createStorefrontMutation,
        (input: TundraStorefrontCreateFromExistingInput) => {
            return {
                input: input
            }
        },
        (data) => data.createStorefrontFromExisting
    );
};

const createCustomDomainMutation = gql`
    ${tundraStorefrontAllFieldsFragment}
    mutation createCustomDomain($input: TundraStorefrontCreateCustomDomainInput!) {
        createCustomDomain(input: $input) {
            ...TundraStorefrontAllFields
        }
    }
`;

export const useCreateCustomDomainMutation = (): BaseMutationTuple<TundraStorefrontCreateCustomDomainInput, TundraStorefront> => {
    return useBaseMutation<TundraStorefrontCreateCustomDomainInput, TundraStorefront>(
        createCustomDomainMutation,
        (input: TundraStorefrontCreateCustomDomainInput) => {
            return {
                input: input
            }
        },
        (data) => data.createCustomDomain
    );
};

const validateCustomDomainMutation = gql`
    ${tundraStorefrontAllFieldsFragment}
    mutation validateCustomDomain($input: TundraStorefrontValidateCustomDomainInput!) {
        validateCustomDomain(input: $input) {
            ...TundraStorefrontAllFields
        }
    }
`;

export const useValidateCustomDomainMutation = (): BaseMutationTuple<TundraStorefrontValidateCustomDomainInput, TundraStorefront> => {
    return useBaseMutation<TundraStorefrontValidateCustomDomainInput, TundraStorefront>(
        validateCustomDomainMutation,
        (input: TundraStorefrontValidateCustomDomainInput) => {
            return {
                input: input
            }
        },
        (data) => data.validateCustomDomain
    );
};

const getStorefrontQuery = gql`
    ${tundraStorefrontAllFieldsFragment}
    query getStorefront($domain: String!) {
        getStorefront(domain: $domain) {
            ...TundraStorefrontAllFields
        }
    }
`;

export const useGetStorefrontQuery = (domain: string): BaseQueryResult<TundraStorefront> => {
    return useBaseQuery<TundraStorefront, { getStorefront: TundraStorefront }>(
        getStorefrontQuery,
        {domain},
        ({data}) => data.getStorefront
    );
};

const deleteStorefrontMutation = gql`
    mutation deleteStorefront($domain: String!) {
        deleteStorefront(domain: $domain)
    }
`;

export const deleteTundraStorefront = (domain: string): Promise<boolean> => {
    return new Promise((resolve, reject): void => {
        jssaMutation({
            mutation: deleteStorefrontMutation,
            variables: {
                domain: domain
            }
        }).then(({data: { deleteStorefront }}): void => {
            resolve(deleteStorefront);
        }).catch((error): void => {
            reject(error);
        });
    });
};
