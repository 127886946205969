import { StoreWorkstreamState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class StoreWorkstreamStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [StoreWorkstreamState.Banner_DesignerPicked] : {
                label: "Banner, Designer Picked",
                value: StoreWorkstreamState.Banner_DesignerPicked,
                color: "neutral"
            },
            [StoreWorkstreamState.Banner_DesignerAssigned] : {
                label: "Banner, Designer Assigned",
                value: StoreWorkstreamState.Banner_DesignerAssigned,
                color: "neutral"
            },
            [StoreWorkstreamState.Banner_QuoteGiven] : {
                label: "Banner, Quote Given",
                value: StoreWorkstreamState.Banner_QuoteGiven,
                color: "neutral"
            },
            [StoreWorkstreamState.Banner_QuoteAccepted] : {
                label: "Banner, Quote Accepted",
                value: StoreWorkstreamState.Banner_QuoteAccepted,
                color: "neutral"
            },
            [StoreWorkstreamState.Banner_Designing] : {
                label: "Banner, Designing",
                value: StoreWorkstreamState.Banner_Designing,
                color: "neutral"
            },
            [StoreWorkstreamState.Banner_EditsRequired] : {
                label: "Banner, Edits Required",
                value: StoreWorkstreamState.Banner_EditsRequired,
                color: "neutral"
            },
            [StoreWorkstreamState.Banner_ClientApproved] : {
                label: "Banner, Client Approved",
                value: StoreWorkstreamState.Banner_ClientApproved,
                color: "neutral"
            },
            [StoreWorkstreamState.Banner_Uploaded] : {
                label: "Banner, Uploaded",
                value: StoreWorkstreamState.Banner_Uploaded,
                color: "neutral"
            },
            [StoreWorkstreamState.Domain_ProductCollectionCreated] : {
                label: "Domain, Product Collection Created",
                value: StoreWorkstreamState.Domain_ProductCollectionCreated,
                color: "neutral"
            },
            [StoreWorkstreamState.Domain_Requested] : {
                label: "Domain, Requested",
                value: StoreWorkstreamState.Domain_Requested,
                color: "neutral"
            },
            [StoreWorkstreamState.Domain_Setup] : {
                label: "Domain, Setup",
                value: StoreWorkstreamState.Domain_Setup,
                color: "neutral"
            },
            [StoreWorkstreamState.Dashboard_Setup] : {
                label: "Dashboard, Setup",
                value: StoreWorkstreamState.Dashboard_Setup,
                color: "green"
            }
        };
    }
}