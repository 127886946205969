import { BuildIcon, CleanIcon, CogIcon, DollarIcon, Icon, Pane, TagIcon } from "evergreen-ui";
import { FC, useEffect, useState } from "react";
import { Pricing } from "../../../common/models";
import { CatalogItem } from "../common/CatalogItem";
import { useMobileMediaQuery } from "../common/MediaQuery";
import { formatDollarRange, formatUnitRange, getPricing } from "./PricingUtils";

export interface ProductWizardPricingProps {
    catalogItem?: CatalogItem;
    isDetailed?: boolean;
}

export const ProductWizardPricing: FC<ProductWizardPricingProps> = ({
    catalogItem,
    isDetailed = false
}) => {
    const [msrp, setMsrp] = useState<string | null>(null);
    const [moq, setMoq] = useState<string | null>(null);
    const [profit, setProfit] = useState<string | null>(null);
    const [creatorCost, setCreatorCost] = useState<string | null>(null);
    const [sampleFee, setSampleFee] = useState<string | null>(null);
    const isMobile = useMobileMediaQuery();

    useEffect(() => {
        const setPricingValues = (pricingList: Pricing[]) => {
            if (pricingList.length === 0) {
                setMsrp(null);
                setMoq(null);
                setProfit(null);
                setCreatorCost(null);
                setSampleFee(null);
                return;
            }

            setMsrp(formatDollarRange(pricingList.map(({msrp}) => msrp)));
            setMoq(formatUnitRange(pricingList.map(({moq}) => moq)));
            setProfit(formatDollarRange(pricingList.map(({msrp, creatorCost}) => msrp - creatorCost)));
            setCreatorCost(formatDollarRange(pricingList.map(({creatorCost}) => creatorCost)));
            setSampleFee(formatDollarRange(pricingList.map(({sampleFee}) => sampleFee)));
        };

        setPricingValues(getPricing(catalogItem));
    }, [catalogItem, isDetailed]);

    const renderDetailedView = () => {
        return (
            <>
                <div className="jc-wizard-pricing-row-ctr">
                    <span className="jc-wizard-pricing-label">
                        <Icon icon={DollarIcon} size={12} />{isMobile ? "Unit Cost" : "Unit Cost"}
                    </span>
                    <span>{creatorCost}</span>
                </div>
                <div className="jc-wizard-pricing-row-ctr">
                    <span className="jc-wizard-pricing-label">
                        <Icon icon={TagIcon} size={12} />{isMobile ? "Sug. Retail" : "Suggested Retail"}
                    </span>
                    <span>{msrp}</span>
                </div>
                <div className="jc-wizard-pricing-row-ctr">
                    <span className="jc-wizard-pricing-label">
                        <Icon icon={CleanIcon} size={12} />{isMobile ? "Profit/Unit" : "Your Profit per Unit"}
                    </span>
                    <span>{profit}</span>
                </div>
                <div className="jc-wizard-pricing-row-ctr">
                    <span className="jc-wizard-pricing-label">
                        <Icon icon={BuildIcon} size={12} />{isMobile ? "Sample Cost" : "Sample Cost"}
                    </span>
                    <span>{sampleFee}</span>
                </div>
                <div className="jc-wizard-pricing-row-ctr">
                    <span className="jc-wizard-pricing-label">
                        <Icon icon={CogIcon} size={12} />{isMobile ? "Min. Order" : "Minimum Order"}
                    </span>
                    <span>{moq}</span>
                </div>
            </>
        )
    };

    const renderShortView = () => {
        return (
            <>
                <div className="jc-wizard-pricing-row-ctr">
                    <span className="jc-wizard-pricing-label">
                        <Icon icon={DollarIcon} size={12} />{isMobile ? "Unit Cost" : "Unit Cost"}
                    </span>
                    <span>{creatorCost}</span>
                </div>
                <div className="jc-wizard-pricing-row-ctr">
                    <span className="jc-wizard-pricing-label">
                        <Icon icon={CogIcon} size={12} />{isMobile ? "Min. Order" : "Minimum Order"}
                    </span>
                    <span>{moq}</span>
                </div>
            </>
        )
    }
    return (
        <Pane className="jc-wizard-pricing">
            {catalogItem && moq && profit ?
                isDetailed ? renderDetailedView() : renderShortView()
                : null}
        </Pane>
    );
}