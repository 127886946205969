import React, { ChangeEvent, useEffect } from "react";
import { Checkbox, TextInputField } from "evergreen-ui";
import { ContentPosition, DetailsPageSection } from "../../common/DetailsPageSection";
import { useFormValidation } from "../../common/FormValidation";
import { Store } from "../../../../common/models";
import { StoreTabInputState } from "./StoreTabInputState";

interface Props {
    disabled?: boolean;
    store: Store;
    onChange: (store: Store) => void;
    onFormStateChange: (storeTabInputState: StoreTabInputState) => void;
    contentPosition?: ContentPosition;
}

export const StoreAdminForm = ({
    disabled = false,
    store,
    onChange,
    onFormStateChange,
    contentPosition,
}: Props) => {
    const { invalidFields } = useFormValidation();

    const invalidFieldsMessage = (
        <span className={"jc-dp-info-component-error"}>
            Please fix the invalid field(s) above.
        </span>
    );

    useEffect(() => {
        let infoComponent: React.ReactNode;
        const hasInvalidFields = invalidFields.length > 0;

        // An invalid field error (i.e. a `LimitedTextInputField` char length is exceeded) takes
        // precedence over missing required fields, since the form cannot actually be
        // submittable for the invalid field error case, due to a database schema constraint.
        if (hasInvalidFields) {
            infoComponent = invalidFieldsMessage;
        }

        onFormStateChange({ infoComponent, hasInvalidFields });
    }, [store, invalidFields]);

    const onChangeInternal = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;
        onFieldChange(name, value);
    };

    const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        onFieldChange(name, checked);
    };

    const onFieldChange = (key: string, value: string | number | string[] | boolean) => {
        onChange({
            ...store,
            [key]: value,
        });
    };

    return (
        <fieldset disabled={disabled}>
            <DetailsPageSection
                contentPosition={contentPosition}
                heading={"Admin"}
            >
                <TextInputField
                    name={"internalVendorName"}
                    label={"Vendor Name"}
                    value={store.internalVendorName || ""}
                    required={false}
                    maxWidth={"300px"}
                    onChange={onChangeInternal}
                />
                <TextInputField
                    name={"domain"}
                    label={"Website Domain"}
                    value={store.domain || ""}
                    required={false}
                    maxWidth={"300px"}
                    onChange={onChangeInternal}
                />
                <Checkbox
                    name={"isPartnershipsTier"}
                    label={"Partnerships"}
                    checked={store.isPartnershipsTier}
                    onChange={onCheckboxChange}
                />
            </DetailsPageSection>
        </fieldset>
    );
};
