import {
    Button,
    ErrorIcon,
    Heading,
    IconButton,
    Pane,
    Paragraph,
    PlusIcon,
    ProjectsIcon,
    Select,
    Strong,
    Table,
    Text
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LaunchType, LaunchTypeDisplayValues, OnlineListingState } from "../../../../common/enums";
import { Store, Pricing, ProductListing } from "../../../../common/models";
import { inOrBeyondState } from "../../../../common/utils/productListingStateHelpers";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { EmptyState } from "../../common/EmptyState";
import { useMobileMediaQuery } from "../../common/MediaQuery";
import { removeJcContentContainerStylesEffect } from "../../common/removeJcContentContainerStylesEffect";
import { useProductListingsForUserQuery } from "../../datastore/UserDataStore";
import { NavigationHelper } from "../../navigation/PrevLocationState";
import { MiniStatusBadge } from "../../MiniStatusBadge";
import { ProductStageBadge } from "../MultiTabProductPage/etc/ProductStageBadge";

enum TableFilter {
    Newest = "Newest",
    Oldest = "Oldest"
}

const Badges = {
    None: <></>,
    StoreFront: {
        Visible: <MiniStatusBadge color={"green"}>Visible</MiniStatusBadge>,
        Hidden: <MiniStatusBadge color={"neutral"}>Hidden</MiniStatusBadge>,
    }
}

export const UserProductListPage = () => {

    const history = useHistory();
    const { loading, error, data } = useProductListingsForUserQuery();
    const isMobile = useMobileMediaQuery();

    const [loadedData, setLoadedData] = useState<ProductListing[]>(data || []);
    const [tableFilter, setTableFilter] = useState<TableFilter>(TableFilter.Newest);

    const sortComparator = (a: ProductListing, b: ProductListing): number => {
        switch (tableFilter) {
            case TableFilter.Oldest:
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            case TableFilter.Newest:
            default:
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }
    }

    const resolveProductName = (name: string): string => {
        return name;
    }

    const resolveStoreName = (store: Store): string => {
        return store.name;
    }

    const resolveProductTypeName = (pricing: Pricing): string => {
        return pricing.catalogOption.name || "";
    }

    const resolveDesignerName = (name?: string): string | JSX.Element => {
        switch (name) {
            case "": return <></>;
            case undefined: return <Text color={"gray600"}>No Designer</Text>;
            default: return name;
        }
    }

    const resolveLaunchModelName = (launchType: LaunchType): string => {
        return launchType === "unset" ? "" : LaunchTypeDisplayValues[launchType];
    }

    const resolveStageBadge = (
        productListing: ProductListing
    ): JSX.Element => {
        return (
            <ProductStageBadge
                productListing={productListing}
            />
        );
    }

    const resolveStorefrontBadge = (productListing: ProductListing): JSX.Element => {
        if (productListing.onlineListingState === OnlineListingState.NotRequired) {
            return Badges.None;
        }
        else if (inOrBeyondState(OnlineListingState, productListing.onlineListingState as OnlineListingState, OnlineListingState.ProductListing_WebsiteReady)) {
            return Badges.StoreFront.Visible;
        } else {
            return Badges.StoreFront.Hidden;
        }
    }

    // this removes the unwanted padding from the parent container.
    useEffect(() => removeJcContentContainerStylesEffect(false), []);

    useEffect(() => {
        if (data) {
            setLoadedData(data.slice());
        }
    }, [data, setLoadedData]);

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (error || !data) {
        return (
            <EmptyState
                title="Failed to load products"
                subtitle="Refresh the page to try again"
            >
                <ErrorIcon size={60}/>
            </EmptyState>
        );
    }

    if (data.length === 0) {
        return (
            <EmptyState
                title="You don’t have any Products right now. Click “New Product” to get started.
                This doesn’t apply for Partnerships stores."
                subtitle="New Product"
                displayAsButton={true}
                onClickSubtitle={() => history.push(NavigationHelper.createNewProductPath())}
            >
                <ProjectsIcon size={60}/>
            </EmptyState>
        );
    }

    return(
        <Pane>
            <Pane width={"100%"} display={"flex"} alignItems={"center"}>
                <Heading size={800} style={{
                    margin: "24px",
                    width: "100%",
                    fontWeight: "unset",
                    color: `var(--grey-secondary-text)`
                }}>Products</Heading>
                { <Select minWidth={!isMobile ? "160px" : "120px"} marginRight={!isMobile ? "24px" : "16px"} size={"large"} value={tableFilter} onChange={event => setTableFilter(event.target.value as TableFilter)}>
                    { (Object.keys(TableFilter) as Array<keyof typeof TableFilter>).map((key, index) => {
                        return <option key={index} value={key}>{key}</option>;
                    }) }
                </Select> }
                { !isMobile ? <Button
                        marginRight={"24px"}
                        minWidth={"160px"}
                        iconBefore={PlusIcon}
                        size={"large"}
                        appearance={"primary"}
                        onClick={
                            () => history.push(NavigationHelper.createNewProductPath())
                        }>
                        New Product
                    </Button> :
                    <IconButton
                        marginRight={"24px"}
                        icon={<PlusIcon color={"white"}/>}
                        size={"large"}
                        appearance={"primary"}
                        onClick={
                            () => history.push(NavigationHelper.createNewProductPath())
                        }
                    /> }
            </Pane>
            { !isMobile ? <Table className={"upl-table"}>
                    <Table.Head background={"white"} textTransform={"none"} padding={0}>
                        {["Name", "Store Name", "Product Type", "Designer", "Launch Model", "Stage", "Storefront"].map((header, index) => {
                            if (isMobile && index > 1) {
                                return <></>;
                            }
                            if (index === 0) {
                                return <Table.TextHeaderCell key={index} padding={"8px"} paddingLeft={"24px"}><Strong size={500}>{header}</Strong></Table.TextHeaderCell>;
                            }
                            return <Table.TextHeaderCell key={index} padding={"8px"}><Strong size={500}>{header}</Strong></Table.TextHeaderCell>;
                        })}
                    </Table.Head>
                    <Table.Body>
                        { loadedData.sort(sortComparator).map((productListing, index: number) => {
                            const {
                                id,
                                name,
                                store,
                                pricing,
                                launchType,
                                designer
                            } = productListing;

                            const rowData = [
                                resolveProductName(name),
                                resolveStoreName(store),
                                resolveProductTypeName(pricing),
                                resolveDesignerName(designer || ""),
                                resolveLaunchModelName(launchType as LaunchType),
                                resolveStageBadge(productListing),
                                resolveStorefrontBadge(productListing)
                            ];

                            return (
                                <Table.Row borderWidth={"0.5px"} height={"auto"} minHeight={"64px"} key={index} isSelectable={true} onClick={() => history.push(`/products/${id}`)}>
                                    { rowData.map((value, index) => {
                                        if (isMobile && index > 1) {
                                            return <></>;
                                        }
                                        if (index === 0) {
                                            return <Table.TextCell key={index} padding={"8px"} paddingLeft={"24px"}><Strong whiteSpace={"normal"}>{value}</Strong></Table.TextCell>;
                                        }
                                        return <Table.TextCell key={index} padding={"8px"}><Paragraph whiteSpace={"normal"}>{value}</Paragraph></Table.TextCell>;
                                    }) }
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table> :
                <Table textAlign={"left"} width={"100%"} height={"100%"}>
                    <Table.Body>
                        { loadedData.sort(sortComparator).map((productListing, index: number) => {
                            const {
                                id,
                                name,
                                store,
                                pricing
                            } = productListing;

                            const productName = resolveProductName(name);
                            const productType = resolveProductTypeName(pricing);
                            const stageBadge = resolveStageBadge(productListing);
                            return (
                                <Table.Row
                                    height={"auto"}
                                    minHeight={"64px"}
                                    key={index}
                                    isSelectable={true}
                                    onClick={() => history.push(`/products/${id}`)}
                                    padding={"24px"}
                                    width={"100%"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Pane
                                        display={"flex"}
                                        alignItems={"center"}
                                        width={"100%"}
                                        paddingBottom={"8px"}
                                    >
                                        <Heading
                                            textOverflow={"ellipsis"}
                                            overflow={"hidden"}
                                            whiteSpace={"nowrap"}
                                            width={"100%"}
                                        >
                                            {productName}
                                        </Heading>
                                        <Pane
                                            display={"inline"}
                                            verticalAlign={"end"}
                                        >
                                            { stageBadge }
                                        </Pane>
                                    </Pane>
                                    <Pane
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <Paragraph
                                            textOverflow={"ellipsis"}
                                            overflow={"hidden"}
                                            whiteSpace={"nowrap"}
                                            width={"100%"}
                                        >
                                            {store.name} • {productType}
                                        </Paragraph>
                                    </Pane>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>}
        </Pane>
    );
}
