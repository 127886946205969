import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { fetchStripePublishableKey } from "./index";
import { isUserContextProcessing, useUserContext } from "../context/UserContext";

export const StripeElementsProvider: React.FC = ({children}) => {
    const {user, loadingState} = useUserContext();
    const [stripe, setStripe] = useState<Stripe | null>(null);

    useEffect(() => {
        if (isUserContextProcessing(loadingState)) {
            return;
        }

        if (!user) {
            setStripe(null);
            return;
        }

        fetchStripePublishableKey()
            .then((publishableKey: string) => loadStripe(publishableKey))
            .then((stripe: Stripe | null) => {
                setStripe(stripe);
            })
            .catch((error) => {
                console.error(error);
                setStripe(null);
            });
    }, [user, loadingState]);

    // This may look like a redundant implementation, but the reason for this is due to the
    // following, which states that once the `stripe` prop is set, it cannot be changed:
    // https://github.com/stripe/react-stripe-js/blob/master/src/components/Elements.tsx#L76.
    // In order to work around this constraint, the `Elements` component must be remounted with
    // a new `stripe` prop, as seen below. The `key` prop must also be unique so that React does
    // not try to re-use the existing component instance.
    return stripe ? (
        <Elements key="stripe-loaded" stripe={stripe}>
            {children}
        </Elements>
    ) : (
        <Elements key="stripe-not-loaded" stripe={null}>
            {children}
        </Elements>
    );
};
