import { Pane } from "evergreen-ui";
import React, { useCallback, useState } from "react";
import { Address, AddressFormFields } from "../../common/addresses/AddressFormFields";

export const useBillingAddressFormFields = () => {

    const [ billingAddress, setBillingAddress ] = useState<Address>({});

    const isBillingAddressComplete = (): boolean => {
        return !!billingAddress.line1
            && !!billingAddress.city
            && !!billingAddress.state
            && !!billingAddress.country
            && !!billingAddress.postalCode;
    };
    
    const renderBillingAddressFields = useCallback(({hide, disabled}: {hide?: boolean, disabled?: boolean}) => {
        return(
            <Pane display={hide ? "none" : "initial"}>
                <h3>Billing Address</h3>
                <AddressFormFields
                    disabled={!!disabled}
                    address={billingAddress}
                    setAddress={setBillingAddress}
                />
            </Pane>
        );
    }, [billingAddress]);

    return {
        renderBillingAddressFields,
        billingAddress,
        isBillingAddressComplete
    }

};