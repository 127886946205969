import React, { ReactNode } from "react";
import { useUserContext } from "../context/UserContext";
import { userIsAuthorized } from "./RampartUtils";

export interface RampartAuthorizedProps {
    children: ReactNode,
    unauthorizedView?: ReactNode,
    requiredGroups: string[]
}

export const RampartAuthorized: React.FC<RampartAuthorizedProps> = React.memo((props: RampartAuthorizedProps) => {

    const {
        children,
        unauthorizedView,
        requiredGroups
    } = props;
    
    const {
        user
    } = useUserContext();

    // Either a fallback view is specified, or we just render nothing.
    const resolvedUnauthorizedView: ReactNode = unauthorizedView ?? <></>

    // Render the primary view if Authorized, the resolved fallback otherwise.
    return (
        <>
            { userIsAuthorized(user, requiredGroups) ? children : resolvedUnauthorizedView }
        </>
    );
});