import React, { useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { MultiTabView } from "../../common/multitab/MultiTabView";
import { TabInfo } from "../../common/multitab/TabInfo";
import { STORE_ROUTE_PATH } from "../../navigation/Routes";
import { StorePayoutRulesTab } from "./payout-rules/StorePayoutRulesTab";
import { StoreSetupTab } from "./StoreSetupTab";
import { StoreTeamTab } from "./StoreTeamTab";
import { useStoreQuery } from "../../datastore/StoreDataStore";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { NavigationHelper } from "../../navigation/PrevLocationState";
import { RampartAuthorized } from "../../rampart/RampartAuthorized";
import { StoreAdminTab } from "./StoreAdminTab";
import { useUserContext } from "../../context/UserContext";
import { userIsAuthorized } from "../../rampart/RampartUtils";
import { StoreTundraAdminPage } from "./StoreTundraAdminPage";

type Params = { storeId: string, tabId?: StoreTabIds };

enum StoreTabIds {
    setup = "setup",
    team = "team",
    admin = "admin",
    payoutRules = "payout-rules",
    storefront = "storefront"
}

const resolveSelectedTabIndex = (tabId?: StoreTabIds): number => {
    switch (tabId) {
        case StoreTabIds.admin:
            return 4;
        case StoreTabIds.storefront:
            return 3;
        case StoreTabIds.payoutRules:
            return 2;
        case StoreTabIds.team:
            return 1;
        case StoreTabIds.setup:
        default:
            return 0;
    }
};

export const MultiTabStorePage = () => {
    const history = useHistory();
    const { tabId, storeId } = useParams<Params>();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(resolveSelectedTabIndex(tabId));
    const { user } = useUserContext();

    // TODO: This is a temporary workaround to populate the multi-tab header with
    //       the store name on first load. Some refactoring is required to update
    //       the store name when the GQL object is mutated by a child component.
    const { data } = useStoreQuery(storeId);

    const getTabs = () => {
        const tabs: TabInfo[] = [
            {
                label: "Setup",
                tabId: "setup",
                component: <StoreSetupTab />,
            },
            {
                label: "Team",
                tabId: "team",
                component: <StoreTeamTab />,
            },
        ];

        // Add the admin tab if we have permissions (I've added two authorizations in case here.)
        if (userIsAuthorized(user, ["juniper.self-serve.admin"])) {
            tabs.push({
                label: "Payout Rules",
                tabId: "payout-rules",
                component: (
                    <RampartAuthorized
                        requiredGroups={["juniper.self-serve.admin"]}
                        unauthorizedView={null}
                    >
                        <StorePayoutRulesTab storeId={storeId} />
                    </RampartAuthorized>
                )
            }, {
                label: "Storefront (Beta)",
                tabId: "storefront",
                component: (
                    <RampartAuthorized
                        requiredGroups={["juniper.self-serve.admin"]}
                        unauthorizedView={null}
                    >
                        <StoreTundraAdminPage store={data!}/>
                    </RampartAuthorized>
                )
            }, {
                label: "Admin",
                tabId: "admin",
                component: (
                    <RampartAuthorized
                        requiredGroups={["juniper.self-serve.admin"]}
                        unauthorizedView={null}
                    >
                        <StoreAdminTab />
                    </RampartAuthorized>
                )
            });
        }

        return tabs;
    }

    const tabs: TabInfo[] = getTabs();

    useEffect(() => {
        NavigationHelper.pushPathname(
            history,
            generatePath(STORE_ROUTE_PATH, {
                storeId: storeId,
                tabId: tabs[selectedTabIndex].tabId,
            }),
        );
    }, [selectedTabIndex]);

    return data ? (
        <MultiTabView
            header={data.name}
            tabs={tabs}
            selectedTabIndex={selectedTabIndex}
            onTabSelected={setSelectedTabIndex}
            fullPathFormat={STORE_ROUTE_PATH}
        />
    ) : <CenteredSpinner />;
};
