import { InventoryStatus } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class InventoryStatusMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [InventoryStatus.Selling_InventoryUnavailable]: {
                label: "Selling, Inventory Unavailable",
                value: InventoryStatus.Selling_InventoryUnavailable,
                color: "neutral"
            },
            [InventoryStatus.Selling_InventoryInStock]: {
                label: "Selling, Inventory In-Stock",
                value: InventoryStatus.Selling_InventoryInStock,
                color: "neutral"
            },
            [InventoryStatus.NotSelling_OutOfStock]: {
                label: "Not Selling, Out of Stock",
                value: InventoryStatus.NotSelling_OutOfStock,
                color: "neutral"
            },
            [InventoryStatus.NotSelling_ReOrdering]: {
                label: "Not Selling, Re-ordering",
                value: InventoryStatus.NotSelling_ReOrdering,
                color: "neutral"
            },
        };
    }
}