import { Avatar, Button, IconButton, Menu, MoreIcon, NewPersonIcon, Pane, Popover, Position } from "evergreen-ui";
import { User } from "../../../../common/models";
import { isInternalEmail } from "../../../../common/utils/isInternalEmail";
import { User as TwilioUser } from "@twilio/conversations";
import { getAvatarColorForIndex } from "../common/getAvatarColorForIndex";
import { ExpandableView } from "../../common/ExpandableView";

interface Props {
    user: User | undefined;
    participants: TwilioUser[];
    handleRemoveParticipant: (participant: TwilioUser) => void;
    onAddButtonClick: () => void;
}

export const PeopleContainer = ({
    user,
    participants,
    onAddButtonClick,
    handleRemoveParticipant
}: Props) => {
    const renderMorePopover = (participant: TwilioUser) => {
        return (<Popover
            position={Position.BOTTOM_RIGHT}
            content={
                <Menu>
                    <Menu.Group>
                        <Menu.Item
                            onSelect={() => handleRemoveParticipant(participant)}
                        >
                            Remove
                        </Menu.Item>
                    </Menu.Group>
                </Menu>
            }
        >
            <IconButton
                appearance="minimal"
                marginLeft={"auto"}
                icon={MoreIcon}
            />
        </Popover>)
    };

    const renderParticipants = () => {
        return (
            <Pane className={"conversation-sidebar-participants-container"}>
                {participants.map((participant, index) => {
                    return (
                        <Pane display={"flex"} key={participant.identity}>
                            {participant.identity === user?.email ?
                                <Pane className={"conversation-sidebar-current-participant-avatar"}>
                                    <Avatar color={"green"} outline={"2px solid white"} name={participant.friendlyName} size={36} />
                                </Pane> :
                                <Avatar color={getAvatarColorForIndex(index)} name={participant.friendlyName} size={40} />}
                            <Pane className={"conversation-sidebar-participant-text"}>
                                <span>
                                    {participant.friendlyName}
                                </span>
                                <br />
                                <span style={{
                                    fontWeight: 400,
                                    fontSize: "11px",
                                }}>
                                    {participant.identity}
                                </span>
                            </Pane>
                            {isInternalEmail(user?.email ?? "") && renderMorePopover(participant)}
                        </Pane>
                    );
                })}
            </Pane>)
    };

    return (
        <ExpandableView
            header={"People"}
            paneProps={{
                alignItems: "center",
                cursor: "pointer",
                display: "flex",
                paddingX: 30,
                paddingTop: 20
            }}
        >
            <Pane className={"conversation-sidebar-section-container"}>
                {renderParticipants()}
                {isInternalEmail(user?.email ?? "") && (
                    <Button
                        justifyContent={"left"}
                        appearance={"minimal"}
                        iconBefore={NewPersonIcon}
                        onClick={() => onAddButtonClick()}
                    >
                        Add People
                    </Button>
                )}
            </Pane>
        </ExpandableView>
    )
}