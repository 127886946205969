import { DesignConceptState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class DesignConceptStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [DesignConceptState.DesignerPicked]: {
                label: "Designer Picked",
                value: DesignConceptState.DesignerPicked,
                color: "neutral"
            },
            [DesignConceptState.DesignerAssigned]: {
                label: "Designer Assigned",
                value: DesignConceptState.DesignerAssigned,
                color: "neutral"
            },
            [DesignConceptState.QuoteGiven]: {
                label: "Quote Given",
                value: DesignConceptState.QuoteGiven,
                color: "neutral"
            },
            [DesignConceptState.QuoteAccepted]: {
                label: "Quote Accepted",
                value: DesignConceptState.QuoteAccepted,
                color: "neutral"
            },
            [DesignConceptState.Designing]: {
                label: "Designing",
                value: DesignConceptState.Designing,
                color: "neutral"
            },
            [DesignConceptState.EditsRequired]: {
                label: "Edits Required",
                value: DesignConceptState.EditsRequired,
                color: "yellow"
            },
            [DesignConceptState.ClientApproved]: {
                label: "Client Approved",
                value: DesignConceptState.ClientApproved,
                color: "neutral"
            },
            [DesignConceptState.InternalApproved]: {
                label: "Internal Approved",
                value: DesignConceptState.InternalApproved,
                color: "green"
            }
        };
    }
}