import { EnumMapper } from "../../../common/tables/etc/EnumMapper";
import { ProductListingForAdmin } from "../../../../../common/tables/product/ProductListingForAdmin";
import { EnumResolver } from "../../../common/tables/etc/EnumResolver";
import { CsSpecialistMapper } from "./mappers/CsSpecialistMapper";
import { DesignConceptStateMapper } from "./mappers/DesignConceptStateMapper";
import { DesignerMapper } from "./mappers/DesignerMapper";
import { DesignInvoiceStateMapper } from "./mappers/DesignInvoiceStateMapper";
import { DqReasonMapper } from "./mappers/DqReasonMapper";
import { DqStageMapper } from "./mappers/DqStageMapper";
import { FulfillmentTypeMapper } from "./mappers/FulfillmentTypeMapper";
import { InventoryStatusMapper } from "./mappers/InventoryStatusMapper";
import { LaunchTypeMapper } from "./mappers/LaunchTypeMapper";
import { ManufacturingDrawingStateMapper } from "./mappers/ManufacturingDrawingStateMapper";
import { ManufacturingWorkstreamStateMapper } from "./mappers/ManufacturingWorkstreamStateMapper";
import { OnlineListingStateMapper } from "./mappers/OnlineListingStateMapper";
import { PackagingStateMapper } from "./mappers/PackagingStateMapper";
import { PreDesignReviewStateMapper } from "./mappers/PreDesignReviewStateMapper";
import { ProductLifecycleStatusMapper } from "./mappers/ProductLifecycleStatusMapper";
import { SamplingStateMapper } from "./mappers/SamplingStateMapper";
import { SourceMapper } from "./mappers/SourceMapper";
import { StoreWorkstreamStateMapper } from "./mappers/StoreWorkstreamStateMapper";
import { WarehousePricingVersionMapper } from "./mappers/WarehousePricingVersionMapper";
import { InventoryOwnerTypeMapper } from "./mappers/InventoryOwnerTypeMapper";

export class AdminProductListEnumResolver extends EnumResolver<ProductListingForAdmin> {
    public resolveEnum(columnName: keyof ProductListingForAdmin): EnumMapper {
        switch (columnName) {
            case "assignee":
                return new CsSpecialistMapper();
            case "designer":
                return new DesignerMapper();
            case "lifecycleStatus":
                return new ProductLifecycleStatusMapper();
            case "warehousePricingVersion":
                return new WarehousePricingVersionMapper();
            case "preDesignReviewState":
                return new PreDesignReviewStateMapper();
            case "designConceptState":
                return new DesignConceptStateMapper();
            case "mfgDrawingState":
                return new ManufacturingDrawingStateMapper();
            case "samplingState":
                return new SamplingStateMapper();
            case "designInvoiceState":
                return new DesignInvoiceStateMapper();
            case "packagingState":
                return new PackagingStateMapper();
            case "onlineListingState":
                return new OnlineListingStateMapper();
            case "inventoryStatus":
                return new InventoryStatusMapper();
            case "launchType":
                return new LaunchTypeMapper();
            case "dqReason":
                return new DqReasonMapper();
            case "dqStage":
                return new DqStageMapper();
            case "storeWorkstreamState":
                return new StoreWorkstreamStateMapper();
            case "mfgWorkstreamState":
                return new ManufacturingWorkstreamStateMapper();
            case "source":
                return new SourceMapper();
            case "fulfillmentType":
                return new FulfillmentTypeMapper();
            case "inventoryOwner":
                return new InventoryOwnerTypeMapper();
            default:
                return {} as EnumMapper;
        }
    }

}
