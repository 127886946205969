import { Badge, BadgeProps } from "evergreen-ui";

export const MiniStatusBadge = ({children, ...props}: BadgeProps) => {
    return (
        <Badge
            textTransform={"none"}
            fontWeight={"normal"}
            height={"20px"}
            lineHeight={"20px"}
            borderRadius={10}
            paddingX={10}
            flexShrink={0}
            {...props}
        >
            {children}
        </Badge>
    );
}
