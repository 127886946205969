export enum ProductListingStatus {
    draft = "draft",
    pendingApproval = "pendingApproval",
    requestedIntakeRevisions = "requestedIntakeRevisions",
    approvedWithRevisions = "approvedWithRevisions",
    designInProgress = "designInProgress",
    sampled = "sampled",
    readyForLaunch = "readyForLaunch",
    completed = "completed",
    cancelled = "cancelled",
}