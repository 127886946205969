import React, {
    Context,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { ErrorIcon, Pane, ProjectsIcon } from "evergreen-ui";
import { SortOrder } from "../../../../../common/query-filters";
import { genericMemo } from "../../GenericMemo";
import { ColumnConfig } from "../config/ColumnConfig";
import { TableCellChangedHandler, TableCellHeaderChangedHandler } from "../factory";
import { TableProviderAPI } from "../state/TableProviderAPI";
import { TableHead } from "./TableHead";
import { TableRow } from "./TableRow";
import { TableRowLoader } from "./TableRowLoader";
import { useTableViewContext } from "./AbstractTableViewProvider";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";
import { EmptyState } from "../../EmptyState";

import '../styles/TableView.css';

export interface TableEmptyAndErrorViewProps {
    primaryText: string;
    secondaryText: string;
    onClickSecondaryText?: () => void;
    icon?: ReactNode;
}

export interface TableViewProps<T extends TableDataWithId, D extends TableDataWithId> {
    context?: Context<TableProviderAPI<T, D>>;
    errorViewProps: TableEmptyAndErrorViewProps;
    emptyViewProps: TableEmptyAndErrorViewProps;
}

function TableViewInternal<T extends TableDataWithId, D extends TableDataWithId>(props: TableViewProps<T, D>) {

    const tableRef = useRef<HTMLDivElement>(null);

    const {
        tableConfig,
        updateRow,
        paginate,
        hasMoreData,
        data,
        dataQuerying,
        dataPaginating,
        setFilterExpressions,
        allFilterExpressions,
        setSort,
        sort,
        dataError,
        tableCellFactory,
    }: TableProviderAPI<T, D> = useTableViewContext();

    const onHeaderCellChanged: TableCellHeaderChangedHandler<T> = useCallback((
        columnName: keyof T,
        queryInput: {sortOrder?: SortOrder, columnValues?: T[keyof T][]},
    ): void => {
        if (queryInput.columnValues) {
            setFilterExpressions(columnName, queryInput.columnValues);
        }

        if (queryInput.sortOrder) {
            setSort({
                column: columnName,
                order: queryInput.sortOrder
            });
        }
    }, [setFilterExpressions, setSort]);

    const onCellChanged: TableCellChangedHandler<T> = useCallback((
        id: string | number,
        columnName: keyof T,
        value: string | number | boolean | null,
        label?: string
    ): void => {
        updateRow(id, columnName, value, label);
    }, [updateRow]);

    const [ currentRowsShown, setCurrentRowsShown ] = useState<number>(30);

    const visibleColumns = useMemo((): ColumnConfig<T>[] => {
        return tableConfig.columns.filter(({hidden}: ColumnConfig<T>) => !hidden);
    }, [tableConfig.columns]);

    const loader: JSX.Element = useMemo(() => {
        return <TableRowLoader columns={visibleColumns} />;
    }, [visibleColumns]);

    const loadingView: JSX.Element = useMemo(() => {
        return (
            <>
                {Array.from(Array(currentRowsShown)).map((value, index) => (
                    <TableRowLoader key={index} columns={visibleColumns} />
                ))}
            </>
        );
    }, [visibleColumns, currentRowsShown]);

    const onScroll = useCallback(() => {
        if (!tableRef.current) {
            return;
        }

        const thresholdPx = 120;
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
        const nearBottom = (scrollHeight - (scrollTop + clientHeight)) <= thresholdPx;
        if (nearBottom && hasMoreData) {
            paginate();
        }
    }, [paginate, hasMoreData]);

    useEffect(() => {
        if (data.length !== 0) {
            setCurrentRowsShown(data.length);
        }
    }, [data.length]);

    const tableHead: ReactNode = useMemo(() => {
        return (
            <TableHead
                columns={visibleColumns}
                tableCellFactory={tableCellFactory}
                filterExpressionsByColumn={allFilterExpressions}
                sort={sort}
                onCellChanged={onHeaderCellChanged}
            />
        );
    }, [
        visibleColumns,
        tableCellFactory,
        allFilterExpressions,
        sort,
        onHeaderCellChanged
    ]);

    const tableBody: ReactNode = useMemo(() => {
        if (dataQuerying) {
            return loadingView;
        }

        if (dataError) {
            return (
                <EmptyState
                    className={"jc-table-empty-state"}
                    title={props.errorViewProps.primaryText}
                    subtitle={props.errorViewProps.secondaryText}
                    onClickSubtitle={props.errorViewProps.onClickSecondaryText}
                    displayAsButton={!!props.errorViewProps.onClickSecondaryText}
                >
                    {props.errorViewProps.icon || <ErrorIcon size={60}/>}
                </EmptyState>
            );
        }

        if (data.length === 0) {
            return (
                <EmptyState
                    className={"jc-table-empty-state"}
                    title={props.emptyViewProps.primaryText}
                    subtitle={props.emptyViewProps.secondaryText}
                    onClickSubtitle={props.emptyViewProps.onClickSecondaryText}
                    displayAsButton={!!props.emptyViewProps.onClickSecondaryText}
                >
                    {props.emptyViewProps.icon || <ProjectsIcon size={60}/>}
                </EmptyState>
            );
        }

        return (
            <>
                {data.map((row: D) => (
                    <TableRow
                        key={row.id}
                        rowData={row}
                        columns={visibleColumns}
                        tableCellFactory={tableCellFactory}
                        onCellChanged={onCellChanged}
                    />
                ))}
                {dataPaginating && loader}
            </>
        );
    }, [
        dataPaginating,
        dataQuerying,
        dataError,
        data,
        loader,
        props.errorViewProps,
        props.emptyViewProps,
        tableCellFactory,
        loadingView,
        visibleColumns,
        onCellChanged
    ]);

    return (
        <Pane
            className={"jc-table"}
            id={"jc-table-scroll-view"}
            ref={tableRef}
            onScroll={onScroll}
        >
            { tableHead }
            { tableBody }
        </Pane>
    );
}

export const TableView = genericMemo(TableViewInternal);
