import React, { useMemo } from "react";
import { Pane } from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { AdminProductListProvider } from "./state/AdminProductListProvider";
import { NavigationHelper } from "../../navigation/PrevLocationState";
import { TableView } from "../../common/tables/view/TableView";
import { TablePageHeader } from "../../common/tables/view/TablePageHeader";

export const AdminProductListPage = React.memo(() => {

    const history = useHistory();

    const emptyViewProps = useMemo(() => {
        return {
            primaryText: "You don’t have any Products right now. Click “New Product” to get started. This doesn’t apply for Partnerships stores.",
            secondaryText: "New Product",
            onClickSecondaryText: () => history.push(NavigationHelper.createNewProductPath()),
        };
    }, [history]);

    const errorViewProps = useMemo(() => {
        return {
            primaryText: "Failed to load products",
            secondaryText: "Refresh the page to try again",
        }
    }, []);

    return (
        <Pane height={"100%"} display={"flex"} flexDirection={"column"}>
            <TablePageHeader
                title={"Products"}
                addButtonText={"New Product"}
                addButtonOnClick={() => history.push(NavigationHelper.createNewProductPath())}
            />
            <AdminProductListProvider>
                <TableView
                    emptyViewProps={emptyViewProps}
                    errorViewProps={errorViewProps}
                />
            </AdminProductListProvider>
        </Pane>
    );
});
