import { Button, Pane, RefreshIcon, ErrorIcon, toaster } from "evergreen-ui";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Prompt } from "react-router-dom";
import { NotificationCategory } from "../../../../../common/enums";
import { getDefaultNotificationPreference } from "../../../../../common/notifications";
import { NotificationCategoryPreference, NotificationOverridePreference, NotificationPreference } from "../../../../../common/models";
import { NotificationCategoryTitles } from "../../../common/NotificationSettings";
import { ConversationPreferenceContainer } from "../components/ConversationPreferenceContainer";
import { NotificationPreferenceContainer } from "../components/NotificationPreferenceContainer";
import { deleteNotificationOverridePreferences, updateNotificationCategoryPreferences, updateNotificationOverridePreferences, useNotificationPreferencesQuery } from "../../../datastore/NotificationPreferenceDataStore";
import { CenteredSpinner } from "../../../common/CenteredSpinner";
import { useMessagingDataContext } from "../../../native-messaging/state/NativeMessagingProvider";
import { FooterActionBar } from "../../../common/FooterActionBar";
import { ConversationPreferenceDialog } from "../components/ConversationPreferenceDialog";
import { isEqualCategoryPreferences, toNotificationCategoryPreferences } from "../../../../../common/notifications/NotificationUtils";
import { EmptyState } from "../../../common/EmptyState";

import '../styles/MultiTabSettingsPage.css'

export const NotificationsTab = () => {

    const categories: NotificationCategory[] = [NotificationCategory.ConversationMessage, NotificationCategory.ProductDevUpdate];
    const { loading, error, data } = useNotificationPreferencesQuery(categories);
    const [notificationPreferences, setNotificationPreferences] = useState<NotificationPreference[]>([]);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [hasDefaultPreferences, setHasDefaultPreferences] = useState<boolean>(false);
    const [initialNotificationPreferences, setInitialNotificationPreferences] = useState<NotificationPreference[]>([]);
    const [conversationOverridePreferences, setConversationOverridePreferences] = useState<NotificationOverridePreference[]>([]);
    const [dialogOverridePreference, setDialogOverridePreference] = useState<NotificationOverridePreference>();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
    const {
        conversationStore,
        conversationStoreLoaded
    } = useMessagingDataContext();

    useEffect(() => {
        if (!loading) {
            setNotificationPreferences(data || []);
            setInitialNotificationPreferences(data || []);

            const pref = data?.find(({ category }: NotificationPreference) => {
                return (category === NotificationCategory.ConversationMessage);
            });

            if (pref && pref.overrides) {
                setConversationOverridePreferences(pref.overrides);
            }
        }
    }, [data, loading]);

    // Used to check if user has default preferences and disable/enable the Restore Defaults button.
    useEffect(() => {
        if (isEqualCategoryPreferences(getDefaultNotificationPreferences(), notificationPreferences)) {
            setHasDefaultPreferences(true);
        } else {
            setHasDefaultPreferences(false);
        }
    }, [notificationPreferences]);

    const getDefaultNotificationPreferences = () => {
        const defaultNotificationPreferences = categories
            .map((category: NotificationCategory) => getDefaultNotificationPreference(category))
        return defaultNotificationPreferences;
    };

    const restoreDefaults = () => {
        const defaultNotificationPreferences = getDefaultNotificationPreferences();
        setNotificationPreferences(defaultNotificationPreferences);
        setHasUnsavedChanges(!isEqualCategoryPreferences(defaultNotificationPreferences, initialNotificationPreferences));
    };

    const onChange = (updatedPreference: NotificationPreference) => {
        const updatedPreferences = notificationPreferences
            .map((previousPreference: NotificationPreference) => {
                if (previousPreference.category === updatedPreference.category) {
                    return updatedPreference;
                }
                return previousPreference;
            });
        setNotificationPreferences(updatedPreferences);
        setHasUnsavedChanges(!isEqualCategoryPreferences(updatedPreferences, initialNotificationPreferences));
    };

    const onDiscardChanges = () => {
        if (hasUnsavedChanges) {
            setNotificationPreferences(initialNotificationPreferences);
            setHasUnsavedChanges(false);
        }
    };

    const renderUnsavedChangesDialog = () => {
        return (
            <Prompt
                when={hasUnsavedChanges}
                message={"You have unsaved changes in the Notifications Settings. Do you want to leave?"}
            />
        )
    };

    const renderConversationPreferenceDialog = () => {
        return (dialogOverridePreference && conversationStoreLoaded &&
            <ConversationPreferenceDialog
                title={`${conversationStore[dialogOverridePreference.overrideEntityId].friendlyName} - Settings`}
                isShown={showDialog}
                notificationOverridePreference={dialogOverridePreference}
                notificationOverridePreferences={conversationOverridePreferences}
                onConfirm={onConversationPreferenceDialogConfirm}
                onCloseComplete={() => {
                    setShowDialog(false);
                    setDialogOverridePreference(undefined);
                }}
                onChange={onConversationPreferenceDialogChange}
            />
        );
    };

    const onConversationPreferenceDialogConfirm = () => {
        if (dialogOverridePreference) {
            updateNotificationOverridePreferences([dialogOverridePreference])
                .then((notifPrefs: NotificationOverridePreference[]) => {
                    if (notifPrefs && notifPrefs.length > 0) {
                        setDialogOverridePreference(notifPrefs[0]);
                        setConversationOverridePreferences(conversationOverridePreferences
                            .map((previousPreference: NotificationOverridePreference) => {
                                if (previousPreference.overrideEntityId === notifPrefs[0].overrideEntityId) {
                                    return notifPrefs[0];
                                }
                                return previousPreference;
                            }));
                        toaster.success("Updated Conversation Notification Preference!");
                    } else {
                        throw Error("Failed to retrieve updated Conversation Notification Preferences.")
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    toaster.danger("Failed to update Conversation Notification Preferences.");
                });
        }
        setShowDialog(false);
    };

    const onConversationPreferenceDialogChange = (notificationOverridePreference: NotificationOverridePreference) => {
        setDialogOverridePreference(notificationOverridePreference);
    };

    const onDeletePreference = (notificationOverridePreferences: NotificationOverridePreference[]) => {
        if (!window.confirm(`Are you sure you want to delete this conversation preference?`)) {
            return;
        }

        deleteNotificationOverridePreferences(notificationOverridePreferences).then(() => {
            const newNotifOverridePreferences = conversationOverridePreferences.filter(({overrideEntityId}: NotificationOverridePreference) => {
                return !(conversationOverridePreferences.map((pref) => pref.overrideEntityId).includes(overrideEntityId));
            });
            setConversationOverridePreferences(newNotifOverridePreferences);
            toaster.success("Successfully deleted conversation preference.");
        }).catch((error) => {
            Sentry.captureException(error);
            toaster.danger("Failed to delete conversation preference.");
        });
    };

    const onSettingsSave = () => {
        if (notificationPreferences) {
            setSaveLoading(true);
            updateNotificationCategoryPreferences(toNotificationCategoryPreferences(notificationPreferences))
                .then((notifPrefs: NotificationCategoryPreference[]) => {
                    if (notifPrefs && notifPrefs.length > 0) {
                        setNotificationPreferences(notifPrefs);
                        setInitialNotificationPreferences(notifPrefs);
                        setHasUnsavedChanges(false);
                        toaster.success("Updated Notification Preferences!");
                    } else {
                        throw Error("Failed to retrieve updated Notification Preferences")
                    }
                    setSaveLoading(false);
                })
                .catch((error) => {
                    setSaveLoading(false);
                    Sentry.captureException(error);
                    toaster.danger("Failed to update Notification Preferences.");
                });
        }
    };

    if (loading || !conversationStoreLoaded || !conversationStore) {
        return <CenteredSpinner />;
    }

    if (error) {
        return (
            <EmptyState
                title="Failed to load settings."
                subtitle="Refresh the page to try again"
            >
                <ErrorIcon size={60} />
            </EmptyState>
        );
    }

    return loading ? <CenteredSpinner /> : (
        <>
            <Pane className={"notification-preference-container"}>
                <span className={"notification-preference-heading"}>
                    Notifications
                </span>
                <p className={"notification-preference-description"}>
                    Control what notifications you receive. Note that we may send you important
                    notifications about your account and products outside of these notification
                    settings.
                </p>
                <Pane display={"flex"} flexWrap={"wrap"}>
                    <Button
                        iconBefore={RefreshIcon}
                        appearance="primary"
                        width={150}
                        textOverflow={"ellipsis"}
                        marginRight={"12px"}
                        onClick={restoreDefaults}
                        disabled={hasDefaultPreferences}
                    >
                        Restore Defaults
                    </Button>
                    <p className={"notification-preference-default-byline"}>
                        This won’t delete conversation-specific settings.
                    </p>
                </Pane>
            </Pane>
            <Pane className={"notification-preference-container"}>
                {notificationPreferences.map((notificationPreference: NotificationPreference) => (
                    <NotificationPreferenceContainer
                        header={
                            <span className={"notification-preference-heading small-margin-left"}>
                                {NotificationCategoryTitles[notificationPreference.category]}
                            </span>
                        }
                        key={notificationPreference.category}
                        notificationPreference={notificationPreference}
                        onChange={onChange}
                    />
                ))}
            </Pane>
            {
                (conversationOverridePreferences.length > 0) &&
                <Pane className={"notification-preference-container-last"}>
                    <ConversationPreferenceContainer
                        conversationStore={conversationStore}
                        notificationOverridePreferences={conversationOverridePreferences}
                        onClick={(newNotifOverridePreference: NotificationOverridePreference) => {
                            // We only really want to set on initial load, otherwise it should be retrieving from state.
                            setDialogOverridePreference(newNotifOverridePreference);
                            setShowDialog(true);
                        }}
                        onClickDelete={(notifOverridePreference: NotificationOverridePreference) => {
                            onDeletePreference([notifOverridePreference]);
                        }}
                    />
                </Pane>
            }
            <FooterActionBar
                primaryButtonText={"Save"}
                primaryButtonDisabled={!hasUnsavedChanges}
                isPrimaryLoading={saveLoading}
                onPrimaryClick={onSettingsSave}
                showSecondaryButton={false}
                tertiaryButtonText={hasUnsavedChanges ? "Discard Changes" : ""}
                onTertiaryClick={onDiscardChanges}
            />
            {renderConversationPreferenceDialog()}
            {renderUnsavedChangesDialog()}
        </>
    );
}