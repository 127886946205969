import { makeVar } from "@apollo/client";
import { AddOn, CatalogCategory, CatalogOption, CatalogProduct } from "../../../../common/models";
import { ProductOnboardingSelection } from "../../product/ProductOnboardingSelection";
import { ProductWizardPage } from "../../product/ProductWizardPage";

// TODO: persist the Reactive Var across sessions by implementing localStorage or Apollo Cache support
export const productOnboardingSelectionReactiveVar = makeVar<ProductOnboardingSelection>({});

class ProductOnboardingHelper {
    public initialize(productOnboardingSelection?: ProductOnboardingSelection): void {
        const {
            catalogCategory,
            catalogProduct,
            catalogOption,
            addOns,
            newStoreName,
            newProductName,
            existingStoreId,
            firstName,
            lastName,
            email,
            productWizardPage
        } = productOnboardingSelection || {};
        productOnboardingSelectionReactiveVar({
            catalogCategory,
            catalogProduct,
            catalogOption,
            addOns,
            newStoreName,
            newProductName,
            existingStoreId,
            firstName,
            lastName,
            email,
            productWizardPage
        });
    }

    public reset(): void {
        productOnboardingSelectionReactiveVar({});
    }

    public setCatalogCategory(catalogCategory?: CatalogCategory): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            catalogCategory,
            catalogProduct: undefined,
        });
    };

    public setCatalogProduct(catalogProduct?: CatalogProduct): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            catalogProduct,
        });
    };

    public setCatalogOption(catalogOption?: CatalogOption): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            catalogOption,
        });
    };

    public setAddOns(addOns: AddOn[]): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            addOns,
        });
    }

    public setFirstName(firstName: string): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            firstName,
        });
    };

    public setLastName(lastName: string): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            lastName,
        });
    };

    public setEmail(email: string): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            email,
        });
    };

    public setNewProductName(newProductName: string): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            newProductName,
        });
    };

    public setNewStoreName(newStoreName: string): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            existingStoreId: undefined,
            newStoreName,
        });
    };

    public setExistingStoreId(existingStoreId: string): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            newStoreName: undefined,
            existingStoreId,
        });
    };

    public setProductWizardPage(productWizardPage: ProductWizardPage | undefined): void {
        productOnboardingSelectionReactiveVar({
            ...productOnboardingSelectionReactiveVar(),
            productWizardPage
        });
    }

    public isEmpty(): boolean {
        return JSON.stringify(productOnboardingSelectionReactiveVar()) === JSON.stringify({});
    };

    public getObject(): ProductOnboardingSelection | undefined {
        return this.isEmpty() ? undefined : productOnboardingSelectionReactiveVar();
    };

    public isCategorySelected(): boolean {
        return !!this.getObject()?.catalogCategory;
    }

    public isProductSelected(): boolean {
        return this.isCategorySelected() && !!this.getObject()?.catalogProduct;
    }

    public isOptionSelected(): boolean {
        return this.isProductSelected() && !!this.getObject()?.catalogOption;
    }

    public isProductInfoComplete(): boolean {
        return this.isOptionSelected() && !!this.getObject()?.newProductName &&
            (!!this.getObject()?.existingStoreId || !!this.getObject()?.newStoreName);
    }
}

export const productOnboardingHelper = new ProductOnboardingHelper();
