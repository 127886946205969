import React, { CSSProperties } from "react";
import { Pane } from "evergreen-ui";
import { genericMemo } from "../../GenericMemo";
import { ColumnConfig } from "../config/ColumnConfig";
import { TableCellFactory, TableCellHeaderChangedHandler } from "../factory";
import { FilterExpressionsByColumn, Sort } from "../state/TableProviderAPI";
import { renderStickyCells } from "../etc/StickyCellUtils";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";

interface TableHeadProps<T extends TableDataWithId, D extends TableDataWithId> {
    columns: ColumnConfig<T>[];
    tableCellFactory: TableCellFactory<T, D>;
    filterExpressionsByColumn: FilterExpressionsByColumn<T>,
    sort: Sort<T>,
    onCellChanged: TableCellHeaderChangedHandler<T>;
}

function TableHeadInternal<T extends TableDataWithId, D extends TableDataWithId>({
    columns,
    tableCellFactory,
    filterExpressionsByColumn,
    sort,
    onCellChanged,
}: TableHeadProps<T, D>) {
    return (
        <Pane className={"jc-head-wrapper"}>
            <Pane className={"jc-tr"}>
                {renderStickyCells(
                    columns,
                    (column: ColumnConfig<T>, style: CSSProperties, stickyClassNames: string) => {
                        const filterExpressions = filterExpressionsByColumn[column.columnName] ?? [];
                        return (
                            <Pane
                                key={column.columnName as string}
                                style={style}
                                className={`jc-th ${stickyClassNames}`}
                            >
                                {tableCellFactory.renderHeaderCell(column, filterExpressions, sort, onCellChanged)}
                            </Pane>
                        );
                    },
                )}
            </Pane>
        </Pane>
    );
}

export const TableHead = genericMemo(TableHeadInternal);
