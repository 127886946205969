import { TableRowSideSheetForm } from "../../../../common/tables/view/TableRowSideSheetForm";
import React, {
    ChangeEvent,
    FC,
    PropsWithChildren,
    useCallback,
    useState,
} from "react";
import { PayoutRule } from "../../../../../../common/models";
import {
    Button, Dialog,
    IconButton,
    InfoSignIcon, Pane, Paragraph,
    PlusIcon, Portal,
    SelectField,
    TextInputField
} from "evergreen-ui";
import { PayoutRuleType } from "../../../../../../common/enums";
import { toTitleCase } from "../../../../../../common/utils";

interface Props {
    isShown: boolean;
    payoutRule: Partial<PayoutRule>;
    mutationLoading: boolean;
    mutationCalled: boolean;
    mutationError: boolean;
    onInputChanged: (payoutRule: Partial<PayoutRule>) => void;
    onDismiss: () => void;
    onOpen: () => void;
    onSubmit: () => void;
}

const PAYOUT_TEXT_FORMULA: string = `
Vendor_Payout = Vendor_Profit_Percent * [
    (Retail_Price * Retail_Price_Percent_Applicable) -
    (Unit_Cost * Unit_Cost_Percent_Applicable) -
    (Juniper_Service_Fee * Juniper_Service_Fee_Percent_Applicable) -
    (Fulfillment_Fee * Fulfillment_Fee_Percent_Applicable) -
    (Maximum(Retail_Price - Shipping_Subsidy, 0) * (Processing_Fee_Percent * Processing_Fee_Percent_Applicable)) - 
    (Shipping_Subsidy * Shipping_Subsidy_Percent_Applicable)
]`;

export const PayoutRulesTableRowSideSheetForm: FC<Props> = ({
    isShown,
    payoutRule,
    mutationLoading,
    mutationCalled,
    mutationError,
    onInputChanged,
    onOpen,
    onDismiss,
    onSubmit,
}: PropsWithChildren<Props>) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const onChange = ({ target: { value, name, type }}: ChangeEvent<HTMLInputElement>) => {
        onInputChanged({
            ...payoutRule,
            [name]: (type === "number") ? parseFloat(value) : value,
        });
    };

    const isReadyToSubmit = useCallback((): boolean => {
        return Object.values(payoutRule).every(value => value !== undefined);
    }, [payoutRule]);

    return (
        <>
            <Portal>
                <Button
                    minWidth={"160px"}
                    iconBefore={PlusIcon}
                    size={"large"}
                    appearance={"primary"}
                    position={"absolute"}
                    bottom={30}
                    right={90}
                    onClick={onOpen}
                    boxShadow={"0 4px 8px rgba(0, 0, 0, 0.2)"}
                >
                    Add Payout Rule
                </Button>
                <IconButton
                    icon={InfoSignIcon}
                    position={"absolute"}
                    marginRight={24}
                    bottom={30}
                    right={10}
                    appearance={"minimal"}
                    size={"large"}
                    boxShadow={"0 4px 8px rgba(0, 0, 0, 0.2)"}
                    onClick={() => setShowDialog(true)}
                />
                <Dialog
                    isShown={showDialog}
                    onCloseComplete={() => setShowDialog(false)}
                    title={"Payout Rule Formula"}
                    hasFooter={false}
                >
                    <Paragraph marginBottom={20}>
                        The dollar amount paid out to a vendor is calculated as follows:
                    </Paragraph>
                    <Pane
                        overflowX={"scroll"}
                        backgroundColor={"#2b2b2b"}
                        color={"white"}
                        paddingX={10}
                        borderRadius={4}
                        marginBottom={40}
                    >
                        <pre
                            style={{
                                lineHeight: "2em",
                                marginTop: 0,
                                marginBottom: 20,
                                fontWeight: "bold"
                            }}
                        >
                            {PAYOUT_TEXT_FORMULA}
                        </pre>
                    </Pane>
                </Dialog>
            </Portal>
            <TableRowSideSheetForm
                isShown={isShown}
                headerTitle={"Add Payout Rule"}
                mutationLoading={mutationLoading}
                mutationCalled={mutationCalled}
                mutationError={mutationError}
                canSubmit={isReadyToSubmit()}
                onDismiss={onDismiss}
                onSubmit={onSubmit}
            >
                <TextInputField
                    name={"name"}
                    label={"Name"}
                    value={payoutRule.name}
                    required={true}
                    maxWidth={"300px"}
                    isInvalid={payoutRule.name === undefined}
                    onChange={onChange}
                />
                <SelectField
                    required={true}
                    name={"type"}
                    label={"Type"}
                    width={"100%"}
                    isInvalid={payoutRule.type === undefined}
                    value={payoutRule.type}
                >
                    {Object.values(PayoutRuleType).map((payoutRuleType, index) => (
                        <option key={index} value={payoutRuleType}>
                            {toTitleCase(payoutRuleType)}
                        </option>
                    ))}
                </SelectField>
                <TextInputField
                    name={"retailPricePercentApplicable"}
                    label={"Retail Price Percent Applicable"}
                    type={"number"}
                    value={payoutRule.retailPricePercentApplicable}
                    isInvalid={payoutRule.retailPricePercentApplicable === undefined}
                    required={true}
                    onChange={onChange}
                />
                <TextInputField
                    name={"unitCostPercentApplicable"}
                    label={"Unit Cost Percent Applicable"}
                    type={"number"}
                    value={payoutRule.unitCostPercentApplicable}
                    isInvalid={payoutRule.unitCostPercentApplicable === undefined}
                    required={true}
                    onChange={onChange}
                />
                <TextInputField
                    name={"juniperServiceFeePercentApplicable"}
                    label={"Juniper Service Fee Percent Applicable"}
                    type={"number"}
                    value={payoutRule.juniperServiceFeePercentApplicable}
                    isInvalid={payoutRule.juniperServiceFeePercentApplicable === undefined}
                    required={true}
                    onChange={onChange}
                />
                <TextInputField
                    name={"fulfillmentFeePercentApplicable"}
                    label={"Fulfillment Fee Percent Applicable"}
                    type={"number"}
                    value={payoutRule.fulfillmentFeePercentApplicable}
                    isInvalid={payoutRule.fulfillmentFeePercentApplicable === undefined}
                    required={true}
                    onChange={onChange}
                />
                <TextInputField
                    name={"processingFeePercentApplicable"}
                    label={"Processing Fee Percent Applicable"}
                    type={"number"}
                    value={payoutRule.processingFeePercentApplicable}
                    isInvalid={payoutRule.processingFeePercentApplicable === undefined}
                    required={true}
                    onChange={onChange}
                />
                <TextInputField
                    name={"shippingSubsidyPercentApplicable"}
                    label={"Shipping Subsidy Percent Applicable"}
                    type={"number"}
                    value={payoutRule.shippingSubsidyPercentApplicable}
                    isInvalid={payoutRule.shippingSubsidyPercentApplicable === undefined}
                    required={true}
                    onChange={onChange}
                />
                <TextInputField
                    name={"vendorProfitSplitPercent"}
                    label={"Vendor Profit Split"}
                    type={"number"}
                    value={payoutRule.vendorProfitSplitPercent}
                    isInvalid={payoutRule.vendorProfitSplitPercent === undefined}
                    required={true}
                    onChange={onChange}
                />
            </TableRowSideSheetForm>
        </>
    );
}
