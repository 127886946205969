// TODO: After adding the cancellation reasons, we should de-dupe these enums throughout the codebase
export enum ProductListingCancelReason {
    under18 = "under18",
    lowEngagement = "lowEngagement",
    spam = "spam",
    unableToMeetExpectations = "unableToMeetExpectations",
    abandoned = "abandoned",
    paused = "paused",
    termination = "termination",
    unableToMeetCreatorDesire = "unableToMeetCreatorDesire",
    designIssues = "designIssues",
    sampleIssues = "sampleIssues",
    priceMOQTooHigh = "priceMOQTooHigh",
    reducingNumProducts = "reducingNumProducts",
    movedToPartnerships = "movedToPartnerships"
}