import { InventoryOwnerType } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class InventoryOwnerTypeMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [InventoryOwnerType.juniper]: {
                label: "Owned by Juniper",
                value: InventoryOwnerType.juniper,
                color: "neutral"
            },
            [InventoryOwnerType.vendor]: {
                label: "Owned by Vendor",
                value: InventoryOwnerType.vendor,
                color: "neutral"
            }
        };
    }
}