import { Button, FilterIcon, Table } from "evergreen-ui";
import React from "react";
import { FilterExpression } from "../../../../../../common/query-filters";
import { Sort } from "../../state/TableProviderAPI";
import { TableHeaderRendererInput } from "../input";
import { AbstractTableHeaderCellRenderer } from "./AbstractTableHeaderCellRenderer";
import { TableCellHeaderChangedHandler } from "../TableCellHeaderChangedHandler";
import { MultiFacetDropdownMenu } from "../../menu/MultiFacetDropdownMenu";
import { StyledSelectMenuItem } from "../../etc/StyledSelectMenuItem";
import { SearchInputProps } from "../../../search/SearchInput";
import { TableDataWithId } from "../../../../../../common/tables/TableDataWithId";

export class TableFilterableHeaderCellRenderer<
    TableDataType extends TableDataWithId,
    SearchInput = {},
    SearchOutput = {},
> extends AbstractTableHeaderCellRenderer<TableDataType> {
    private readonly filterExpressions: FilterExpression<TableDataType>[];
    private readonly sort: Sort<TableDataType>;
    private readonly onCellChanged: TableCellHeaderChangedHandler<TableDataType>;
    private readonly options: StyledSelectMenuItem[];
    private readonly dynamicSearchInputProps?: Pick<SearchInputProps<SearchInput, SearchOutput>, "query" | "queryInput" | "resultsMap">;

    constructor(
        input: TableHeaderRendererInput<TableDataType>,
        filterExpressions: FilterExpression<TableDataType>[],
        sort: Sort<TableDataType>,
        onCellChanged: TableCellHeaderChangedHandler<TableDataType>,
        options: StyledSelectMenuItem[] = [],
        dynamicSearchInputProps?: Pick<SearchInputProps<SearchInput, SearchOutput>, "query" | "queryInput" | "resultsMap">,
    ) {
        super(input);
        this.filterExpressions = filterExpressions;
        this.sort = sort;
        this.onCellChanged = onCellChanged;
        this.options = options;
        this.dynamicSearchInputProps = dynamicSearchInputProps;
    }

    protected getMemoizedComponent(): React.FunctionComponent {
        const {
            columnConfig,
        } = this.input;

        return React.memo(() => {

            const headingColor: string = this.sort.column === columnConfig.columnName ||
                this.filterExpressions
                    .find((expression: FilterExpression<TableDataType>): boolean => expression!.columnValue.length > 0) ?
                        "var(--green-primary)" : "var(--black-heading-text)";

            return (
                <Table.TextHeaderCell className={"jc-header-cell"}>
                    <MultiFacetDropdownMenu
                        key={columnConfig.columnName as string}
                        columnConfig={columnConfig}
                        filterExpressions={this.filterExpressions}
                        sort={this.sort}
                        options={this.options}
                        onChange={this.onCellChanged}
                        disableSort={!columnConfig.sortable}
                        disableFilters={!columnConfig.filterable}
                        dynamicSearchInputProps={this.dynamicSearchInputProps}
                    >
                        <Button
                            className={"jc-header-cell-btn"}
                            appearance={"minimal"}
                            style={{
                                color: headingColor
                            }}
                            iconAfter={<FilterIcon size={12} />}
                        >
                            {columnConfig.displayName}
                        </Button>
                    </MultiFacetDropdownMenu>
                </Table.TextHeaderCell>
            );
        });

    }
}
