import { CatalogProduct, CatalogOption, Pricing } from "../../../common/models";
import { CatalogItem } from "../common/CatalogItem";

const getPricingList = (catalogProduct: CatalogProduct) => {
    return catalogProduct.catalogOptions
        .filter((catalogOption: CatalogOption) => !catalogOption.defaultOption)
        .map((catalogOption: CatalogOption) => catalogOption.pricing);
}

const getOptionPricing = (catalogOption: CatalogOption) => catalogOption.pricing;

export const nf = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const getPricing = (catalogItem: CatalogItem | undefined): Pricing[] => {
    if (!catalogItem) {
        // Skip as there is nothing to render if the catalog item hasn't been selected yet
        return [];
    }
    let pricingList: Pricing[] = [];
    if ('catalogOptions' in catalogItem) {
        // item is a CatalogProduct
        pricingList = getPricingList(catalogItem);
    } else if ('pricing' in catalogItem) {
        // item is a CatalogOption
        pricingList = [getOptionPricing(catalogItem)];
    } else {
        // item is a CatalogCategory
        pricingList = catalogItem.catalogProducts
            .map((catalogProduct: CatalogProduct) => getPricingList(catalogProduct))
            .flat();
    }
    return pricingList;
}

const getValueOrRange = (
    values: number[],
    equalFxn: (min: number) => string,
    notEqualFxn: (min: number, max: number) => string
): string => {
    const min = Math.min(...values);
    const max = Math.max(...values);
    return (max === min) ? equalFxn(min) : notEqualFxn(min, max);
};

export const formatDollarRange = (values: number[]): string => {
    return getValueOrRange(
        values,
        (min: number): string => {
            return nf.format(min / 100);
        },
        (min, max): string => {
            return `${nf.format(min / 100)} - ${nf.format(max / 100)}`;
        },
    );
};


export const formatUnitRange = (values: number[]): string => {
    return getValueOrRange(
        values,
        (min: number): string => {
            return `${min.toLocaleString()} Units`;
        },
        (min: number, max: number): string => {
            return `${min.toLocaleString()} - ${max.toLocaleString()} Units`;
        }
    );
};