export enum StoreWorkstreamState {
    Banner_DesignerPicked = "Banner_DesignerPicked",
    Banner_DesignerAssigned = "Banner_DesignerAssigned",
    Banner_QuoteGiven = "Banner_QuoteGiven",
    Banner_QuoteAccepted = "Banner_QuoteAccepted",
    Banner_Designing = "Banner_Designing",
    Banner_EditsRequired = "Banner_EditsRequired",
    Banner_ClientApproved = "Banner_ClientApproved",
    Banner_Uploaded = "Banner_Uploaded",
    Domain_ProductCollectionCreated = "Domain_ProductCollectionCreated",
    Domain_Requested = "Domain_Requested",
    Domain_Setup = "Domain_Setup",
    Dashboard_Setup = "Dashboard_Setup",
}
