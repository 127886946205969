export class MoxtraContext {
    private _mepsdk: any;
    private _isInitialized: boolean;

    constructor() {
        this._mepsdk = null;
        this._isInitialized = false;
    }

    get mepsdk(): any {
        return this._mepsdk;
    }

    get isInitialized(): boolean {
        return this._isInitialized;
    }

    set mepsdk(mepsdk: any) {
        // Override the `setup()` method to indicate that we've initialized Moxtra
        // when invoked
        let clonedMepsdk = Object.assign({}, mepsdk)
        clonedMepsdk.setup = (...params: any) => {
            this._isInitialized = true;
            return mepsdk.setup(...params)
        }
        this._mepsdk = clonedMepsdk;

    }
}

export const moxtraCtx: MoxtraContext = new MoxtraContext();