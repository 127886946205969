export enum UserRole {
    unset = "unset",
    creator = "creator",
    manager = "manager",
}

export const UserRoleDisplayValues = {
    [UserRole.unset]: "",
    [UserRole.creator]: "Creator",
    [UserRole.manager]: "Manager",
};
