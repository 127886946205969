import { StytchLoginForm } from "../navigation/StytchLoginForm";
import './styles/Login.css';

export const LoginPage = () => {
    return (
        <div className="jc-login-page-container">
            <StytchLoginForm forNewAccount={false}/>
        </div>
    );
};

