import { TableConfig } from "../../../../common/tables/config/TableConfig";
import { ProductListingForAdmin } from "../../../../../../common/tables/product/ProductListingForAdmin";
import { SortOrder } from "../../../../../../common/query-filters";
import { CellType } from "../../../../common/tables/config/CellType";

// TODO: the table controls will eventually access/modify this object as a React state or context.
export const DefaultProductListingTableConfig: TableConfig<ProductListingForAdmin> = {
    columns: [
        {
            columnName: "customerStageSheetId",
            displayName: "Sheet ID",
            cellType: CellType.readOnlyText,
            hidden: false,
            filtered: false,
            sticky: true,
            width: "100px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "storeId",
            displayName: "Store",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            sticky: true,
            width: "200px",
            sortable: false,
            filterable: true
        },
        {
            columnName: "name",
            displayName: "Product",
            cellType: CellType.textInputWithMenuButton,
            hidden: false,
            filtered: false,
            sticky: true,
            width: "240px",
            sortable: false,
            filterable: false
        },
        {
            columnName: "productType",
            displayName: "Product Type",
            cellType: CellType.redirect,
            hidden: false,
            filtered: false,
            width: "240px",
            sortable: false,
            filterable: false
        },
        {
            columnName: "modifier",
            displayName: "Modifier",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            width: "160px",
            sortable: false,
            filterable: false
        },
        {
            columnName: "submissionDate",
            displayName: "Submission Date",
            cellType: CellType.readOnlyDate,
            hidden: false,
            filtered: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "qualifiedDate",
            displayName: "Qualified Date",
            cellType: CellType.datePicker,
            hidden: false,
            filtered: false,
            sticky: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "source",
            displayName: "Source",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "180px",
            sortable: false,
            filterable: true
        },
        {
            columnName: "fulfillmentType",
            displayName: "Fulfillment Type",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "180px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "inventoryOwner",
            displayName: "Inventory Owner",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "180px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "lead",
            displayName: "Lead",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            width: "100px",
            sortable: false,
            filterable: false
        },
        {
            columnName: "agency",
            displayName: "Agency",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            width: "100px",
            sortable: false,
            filterable: false
        },
        {
            columnName: "id",
            displayName: "ID",
            cellType: CellType.readOnlyText,
            hidden: false,
            filtered: false,
            width: "200px",
            sortable: false,
            filterable: false
        },
        {
            columnName: "sku",
            displayName: "SKU",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            width: "120px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "assignee",
            displayName: "Assignee",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "120px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "designer",
            displayName: "Designer",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "120px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "notes",
            displayName: "Notes",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            width: "240px",
            sortable: false,
            filterable: false
        },
        {
            columnName: "shopifyProductId",
            displayName: "Shopify Product ID",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            width: "180px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "lifecycleStatus",
            displayName: "Status",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "140px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "preDesignReviewState",
            displayName: "Pre-Design Review",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "170px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "designConceptState",
            displayName: "Design Concept",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "170px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "mfgDrawingState",
            displayName: "Mfg. Drawing",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "160px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "samplingState",
            displayName: "Sampling",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "200px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "designInvoiceState",
            displayName: "Design Invoice",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "160px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "packagingState",
            displayName: "Packaging",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "180px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "onlineListingState",
            displayName: "Online Listing",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "260px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "launchType",
            displayName: "Launch Type",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "140px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "storeWorkstreamState",
            displayName: "Store Workstream",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "260px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "mfgWorkstreamState",
            displayName: "Mfg. Workstream",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "320px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "domain",
            displayName: "Store Domain",
            cellType: CellType.textInput,
            hidden: false,
            filtered: false,
            width: "200px",
            sortable: false,
            filterable: false,
        },
        {
            columnName: "launchDate",
            displayName: "Launch Date",
            cellType: CellType.datePicker,
            hidden: false,
            filtered: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "launchEnd",
            displayName: "Launch End",
            cellType: CellType.datePicker,
            hidden: false,
            filtered: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "inventoryStatus",
            displayName: "Inventory Status",
            cellType: CellType.singleSelect,
            hidden: true,
            filtered: false,
            width: "140px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "dqReason",
            displayName: "DQ Reason",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "230px",
            sortable: false,
            filterable: true
        },
        {
            columnName: "dqDate",
            displayName: "DQ Date",
            cellType: CellType.datePicker,
            hidden: false,
            filtered: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "dqStage",
            displayName: "DQ Stage",
            cellType: CellType.singleSelect,
            hidden: false,
            filtered: false,
            width: "140px",
            sortable: true,
            filterable: true
        },
        {
            columnName: "createdAt",
            displayName: "Created At",
            cellType: CellType.readOnlyDate,
            hidden: false,
            filtered: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
        {
            columnName: "updatedAt",
            displayName: "Updated At",
            cellType: CellType.readOnlyDate,
            hidden: false,
            filtered: false,
            width: "150px",
            sortable: true,
            filterable: false
        },
    ],
    // TODO: The ContextProvider should be referencing this `filters` object.
    filters: {
        expressions: [],
        sortOrder: SortOrder.DESCENDING,
        sortColumn: "customerStageSheetId",
        pageSize: 50,
    }
};
