import { gql } from "@apollo/client";
import { BaseQueryResult, jssaMutation, jssaQuery, useBaseQuery } from ".";
import { NotificationCategory } from "../../../common/enums";
import { NotificationCategoryPreference, NotificationOverridePreference, NotificationPreference } from "../../../common/models";

const updateNotificationOverridePreferencesMutation = gql`
    mutation updateNotificationOverridePreferences($preferences: [NotificationOverridePreferenceInput!]!) {
        updateNotificationOverridePreferences(preferences: $preferences) {
            overrideEntityId
            category
            frequency
            sms
            email
            push
        }
    }
`;

const updateNotificationCategoryPreferencesMutation = gql`
    mutation updateNotificationCategoryPreferences($preferences: [NotificationCategoryPreferenceInput!]!) {
        updateNotificationCategoryPreferences(preferences: $preferences) {
            category
            frequency
            sms
            email
            push
        }
    }
`;

const notificationOverridePreferenceQuery = gql`
    query notificationOverridePreference($category: String!, $overrideEntityId: String!){
        notificationOverridePreference(category: $category, overrideEntityId: $overrideEntityId) {
            overrideEntityId
            category
            frequency
            sms
            email
            push
        }
    }
`;

const notificationPreferencesQuery = gql`
    query notificationPreferences($categories: [NotificationCategory!]!){
        notificationPreferences(categories: $categories) {
            category
            frequency
            sms
            email
            push
            overrides {
                overrideEntityId
                sms
                email
                push
                category
                frequency
            }
        }
    }
`;

const deleteNotificationOverridePreferencesMutation = gql`
    mutation deleteNotificationOverridePreferences($preferences: [NotificationOverridePreferenceInput!]!) {
        deleteNotificationOverridePreferences(preferences: $preferences)
    }
`;


export const useNotificationPreferencesQuery = (categories: NotificationCategory[]): BaseQueryResult<NotificationPreference[]> => {
    return useBaseQuery<NotificationPreference[], { notificationPreferences: NotificationPreference[]}>(
        notificationPreferencesQuery, {categories}, ({data}) => data.notificationPreferences);
};

export const updateNotificationOverridePreferences = (input: NotificationOverridePreference[]): Promise<NotificationOverridePreference[]> => {
    return new Promise((resolve, reject) => {
        jssaMutation({
            mutation: updateNotificationOverridePreferencesMutation,
            variables: {
               preferences: input
            }
        }).then(({data: { updateNotificationOverridePreferences }}) => {
            resolve(updateNotificationOverridePreferences)
        }).catch((error) => {
            reject(error);
        })
    });
};

export const updateNotificationCategoryPreferences = (input: NotificationCategoryPreference[]): Promise<NotificationCategoryPreference[]> => {
    return new Promise((resolve, reject) => {
        jssaMutation({
            mutation: updateNotificationCategoryPreferencesMutation,
            variables: {
               preferences: input
            }
        }).then(({data: { updateNotificationCategoryPreferences }}) => {
            resolve(updateNotificationCategoryPreferences)
        }).catch((error) => {
            reject(error);
        })
    }); 
}

export const deleteNotificationOverridePreferences = (input: NotificationCategoryPreference[]) => {
    return new Promise((resolve, reject) => {
        jssaMutation({
            mutation: deleteNotificationOverridePreferencesMutation,
            variables: {
                preferences: input
             }
        }).then(({data: {deleteNotificationOverridePreferences}}) => {
            resolve(deleteNotificationOverridePreferences);
        }).catch((error) => {
            reject(error);
        });
    });
};

export const getNotificationOverridePreference = (category: NotificationCategory, overrideEntityId: string): Promise<NotificationOverridePreference> => {
    return new Promise((resolve, reject) => {
        jssaQuery({
            query: notificationOverridePreferenceQuery,
            variables: {
                category,
                overrideEntityId 
            }
        }).then(({data: { notificationOverridePreference }}) => {
            resolve(notificationOverridePreference)
        }).catch((error) => {
            reject(error);
        })
    });
};
