const AUTH_ENDPOINT = process.env.REACT_APP_SERVER_URI + "/sales-dashboard";

export const fetchDashboardUrl = (storeId?: string | null): Promise<string> => {
    return fetch(AUTH_ENDPOINT + "/" + storeId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include"
    }).then((response) => response.json()).then((data) => data.dashboardUrl);
};
