import { DqReason } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class DqReasonMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [DqReason.under18]: {
                label: "Under 18",
                value: DqReason.under18,
                color: "neutral"
            },
            [DqReason.lowEngagement]: {
                label: "Low Engagement",
                value: DqReason.lowEngagement,
                color: "neutral"
            },
            [DqReason.spam]: {
                label: "Spam",
                value: DqReason.spam,
                color: "neutral"
            },
            [DqReason.unableToMeetCreatorDesire]: {
                label: "Unable to Meet Creator Desire",
                value: DqReason.unableToMeetCreatorDesire,
                color: "neutral"
            },
            [DqReason.abandoned]: {
                label: "Abandoned",
                value: DqReason.abandoned,
                color: "neutral"
            },
            [DqReason.paused]: {
                label: "Paused",
                value: DqReason.paused,
                color: "neutral"
            },
            [DqReason.termination]: {
                label: "Termination",
                value: DqReason.termination,
                color: "neutral"
            },
            [DqReason.movedToPartnerships]: {
                label: "Moved to Partnerships",
                value: DqReason.movedToPartnerships,
                color: "neutral"
            },
            [DqReason.priceMOQTooHigh]: {
                label: "Price MOQ Too High",
                value: DqReason.priceMOQTooHigh,
                color: "neutral"
            },
            [DqReason.sampleIssues]: {
                label: "Sample Issues",
                value: DqReason.sampleIssues,
                color: "neutral"
            },
            [DqReason.designIssues]: {
                label: "Design Issues",
                value: DqReason.designIssues,
                color: "neutral"
            },
            [DqReason.reducingNumberOfProducts]: {
                label: "Reducing Number of Products",
                value: DqReason.reducingNumberOfProducts,
                color: "neutral"
            }
        };
    }
}