import { Button, Pane } from "evergreen-ui";
import React, { FC, useEffect, useLayoutEffect, useRef } from "react";

export interface FooterActionBarProps {
    isPrimaryLoading?: boolean;
    isSecondaryLoading?: boolean;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    tertiaryButtonText?: string;
    primaryButtonIcon?: React.ElementType | JSX.Element;
    secondaryButtonIcon?: React.ElementType | JSX.Element;
    primaryButtonDisabled?: boolean;
    showSecondaryButton?: boolean;
    onPrimaryClick?: () => void;
    onSecondaryClick?: () => void;
    onTertiaryClick?: () => void;
    infoComponent?: React.ReactNode;
    sidebarShown?: boolean;
}

export const FooterActionBar: FC<FooterActionBarProps> = ({
    isPrimaryLoading = false,
    isSecondaryLoading = false,
    primaryButtonText = "Continue",
    secondaryButtonText = "Go Back",
    tertiaryButtonText,
    primaryButtonIcon,
    secondaryButtonIcon,
    primaryButtonDisabled = true,
    showSecondaryButton = true,
    onPrimaryClick = () => {},
    onSecondaryClick = () => {},
    onTertiaryClick = () => {},
    infoComponent,
    sidebarShown = false
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const paddingRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        // The height of the action bar itself is dynamic (based on its contents) so we need to
        // set the height of the placeholder padding element behind it accordingly.
        const updateHeight = () => {
            if (containerRef.current && paddingRef.current) {
                paddingRef.current.style.height = `${containerRef.current.clientHeight}px`;
            }
        };

        // Actually set the height here when the component first mounts.
        updateHeight();

        // The height must be recalculated any time the window is resized.
        window.addEventListener('resize', updateHeight);

        // The listener must be cleaned up when the component unmounts.
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    return (
        <>
            <Pane
                ref={containerRef}
                className={`jc-wizard-action-bar ${sidebarShown ? "jc-wizard-sidebar-offset" : ""}`}
            >
                <Pane>
                    {infoComponent}
                </Pane>
                <Pane className={`jc-wizard-action-bar-buttons ${sidebarShown ? "desktop-sidebar" : ""}`}>
                    {(tertiaryButtonText && onTertiaryClick) && (
                        <Button
                            className="jc-wizard-button"
                            appearance="minimal"
                            onClick={onTertiaryClick}
                        >
                            {tertiaryButtonText}
                        </Button>
                    )}
                    {showSecondaryButton && (
                        <Button
                            className="jc-wizard-button back"
                            appearance="minimal"
                            isLoading={isSecondaryLoading}
                            onClick={onSecondaryClick}
                            iconBefore={secondaryButtonIcon}
                        >
                            {secondaryButtonText}
                        </Button>
                    )}
                    <Button
                        className="jc-wizard-button"
                        appearance="primary"
                        isLoading={isPrimaryLoading}
                        onClick={onPrimaryClick}
                        disabled={primaryButtonDisabled}
                        iconBefore={primaryButtonIcon}
                    >
                        {primaryButtonText}
                    </Button>
                </Pane>
            </Pane>
            <Pane ref={paddingRef} className="jc-wizard-action-bar-padding"/>
        </>
    );
};
