const AUTH_ENDPOINT = process.env.REACT_APP_SERVER_URI + "/payout-dashboard";

export interface PayoutDashboardResponse {
    jwt: string;
    dashboardUrl: string;
}

export const fetchPayoutDashboard = (storeId?: string | null): Promise<PayoutDashboardResponse> => {
    return fetch(AUTH_ENDPOINT + "/" + storeId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include"
    }).then((response) => response.json());
};
