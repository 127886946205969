import { TundraStorefrontDomain } from "../../../../../common/models";
import { ElapsedTimeDetails, getElapsedTimeDetails } from "../../../product/Utils";
import { isInternalDomain } from "./DomainUtils";

export interface StatusIndicator {
    status: string;
    intent: "none"| "success" | "warning" | "danger";
    details?: string;
}

export interface TundraStorefrontStatus {
    scan: {
        root: {
            status: StatusIndicator;
            time: StatusIndicator;
        };
        www: {
            status: StatusIndicator;
            time: StatusIndicator;
        } | null;
    };
    ssl: {
        validationStatus: StatusIndicator | null;
        lastRenewStatus: StatusIndicator | null;
        lastRenewTime: StatusIndicator | null;
        expirationTime: StatusIndicator | null;
        renewWorkflow: StatusIndicator | null;
    };
    dns: {
        root: StatusIndicator | null;
        www: StatusIndicator | null;
    };
}

export class TundraStorefrontStatusResolver {

    public readonly status: TundraStorefrontStatus;

    constructor(
        domain: TundraStorefrontDomain,
        subDomain?: TundraStorefrontDomain
    ) {
        this.status = {
            scan: {
                root: {
                    status: this.getLastScannedStatus(domain),
                    time: this.getLastScannedTime(domain)
                },
                www: subDomain ? {
                    status: this.getLastScannedStatus(subDomain),
                    time: this.getLastScannedTime(subDomain)
                } : null
            },
            ssl: {
                validationStatus: this.getValidationStatus(domain),
                lastRenewStatus: this.getLastRenewedStatus(domain),
                lastRenewTime: this.getLastRenewedTime(domain),
                expirationTime: this.getExpirationTime(domain),
                renewWorkflow: this.getRenewalWorkflow(domain)
            },
            dns: {
                root: this.getRootDns(domain),
                www: this.getWwwDns(domain, subDomain)
            }
        };
    }

    private getLastScannedStatus(domain: TundraStorefrontDomain): StatusIndicator {
        if (domain.alias.lastScannedStatus === "OK") {
            return {
                status: "OK",
                details: "The domain was last scanned successfully.",
                intent: "success"
            };
        }

        if (domain.alias.lastScannedStatus === "NOT_REACHABLE") {
            return {
                status: "NOT REACHABLE",
                details: "The domain was not able to be reached, please check the DNS configuration.",
                intent: "warning"
            };
        }

        return {
            status: domain.alias.lastScannedStatus ?? "UNKNOWN",
            details: "The domain was not scanned successfully.",
            intent: "danger"
        }
    }

    private getLastScannedTime(domain: TundraStorefrontDomain): StatusIndicator {
        if (domain.alias.lastScannedTime) {
            const elapsedTime: ElapsedTimeDetails = getElapsedTimeDetails(new Date(domain.alias.lastScannedTime));
            if (elapsedTime.unit !== "day(s)") { // last 24 hours
                return {
                    status: "OK",
                    details: `The domain was successfully scanned ${elapsedTime.time} ${elapsedTime.unit} ago`,
                    intent: "success"
                };
            } else {
                return {
                    status: "Stale",
                    details: `The domain was last scanned ${elapsedTime.time} ${elapsedTime.unit} ago`,
                    intent: "warning"
                };
            }
        }

        return {
            status: "Never scanned",
            details: "The domain has never been scanned successfully.",
            intent: "danger"
        }
    }

    private getLastRenewedStatus(domain: TundraStorefrontDomain): StatusIndicator | null {
        // Default URLs are always good to go
        if (isInternalDomain(domain.alias.alias)) {
            return null;
        }

        if (domain.alias.lastCertificateRenewal && domain.alias.lastCertificateRenewal === "OK") {
            return {
                status: "OK",
                details: "The certificate was last renewed successfully.",
                intent: "success"
            };
        }

        return {
            status: "Not renewed",
            details: "The certificate failed to be renewed.",
            intent: "danger"
        }
    }

    private getLastRenewedTime(domain: TundraStorefrontDomain): StatusIndicator | null {
        // Default URLs are always good to go
        if (isInternalDomain(domain.alias.alias)) {
            return null;
        }

        if (domain.alias.lastCertificateRenewalTime) {
            const elapsedTime = getElapsedTimeDetails(new Date(domain.alias.lastCertificateRenewalTime));
            if (["second(s)", "minute(s)", "hour(s)"].includes(elapsedTime.unit) || elapsedTime.time < 50) { // 50 days
                return {
                    status: "OK",
                    details: `The certificate was last renewed ${elapsedTime.time} ${elapsedTime.unit} ago`,
                    intent: "success"
                };
            } else if (elapsedTime.unit === "day(s)" && elapsedTime.time < 60) { // 60 days
                return {
                    status: "Approaching target renewal",
                    details: `The certificate was last renewed ${elapsedTime.time} ${elapsedTime.unit} ago, which is approaching the 60 day renew target.`,
                    intent: "none"
                };
            } else if (elapsedTime.unit === "day(s)" && elapsedTime.time < 90) { // 90 days
                return {
                    status: "Approaching expiration",
                    details: `The certificate was last renewed ${elapsedTime.time} ${elapsedTime.unit} ago, which is past the 60 day renew target and approaching the 90 day expiration.`,
                    intent: "warning"
                }
            } else { // 90+ days
                return {
                    status: "Expired",
                    details: `The certificate was last renewed ${elapsedTime.time} ${elapsedTime.unit} ago, which means it has likely expired.`,
                    intent: "danger"
                };
            }
        }

        return {
            status: "Never renewed",
            details: "The certificate has never been renewed successfully.",
            intent: "danger"
        }
    }

    private getExpirationTime(domain: TundraStorefrontDomain): StatusIndicator | null {
        // Default URLs are always good to go
        if (isInternalDomain(domain.alias.alias)) {
            return null;
        }

        if (domain.alias.lastCertificateExpirationTime) {
            const elapsedTime = getElapsedTimeDetails(new Date(domain.alias.lastCertificateExpirationTime));
            if (elapsedTime.direction === "future") {
                return {
                    status: "OK",
                    details: `The certificate will not expire for ${Math.abs(elapsedTime.time)} ${elapsedTime.unit}`,
                    intent: "success"
                };
            } else {
                return {
                    status: "Expired",
                    details: `The certificate may have expired ${elapsedTime.time} ${elapsedTime.unit} ago`,
                    intent: "danger"
                };
            }
        }

        return {
            status: "Unknown expiration",
            details: "It is unknown when the certificate is set to expire.",
            intent: "danger"
        }
    }

    private getRenewalWorkflow(domain: TundraStorefrontDomain): StatusIndicator | null {
        // Default URLs are always good to go
        if (isInternalDomain(domain.alias.alias)) {
            return null;
        }

        // Otherwise, the domain should have a renewal associated with it
        if (domain.renewal) {

            const workflowStartTimeMs: number = new Date(domain.renewal.startDate).getTime();
            let inputObj: any = {};
            try {
                inputObj = JSON.parse(domain.renewal.input ?? "{}");
            } catch (e) { /* Eat it */ }
            const workflowDelaySeconds: number | null = inputObj["delay"] ?? null;

            if (domain.renewal.status === "RUNNING" && workflowDelaySeconds !== null) {
                const endTime: Date = new Date(workflowStartTimeMs + (workflowDelaySeconds * 1000));
                const elapsedTime: ElapsedTimeDetails = getElapsedTimeDetails(endTime);
                return {
                    status: "OK",
                    details: `The certificate renewal workflow is set to renew the certificate in ${elapsedTime.time} ${elapsedTime.unit}`,
                    intent: "success"
                };
            } else {
                return {
                    status: "Workflow stalled",
                    details: "Please have @engineering check the renewal workflow status for more information.",
                    intent: "warning"
                };
            }
        }

        // No idea
        return {
            status: "No workflow found",
            details: "Please have @engineering create a renewal workflow before the active certificate expires.",
            intent: "danger"
        };
    }

    private getRootDns(domain: TundraStorefrontDomain): StatusIndicator | null {
        // Default URLs are always good to go
        if (isInternalDomain(domain.alias.alias)) {
            return null;
        }

        if (domain.dns && domain.dns.values.length > 0) {
            if (domain.dns.type === "A" && domain.dns.values.length === 1 && domain.dns.values[0] === "3.219.253.138") {
                return {
                    status: "OK",
                    details: "The A record(s) for this domain are set correctly.",
                    intent: "success"
                }
            } else if (domain.dns.type === "A" && domain.dns.values.length > 1 && domain.dns.values.includes("3.219.253.138")) {
                return {
                    status: "Invalid",
                    details: "The A record(s) for this domain are set incorrectly.  There are other A records on this domain record which conflict with the expected target of 3.219.253.138",
                    intent: "warning"
                }
            } else {
                return {
                    status: "Invalid",
                    details: `The A record(s) for this domain are set incorrectly. There should be one A record pointing to 3.219.253.138 - type: ${domain.dns.type} [${domain.dns.values}]`,
                    intent: "warning"
                }
            }
        }

        return {
            status: "Could not resolve",
            details: "DNS is not configured correctly for this domain - there should be one A record pointing to 3.219.253.138",
            intent: "danger"
        }
    }

    private getWwwDns(domain: TundraStorefrontDomain, subDomain?: TundraStorefrontDomain): StatusIndicator | null {
        // Default URLs are always good to go
        if (!subDomain || isInternalDomain(subDomain.alias.alias)) {
            return null;
        }

        if (subDomain.dns && subDomain.dns.values.length > 0) {
            if (subDomain.dns.type === "A") {
                return this.getRootDns(subDomain);
            }

            if (subDomain.dns.type === "CNAME" && subDomain.dns.values.length === 1 && subDomain.dns.values[0] === domain.alias.alias) {
                return {
                    status: "OK",
                    details: "The CNAME record(s) for this domain are set correctly.",
                    intent: "success"
                }
            } else {
                return {
                    status: "Invalid",
                    details: `The CNAME record(s) for this domain are set incorrectly. There should be one CNAME record pointing to ${domain.alias.alias} Type: ${subDomain.dns.type} [${subDomain.dns.values}]`,
                    intent: "warning"
                }
            }
        }

        return {
            status: "Could not resolve",
            details: `DNS is not configured correctly for this subdomain - there should be one CNAME record pointing to ${domain.alias.alias}`,
            intent: "danger"
        }
    }

    private getValidationStatus(domain: TundraStorefrontDomain): StatusIndicator | null {
        // Default URLs are always good to go
        if (isInternalDomain(domain.alias.alias)) {
            return null;
        }

        if (domain.certificate) {
            if (domain.certificate.validationStatus === "ISSUED") {
                return {
                    status: "OK",
                    details: "The certificate has been successfully issued.",
                    intent: "success"
                }
            } else if (["N/A", "EXPIRED", "FAILED", "REVOKED"].includes(domain.certificate.validationStatus)) {
                return {
                    status: domain.certificate.validationStatus,
                    details: "The certificate failed validation.",
                    intent: "danger"
                }
            } else {
                return {
                    status: domain.certificate.validationStatus,
                    details: "The certificate has not yet been validated.",
                    intent: "warning"
                }
            }
        }

        return null;
    }

}
