import {Pane, TextInputField} from "evergreen-ui";
import {ChangeEvent, FC, useState} from "react";
import {productOnboardingHelper} from "../../datastore/local/ProductOnboardingHelper";
import {StoreSelector} from "../../common/StoreSelector";

interface InfoSelectorInputProps {
    name: string;
    label: string;
    placeholderText: string;
    value: string;
    onChange: (value: string) => void;
}

interface InfoSelectorPageProps {
    title: string;
    description: string;
    topInputProps: InfoSelectorInputProps;
}

export const InfoSelectorPage: FC<InfoSelectorPageProps> = ({
    title,
    description,
    topInputProps,
}) => {

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        topInputProps.onChange(value);
    };

    const renderStoreSelector = () => {
        return (
            <StoreSelector
                inputStyle={{height: 50}}
                required={true}
                onStoreConfirmed={() => {
                }}
                onStoreChanged={(newStoreName, existingStoreId) => {
                    if (existingStoreId) {
                        productOnboardingHelper.setExistingStoreId(existingStoreId);
                    } else {
                        productOnboardingHelper.setNewStoreName(newStoreName);
                    }
                }}
            />
        );
    }

    return (
        <div className={"jc-wizard-container-info-page"}>
            <div className={"jc-wizard-header"}>
                <h1 className={"jc-wizard-title"}>
                    {title}
                </h1>
                <span className={"jc-wizard-description"}>
                    {description}
                </span>
            </div>
            <Pane>
                <TextInputField
                    inputHeight={50}
                    style={{
                        fontSize: 12
                    }}
                    required={true}
                    name={topInputProps.name}
                    label={topInputProps.label}
                    value={topInputProps.value}
                    placeholder={topInputProps.placeholderText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
                />
                {renderStoreSelector()}
            </Pane>
        </div>
    )
}
