import { PreDesignReviewState } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class PreDesignReviewStateMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [PreDesignReviewState.Submitted]: {
                label: "Submitted",
                value: PreDesignReviewState.Submitted,
                color: "neutral"
            },
            [PreDesignReviewState.EditsRequired]: {
                label: "Edits Required",
                value: PreDesignReviewState.EditsRequired,
                color: "yellow"
            },
            [PreDesignReviewState.Approved]: {
                label: "Approved",
                value: PreDesignReviewState.Approved,
                color: "green"
            },
            [PreDesignReviewState.Rejected]: {
                label: "Rejected",
                value: PreDesignReviewState.Rejected,
                color: "red"
            },
        };
    }
}