import { ApolloQueryResult, DocumentNode, gql } from "@apollo/client";
import { ProductListingsInput } from "../../../common/inputs/ProductListingsInput";
import { ProductListing, User, UserExists } from "../../../common/models";
import {
    BaseMutationTuple,
    BaseQueryResult,
    jssaQuery,
    useBaseMutation,
    useBaseQuery
} from "./index";
import { cleanObject } from "../../../common/utils";
import { useUserContext } from "../context/UserContext";
import {
    productListingAllFieldsFragment,
    productListingForAdminFieldsFragment,
    userAllFieldsFragment
} from "./CommonQueries";
import { QueryInput } from "../../../common/query-filters";
import { ProductListingForAdmin } from "../../../common/tables/product/ProductListingForAdmin";

const userExistsQuery: DocumentNode = gql`
    query userExists($email: String!) {
        userExists(email: $email) {
            exists
            invitationPending
            loginStatus
        }
    }
`;

export const userQuery = gql`
    ${userAllFieldsFragment}
    query user {
        user {
            ...UserAllFields
        }
    }
`;

export const productListingsForUserQuery = gql`
    ${productListingAllFieldsFragment}
    query productListingsForUser($options: ProductListingsInput!) {
        productListingsForUser(options: $options) {
            ...ProductListingAllFields
        }
    }
`;

export const productListingsForAdminQuery = gql`
    ${productListingForAdminFieldsFragment}
    query productListingsForAdmin($options: ProductListingsInput!) {
        productListingsForAdmin(options: $options) {
            ...ProductListingForAdminFields
        }
    }
`;

const updateUserMutation = gql`
    ${userAllFieldsFragment}
    mutation updateUser($data: UserUpdateInput!) {
        updateUser(data: $data) {
            ...UserAllFields
        }
    }
`;

export const doesAccountExist = (email: string): Promise<UserExists> => {
    return new Promise<UserExists>((resolve, reject) => {
        jssaQuery({
            query: userExistsQuery,
            variables: {
                email,
            },
        }).then(({data: {userExists}}) => {
            resolve(userExists);
        }).catch((error) => {
            reject(error);
        });
    });
};

export const useUserQuery = (): BaseQueryResult<User> => {
    return useBaseQuery<User, { user: User }>(userQuery, {}, ({data}) => {
        return data.user;
    });
};

export const useProductListingsForUserQuery = (options: ProductListingsInput = {}): BaseQueryResult<ProductListing[]> => {
    return useBaseQuery<ProductListing[], {productListingsForUser: ProductListing[]}>(
        productListingsForUserQuery,
        { options },
        ({data}) => {
            return data.productListingsForUser;
        });
}

export type UpdateUserMutationTuple = BaseMutationTuple<User, User>;

export const useUpdateUserMutation = (): UpdateUserMutationTuple => {
    const {updateUser} = useUserContext();
    const transformInput = (user: User) => {
        return {
            data: cleanObject(user, {
                exclusions: [
                    "email",
                    "id",
                    "moxtraId",
                    "nativeMessagingEnabled",
                    "twilioUserId",
                    "rampartUniqueId",
                    "rampartGroups",
                    "clientTypes"
                ]
            })
        };
    };
    const transformOutput = (output: any): User => {
        return output.updateUser;
    };
    const onResponse = (user: User) => {
        updateUser(user);
    };
    return useBaseMutation<User, User>(
        updateUserMutation,
        transformInput,
        transformOutput,
        onResponse,
    );
};

// TODO: for consistency on both the client and server, we should have all product-related GQL
//  resources live under the ProductListing node, not the User node.
type QueryProductListingsForAdminResponse = { productListingsForAdmin: ProductListing[] };

export const queryProductListingsForAdmin = (
    queryInput: QueryInput<ProductListingForAdmin>,
): Promise<ProductListing[]> => {
    return jssaQuery<QueryProductListingsForAdminResponse>({
        query: productListingsForAdminQuery,
        variables: {
            options: queryInput,
        },
    }).then(({data}: ApolloQueryResult<QueryProductListingsForAdminResponse>) => {
        return data.productListingsForAdmin;
    });
}
