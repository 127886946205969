import React, { useCallback, useEffect, useState } from "react";
import { ChatIcon, Pane, Table } from "evergreen-ui";
import { EmptyState } from "../../common/EmptyState";
import { useMessagingClientAPIContext, useMessagingDataContext } from "../state/NativeMessagingProvider";
import { ConversationMetadata } from "../types";
import { ConversationListEntryView } from "./ConversationListEntry";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { ResizablePane } from "../../common/ResizablePane";

import './ConversationListView.css';

export interface ConversationListViewProps {
    isMobile?: boolean
}

interface MemoRowProps {
    key: string,
    isSelected: boolean,
    conversationMetadata: ConversationMetadata,
    selectConversation: (sid: string) => void,
}

interface MemoTableProps {
    visibleConversationList: ConversationMetadata[],
    setSearchText: (searchText: string) => void,
    selectConversation: (sid: string) => void,
}

const MemoRow = React.memo(({
    isSelected,
    conversationMetadata,
    selectConversation
}: MemoRowProps) => {
    return (
        <Table.Row
            className="clv-row"
            isSelectable={true}
            isSelected={isSelected}
            onClick={() => selectConversation(conversationMetadata.sid)}
        >
            <Table.Cell padding={0}>
                <ConversationListEntryView key={conversationMetadata.sid} {...conversationMetadata}/>
            </Table.Cell>
        </Table.Row>
    );
});

const MemoTable = React.memo(({
    visibleConversationList,
    setSearchText,
    selectConversation,
}: MemoTableProps) => {
    const {
        activeConversationSid,
        conversationStore,
        conversationStoreError
    } = useMessagingDataContext();

    const showContents = (): boolean => {
        return (Object.keys(conversationStore).length > 0) && !conversationStoreError;
    };

    const onSearchTextChange = (value: string): void => {
        setSearchText(value.toLowerCase());
    };

    return (
        <Table className="clv-table">
            <Table.Head>
                <Table.SearchHeaderCell onChange={onSearchTextChange}/>
            </Table.Head>
            <Table.Body className="clv-body">
                {showContents() ?
                    visibleConversationList.map((conversationMetadata: ConversationMetadata) => {
                        return (
                            <MemoRow
                                key={conversationMetadata.sid}
                                isSelected={conversationMetadata.sid === activeConversationSid}
                                conversationMetadata={conversationMetadata}
                                selectConversation={selectConversation}
                            />
                        )
                    }) :
                    <EmptyState
                        title={conversationStoreError ?
                            "Failed to load conversations" :
                            "No conversations found."
                        }
                        subtitle={" "}
                    >
                        <ChatIcon size={60}/>
                    </EmptyState>
                }
            </Table.Body>
        </Table>
    );
});

export const ConversationListView = React.memo((props: ConversationListViewProps) => {

    const {isMobile} = props;
    const [ visibleConversationList, setVisibleConversationList ] = useState<ConversationMetadata[]>([]);
    const [ searchText, setSearchText ] = useState<string>("");

    const {
        showListView,
        conversationStore,
        conversationStoreLoaded,
    } = useMessagingDataContext();

    const {
        toggleShowListView,
        updateActiveConversation
    } = useMessagingClientAPIContext();

    useEffect(() => {
        const conversations = Object.values(conversationStore)
            .filter(conversation => conversation.friendlyName.toLowerCase().includes(searchText))
            .sort(dateComparator);
        setVisibleConversationList(conversations);
    }, [conversationStore, searchText]);

    // Show newest first, oldest last
    const dateComparator = (a: ConversationMetadata, b: ConversationMetadata): number => {
        if (a.lastUpdated && !b.lastUpdated) {
            return -1;
        } else if (!a.lastUpdated && !b.lastUpdated) {
            return 1;
        } else if (!a.lastUpdated && !b.lastUpdated) {
            return 0;
        }

        if (a.lastUpdated && b.lastUpdated) {
            if (a.lastUpdated > b.lastUpdated) {
                return -1;
            } else if (a.lastUpdated < b.lastUpdated) {
                return 1;
            } else {
                return 0;
            }
        }

        return 0;
    };

    const selectConversation = useCallback((sid: string) => {
        updateActiveConversation(sid);
        if (isMobile) {
            toggleShowListView(false);
        }
    }, [isMobile, toggleShowListView, updateActiveConversation])

    return (
        <ResizablePane
            float={"left"}
            width={isMobile ? "100%" : "300px"}
            minWidth={"200px"}
            resizeDisabled={isMobile}
            maxWidth={isMobile ? "100%" : "calc(100% - 200px)"}
            height={"100%"}
            display={showListView || !isMobile ? "flex": "none"}
        >
            {conversationStoreLoaded ? (
                <MemoTable
                    visibleConversationList={visibleConversationList}
                    setSearchText={setSearchText}
                    selectConversation={selectConversation}
                />
            ) : (
                <CenteredSpinner/>
            )}
        </ResizablePane>
    );
});
