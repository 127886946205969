import { CsSpecialist } from "../../../../../../common/enums";
import { EnumMapper, ResolvedEnum } from "../../../../common/tables/etc/EnumMapper";

export class CsSpecialistMapper implements EnumMapper {
    public map(): ResolvedEnum {
        return {
            [CsSpecialist.Riza]: {
                label: CsSpecialist.Riza,
                value: CsSpecialist.Riza,
                color: "neutral"
            },
            [CsSpecialist.Alliza]: {
                label: CsSpecialist.Alliza,
                value: CsSpecialist.Alliza,
                color: "neutral"
            },
            [CsSpecialist.Louella]: {
                label: CsSpecialist.Louella,
                value: CsSpecialist.Louella,
                color: "neutral"
            },
            [CsSpecialist.Chelsey]: {
                label: CsSpecialist.Chelsey,
                value: CsSpecialist.Chelsey,
                color: "neutral"
            },
        };
    }
}