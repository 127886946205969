import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Button, majorScale, Pane, toaster } from "evergreen-ui";
import { doesAccountExist } from "../datastore/UserDataStore";
import { TextButton } from "../common/TextButton";
import { analytics, EventName } from "../analytics";
import { EMAIL_MAX_LENGTH, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH } from "../../../common/constants/InputFormFieldLengths";
import { useFormValidation } from "../common/FormValidation";
import { LimitedTextInputField } from "../common/LimitedTextInputField";
import { StytchContext } from "../stytch/StytchContext";
import { UserLoginStatus } from "../../../common/enums";
import { CenteredSpinner } from "../common/CenteredSpinner";
import { authenticate, login, signup } from "../datastore/AuthDataStore";
import { productOnboardingHelper } from "../datastore/local/ProductOnboardingHelper";
import { StytchOTPInput } from "../stytch/StytchOTPInput";
import { NavigationHelper } from "./PrevLocationState";

import '../pages/styles/Login.css';

interface LoginFormProps {
    forNewAccount?: boolean;
    disableFormToggle?: boolean;
}

class LoginFormError extends Error{
    setEmailInvalid: boolean;

    constructor(msg: string, setEmailInvalid: boolean) {
        super(msg);
        this.setEmailInvalid = setEmailInvalid;
    }
}

enum LoginFormState {
    initialized,
    sendingOTP,
    waitingToAuthOTP,
    authOTP
}

export const StytchLoginForm: FC<LoginFormProps> = (
    {
        forNewAccount = false,
        disableFormToggle = false,
    }
) => {
    const location = useLocation<{ from: string }>();
    const params = new URLSearchParams(location.search);
    const [isNewAccountForm, setIsNewAccountForm] = useState<boolean>(forNewAccount);
    const {isAuthenticated, isLoading: isStytchLoading} = useContext(StytchContext);
    const [formToggleDisabled, setFormToggleDisabled] = useState<boolean>(disableFormToggle);
    const {invalidFields, onInvalidChange} = useFormValidation();
    const [rawEmailFromInput, setRawEmailFromInput] = useState<string>(params.get("email") || "");
    const [formattedEmail, setFormattedEmail] = useState<string>(rawEmailFromInput.trim().toLowerCase());
    const [firstName, setFirstName] = useState<string>(params.get("firstName") || "");
    const [lastName, setLastName] = useState<string>(params.get("lastName") || "");
    const [formErrMsg, setFormErrMsg] = useState<string>("");
    const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
    const [formState, setFormState] = useState<LoginFormState>(LoginFormState.initialized);
    const [passcodeInvalid, setPasscodeInvalid] = useState<boolean>(false);
    const [passcode, setPasscode] = useState<string>("");
    const [emailId, setEmailId] = useState<string>("");
    const [OTPError, setOTPError] = useState<boolean>(false);
    const { setIsAuthenticated, setIsInvited } = useContext(StytchContext);
    const history = useHistory();


    useEffect(() => {
        if (!isAuthenticated
            && params.get("firstName")
            && params.get("lastName")
            && params.get("email")
            && params.get("isNewAccount")
            && formState === LoginFormState.initialized) {
            sendOTP();
        }
        // Clear any lingering form error message whenever the form inputs change
        setFormErrMsg("");
        setEmailInvalid(false);
    }, [firstName, lastName, rawEmailFromInput, isNewAccountForm]);

    const getTitle = () => {
        switch(formState) {
            case LoginFormState.waitingToAuthOTP:
            case LoginFormState.authOTP:
                return "Check your email! \u2709\uFE0F";
            default:
                return "Welcome! 👋"
        }
    };

    const getSubtitle = () => {
        return formState === LoginFormState.waitingToAuthOTP || formState === LoginFormState.authOTP ?
            <p>Please enter the one-time passcode sent to your email.</p> :
            <p>Submit your information below and we'll send you a passcode to your email.</p>
    };

    const onSignIn = async (exists: boolean, status?: keyof typeof UserLoginStatus) => {
        if (!exists || status === UserLoginStatus.pendingRegistration) {
            throw new LoginFormError("Email not found. Please create a new account.", true);
        }
        return login(formattedEmail)
            .then(resp => Promise.all([resp.status, resp.json()]))
            .then(([resp_status, resp_json]) => {
                return signInCompleteCallback(resp_status, resp_json);
            });
    };

    const onSignUp = async (exists: boolean, status?: keyof typeof UserLoginStatus) => {
        if (exists && status === UserLoginStatus.active) {
            setFormToggleDisabled(false);
            throw new LoginFormError("This account already exists. Click below to login", true);
        }
        analytics.track(EventName.SignUpStarted);
        return signup(formattedEmail, firstName, lastName, productOnboardingHelper.getObject())
            .then(resp => Promise.all([resp.status, resp.json()]))
            .then(([resp_status, resp_json]) => {
                if (resp_status > 199 && resp_status < 300) {
                    analytics.track(EventName.SignUpCompleted);
                }
                return signInCompleteCallback(resp_status, resp_json);
            });
    };

    const signInCompleteCallback = (responseStatus: number, responseJson: any): string => {
        if (responseStatus > 199 && responseStatus < 300) {
            return responseJson.email_id as string;
        } else {
            let message: string = responseJson.error;
            if (responseStatus === 429) {
                message = "Too many passcode generation attempts, please wait briefly before trying again."
            }
            throw new LoginFormError(message, false);
        }
    }

    const onPasscodeUpdate = (valid: boolean, passcode: string) => {
        // reset the OTP error after the passcode changes
        if (OTPError) {
            setOTPError(false);
        }
        setPasscodeInvalid(!valid);
        setPasscode(passcode);
    };

    const sendOTP = async (e?: FormEvent) => {
        if (e) {
            e.preventDefault();
        }

        setFormErrMsg("");
        setFormState(LoginFormState.sendingOTP);
        analytics.track(EventName.StytchInitiated, {
            email: formattedEmail
        });
        doesAccountExist(formattedEmail)
            .then(({exists, loginStatus}) => {
                return (isNewAccountForm ? onSignUp : onSignIn)(exists, loginStatus);
            }).then((emailId: string) => {
                setEmailId(emailId);
                setFormState(LoginFormState.waitingToAuthOTP);
            }).catch(err => {
                if (err.setEmailInvalid) {
                    setEmailInvalid(true);
                }
                setFormErrMsg(err.message);
                setFormState(LoginFormState.initialized);
                analytics.sendException(err);
            });
    };

    const clickAuthenticateOTP = async (e: FormEvent) => {
        e.preventDefault();

        setFormState(LoginFormState.authOTP);
        authenticate(emailId, passcode)
            .then(resp => Promise.resolve(resp.json()))
            .then((resp_json) => {
                const data: {invitedUser: boolean} = resp_json;
                setIsInvited(data.invitedUser);
                setIsAuthenticated(true);
                if (location.state && location.state.from) {
                    history.replace(location.state.from);
                } else {
                    history.replace({
                        pathname: "/products",
                        state: {
                            accountNewlyCreated: isNewAccountForm
                        }
                    });
                }
            })
            .catch(err => {
                analytics.sendException(err);
                setOTPError(true);
                toaster.danger("Submitted passcode is invalid! Please try again.");
                setFormState(LoginFormState.waitingToAuthOTP);
            });
    };

    const onToggleLoginForm = () => {
        if (isNewAccountForm) {
            setIsNewAccountForm((prevState => !prevState));
        } else {
            history.push(NavigationHelper.createNewProductPath());
        }
    };

    const renderFormToggleText = () => {
        return isNewAccountForm
            ? "Login with existing account"
            : "New to Juniper? Get started with your first product"
    };

    const renderSubmitButton = ({
        disabled,
        isLoading,
        text,
        onClick
    }: {
        disabled: boolean,
        isLoading: boolean,
        text: string,
        onClick: (e: FormEvent) => Promise<void>;
    }) => {
        return (
            <Pane
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Button
                    disabled={disabled}
                    isLoading={isLoading}
                    appearance="primary"
                    width="100%"
                    size="large"
                    fontWeight={600}
                    onClick={onClick}
                >
                    {text}
                </Button>
            </Pane>
        )
    };

    const renderInputs = () => {
        switch(formState) {
            case LoginFormState.initialized:
            case LoginFormState.sendingOTP:
                const inputFieldStyle = {height: 40};
                return (
                    <>
                        <LimitedTextInputField
                            style={inputFieldStyle}
                            display={isNewAccountForm ? undefined : "none"}
                            maxChars={FIRST_NAME_MAX_LENGTH}
                            name="firstName"
                            label={"First Name"}
                            placeholder="Felix"
                            disabled={formState === LoginFormState.sendingOTP}
                            required
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            onInvalidChange={onInvalidChange}
                            type="text"
                        />
                        <LimitedTextInputField
                            style={inputFieldStyle}
                            display={isNewAccountForm ? undefined : "none"}
                            maxChars={LAST_NAME_MAX_LENGTH}
                            name="lastName"
                            label={"Last Name"}
                            placeholder="Kjellberg"
                            disabled={formState === LoginFormState.sendingOTP}
                            required
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            onInvalidChange={onInvalidChange}
                            type="text"
                        />
                        <LimitedTextInputField
                            style={inputFieldStyle}
                            maxChars={EMAIL_MAX_LENGTH}
                            name="email"
                            label="Email Address"
                            placeholder="email@example.com"
                            disabled={formState === LoginFormState.sendingOTP}
                            required
                            value={rawEmailFromInput}
                            onChange={e => {
                                const {value} = e.target;
                                setRawEmailFromInput(value);
                                setFormattedEmail(value.trim().toLowerCase());
                            }}
                            onInvalidChange={onInvalidChange}
                            type="email"
                            isInvalid={emailInvalid}
                            validationMessage={formErrMsg}
                        />
                        {renderSubmitButton({
                            disabled: invalidFields.length > 0,
                            isLoading: formState === LoginFormState.sendingOTP,
                            text: isNewAccountForm ? "Create Account" : "Sign in",
                            onClick: sendOTP
                        })}
                    </>
                );
            case LoginFormState.waitingToAuthOTP:
            case LoginFormState.authOTP:
                return (
                    <>
                        <StytchOTPInput
                            disabled={formState === LoginFormState.authOTP}
                            error={OTPError}
                            onPasscodeUpdate={onPasscodeUpdate}
                        />
                        {renderSubmitButton({
                            disabled: passcodeInvalid || formState === LoginFormState.authOTP,
                            isLoading: formState === LoginFormState.authOTP,
                            text: isNewAccountForm ? "Complete Registration" : "Confirm",
                            onClick: clickAuthenticateOTP
                        })}
                        <br />
                        <TextButton label={"Click here to resend one-time passcode."} onClick={sendOTP} />
                    </>
                );
        }
    };

    const renderFormContents = () => {
        const formToggleButton = !formToggleDisabled && formState === LoginFormState.initialized ? (
            <TextButton label={renderFormToggleText()} onClick={onToggleLoginForm}/>
        ) : null;
        return (
            <>
                <Pane
                    paddingBottom={majorScale(5)}
                >
                    <form>
                        {renderInputs()}
                    </form>
                </Pane>
                {formToggleButton}
            </>
        );
    };

    if (isStytchLoading) {
        return <CenteredSpinner/>;
    }
    return !isAuthenticated ?
        <Pane>
            <h1 className="js-login-title">{getTitle()}</h1>
            <span className="js-login-subtitle">{getSubtitle()}</span>
            <Pane className="js-login-container">
                {renderFormContents()}
            </Pane>
        </Pane>
    : <Redirect to="/"/>;
};
