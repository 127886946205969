import { CatalogProduct } from "./CatalogProduct";

// Temporary hack to indicate a ProductCategory is custom without requiring server schema changes.
export const CUSTOM_CATEGORY_NAME = "Custom Product Category";

export interface CatalogCategory {
    id: number;
    name: string;
    displayName?: string;
    shortDescription?: string;
    imageUrl?: string;
    description?: string;
    catalogProducts: CatalogProduct[];
    isCustom: boolean;
    rank: number;
    isPopular: boolean;
    isNew: boolean;
}
