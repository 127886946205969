import React, { CSSProperties, FC } from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeftIcon, Button } from "evergreen-ui";
import { ColorPalette } from "../context/Theme";
import { NavigationHelper, PrevLocationState } from "./PrevLocationState";

interface Props {
    backNavigation?: PrevLocationState;
    style?: CSSProperties;
    className?: string;
    size?: 'small' | 'medium' | 'large';
}

export const BackNavigationButton: FC<Props> = (props) => {
    const { backNavigation, style, className, size } = props;
    const history = useHistory();

    return (
        <Button
            className={className}
            style={{
                marginLeft: "-12px",
                color: ColorPalette.primaryAccent,
                fontWeight: 500,
                ...style,
            }}
            size={size || 'medium'}
            appearance="minimal"
            iconBefore={ArrowLeftIcon}
            onClick={NavigationHelper.onBack(history, backNavigation)}
        >
            {backNavigation?.label || NavigationHelper.DefaultLabel}
        </Button>
    );
};
