import React, { ChangeEvent, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    IconButton,
    Menu,
    Pane,
    PeopleIcon,
    PlusIcon,
    Popover,
    Position,
    Table,
    TextInputField,
    toaster,
    TrashIcon
} from "evergreen-ui";
import { useParams } from "react-router-dom";
import { Store, User } from "../../../../common/models";
import { useUserContext } from "../../context/UserContext";
import { useAddUserToStoreMutation, useStoreQuery, useRemoveUserFromStoreMutation } from "../../datastore/StoreDataStore";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { isInternalEmail } from "../../../../common/utils/isInternalEmail";
import { DetailsPageSection } from "../../common/DetailsPageSection";
import { EmptyState } from "../../common/EmptyState";

type UserInviteFieldsType = { email?: string, firstName?: string, lastName?: string };
export const StoreTeamTab = () => {
    const { user } = useUserContext();
    const { storeId } = useParams<{ storeId: string }>();

    const [inviteDialogShown, setInviteDialogShown] = useState<boolean>(false);
    const [userInviteFields, setUserInviteFields] = useState<UserInviteFieldsType>({});
    const [members, setMembers] = useState<User[]>([]);
    const [store, setStore] = useState<Store | null>(null);
    const { loading, error, data } = useStoreQuery(storeId);

    const [
        addUserToStore,
        {
            loading: addUserLoading,
            error: addUserError,
            called: addUserCalled,
            data: storeWithAddedUser,
        }
    ] = useAddUserToStoreMutation();
    const [
        removeUserFromStore,
        {
            loading: removeUserLoading,
            error: removeUserError,
            called: removeUserCalled,
            data: storeWithRemovedUser,
        }
    ] = useRemoveUserFromStoreMutation();

    useEffect(() => {
        if (!removeUserLoading && removeUserCalled) {
            if (removeUserError) {
                toaster.warning("Server error! Unable to remove user.");
            } else {
                toaster.closeAll();
                toaster.success(`User was removed successfully.`, { duration: 2 });
                updateMembers(storeWithRemovedUser);
            }
        }
    }, [removeUserLoading, removeUserError, removeUserCalled, storeWithRemovedUser]);

    useEffect(() => {
        if (!addUserLoading && addUserCalled) {
            if (addUserError) {
                toaster.warning("Server error! Unable to add user.");
            } else {
                setInviteDialogShown(false);
                setUserInviteFields({});
                toaster.success(`User was added successfully.`, { duration: 2 });
                updateMembers(storeWithAddedUser);
            }
        }
    }, [addUserLoading, addUserError, addUserCalled, storeWithAddedUser]);

    useEffect(() => {
        if (!loading && !error) {
            updateMembers(data);
            setStore(data || null);
        }
    }, [loading, error, data]);

    const updateMembers = (store?: Store | null) => {
        const fetchedMembers = new Set<User>();
        store?.organizations!.forEach((organization) => {
            organization.members!.forEach((user) => {
                fetchedMembers.add(user);
            });
        });
        setMembers(Array.from(fetchedMembers));
    };

    const onRemoveUser = (email: string, closePopover: () => void) => {
        removeUserFromStore({ storeId: storeId, email });
        toaster.notify("Processing...", { duration: 0.5 });
        closePopover();
    };

    const isSelf = (member: User): boolean => {
        return (member.email === user!.email);
    };

    const renderAddUserDialog = () => {
        const { email = "", firstName = "", lastName = "" } = userInviteFields;

        const onInviteFieldChanged = (event: ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            setUserInviteFields({
                ...userInviteFields,
                [name]: value,
            });
        };

        const isLoading = addUserCalled && addUserLoading;

        return (
            <Dialog
                isShown={inviteDialogShown}
                title={`Add a collaborator to ${store?.name || "Store"}`}
                onCloseComplete={() => {
                    setInviteDialogShown(false);
                }}
                isConfirmLoading={isLoading}
                isConfirmDisabled={!email || !firstName || !lastName}
                onConfirm={() => {
                    addUserToStore({
                        storeId: storeId,
                        email,
                        firstName,
                        lastName,
                    })
                }}
                confirmLabel="Invite"
            >
                <Pane>
                    <p style={{ margin: "0 0 30px 0px" }}>
                        If the invitee does not yet have a Juniper account, they can sign in with
                        the email address provided below to access the store info and its associated chats and dashboards.
                    </p>
                    <TextInputField
                        name="firstName"
                        required
                        disabled={isLoading}
                        label="First Name"
                        value={userInviteFields.firstName || ""}
                        onChange={onInviteFieldChanged}
                    />
                    <TextInputField
                        name="lastName"
                        required
                        disabled={isLoading}
                        label="Last Name"
                        value={userInviteFields.lastName || ""}
                        onChange={onInviteFieldChanged}
                    />
                    <TextInputField
                        name="email"
                        required
                        disabled={isLoading}
                        label="Email Address"
                        value={userInviteFields.email || ""}
                        onChange={onInviteFieldChanged}
                    />
                </Pane>
            </Dialog>
        )
    };

    const renderDeleteButton = (member: User) => {
        return (!isSelf(member) && !isInternalEmail(member.email)) ? (
            <Popover
                position={Position.BOTTOM}
                content={({ close }: { close: () => void }) => (
                    <Menu>
                        <Menu.Group>
                            <Menu.Item
                                onSelect={() => onRemoveUser(member.email, close)}
                                intent="danger"
                            >
                                Remove User
                            </Menu.Item>
                        </Menu.Group>
                    </Menu>
                )}
            >
                <IconButton icon={TrashIcon} appearance="minimal" />
            </Popover>
        ) : null;
    };

    const renderRow = (member: User) => {
        const inviteStatus = () => {
            if (isSelf(member)) {
                return "Me";
            } else if (isInternalEmail(member.email)) {
                return "Added by Juniper";
            } else {
                return (member.loginStatus === "pendingInvitation" ? "Invite Pending" : "Added")
            }
        };

        return (
            <Table.Row key={member.email}>
                <Table.TextCell>{member.email}</Table.TextCell>
                <Table.TextCell>{inviteStatus()}</Table.TextCell>
                <Table.TextCell textAlign="right">
                    {renderDeleteButton(member)}
                </Table.TextCell>
            </Table.Row>
        );
    };

    if (loading) {
        return <CenteredSpinner />;
    }

    return <>
        {members.length > 0 ? (
            <DetailsPageSection
                heading={(
                    <Pane
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <h3 style={{marginTop: 0}}>Collaborators</h3>
                        <Button
                            appearance="default"
                            iconBefore={PlusIcon}
                            onClick={() => setInviteDialogShown(true)}
                        >
                            Add User
                        </Button>
                    </Pane>
                )}
                hideBorder={true}
            >
                <Table marginY={12}>
                    <Table.Head height={40}>
                        <Table.TextHeaderCell>User</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                        <Table.TextHeaderCell />
                    </Table.Head>
                    <Table.Body>
                        {members.map((user: User) => renderRow(user))}
                    </Table.Body>
                </Table>
            </DetailsPageSection>
        ) : (
            <EmptyState
                title={"No collaborators found"}
                subtitle={"Click to invite a new user"}
                onClickSubtitle={() => setInviteDialogShown(true)}
            >
                <PeopleIcon size={60} />
            </EmptyState>
        )}
        {renderAddUserDialog()}
    </>;
};
