import React, { FC } from "react";
import { SearchInput, SearchInputProps } from "../../common/search/SearchInput";
import { queryStoresForAdmin } from "../../datastore/StoreDataStore";
import { FilterExpressionType, SortOrder } from "../../../../common/query-filters";
import { Store } from "../../../../common/models";
import { StoreForAdmin } from "../../../../common/tables/store/StoreForAdmin";

interface StoreSearchInputProps
    extends Omit<SearchInputProps<StoreForAdmin, Store>, "queryInput" | "query" | "resultsMap"> {
    searchResultSize?: number;
}

export const StoreSearchInput: FC<StoreSearchInputProps> = ({
    searchResultSize,
    placeholderText,
    leftIconContainerProps,
    spinnerProps,
    searchIconProps,
    textInputProps,
    onSelectSearchResult,
}) => {
    return (
        <SearchInput
            placeholderText={placeholderText}
            query={queryStoresForAdmin}
            queryInput={{
                expressions: [{
                    columnName: "name",
                    columnValue: [""],
                    type: FilterExpressionType.contains,
                    caseInsensitive: true,
                }],
                page: 0,
                pageSize: searchResultSize,
                sortOrder: SortOrder.ASCENDING,
                sortColumn: "internalVendorName"
            }}
            resultsMap={(store: Store) => ({
                label: store.name,
                value: store.id,
                isVerified: !!store.internalVendorName,
            })}
            onSelectSearchResult={onSelectSearchResult}
            leftIconContainerProps={leftIconContainerProps}
            spinnerProps={spinnerProps}
            searchIconProps={searchIconProps}
            textInputProps={textInputProps}
        />
    )
};
