import React, { ChangeEvent, useState } from "react";
import { Table } from "evergreen-ui";
import { TableCellChangedHandler } from "../factory";
import { ColumnConfig } from "../config/ColumnConfig";

interface TableEditableCellProps<T> {
    columnConfig: ColumnConfig<T>;
    id: string | number;
    cellValue: string;
    onCellChanged?: TableCellChangedHandler<T>;
    inputType: "number" | "text";
}

export const TableInputCell = <T,>({
    columnConfig,
    id,
    cellValue,
    onCellChanged,
    inputType,
}: TableEditableCellProps<T>) => {

    // Used to distinguish between an escape key press and a blur event.
    const escapeKeyPressedRef = React.useRef<boolean>(false);

    const [unsubmittedValue, setUnsubmittedValue ] = useState<string>(cellValue);

    const resolveCellValue = (value: string): string | number => {
        switch (inputType) {
            case "number":
                return parseFloat(value);
            case "text":
            default:
                return value;
        }
    }

    const submitValueIfNeeded = () => {
        if (cellValue === unsubmittedValue || escapeKeyPressedRef.current) {
            return;
        }

        if (onCellChanged) {
            onCellChanged(
                id,
                columnConfig.columnName as keyof T,
                resolveCellValue(unsubmittedValue),
            );
        }
    };

    return (
        <Table.Cell className={"jc-body-cell input"}>
            <input
                type={inputType}
                value={unsubmittedValue}
                onChange={({ target : { value } }: ChangeEvent<HTMLInputElement>) => {
                    setUnsubmittedValue(value);
                }}
                onBlur={() => {
                    // Update the table cell with the new value.
                    submitValueIfNeeded();

                    // Reset the escape key flag, so that the next onBlur can submit the cell value.
                    escapeKeyPressedRef.current = false;
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === "Escape") {
                        // Reset the input value to the initial value.
                        setUnsubmittedValue(cellValue);

                        // Indicate that the escape key is triggering the onBlur event.
                        escapeKeyPressedRef.current = true;

                        // Remove focus from the input (the onBlur callback will be fired).
                        event.currentTarget.blur();
                    } else if (event.key === "Enter") {
                        // Update the table cell with the new value.
                        submitValueIfNeeded();
                    }
                }}
            />
        </Table.Cell>
    )
}
