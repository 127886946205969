import {
    ApplicationIcon,
    ArrowRightIcon,
    BoxIcon,
    BuildIcon,
    CameraIcon,
    CloudUploadIcon,
    CreditCardIcon,
    DollarIcon,
    EditIcon,
    GlobeIcon,
    InfoSignIcon,
    LightbulbIcon,
    ManuallyEnteredDataIcon,
    MobilePhoneIcon,
    RefreshIcon,
    SelectionIcon,
    SendMessageIcon,
    ShareIcon,
    ShopIcon,
    ShoppingCartIcon,
    TagIcon,
    ThumbsUpIcon
} from "evergreen-ui";
import React from "react";
import { SectionStatus } from "../../../../common/enums/SectionStatus";
import { ColorPalette } from "../../context/Theme";
import { MiniStatusBadge } from "../../MiniStatusBadge";

export interface IconPair {
    normal: any;
    done: any;
}

export const TimelineIcons: {[key: string]: IconPair} = {
    "manuallyentereddata": {
        normal: <ManuallyEnteredDataIcon color={ColorPalette.lightGreyText}/>,
        done: <ManuallyEnteredDataIcon color={ColorPalette.primaryAccent}/>
    },
    "arrowright": {
        normal: <ArrowRightIcon color={ColorPalette.lightGreyText}/>,
        done: <ArrowRightIcon color={ColorPalette.primaryAccent}/>
    },
    "creditcard": {
        normal: <CreditCardIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <CreditCardIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "infosign": {
        normal: <InfoSignIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <InfoSignIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "refresh": {
        normal: <RefreshIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <RefreshIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "lightbulb": {
        normal: <LightbulbIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <LightbulbIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "edit": {
        normal: <EditIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <EditIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "tag": {
        normal: <TagIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <TagIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "application": {
        normal: <ApplicationIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <ApplicationIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "camera": {
        normal: <CameraIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <CameraIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "thumbsup": {
        normal: <ThumbsUpIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <ThumbsUpIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "box": {
        normal: <BoxIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <BoxIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "dollar": {
        normal: <DollarIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <DollarIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "globe": {
        normal: <GlobeIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <GlobeIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "cloudupload": {
        normal: <CloudUploadIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <CloudUploadIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "shop": {
        normal: <ShopIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <ShopIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "build": {
        normal: <BuildIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <BuildIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "share": {
        normal: <ShareIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <ShareIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "shoppingcart": {
        normal: <ShoppingCartIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <ShoppingCartIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "selection": {
        normal: <SelectionIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <SelectionIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "phone": {
        normal: <MobilePhoneIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <MobilePhoneIcon size={16} color={ColorPalette.primaryAccent}/>
    },
    "sendmessage": {
        normal: <SendMessageIcon size={16} color={ColorPalette.lightGreyText}/>,
        done: <SendMessageIcon size={16} color={ColorPalette.primaryAccent}/>
    }
};

export const TimelineBadges: Record<SectionStatus, JSX.Element> = {
    "none": <></>,
    "complete": <MiniStatusBadge whiteSpace={"nowrap"} textTransform={"none"} color={"green"}>Complete</MiniStatusBadge>,
    "inProgress": <MiniStatusBadge whiteSpace={"nowrap"} textTransform={"none"} color={"red"}>In Progress</MiniStatusBadge>,
    "waiting": <MiniStatusBadge whiteSpace={"nowrap"} textTransform={"none"} color={"neutral"}>Waiting</MiniStatusBadge>
}
