import {
    IconProps,
    Pane, PaneProps,
    Popover,
    Position,
    SearchIcon,
    Spinner, SpinnerProps,
    TextInput,
    TextInputProps
} from "evergreen-ui";
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { QueryInput } from "../../../../common/query-filters";
import { ColorPalette } from "../../context/Theme";
import { SearchInputMenu } from "./SearchInputMenu";

import './styles/SearchInput.css';

export interface SearchInputProps<InputType, OutputType> {
    queryInput: QueryInput<InputType>;
    query: (queryInput: QueryInput<InputType>) => Promise<OutputType[]>;
    resultsMap: (result: OutputType) => { label: string; value: string, isVerified: boolean };
    placeholderText?: string;
    onSelectSearchResult: (label: string, value: string) => void;
    leftIconContainerProps?: PaneProps;
    spinnerProps?: SpinnerProps;
    searchIconProps?: IconProps;
    textInputProps?: TextInputProps;
}

export const SearchInput = <InputType, OutputType>({
    placeholderText,
    query,
    queryInput,
    resultsMap,
    onSelectSearchResult,
    leftIconContainerProps,
    spinnerProps,
    searchIconProps,
    textInputProps,
}: SearchInputProps<InputType, OutputType>) => {
    const [searchSubstring, setSearchSubstring] = useState<string>("");
    const [querying, setQuerying] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [results, setResults] = useState<OutputType[]>([]);
    const [isShown, setIsShown] = useState<boolean>(false);

    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);

    const onSelectSearchResultInternal = useCallback((label: string, value: string) => {
        onSelectSearchResult(label, value);
        setIsShown(false);
    }, [onSelectSearchResult]);

    const popoverContent = useMemo(() => {
        return searchSubstring && (
            <Pane className={"search-input"}>
                <SearchInputMenu
                    searchResults={results.map(resultsMap)}
                    loading={querying}
                    error={error}
                    onSelectResult={onSelectSearchResultInternal}
                />
            </Pane>
        );
    }, [searchSubstring, results, querying, error, onSelectSearchResultInternal]);

    const onEscape = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Escape") {
            setSearchSubstring("");
            event.currentTarget.blur();
        }
    }, []);

    const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchSubstring(event.target.value)
    }, []);

    useEffect(() => {
        if (!searchSubstring) {
            setResults([]);
            setError(false);
            setQuerying(false);
            return;
        }

        timeoutIdRef.current = setTimeout(() => {
            setQuerying(true);
            query({
                ...queryInput,
                expressions: [{
                    ...queryInput.expressions[0],
                    columnValue: [searchSubstring as unknown as InputType[keyof InputType]],
                }]
            }).then((_results: OutputType[]) => {
                setResults(_results);
                setError(false);
            }).catch((error: Error) => {
                console.error(error);
                setError(true);
                setResults([]);
            }).finally(() => {
                setQuerying(false);
            });
        }, 300);

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [searchSubstring]);

    return (
        <Pane width={textInputProps?.width || "max-content"}>
            <Popover
                isShown={isShown}
                minHeight={0}
                minWidth={inputRef.current ? inputRef.current.clientWidth : 0}
                position={Position.BOTTOM}
                content={popoverContent}
            >
                <Pane position={"relative"} display={"flex"} alignItems={"center"}>
                    <Pane
                        ref={iconRef}
                        position={"absolute"}
                        top={0}
                        left={0}
                        height={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        width={28}
                        zIndex={10}
                        {...leftIconContainerProps}
                    >
                        {querying ? (
                            <Spinner
                                size={14}
                                {...spinnerProps}
                            />
                        ) : (
                            <SearchIcon
                                size={14}
                                color={ColorPalette.lightGreyText}
                                {...searchIconProps}
                            />
                        )}
                    </Pane>
                    <TextInput
                        ref={inputRef}
                        placeholder={placeholderText}
                        value={searchSubstring}
                        onChange={onInputChange}
                        onFocus={() => setIsShown(true)}
                        onBlur={() => setIsShown(false)}
                        onKeyDown={onEscape}
                        paddingLeft={iconRef.current ? iconRef.current.clientWidth : 28}
                        {...textInputProps}
                    />
                </Pane>
            </Popover>
        </Pane>
    );
};
