import { User } from "../../../common/models";

export const userIsAuthorized = (user: User | undefined, requiredGroups: string[]): boolean => {
    if (!user || !user.rampartGroups) {
        return false;
    }
    
    return requiredGroups.every((group: string) => {
        return user!.rampartGroups!.includes(group);
    });
}