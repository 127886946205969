import React from "react";

/**
 * This is a lightweight wrapper of `React.memo` that enables usage of TypeScript generic typing
 * on a React `FunctionalComponent` (the transpiler throws a fit without this).
 *
 * "Generic" in this case doesn't refer to "general", but rather TypeScript "generics":
 * https://www.typescriptlang.org/docs/handbook/2/generics.html.
 */
export const genericMemo: <T>(component: T) => T = React.memo;
