import { TableCellFactory } from "../../../../common/tables/factory";
import { StoreForAdmin } from "../../../../../../common/tables/store/StoreForAdmin";
import { Store } from "../../../../../../common/models";
import { FloatingMenuButtonPopoverItems } from "../../../../common/FloatingMenuButton";
import { EnumResolver } from "../../../../common/tables/etc/EnumResolver";

export class StoreTableCellFactory extends TableCellFactory<StoreForAdmin, Store> {
    readonly enumResolver: EnumResolver<StoreForAdmin> = {} as EnumResolver<StoreForAdmin>;

    protected resolvePopoverCellMenuItems(store: Store): FloatingMenuButtonPopoverItems[] {
        return [
            {
                label: "View payout rules",
                onSelect: () => {
                    this.openUrl(`/stores/${store.id}/payout-rules`);
                },
            },
            {
                label: "Edit storefront",
                onSelect: () => {
                    this.openUrl(`/stores/${store.id}/storefront`);
                }
            },
            {
                label: "Go to storefront",
                onSelect: () => {
                    this.openUrl("", store.domain ? `https://${store.domain}` : "");
                },
                disabled: !store.domain,  // No domain available.
            }
        ];
    }
}
