import { Pane } from "evergreen-ui";
import { NotificationChannelSelector } from "./NotificationChannelSelector";
import { NotificationFrequencySelector } from "./NotificationFrequencySelector";
import { NotificationPreference } from "../../../../../common/models";

import '../styles/MultiTabSettingsPage.css'

interface Props {
    notificationPreference: NotificationPreference;
    showChannelSelector?: boolean;
    showFrequencySelector?: boolean;
    onChange: (notificationPreference: NotificationPreference) => void;
}

export const NotificationPreferenceItem = ({ 
    notificationPreference, 
    showChannelSelector = true, 
    showFrequencySelector = true,
    onChange
}: Props) => {
    return (
        <Pane>
            {showChannelSelector && <NotificationChannelSelector
                title="How do you want to be notified?"
                notificationPreference={notificationPreference}
                onChange={onChange}
            />}
            {showFrequencySelector && <NotificationFrequencySelector
                title="How often should we notify you?"
                notificationPreference={notificationPreference}
                onChange={onChange}
            />}
        </Pane>
    );
}