import { Popover, Position } from "evergreen-ui";
import { ReactNode } from "react";
import { FilterExpression } from "../../../../../../common/query-filters";
import { ColumnConfig } from "../../config/ColumnConfig";
import { Sort } from "../../state/TableProviderAPI";
import { MenuContent } from "./MenuContent";
import { TableCellHeaderChangedHandler } from "../../factory";
import { StyledSelectMenuItem } from "../../etc/StyledSelectMenuItem";
import { SearchInputProps } from "../../../search/SearchInput";
import { TableDataWithId } from "../../../../../../common/tables/TableDataWithId";

export interface MultiFacetDropdownMenuProps<
    TableDataType extends TableDataWithId,
    SearchInputType,
    SearchOutputType,
> {
    children: ReactNode;
    filterExpressions: FilterExpression<TableDataType>[];
    sort: Sort<TableDataType>;
    columnConfig: ColumnConfig<TableDataType>;
    onChange: TableCellHeaderChangedHandler<TableDataType>;
    options: StyledSelectMenuItem[];
    disableSort?: boolean;
    disableFilters?: boolean;
    dynamicSearchInputProps?: Pick<SearchInputProps<SearchInputType, SearchOutputType>, "query" | "queryInput" | "resultsMap">;
}

export function MultiFacetDropdownMenu<
    TableDataType extends TableDataWithId,
    SearchInputType,
    SearchOutputType,
>(props: MultiFacetDropdownMenuProps<TableDataType, SearchInputType, SearchOutputType>) {

    const {
        children,
        columnConfig,
        filterExpressions,
        sort,
        onChange,
        options,
        disableSort,
        disableFilters,
        dynamicSearchInputProps,
    } = props;

    return (
        <Popover
            minWidth={"360px"}
            bringFocusInside={true}
            position={Position.BOTTOM_LEFT}
            content={
                <MenuContent
                    columnConfig={columnConfig}
                    filterExpressions={filterExpressions}
                    sort={sort}
                    filterOptions={options}
                    onChange={onChange}
                    disableSort={disableSort ?? false}
                    disableFilters={disableFilters ?? false}
                    dynamicSearchInputProps={dynamicSearchInputProps}
                />
            }
        >
            { children }
        </Popover>
    );
}
