import { RampartAuthorized } from "../../rampart/RampartAuthorized";
import { AdminProductListPage } from "./AdminProductListPage";
import { UserProductListPage } from "./UserProductListPage";

import "./styles/ProductListPage.css";

export const ProductListPage = () => {
    return (
        <RampartAuthorized
            requiredGroups={["juniper.self-serve.admin"]}
            unauthorizedView={<UserProductListPage />}
        >
            <AdminProductListPage />
        </RampartAuthorized>
    );
}
