import { DestinationType } from '../enums';
import { NotificationOverridePreference, NotificationPreference } from '../models';

export const getNotificationDestinationTypes = (notificationPreference: NotificationPreference | NotificationOverridePreference): DestinationType[] => {
    const destinationTypes: DestinationType[] = [];
    if (notificationPreference.sms) {
        destinationTypes.push(DestinationType.SMS);
    }
    if (notificationPreference.email) {
        destinationTypes.push(DestinationType.Email);
    }
    if (notificationPreference.push) {
        destinationTypes.push(DestinationType.Push);
    }
    return destinationTypes;
};