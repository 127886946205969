import { useEffect, useState } from "react";

export interface ScriptOptions {
    onReady: () => void;
    onError: () => void;
    async: boolean;
    removeElementOnUnmount?: boolean;
}

enum ScriptLoadStatus {
    Idle = 'idle',
    Loading = 'loading',
    Ready = 'ready',
    Error = 'error',
}

const scriptLoadStatusKey = 'data-status';

export const useScript = (src: string, {
        onReady = () => null,
        onError = () => null,
        async = true,
        removeElementOnUnmount = false,
    }: Partial<ScriptOptions>
): void => {

    const [scriptLoadStatus, setScriptLoadStatus] = useState<ScriptLoadStatus>(
        src ? ScriptLoadStatus.Loading : ScriptLoadStatus.Idle);

    const getStatusFromEvent = (event: Event): ScriptLoadStatus => {
        return (event.type === 'load') ? ScriptLoadStatus.Ready : ScriptLoadStatus.Error;
    };

    const updateScriptLoadStatusFromEvent = (event: Event): void => {
        setScriptLoadStatus(getStatusFromEvent(event));
    };

    useEffect(() => {
        if (!src) {
            setScriptLoadStatus(ScriptLoadStatus.Idle);
            return;
        }

        // Query the DOM for an existing script element created by another instance of this hook.
        let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);

        if (script) {
            // Update the React state with the ScriptLoadStatus enum value stored on the
            // <script data-status="..."> element.
            setScriptLoadStatus(script.getAttribute(scriptLoadStatusKey) as ScriptLoadStatus);
        } else {
            setScriptLoadStatus(ScriptLoadStatus.Loading);

            // Initialize the script element
            script = document.createElement('script');
            script.async = async;
            script.src = src;
            script.setAttribute(scriptLoadStatusKey, ScriptLoadStatus.Loading);

            // Store the ScriptLoadStatus enum value on the <script data-status="..."> element in
            // case this hook is unmounted while the script is still loading. Then, if the hook is
            // mounted again later, the ScriptLoadStatus enum value can be retrieved.
            const setLoadStatusAttributeFromEvent = (event: Event): void => {
                script?.setAttribute(scriptLoadStatusKey, getStatusFromEvent(event));
            };
            script.addEventListener('load', setLoadStatusAttributeFromEvent);
            script.addEventListener('error', setLoadStatusAttributeFromEvent);

            // Apply the script element to the DOM.
            document.body.appendChild(script);
        }

        script.addEventListener('load', updateScriptLoadStatusFromEvent);
        script.addEventListener('error', updateScriptLoadStatusFromEvent);

        return () => {
            if (script) {
                // When the hook is unmounted, remove the listeners that update the React state.
                script.removeEventListener('load', updateScriptLoadStatusFromEvent);
                script.removeEventListener('error', updateScriptLoadStatusFromEvent);

                if (removeElementOnUnmount) {
                    document.body.removeChild(script);
                }
            }
        }
    }, [src]);

    useEffect(() => {
        if (scriptLoadStatus === ScriptLoadStatus.Error) {
            onError();
        } else if (scriptLoadStatus === ScriptLoadStatus.Ready) {
            onReady();
        }
    }, [scriptLoadStatus]);
};
