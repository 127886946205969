export { featureToggles } from "./FeatureToggles";

type ModifierOptions<T> = {
    /** The list of fields to exclude even if they're not empty */
    exclusions?: Array<keyof T>;
    /** The list of fields to include even if they're empty */
    inclusions?: Array<keyof T>;
};

/**
 * Cleans up object by removing all {@code null} and {@code undefined} fields.
 *
 * @param obj The unmodified object
 * @param options Fields to be excluded from / included in the output
 */
export const cleanObject = <T>(
    obj: T,
    options: ModifierOptions<T> = {},
): any => {
    const {exclusions, inclusions} = options;
    return Object.getOwnPropertyNames(obj)
        .filter((key: string) => {
            return !exclusions || (exclusions.indexOf(key as keyof T) === -1);
        })
        .filter((key: string) => {
            return (obj[key as keyof T] != null)
                || (inclusions && (inclusions.indexOf(key as keyof T) !== -1));
        })
        .reduce((newObj: {}, key: string) => {
            return {
                ...newObj,
                [key]: obj[key as keyof T]
            };
        }, {});
};

export const toLowercaseWithSpaces = (s: string): string => {
    return s.toString()
        .replace(/([A-Z])/g, ' $1')
        .replace(/./g, (s: string) => s.toLowerCase());
};

export const toTitleCase = (s: string): string => {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export const toHyphenCase = (...substrings: Array<string>): string => {
    const output: string[] = [];
    substrings.filter((substring: string) => !!substring)
        .forEach((substring: string) => {
            output.push(substring.replace(/\s+/g, "-").toLowerCase());
        });
    return output.join("-");
};

export const toNumber = (value: string | number): number => {
    return (typeof value === "number") ? value : parseInt(value);
};
