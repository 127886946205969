import { SelectMenuItem } from "evergreen-ui";
import { FilterExpression } from "../../../../../common/query-filters";
import { ColumnConfig } from "../config/ColumnConfig";
import { Sort } from "../state/TableProviderAPI";
import { TableCellHeaderChangedHandler } from "./TableCellHeaderChangedHandler";
import { TableCellChangedHandler } from "./TableCellChangedHandler";
import { CellType } from "../config/CellType";
import { TableBodyCellRendererInput } from "./input";
import {
    AbstractTableBodyCellRenderer,
    AbstractTableHeaderCellRenderer,
    TableCheckboxCellRenderer,
    TableDatePickerCellRenderer,
    TableFilterableHeaderCellRenderer,
    TableInputCellRenderer,
    TablePopoverCellRenderer,
    TableReadOnlyCellRenderer,
    TableReadOnlyHeaderCellRenderer,
    TableRedirectCellRenderer,
    TableSingleSelectCellRenderer,
} from "./renderers";
import { StyledSelectMenuItem, SupportedColor } from "../etc/StyledSelectMenuItem";
import { FloatingMenuButtonPopoverItems } from "../../FloatingMenuButton";
import { EnumResolver } from "../etc/EnumResolver";
import { TableDataWithId } from "../../../../../common/tables/TableDataWithId";

export abstract class TableCellFactory<TableDataType extends TableDataWithId, GraphQLDataType> {
    abstract readonly enumResolver: EnumResolver<TableDataType>;

    public renderHeaderCell(
        columnConfig: ColumnConfig<TableDataType>,
        filterExpressions: FilterExpression<TableDataType>[],
        sort: Sort<TableDataType>,
        onCellChanged: TableCellHeaderChangedHandler<TableDataType>,
    ): JSX.Element {
        return this.createHeaderCellRenderer(
            columnConfig,
            filterExpressions,
            sort,
            onCellChanged,
        ).render();
    }

    public renderBodyCell(
        columnConfig: ColumnConfig<TableDataType>,
        rowData: GraphQLDataType,
        onCellChanged: TableCellChangedHandler<TableDataType>,
    ): JSX.Element {
        return this.createBodyCellRenderer(
            columnConfig,
            rowData,
            onCellChanged,
        ).render();
    }

    public resolveFilterOptions(column: ColumnConfig<TableDataType>): SelectMenuItem[] {
        switch (column.cellType) {
            case CellType.singleSelect:
                return this.enumResolver.resolve(column.columnName);
            default:
                return [];
        }
    };

    protected resolvePopoverCellMenuItems(rowData: GraphQLDataType): FloatingMenuButtonPopoverItems[] {
        return [];
    }

    protected resolveRedirectUrl(column: ColumnConfig<TableDataType>, rowData: GraphQLDataType): string {
        return "";
    }

    protected resolveCellData(columnConfig: ColumnConfig<TableDataType>, rowData: GraphQLDataType): string {
        const rawValue: string | number | null = (rowData as any)[columnConfig.columnName];

        // Perform an explicit null check rather than a truthy check since `0` is a valid value for
        // the number input cell type.
        if (columnConfig.cellType === CellType.numberInput) {
            return rawValue === null ? "" : rawValue.toString();
        }

        // Quick return with an empty string if the value is not truthy.
        if (!rawValue) {
            return "";
        }

        // Convert the date cell types to a human-readable format.
        if ([CellType.readOnlyDate, CellType.datePicker].includes(columnConfig.cellType)) {
            return new Date(rawValue)
                .toLocaleString('en-US', {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                });
        }

        // Convert the enum value for a single select cell type.
        if (CellType.singleSelect === columnConfig.cellType) {
            return this.enumResolver.resolveEnum(columnConfig.columnName).map()[rawValue as string].label;
        }

        // Ordinary case: return the raw value as a string.
        return rawValue as string;
    }

    protected resolveCellBadgeColor(
        columnConfig: ColumnConfig<TableDataType>,
        rowData: GraphQLDataType,
    ): SupportedColor {
        if (CellType.singleSelect !== columnConfig.cellType) {
            return undefined;
        }

        const cellValue: string = (rowData as any)[columnConfig.columnName];
        if (!cellValue) {
            return undefined;
        }

        return this.enumResolver.resolveEnum(columnConfig.columnName).map()[cellValue].color;
    }

    protected createHeaderCellRenderer(
        columnConfig: ColumnConfig<TableDataType>,
        filterExpressions: FilterExpression<TableDataType>[],
        sort: Sort<TableDataType>,
        onCellChanged: TableCellHeaderChangedHandler<TableDataType>,
    ): AbstractTableHeaderCellRenderer<TableDataType> {
        if (columnConfig.filterable || columnConfig.sortable) {
            return new TableFilterableHeaderCellRenderer<TableDataType>(
                {columnConfig}, filterExpressions, sort, onCellChanged, this.resolveFilterOptions(columnConfig));
        }

        return new TableReadOnlyHeaderCellRenderer<TableDataType>({columnConfig});
    }

    protected createBodyCellRenderer(
        columnConfig: ColumnConfig<TableDataType>,
        rowData: GraphQLDataType,
        onCellChanged: TableCellChangedHandler<TableDataType>,
    ): AbstractTableBodyCellRenderer<TableDataType> {
        let renderer: AbstractTableBodyCellRenderer<TableDataType>;

        const rendererInput: TableBodyCellRendererInput<TableDataType> = {
            columnConfig: columnConfig,
            cellValue: this.resolveCellData(columnConfig, rowData),
            cellBadgeColor: this.resolveCellBadgeColor(columnConfig, rowData),
            id: (rowData as unknown as TableDataWithId).id,
            onCellChanged: onCellChanged,
        };

        switch (columnConfig.cellType) {
            case CellType.textInputWithMenuButton:
                renderer = new TablePopoverCellRenderer(
                    rendererInput,
                    this.resolvePopoverCellMenuItems(rowData),
                );
                break;
            case CellType.textInput:
                renderer = new TableInputCellRenderer<TableDataType>(rendererInput, "text");
                break;
            case CellType.numberInput:
                renderer = new TableInputCellRenderer<TableDataType>(rendererInput, "number");
                break;
            case CellType.singleSelect:
                const options: StyledSelectMenuItem[] = this.resolveFilterOptions(columnConfig);
                renderer = new TableSingleSelectCellRenderer<TableDataType>(rendererInput, options);
                break;
            case CellType.datePicker:
                renderer = new TableDatePickerCellRenderer<TableDataType>(rendererInput);
                break;
            case CellType.redirect:
                renderer = new TableRedirectCellRenderer(rendererInput, this.resolveRedirectUrl(columnConfig, rowData));
                break;
            case CellType.checkbox:
                renderer = new TableCheckboxCellRenderer(rendererInput);
                break;
            case CellType.readOnlyText:
            case CellType.readOnlyDate:
            default:
                renderer = new TableReadOnlyCellRenderer<TableDataType>(rendererInput);
        }

        return renderer;
    }

    protected openUrl(path: string, origin: string = window.location.origin): void {
        window.open(origin + path, "_blank");
    }
}
