import { Pane, toaster } from "evergreen-ui";
import React, { UIEventHandler, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CenteredSpinner } from "../../common/CenteredSpinner";
import { useUpdateUserMutation, useUserQuery } from "../../datastore/UserDataStore";
import { LegalAgreement } from "../../legal/LegalAgreement";
import { NewUserContainer, NewUserPage, NewUserState } from "./NewUserContainer";
import { NavigationHelper } from "../../navigation/PrevLocationState";

export const NewUserAgreementPage = () => {

    const history = useHistory<NewUserState>();
    const {
        phoneNumber,
        agreementAccepted,
        wasRedirected
    } = history.location.state || {};

    const {
        loading: queryLoading,
        error: queryError,
        data: fetchedUser,
    } = useUserQuery();

    const [
        updateUser,
        {
            loading: mutationLoading,
            error: mutationError,
            called: mutationCalled
        }
    ] = useUpdateUserMutation();

    const [enableContinue, setEnableContinue] = useState<boolean>(agreementAccepted || false);

    useEffect(() => {
        // agreement has been accepted, but phone number is missing.
        // we can just save the phone number real quick and not make them agree again.
        if (fetchedUser && !fetchedUser.phoneNumber && phoneNumber && fetchedUser.agreementAcceptedDate) {
            updateUser({
                ...fetchedUser,
                phoneNumber: phoneNumber
            })
        }
    }, [fetchedUser]);

    if (mutationCalled && !mutationLoading && !mutationError) {
        history.push("/new-user/billing", {
            phoneNumber: phoneNumber,
            agreementAccepted: true,
            wasRedirected: wasRedirected
        });
    }

    if (mutationError || queryError) {
        toaster.danger("Something went wrong. Try again.");
    }

    // If there is no phone number present, then we need to go back and get one from the user
    if (!queryLoading && fetchedUser && !fetchedUser.phoneNumber && !phoneNumber) {
        history.push("/new-user/phone-number");
    }

    // If everything is satisfied, and we didn't get here from another breadcrumb, we should just go home.
    if (!queryLoading && fetchedUser && fetchedUser.phoneNumber && fetchedUser.agreementAcceptedDate && !agreementAccepted) {
        history.push(NavigationHelper.createNewUserFirstProductPath());
    }

    if (queryLoading) {
        return <CenteredSpinner />;
    }

    const onScrollChange: UIEventHandler<HTMLDivElement> = (e) => {
        const element: HTMLDivElement = e.target as HTMLDivElement;
        // scrollHeight - scrollTop should equal clientHeight if it's at the bottom; we throw another 100px
        //  in there to make it a little more flexible
        const nearBottom: boolean = (element.scrollHeight - element.scrollTop) <= (element.clientHeight + 100);
        setEnableContinue(nearBottom);
    };

    return (
        <NewUserContainer
            page={NewUserPage.Agreement}
            primaryButtonText={"Agree + Continue"}
            isLoading={mutationLoading}
            continueEnabled={enableContinue}
            infoComponent={(
                <p style={{marginRight: "10px"}}>
                    {enableContinue ?
                        "You'll be able to access this agreement in your account settings." :
                        "Scroll to the bottom to agree and continue."}
                </p>
            )}
            onContinue={() => {
                if (fetchedUser) {
                    updateUser({
                        ...fetchedUser,
                        agreementAcceptedDate: new Date(),
                        phoneNumber: phoneNumber
                    })
                }
            }}
        >
            <Pane
                overflow={"auto"}
                height={"calc(100vh - 180px)"}
                width={"100%"}
                marginTop={"80px"}
                marginBottom={"120px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                onScroll={onScrollChange}
            >
                <Pane
                    height={"100%"}
                    maxWidth={"960px"}
                    flexDirection={"column"}
                    alignItems={"left"}
                >
                    <LegalAgreement />
                </Pane>
            </Pane>
        </NewUserContainer>
    );
};
