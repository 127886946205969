import { StyledSelectMenuItem } from "../../etc/StyledSelectMenuItem";
import { TableBodyCellRendererInput } from "../input";
import { AbstractTableBodyCellRenderer } from "./AbstractTableBodyCellRenderer";
import React, { FunctionComponent } from "react";
import { Badge, SelectMenuProps, Table } from "evergreen-ui";

export abstract class AbstractTableSingleSelectCellRenderer<T> extends AbstractTableBodyCellRenderer<T> {

    protected readonly options: StyledSelectMenuItem[];

    public constructor(input: TableBodyCellRendererInput<T>, options: StyledSelectMenuItem[]) {
        super(input);
        this.options = options;
    }

    protected abstract resolveSelectMenuProps(): Omit<SelectMenuProps, 'children'>;

    protected getMemoizedComponent(): FunctionComponent {
        const {componentProps, cellValue, cellBadgeColor} = this.input;
        return React.memo(() => {
            return (
                <Table.SelectMenuCell
                    className={"jc-body-cell"}
                    selectMenuProps={this.resolveSelectMenuProps()}
                    {...componentProps}
                >
                    {
                        cellValue === "" || cellBadgeColor === undefined ?
                            <>
                                { cellValue }
                            </> :
                            <Badge
                                textTransform={"none"}
                                fontWeight={"normal"}
                                borderRadius={8}
                                paddingX={8}
                                flexShrink={0}
                                color={cellBadgeColor}
                            >
                                { cellValue }
                            </Badge>
                    }
                </Table.SelectMenuCell>
            );
        });
    }
}
