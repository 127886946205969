import { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
    Client,
    ConnectionState,
    Conversation,
    JSONObject,
    Message as TwilioMessage,
    MessageBuilder,
    Paginator,
    Participant as TwilioParticipant,
    SendMediaOptions,
    User as TwilioUser
} from "@twilio/conversations";
import * as Sentry from "@sentry/react";
import { fetchAccessToken } from "../../twilio/TwilioDataStore";
import { ConversationTagManager } from "../tagging/ConversationTagManager";
import { Message, ConversationMetadata, SendResult } from "../types";
import { MessageAdapter } from "../adapters/MessageAdapter";
import { useConversationSidParam } from "./ConversationSidParamHook";
import { ConversationMetadataAdapter } from "../adapters/ConversationMetadataAdapter";
import { isConversationDisabled } from "../../datastore/MessagesDataStore";
import { useCancellablePromise } from "../../utils/cancellablePromise";
import { StytchContext } from "../../stytch/StytchContext";
import { analytics, MessageEventName } from "../../analytics";
import { useUserContext } from "../../context/UserContext";
import { MessageAttributes } from "../../../../common/types";

export const DEFAULT_PAGE_SIZE = 30;

export interface ConversationStore {
    [conversationSid: string]: ConversationMetadata;
}

/**
 * API Types:
 * Client - API methods that don't directly operate on the active conversation - these generally
 *          operate directly on the client but don't necessarily need to.
 * ActiveConversation - API methods that operate directly on the active conversation.
 *
 * The API is broken into two different APIs in order to provide a performance optimization to
 * consumers of only the Client API. The API methods in the ActiveConversation API have a
 * dependency on the `activeConversation` state, which means these methods will be re-defined
 * whenever the `activeConversation` changes. The API methods in the Client API don't have this
 * dependency and thus won't need to unnecessarily get re-defined. By having the methods in the
 * Client API not get re-defined on `activeConversation` changes, consumers of the Client API
 * won't get re-rendered.
 */
type ClientAPI = {
    /**
     * Controls whether or not the Conversation ListView is shown. Used to hide the
     * Conversation ListView on mobile devices
     * @param value Toggle that controls whether or not the Conversation ListView is shown
     */
    toggleShowListView: (value: boolean) => void,

    /**
     * Updates the context internals to reflect the state of the provided Conversation SID.
     * Providing an undefined SID will clear the active conversation.
     * @param conversationSid The new active Conversation
     */
    updateActiveConversation: (conversationSid?: string) => void,

    /**
     * Marks the active conversation as read or unread based on whether there are currently
     * any unread messages.
     * @param conversationSid The Conversation to be updated
     */
    markConversationAsReadOrUnread: (conversationSid: string) => Promise<void>;
}

type ActiveConversationAPI = {
    /**
     * Fetch messages for the active conversation and loads them into the message store.
     * Note that calling this will fetch the most recent page of messages and overwrite
     * the message store.
     */
    fetchMessages: () => Promise<void>,

    /**
     * Fetch the next page of messages for the active conversation.
     */
    fetchMoreMessages: () => Promise<void>,

    /**
     * Sends a message to the active Conversation.
     * @param text Text body of the message.
     * @param sendMediaOptions Attached media configurations
     * @package attributes any message attributes to send along
     */
    sendMessage: (text: string, sendMediaOptions: SendMediaOptions[], attributes?: MessageAttributes) => Promise<SendResult>,

    /**
     * Deletes a message from the active Conversation.
     * @param sid SID of the message to delete.
     */
    deleteMessage: (sid: string | null) => void,

    /**
     * @returns The list of participants for the currently active conversation.
     */
    getParticipants: () => TwilioUser[],
}

type State = {
    clientLoaded: boolean,
    clientError: boolean,
    conversationStoreLoaded: boolean,
    conversationStoreError: boolean,
    activeConversationLoaded: boolean,
    activeConversationError: boolean,
    showListView: boolean,
    conversationStore: ConversationStore,
    activeConversationMessageStore: Message[],
    activeConversationSid: string | null,
    activeConversationPaginator: Paginator<TwilioMessage> | null,
    activeConversationDisabled: boolean,
    conversationTagManager: ConversationTagManager | null
}

const NativeMessagingClientAPIContext = createContext<ClientAPI>({} as ClientAPI);
const NativeMessagingActiveConversationAPIContext = createContext<ActiveConversationAPI>({} as ActiveConversationAPI);
const NativeMessagingDataContext = createContext<State>({} as State);

export const useMessagingClientAPIContext = () => useContext(NativeMessagingClientAPIContext);
export const useActiveConversationAPIContext = () => useContext(NativeMessagingActiveConversationAPIContext);
export const useMessagingDataContext = () => useContext(NativeMessagingDataContext);

export const NativeMessagingProvider: FC = ({ children }) => {

    const { isAuthenticated } = useContext(StytchContext);
    const { addToCancellablePromises } = useCancellablePromise();

    const [client, setClient] = useState<Client | undefined>();
    const [clientLoaded, setClientLoaded] = useState<boolean>(false);
    const [clientError, setClientError] = useState<boolean>(false);
    const [conversationStoreLoaded, setConversationStoreLoaded] = useState<boolean>(false);
    const [conversationStoreError, setConversationStoreError] = useState<boolean>(false);
    const [activeConversationLoaded, setActiveConversationLoaded] = useState<boolean>(false);
    const [activeConversationError, setActiveConversationError] = useState<boolean>(false);
    const [showListView, setShowListView] = useState<boolean>(true);
    const [activeConversationDisabled, setActiveConversationDisabled] = useState<boolean>(false);

    const [conversationStore, setConversationStore] = useState<ConversationStore>({});
    const [activeConversationMessageStore, setActiveConversationMessageStore] = useState<Message[]>([]);
    // Make sure to always update the `activeConversation` first before updating the `activeConversationSid`
    // as only the latter is exposed externally and once it updates it should be assumed that all internal
    // state has updated as well
    const [activeConversation, setActiveConversation] = useState<Conversation | null>(null);

    // Use a reference for the Paginator so that it can be immediately updated for consecutive
    // `fetchMoreMessage()` calls to use
    const activeConversationPaginator = useRef<Paginator<TwilioMessage> | null>(null);
    const conversationsPaginator = useRef<Paginator<Conversation> | null>(null);

    const { setConversationSidParam } = useConversationSidParam();
    const { user } = useUserContext();

    const setClientState = ({ loaded, error }: { loaded: boolean, error: boolean }) => {
        setClientError(error);
        setClientLoaded(loaded);
    };

    const clientAPI: ClientAPI = useMemo<ClientAPI>(() => {
        const toggleShowListView = (value: boolean) => {
            setShowListView(value);
        };

        const updateActiveConversation = (conversationSid?: string) => {
            if (!client) {
                return;
            }

            // Update the URL path `/messages/:conversationSid`.
            setConversationSidParam(conversationSid);

            if (!conversationSid) {
                setActiveConversation(null);
                setShowListView(true);
                setActiveConversationLoaded(true);
                return;
            }

            // Reset the loading and error states before the network call is kicked off.
            setActiveConversationLoaded(false);
            setActiveConversationError(false);

            client.getConversationBySid(conversationSid)
                .then((conversation) => {
                    setActiveConversation(conversation);
                    analytics.heap_track(MessageEventName.ActiveConversationLoadSuccess);
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    analytics.heap_track(MessageEventName.ActiveConversationLoadFail, { error: error.toString() });
                    setActiveConversationError(true);
                    setActiveConversationLoaded(true);
                });

            // Delegate setting the loading state to the `activeConversation` hook below, since we want
            // to wait for all necessary initialization steps to be completed for notifying the app
            // that loading has finished.
        };

        const markConversationAsReadOrUnread = async (conversationSid: string): Promise<void> => {
            if (!client) {
                return;
            }

            const conversation = await client.getConversationBySid(conversationSid);
            const lastMessageIndex = conversation.lastMessage?.index ?? 0;
            const lastReadMessageIndex = conversation.lastReadMessageIndex;

            const unread = (lastReadMessageIndex === null) || lastReadMessageIndex < lastMessageIndex;
            const penultimateMessageIndex = lastMessageIndex - 1;

            // TODO: we eventually want to consider a null index to mean an entire conversation
            //  is unread, but for now, we'll simply toggle between read and unread by updating
            //  the index to either the last or second from last (penultimate) index.
            //  https://app.asana.com/0/1202793033122602/1202796877706707

            if (unread) {
                await conversation.setAllMessagesRead();
            } else {
                await conversation.updateLastReadMessageIndex(penultimateMessageIndex);
            }
        };

        return {
            toggleShowListView,
            updateActiveConversation,
            markConversationAsReadOrUnread
        }
    }, [client, setConversationSidParam]);

    const activeConversationAPI: ActiveConversationAPI = useMemo<ActiveConversationAPI>(() => {
        const fetchMessages = async () => {
            if (!client || !activeConversation) {
                return;
            }
            // TODO: This might lead to a conversation failing to load if selected while already
            // being the activeConversation
            const cachedActiveConversation = activeConversation;

            try {
                const paginator = await activeConversation.getMessages(DEFAULT_PAGE_SIZE);
                const messages = await Promise.all(paginator.items.map(message => new MessageAdapter(message).adapt()));

                // Handle the case where a user fetches messages but then quickly switches to a new conversation
                // while the messages are still being fetched. In this case the fetched messages are for a stale
                // conversation so don't update the message store.
                if (cachedActiveConversation !== activeConversation) {
                    return;
                }

                activeConversationPaginator.current = paginator;
                setActiveConversationMessageStore(messages.reverse());
                analytics.heap_track(MessageEventName.InitialMessagesLoadSuccess);
            } catch (err: any) {
                Sentry.captureException(err);
                analytics.heap_track(MessageEventName.InitialMessagesLoadFail, { error: err.toString() });
            }
        };

        const fetchMoreMessages = async () => {
            const paginator = activeConversationPaginator.current;
            if (!paginator || !paginator.hasPrevPage) {
                return;
            }

            try {
                const newPaginator = await paginator.prevPage();
                const transformedPrevMessages = await Promise.all(newPaginator.items.map(message => new MessageAdapter(message).adapt()));

                // Handle the case where a user fetches more messages but then quickly switches to a new conversation
                // while the messages are still being fetched. In this case the fetched messages are for a stale
                // conversation so don't update the message store.
                if (paginator !== activeConversationPaginator.current) {
                    return;
                }
                activeConversationPaginator.current = newPaginator;
                setActiveConversationMessageStore(currMessages => currMessages.concat(transformedPrevMessages.reverse()));
                analytics.heap_track(MessageEventName.PaginatedMessagesLoadSuccess);
            } catch (err: any) {
                Sentry.captureException(err);
                analytics.heap_track(MessageEventName.PaginatedMessagesLoadFail, { error: err.toString() });
            }
        };

        const sendMessage = async (text: string, sendMediaOptions: SendMediaOptions[], attributes?: MessageAttributes): Promise<SendResult> => {
            if (!activeConversation) {
                return SendResult.ignored;
            }
            let unsentMessage: MessageBuilder = activeConversation.prepareMessage()
                .setBody(text ? text : "");

            if (attributes) {
                unsentMessage.setAttributes(attributes as unknown as JSONObject);
            }

            sendMediaOptions.forEach((mediaOption) => {
                unsentMessage.addMedia(mediaOption);
            });

            try {
                const index = await unsentMessage.build().send();
                await activeConversation.updateLastReadMessageIndex(index);
                if (index !== null) {
                    analytics.heap_track(MessageEventName.MessageSentSuccess, { message: text })
                    return SendResult.success;
                } else {
                    analytics.heap_track(MessageEventName.MessageSentFail, { message: text })
                    return SendResult.failure
                }
            } catch (err: any) {
                Sentry.captureException(err)
                analytics.heap_track(MessageEventName.MessageSentFail, { message: text, error: err.toString() })
                return SendResult.failure;
            }
        };

        const deleteMessage = (sid: string | null) => {
            if (!sid || !activeConversation) {
                return;
            }
            const message = activeConversationMessageStore.find(message => message.sid === sid)
            if (!message) {
                Sentry.captureException(`Could not find message ${sid} to delete`);
                return;
            }
            // Instruct Twilio's backend to delete this message
            message.ref.remove();
        }

        const getParticipants = (): TwilioUser[] => {
            if (!activeConversation?.sid) {
                return [];
            }
            const conversationMetadata = conversationStore[activeConversation.sid];
            if (!conversationMetadata) {
                return [];
            }
            return conversationMetadata.participants;
        };

        return {
            fetchMessages,
            fetchMoreMessages,
            sendMessage,
            deleteMessage,
            getParticipants
        };
    }, [client, activeConversation, activeConversationMessageStore, conversationStore]);

    const shutdownClient = useCallback((c: Client | undefined) => {
        if (!c) {
            return;
        }
        console.log("Shutdown client");
        c.removeAllListeners();
        // We don't really care too much about the error handling here
        // since the component is getting unmounted anyways, but we don't
        // want to have unhandled exceptions
        c.shutdown()
            .then(() => {
                analytics.heap_track(MessageEventName.ClientShutdown);
            })
            .catch(err => {
                console.error(err);
                analytics.heap_track(MessageEventName.ClientShutdownError, { error: err.toString() });
            });
        setClient(undefined);
    }, []);

    const initClientListeners = useCallback((c: Client) => {
        if (!c) {
            return;
        };
        c.on("connectionStateChanged", (state: ConnectionState) => {
            console.log(`Twilio client state: ${state}`);
            switch (state) {
                case "connected":
                    setClientState({ loaded: true, error: false });
                    analytics.heap_track(MessageEventName.ClientConnected);
                    break;
                case "connecting":
                    setClientState({ loaded: false, error: false });
                    analytics.heap_track(MessageEventName.ClientConnecting);
                    break;
                case "denied":
                    // Retry
                    setClientState({ loaded: false, error: false });
                    analytics.heap_track(MessageEventName.ClientDenied);
                    addToCancellablePromises(fetchAccessToken()
                        .then(token => c.updateToken(token))
                        .then(_ => {
                            setClientState({ loaded: true, error: false });
                            analytics.heap_track(MessageEventName.AccessTokenSuccess);
                        })
                        .catch((err) => {
                            setClientState({ loaded: true, error: true });
                            analytics.heap_track(MessageEventName.AccessTokenFail, { error: err.toString() });
                        }));
                    break;
                case "disconnected":
                    shutdownClient(c);
                    setClientState({ loaded: true, error: true });
                    analytics.heap_track(MessageEventName.ClientDisconnected);
                    break;
            }
        });
        c.on("tokenAboutToExpire", async () => {
            analytics.heap_track(MessageEventName.AccessTokenExpireSoon);
            try {
                const token = await addToCancellablePromises(fetchAccessToken());
                await c.updateToken(token);
                analytics.heap_track(MessageEventName.AccessTokenSuccess);
            } catch (err: any) {
                setClientState({ loaded: true, error: true });
                analytics.heap_track(MessageEventName.AccessTokenFail, { error: err.toString() });
            }
        });
        c.on("tokenExpired", () => {
            console.log("Token expired");
            shutdownClient(c);
            setClientState({ loaded: true, error: true });

        });
    }, [addToCancellablePromises, shutdownClient]);

    const initClient = useCallback(async () => {
        if (client) {
            return;
        }
        console.log("Initializing a new client");
        setClientState({ loaded: false, error: false });
        try {
            const token = await addToCancellablePromises(fetchAccessToken());
            const c = new Client(token);
            initClientListeners(c);
            setClient(c);
            analytics.heap_track(MessageEventName.ClientInitSuccess);
        } catch (err: any) {
            Sentry.captureException(err);
            setClientState({ loaded: true, error: true });
            analytics.heap_track(MessageEventName.ClientInitFail, { error: err.toString() });
        }
    }, [addToCancellablePromises, client, initClientListeners]);

    useEffect(() => {
        if (!user || !user.twilioUserId) {
            setClientState({ loaded: true, error: false });
            setConversationStoreLoaded(true);
            return;
        }
        if (isAuthenticated) {
            initClient();
        } else {
            shutdownClient(client);
        }
    }, [client, initClient, isAuthenticated, shutdownClient, user]);

    const onMessageAdded = (message: TwilioMessage) => {
        new MessageAdapter(message).adapt()
            .then(transformedMessage => {
                // Bit of a hack that ensures once we receive a message in an active
                // conversation, we mark it as read. Once we implement proper tracking
                // of the read horizon via scrolling, we can remove this.
                message.conversation.setAllMessagesRead();
                setActiveConversationMessageStore(messages => [transformedMessage].concat(messages));
            }).catch(err => {
                analytics.heap_track(MessageEventName.MessageAdapterError, { error: err.toString() })
                Sentry.captureException(err);
            });
    };

    const onMessageDeleted = (deletedMessage: TwilioMessage) => {
        setActiveConversationMessageStore(messageStore => {
            const newMessageStore = messageStore.filter(message => message.sid !== deletedMessage.sid);
            return [...newMessageStore];
        })
    }

    const onConversationAdded = (conversation: Conversation) => {
        if (conversation.sid in conversationStore) {
            // Conversation has already been added to the conversation store
            return;
        }
        updateConversationMetadata(conversation);
    };

    const onConversationUpdated = ({ conversation }: { conversation: Conversation }) => {
        updateConversationMetadata(conversation);
    };

    const onConversationRemoved = (conversation: Conversation) => {
        // If removed from the actively viewed conversation, then delete all
        // artifcats of that conversation and render the empty conversation view
        setActiveConversation(activeConversation => activeConversation && conversation.sid === activeConversation.sid ? null : activeConversation);

        // Delete conversation from conversation-store
        setConversationStore(conversationStore => {
            const { [conversation.sid]: _, ...newConversationStore } = conversationStore;
            return newConversationStore;
        });
    };

    const onParticipantJoinedOrLeft = (twilioParticipant: TwilioParticipant) => {
        const conversation = twilioParticipant.conversation;
        const conversationSid = twilioParticipant.conversation.sid;
        conversation.getParticipants()
            .then(participants => Promise.all(participants.map(participant => participant.getUser())))
            .then(twilioUsers => {
                setConversationStore(conversationStore => {
                    const conversationMetadata = conversationStore[conversationSid];
                    // If the converesation isn't in the conversationStore for some reason then bail out.
                    // Should never be hit in theory.
                    if (!conversationMetadata) {
                        return conversationStore;
                    }
                    conversationMetadata.participants = twilioUsers;
                    // Create a new conversationStore object to trigger all consumers to re-render since
                    // there may potentially be new participants data
                    return {
                        ...conversationStore,
                        [conversationSid]: conversationMetadata
                    }
                })
            })
            .catch();
    };

    const onConnectionError = ({ terminal, message, httpStatusCode, errorCode }: { terminal: boolean, message: string, httpStatusCode?: number, errorCode?: number}) => {
        console.log(`Connection error handler - terminal: ${terminal}, message: ${message}, httpStatusCode: ${httpStatusCode}, errorCode: ${errorCode}`);
        analytics.heap_track(MessageEventName.ClientInitFail, {
            terminal: `${terminal}`,
            message,
            httpStatusCode: `${httpStatusCode}`,
            errorCode: `${errorCode}`
        });
    }

    const fetchConversationStore = useCallback(async () => {
        if (!client) {
            return {};
        }
        let conversations: Conversation[] = [];
        try {
            conversationsPaginator.current = await client.getSubscribedConversations();
            conversations = conversationsPaginator.current.items;
            analytics.heap_track(MessageEventName.GetSubscribedConversationsSuccess)
        } catch (err: any) {
            Sentry.captureException(err);
            analytics.heap_track(MessageEventName.GetSubscribedConversationsFail, { error: err.toString() })
            return Promise.reject("Conversations could not be fetched");
        }

        // Fetch conversation metadata in parallel
        return Promise.all(
            conversations.map(async conversation => new ConversationMetadataAdapter(conversation).metadata())
        )
            .then(conversationMetadataList =>
                conversationMetadataList.reduce<ConversationStore>(
                    (store, conversationMetadata) => {
                        store[conversationMetadata.sid] = conversationMetadata;
                        return store;
                    }, {}
                )
            )
    }, [client]);

    const lazyloadRemainingConversations = async () => {
        // Cannot lazy-load conversations without paginator
        if (conversationsPaginator.current === null) {
            return;
        }
        const jobsToFinish: Promise<void>[] = [];
        while (conversationsPaginator.current.hasNextPage) {
            const newConversationsPaginator: Paginator<Conversation> = await (conversationsPaginator.current.nextPage());
            const newConversations = newConversationsPaginator.items;
            // Asynchronously add the new conversations to the conversation store
            const job = Promise.all(
                // Convert the conversations into a list of conversation metadata
                newConversations.map(conversation => new ConversationMetadataAdapter(conversation).metadata())
            )
                // Convert the list of conversation metadata into a ConversationStore object consisting of just the
                // new conversations
                .then(conversationMetadataList =>
                    conversationMetadataList.reduce<ConversationStore>(
                        (store, conversationMetadata) => {
                            store[conversationMetadata.sid] = conversationMetadata;
                            return store;
                        }, {}
                    )
                )
                // Merge the new ConversationStore with the existing one
                .then(conversationStoreToAdd => setConversationStore(conversationStore => ({
                    ...conversationStore,
                    ...conversationStoreToAdd
                })));
            jobsToFinish.push(job);
            conversationsPaginator.current = newConversationsPaginator;
        }
        return Promise.all(jobsToFinish);
    };

    const updateConversationMetadata = (conversation: Conversation) => {
        const adapter = new ConversationMetadataAdapter(conversation);
        adapter.metadata()
            .then(conversationMetadata => {
                setConversationStore(conversationStore => ({
                    ...conversationStore,
                    [conversation.sid]: conversationMetadata
                }));
                analytics.heap_track(MessageEventName.ConversationMetadataSuccess);
            }
            ).catch(err => {
                Sentry.captureException(err);
                analytics.heap_track(MessageEventName.ConversationMetadataFail, { error: err.toString() });
            });
    };

    // Set up active conversation listeners
    useEffect(() => {
        if (activeConversation === null) {
            // No conversation selected, so end the loading phase early.
            setActiveConversationDisabled(false);
            setActiveConversationLoaded(true);
            setShowListView(true);
            return;
        }

        activeConversation.on("messageAdded", onMessageAdded);
        activeConversation.on("messageRemoved", onMessageDeleted);
        activeConversation.setAllMessagesRead()
            .then(_ => isConversationDisabled(activeConversation.sid))
            .then(conversationDisabled => setActiveConversationDisabled(conversationDisabled))
            .finally(() => {
                // This is an optional Twilio operation, so we always terminate the loading
                // phase after this (regardless of whether the API call succeeds or fails).
                setActiveConversationLoaded(true);
                setShowListView(false);
            });

        return () => {
            activeConversationPaginator.current = null;
            activeConversation?.removeListener("messageAdded", onMessageAdded);
            activeConversation?.removeListener("messageAdded", onMessageDeleted);
            setActiveConversationMessageStore([]);
        }
    }, [activeConversation]);

    const conversationTagManager: ConversationTagManager = new ConversationTagManager(activeConversationAPI.getParticipants);

    // Fetch all conversations and set up the client listeners once client is loaded
    useEffect(() => {
        if (!client || clientError) {
            return;
        }

        setConversationStoreLoaded(false);
        setConversationStoreError(false);

        fetchConversationStore()
            .then((fetchedConversationStore) => {
                setConversationStore(fetchedConversationStore);
                analytics.heap_track(MessageEventName.InitialConversationStoreSuccess);
                return lazyloadRemainingConversations();
            })
            .catch((err: any) => {
                Sentry.captureException(err);
                analytics.heap_track(MessageEventName.ConversationStoreFail, { error: err.toString() });
                setConversationStoreError(true);
            })
            .finally(() => {
                setConversationStoreLoaded(true);
                client.on("conversationAdded", onConversationAdded);
                client.on("conversationUpdated", onConversationUpdated);
                client.on("conversationRemoved", onConversationRemoved);
                client.on("participantJoined", onParticipantJoinedOrLeft);
                client.on("participantLeft", onParticipantJoinedOrLeft);
                client.on("connectionError", onConnectionError);
            });
    }, [client, clientError, fetchConversationStore]);

    return (
        <NativeMessagingClientAPIContext.Provider value={clientAPI}>
            <NativeMessagingActiveConversationAPIContext.Provider value={activeConversationAPI}>
                <NativeMessagingDataContext.Provider value={{
                    clientLoaded,
                    clientError,
                    conversationStoreLoaded,
                    conversationStoreError,
                    activeConversationLoaded,
                    activeConversationError,
                    showListView,
                    conversationStore,
                    activeConversationMessageStore,
                    activeConversationSid: activeConversation ? activeConversation.sid : null,
                    activeConversationPaginator: activeConversationPaginator.current,
                    activeConversationDisabled: activeConversationDisabled,
                    conversationTagManager: conversationTagManager
                }}>
                    {children}
                </NativeMessagingDataContext.Provider>
            </NativeMessagingActiveConversationAPIContext.Provider>
        </NativeMessagingClientAPIContext.Provider>
    );
}