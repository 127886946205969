import { User as TwilioUser } from "@twilio/conversations";
import { Tag } from "../../../../../common/types/messaging/Tag";
import { TagResolver } from "./TagResolver";

export class ParticipantTagResolver implements TagResolver {

    private getParticipants: () => TwilioUser[];

    constructor(getParticipants: () => TwilioUser[]) {
        this.getParticipants = getParticipants;
    }

    public readonly tagCharacter = "@";

    public async dispatchTagAction(tag: Tag): Promise<void> {
        // No-op for this?
    }

    public async getTagValues(startIdx: number, filterText?: string): Promise<Tag[]> {
        return this.getParticipants().filter((user: TwilioUser) => {
            return user.friendlyName?.toLowerCase().startsWith(filterText?.toLowerCase() || "");
        }).map((user: TwilioUser) => {
            return this.twilioUserToTag(user, startIdx);
        });
    }

    public onInputChange(filterText: string, startIdx: number, tagValuesListener?: (tagValues: Tag[]) => void): void {
        if (tagValuesListener) {
            this.getTagValues(startIdx, filterText).then((tags: Tag[]) => {
                tagValuesListener(tags);
            });
        }
    }

    private twilioUserToTag(user: TwilioUser, startIdx: number): Tag {
        const displayName: string = this.tagCharacter + user.friendlyName || user.identity;
        return {
            character: this.tagCharacter,
            data: {
                displayName: displayName,
                userId: user.identity,
            },
            pos: {
                startIdx: startIdx,
                endIdx: startIdx + displayName.length
            }
        }
    }
}