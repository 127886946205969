import React from "react";
import { InfoSignIcon, Tooltip } from "evergreen-ui";
import { baseLabelStyle } from "./FormComponentStyles";

interface Props {
    label: string;
    showToolTip?: boolean;
    description: string;
}

export const LabelledTooltip = ({label, showToolTip = true, description}: Props) => {
    return (
        <div style={baseLabelStyle}>
            {label}
            { showToolTip && <Tooltip content={description}>
                <InfoSignIcon style={{marginLeft: "4px"}} size={10}/>
            </Tooltip> }
        </div>
    );
}