import React from "react";
import { RampartAuthorized } from "../../rampart/RampartAuthorized";
import { UserStoreListPage } from "./UserStoreListPage";
import { AdminStoreTableView } from "./AdminStoreTableView";

export const StoreListPage = () => {
    return (
        <RampartAuthorized
            requiredGroups={["juniper.self-serve.admin"]}
            unauthorizedView={<UserStoreListPage />}
        >
            <AdminStoreTableView />
        </RampartAuthorized>
    );
}
