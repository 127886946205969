import { defaultTheme, DefaultTheme } from "evergreen-ui";

export enum ColorPalette {
    primaryAccent = "#33AA85",
    primaryAccentHover = "#21A079",
    primaryAccentDisabled = "#33AA856E",
    primaryBackgroundLight = "#2AAC8D0D",
    primaryText = "#5A5A5A",
    secondaryAccentHover = "#ACF7E2",
    disabledText = "#BFBFBF",
    white = "#FFFFFF",
    grey = "#E4E6EB",
    greyBackground = "#F6F6F6",
    lightGreyText = "#999999",
    black = "#000000",
    transparent = "#FFFFFF00",
    borderColor = "#E5E5E5",
    inputBorderColor = "#D8DAE5",
    statusBad = "#F66A6A",
    statusPending = "#F6BE6A",
    statusGood = "#33AA85",
    statusGoodBackground = "#F5FBF8",
    warningColor = "#F2B85C",
    neutralColor = "#999999",
    warningBackground = "#F2B85C1A",
    inputFieldInvalid = "#FAF0F0"
}

export const defaultFontFamily = 'Inter, sans-serif';
export const headerFontFamily = 'Lexend Deca, sans-serif'

export const theme: DefaultTheme = {
    ...defaultTheme,
    fontFamilies: {
        display: defaultFontFamily,
        ui: defaultFontFamily,
        mono: defaultFontFamily,
    },
    components: {
        ...defaultTheme.components,
        Button: {
            ...defaultTheme.components.Button,
            baseStyle: {
                ...defaultTheme.components.Button.baseStyle,
                padding: 12,
                borderRadius: 6,
                fontFamily: defaultFontFamily,
            },
            appearances: {
                primary: {
                    ...defaultTheme.components.Button.appearances.primary,
                    backgroundColor: ColorPalette.primaryAccent,
                    color: ColorPalette.white,
                    selectors: {
                        ...defaultTheme.components.Button.appearances.primary.selectors,
                        _hover: {
                            backgroundColor: ColorPalette.primaryAccentHover,
                        },
                        _active: {
                            backgroundColor: ColorPalette.primaryAccentHover,
                        },
                        _focus: {
                            backgroundColor: ColorPalette.primaryAccentHover,
                        },
                        _disabled: {
                            backgroundColor: ColorPalette.primaryAccentDisabled,
                        },
                    },
                },
                minimal: {
                    ...defaultTheme.components.Button.appearances.minimal,
                    backgroundColor: ColorPalette.transparent,
                    color: ColorPalette.primaryText
                },
                default: {
                    ...defaultTheme.components.Button.appearances.default
                },
                destructive: {
                    ...defaultTheme.components.Button.appearances.destructive
                }
            }
        },
        Label: {
            ...defaultTheme.components.Label,
            baseStyle: {
                ...defaultTheme.components.Label.baseStyle,
                color: ColorPalette.primaryText,
                fontSize: "12px !important",
                fontWeight: "400",
            },
            sizes: {
                100: { fontSize: 10 },
                200: { fontSize: 10 },
                300: { fontSize: 11 },
                400: { fontSize: 11 },
                500: { fontSize: 12 },
                600: { fontSize: 12 },
                700: { fontSize: 14 },
                800: { fontSize: 14 },
                900: { fontSize: 16 }
            }
        },
        Tab: {
            ...defaultTheme.components.Tab,
            baseStyle: {
                ...defaultTheme.components.Tab.baseStyle,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                marginRight: '20px',
                selectors: {
                    ...defaultTheme.components.Tab.baseStyle.selectors,
                    _focus: {
                        boxShadow: 'none',
                        outline: 'none',
                    },
                },
            },
            appearances: {
                ...defaultTheme.components.Tab.appearances,
                primary: {
                    ...defaultTheme.components.Tab.appearances.primary,
                    color: ColorPalette.primaryText,
                    backgroundColor: 'transparent',
                    selectors: {
                        ...defaultTheme.components.Tab.appearances.primary.selectors,
                        _hover: {
                            backgroundColor: 'transparent',
                        },
                        _focus: {
                            color: ColorPalette.primaryAccentHover,
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            outline: 'none',
                        },
                        _current: {
                            color: ColorPalette.primaryAccentHover,
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    },
                },
                secondary: {
                    ...defaultTheme.components.Tab.appearances.secondary,
                    backgroundColor: 'transparent',
                    selectors: {
                        _hover: {
                            backgroundColor: 'transparent',
                        },
                        _focus: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            outline: 'none',
                        },
                        _current: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    },
                }
            },
        },
        Text: {
            ...defaultTheme.components.Text,
            baseStyle: {}
        },
        Heading: {
            ...defaultTheme.components.Heading,
            baseStyle: {
                fontFamily: headerFontFamily,
            },
        },
        Radio: {
            ...defaultTheme.components.Radio,
            appearances: {
                ...defaultTheme.components.Radio.appearances.default,
                default: {
                    selectors: {
                        ...defaultTheme.components.Radio.appearances.default.selectors,
                        _checked: {
                            backgroundColor: ColorPalette.primaryAccent,
                            borderColor: ColorPalette.primaryAccent,
                            color: ColorPalette.white,
                        },
                        _hover: {
                            borderColor: ColorPalette.primaryAccentHover,
                        },
                        _checkedHover: {
                            backgroundColor: ColorPalette.primaryAccentHover,
                            borderColor: ColorPalette.primaryAccentHover,
                        },
                        _disabled: {
                            backgroundColor: ColorPalette.greyBackground,
                            borderColor: ColorPalette.inputBorderColor,
                            color: ColorPalette.disabledText,
                        },
                    },
                }
            },
        },
        Checkbox: {
            ...defaultTheme.components.Checkbox,
            appearances: {
                ...defaultTheme.components.Checkbox.appearances,
                default: {
                    ...defaultTheme.components.Checkbox.appearances.default,
                    selectors: {
                        ...defaultTheme.components.Checkbox.appearances.default.selectors,
                        _checked: {
                            backgroundColor: ColorPalette.primaryAccent,
                            color: ColorPalette.white,
                        },
                        _checkedHover: {
                            backgroundColor: ColorPalette.primaryAccentHover,
                            color: ColorPalette.white,
                        },
                        _checkedActive: {
                            backgroundColor: ColorPalette.primaryAccentHover,
                            color: ColorPalette.white,
                        },
                    }
                }
            }
        },
        TableCell: {
            ...defaultTheme.components.TableCell,
            appearances: {
                default: {
                    ...defaultTheme.components.TableCell.appearances.default,
                    selectors: {
                        ...defaultTheme.components.TableCell.appearances.default.selectors,
                        _focus: {
                            boxShadow: `inset 0 0 0 1px ${ColorPalette.primaryAccent}`
                        }
                    }
                }
            }
        }
    }
};
