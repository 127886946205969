import { User as TwilioUser } from "@twilio/conversations";
import { featureToggles } from "../../../../common/utils";
import { ParticipantTagResolver } from "./resolvers/ParticipantTagResolver";
import { TagResolver } from "./resolvers/TagResolver";
import { Tag } from "../../../../common/types/messaging/Tag";

export class ConversationTagManager {

    private supportedTags: TagResolver[];

    constructor(getParticipants: () => TwilioUser[]) {
        this.supportedTags = [
            new ParticipantTagResolver(getParticipants)
        ];
    }

    /**
     * Callback to fire every time the input text string changes in the conversation view
     * @param inputText
     *      the current text in the conversation input text box
     * @param cursorIdx - the index of the cursor within the inputText
     * @param tagValuesListener
     *      a listener defined by the conversation viewer to tell what tag values should
     *      be shown when a tag is detected.
     */
    public onInputChange(inputText: string, cursorIdx: {start: number, end: number},
                         tagValuesListener?: (tagValues: Tag[]) => void): void {
        if (!featureToggles.isFeatureEnabled("messageTagging")) {
            return;
        }

        // this is the selection case, and we don't do anything with it.
        if (cursorIdx.start !== cursorIdx.end) {
            return;
        }

        // Initially we only really care about the tag strings themselves for matching tags.
        const tagCharacters: string[] = this.supportedTags.map((tagResolver: TagResolver) => tagResolver.tagCharacter);

        // The TagResolver and the initial index of the tag string's token are all we're looking for.
        let currentTag: TagResolver | null = null;
        let currentTagStartIdx: number | null = null;

        // We'll start from the cursor position in all cases.
        let ptr = cursorIdx.start;

        // Let's move backwards checking each character until we're satisfied, or fall off the front.
        while (ptr >= 0 && currentTagStartIdx === null) {

            // If we find a tag that's either at the front of the text, or preceded by some form of whitespace, then we consider it a match ...
            if (tagCharacters.includes(inputText.charAt(ptr)) && (ptr === 0 || /\s/.test(inputText.charAt(ptr - 1)))) {

                // ... and save that position for later.
                currentTagStartIdx = ptr;
            }

            --ptr;
        }

        // If we didn't find anything, then it's just normal text and there's nothing to do - we can just dump out.
        if (currentTagStartIdx === null) {
            if (tagValuesListener) {
                tagValuesListener([]);
            }
            return;
        } else {

            // Otherwise, we figured out which tag we care about, so let's grab that as well.
            currentTag = this.supportedTags.find((tagResolver: TagResolver) => tagResolver.tagCharacter === inputText.charAt(currentTagStartIdx as number)) || null;
        }

        // If the above .find() call didn't find a matching TagResolver somehow, then something is broken, and we can dump out; the resolver wasn't found.
        if (!currentTag) {
            if (tagValuesListener) {
                tagValuesListener([]);
            }
            return;
        }

        // If we get this far, we should have a currentTag and a startIdx; plus we've verified that we have a tag token we want to do something with.
        let currentTagEndIdx = currentTagStartIdx;

        // We just need to figure out where the end of the current tag is, because it could be past the cursorIdx.
        while (currentTagEndIdx < cursorIdx.end && currentTagEndIdx < inputText.length) {
            ++currentTagEndIdx;
        }

        // If the cursor is past the end of this token, then the user isn't manipulating the tag anymore, and we can commit whatever tag was last observed.
        if (cursorIdx.start > currentTagEndIdx) {
            if (tagValuesListener) {
                tagValuesListener([]);
            }
            return;
        }

        // Last, we call the currentTag's resolver with the filterText token.
        if (cursorIdx.start !== currentTagStartIdx) {
            currentTag.onInputChange(inputText.slice(currentTagStartIdx + 1, currentTagEndIdx), currentTagStartIdx, tagValuesListener);
        }
    }

    /**
     * Callback to fire when a tag is actually committed by the user.
     * @param tag
     *      the details of the tag being committed.
     */
    public async onTagCommitted(tag: Tag): Promise<void> {
        if (!featureToggles.isFeatureEnabled("messageTagging")) {
            return;
        }

        const tagResolver: TagResolver | undefined = this.supportedTags.find((tagResolver: TagResolver) => tagResolver.tagCharacter === tag.character);
        if (tagResolver) {
            return tagResolver.dispatchTagAction(tag);
        }
    }

    /**
     * Returns all the possible tags
     */
    public async getAllPossibleTags(): Promise<Tag[]> {
        const tags: Tag[] = [];
        for (let i = 0; i < this.supportedTags.length; i++) {
            tags.push(...(await this.supportedTags[i].getTagValues(0)));
        }
        return tags;
    }
}